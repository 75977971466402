import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function RolePermission() {
  const modules = [
    {
      tag: "User Master",
      title: "User Master",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sahay Type Master",
      title: "Sahay Type Master",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sub Sahay Type Master",
      title: "Sub Sahay Type Master",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
  ];

  const checkUncheckAllType = (setFieldValue, permission, action, type) => {
    if (action === "add") {
      let newData = modules?.map((ele) => {
        return ele?.tag + type;
      });
      const updatedData = permission.concat(newData);
      setFieldValue("permission", updatedData);
    } else {
      const data = modules?.map((ele) => {
        return ele?.tag + type;
      });
      const removedArray = permission.filter((el) => !data.includes(el));
      setFieldValue("permission", removedArray);
    }
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    // setLoading(true);
    // setFieldValue("isSubmit", true);
    // if (state?.data) {
    //   let updateData = {
    //     role: values.role,
    //   };
    //   updateRollMaster(updateData);
    // } else {
    //   let addData = {
    //     role: values.role,
    //   };
    //   addRoleMaster(addData);
    // }
  };
  let initialValues = {
    role: "",
    isSubmit: false,
    permission: [],
  };
  return (
    <>
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex">
            <Index.Box className="title-main mb-10">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Role Permission
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row admin-rolelist-row">
          <Index.Box
            display="grid"
            className="display-row-userlist"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
                xl: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="role-card-main">
                <Index.Box className="role-total-user-flex">
                  <Index.Box className="role-totle-user-text">
                    Total 4 Users
                  </Index.Box>
                  <Index.Box className="role-user-img-flex">
                    <Index.AvatarGroup
                      total={10}
                      className="user-avtar-group-main"
                    >
                      <Index.Avatar
                        alt="Remy Sharp"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Travis Howard"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Agnes Walker"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Trevor Henderson"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                    </Index.AvatarGroup>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="role-user-name-box">
                  <Index.Box className="role-user-name">
                    Administrator
                  </Index.Box>
                </Index.Box>
                <Index.Box className="edit-role-flex">
                  <Index.Typography className="edit-role-text">
                    Edit Role
                  </Index.Typography>
                  <img src={PagesIndex.Svg.copy} className="edit-role-icon" />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
                xl: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="role-card-main">
                <Index.Box className="role-total-user-flex">
                  <Index.Box className="role-totle-user-text">
                    Total 4 Users
                  </Index.Box>
                  <Index.Box className="role-user-img-flex">
                    <Index.AvatarGroup
                      total={10}
                      className="user-avtar-group-main"
                    >
                      <Index.Avatar
                        alt="Remy Sharp"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Travis Howard"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Agnes Walker"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Trevor Henderson"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                    </Index.AvatarGroup>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="role-user-name-box">
                  <Index.Box className="role-user-name">Manager</Index.Box>
                </Index.Box>
                <Index.Box className="edit-role-flex">
                  <Index.Typography className="edit-role-text">
                    Edit Role
                  </Index.Typography>
                  <img src={PagesIndex.Svg.copy} className="edit-role-icon" />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
                xl: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="role-card-main">
                <Index.Box className="role-total-user-flex">
                  <Index.Box className="role-totle-user-text">
                    Total 4 Users
                  </Index.Box>
                  <Index.Box className="role-user-img-flex">
                    <Index.AvatarGroup
                      total={10}
                      className="user-avtar-group-main"
                    >
                      <Index.Avatar
                        alt="Remy Sharp"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Travis Howard"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Agnes Walker"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Trevor Henderson"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                    </Index.AvatarGroup>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="role-user-name-box">
                  <Index.Box className="role-user-name">Users</Index.Box>
                </Index.Box>
                <Index.Box className="edit-role-flex">
                  <Index.Typography className="edit-role-text">
                    Edit Role
                  </Index.Typography>
                  <img src={PagesIndex.Svg.copy} className="edit-role-icon" />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
                xl: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="role-card-main">
                <Index.Box className="role-total-user-flex">
                  <Index.Box className="role-totle-user-text">
                    Total 4 Users
                  </Index.Box>
                  <Index.Box className="role-user-img-flex">
                    <Index.AvatarGroup
                      total={10}
                      className="user-avtar-group-main"
                    >
                      <Index.Avatar
                        alt="Remy Sharp"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Travis Howard"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Agnes Walker"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Trevor Henderson"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                    </Index.AvatarGroup>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="role-user-name-box">
                  <Index.Box className="role-user-name">Support</Index.Box>
                </Index.Box>
                <Index.Box className="edit-role-flex">
                  <Index.Typography className="edit-role-text">
                    Edit Role
                  </Index.Typography>
                  <img src={PagesIndex.Svg.copy} className="edit-role-icon" />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
                xl: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="role-card-main">
                <Index.Box className="role-total-user-flex">
                  <Index.Box className="role-totle-user-text">
                    Total 4 Users
                  </Index.Box>
                  <Index.Box className="role-user-img-flex">
                    <Index.AvatarGroup
                      total={10}
                      className="user-avtar-group-main"
                    >
                      <Index.Avatar
                        alt="Remy Sharp"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Travis Howard"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Agnes Walker"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                      <Index.Avatar
                        alt="Trevor Henderson"
                        src={PagesIndex.Png.usericon}
                        className="role-user-imges"
                      />
                    </Index.AvatarGroup>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="role-user-name-box">
                  <Index.Box className="role-user-name">
                    Restricted User
                  </Index.Box>
                </Index.Box>
                <Index.Box className="edit-role-flex">
                  <Index.Typography className="edit-role-text">
                    Edit Role
                  </Index.Typography>
                  <img src={PagesIndex.Svg.copy} className="edit-role-icon" />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        {/*  */}

        <Index.Box className="role-list-check">
          <Index.Box sx={{ width: 1 }} className="grid-main add-role-row">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.validationSchemaRollMaster}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                errors,
                handleBlur,
                touched,
                // isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box
                    display="grid"
                    className="display-row-add-user"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    {modules?.map((row, index) => (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                          xl: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="role-card-main">
                          <Index.Box className="role-card-inner-flex">
                            <Index.Box className="role-titles-main">
                              <Index.Typography className="role-titles">
                                {row?.title}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="roles-data-main-flex">
                              <Index.Box className="roles-data-box">
                                <Index.Box className="add-role-flex">
                                  {/* <Index.Checkbox
                                    name="delete"
                                    className="check-box-role"
                                  /> */}
                                  <Index.Checkbox
                                    className="check-box-role"
                                    name="permission"
                                    // onChange={handleChange}
                                    disabled={row?.isView === "false"}
                                    checked={
                                      row?.isView === "true"
                                        ? values?.permission?.includes(
                                            row?.tag + "_view"
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (
                                        values?.permission?.includes(
                                          row?.tag + "_view"
                                        )
                                      ) {
                                        const newData =
                                          values?.permission?.filter(function (
                                            item
                                          ) {
                                            return item !== row?.tag + "_view";
                                          });
                                        setFieldValue("permission", newData);
                                      } else {
                                        const newData = [...values?.permission];
                                        newData.push(row?.tag + "_view");
                                        setFieldValue("permission", newData);
                                      }
                                    }}
                                  />
                                  <Index.Box className="role-data-title">
                                    Read
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="roles-data-box">
                                <Index.Box className="add-role-flex">
                                  {/* <Index.Checkbox
                                    name="delete"
                                    className="check-box-role"
                                  /> */}
                                  <Index.Checkbox
                                    className="check-box-role"
                                    name="permission"
                                    disabled={row?.isAdd === "false"}
                                    checked={
                                      row?.isAdd === "true"
                                        ? values?.permission?.includes(
                                            row?.tag + "_add"
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (
                                        values?.permission?.includes(
                                          row?.tag + "_add"
                                        )
                                      ) {
                                        const newData =
                                          values?.permission?.filter(function (
                                            item
                                          ) {
                                            return item !== row?.tag + "_add";
                                          });
                                        setFieldValue("permission", newData);
                                      } else {
                                        const newData = [...values?.permission];
                                        newData.push(row?.tag + "_add");
                                        setFieldValue("permission", newData);
                                      }
                                    }}
                                  />
                                  <Index.Box className="role-data-title">
                                    Create
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="roles-data-box">
                                <Index.Box className="add-role-flex">
                                  {/* <Index.Checkbox
                                    name="delete"
                                    className="check-box-role"
                                  /> */}
                                  <Index.Checkbox
                                    className="check-box-role"
                                    name="permission"
                                    disabled={row?.isEdit === "false"}
                                    checked={
                                      row?.isEdit === "true"
                                        ? values?.permission?.includes(
                                            row?.tag + "_edit"
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (
                                        values?.permission?.includes(
                                          row?.tag + "_edit"
                                        )
                                      ) {
                                        const newData =
                                          values?.permission?.filter(function (
                                            item
                                          ) {
                                            return item !== row?.tag + "_edit";
                                          });
                                        setFieldValue("permission", newData);
                                      } else {
                                        const newData = [...values?.permission];
                                        newData.push(row?.tag + "_edit");
                                        setFieldValue("permission", newData);
                                      }
                                    }}
                                  />
                                  <Index.Box className="role-data-title">
                                    Update
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="roles-data-box">
                                <Index.Box className="add-role-flex">
                                  {/* <Index.Checkbox
                                    name="delete"
                                    className="check-box-role"
                                  /> */}

                                  <Index.Checkbox
                                    className="check-box-role"
                                    name="permission"
                                    disabled={row?.isDelete === "false"}
                                    checked={
                                      row?.isDelete === "true"
                                        ? values?.permission?.includes(
                                            row?.tag + "_delete"
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (
                                        values?.permission?.includes(
                                          row?.tag + "_delete"
                                        )
                                      ) {
                                        const newData =
                                          values?.permission?.filter(function (
                                            item
                                          ) {
                                            return (
                                              item !== row?.tag + "_delete"
                                            );
                                          });
                                        setFieldValue("permission", newData);
                                      } else {
                                        const newData = [...values?.permission];
                                        newData.push(row?.tag + "_delete");
                                        setFieldValue("permission", newData);
                                      }
                                    }}
                                  />
                                  <Index.Box className="role-data-title">
                                    Delete
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ))}
                    {/* <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Box className="common-button blue-button flex-start save-btn mt-btn-spacing">
                        <Index.Button variant="contained" type="submit">
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid> */}

                    {/* <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                        xl: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="role-card-main">
                        <Index.Box className="role-card-inner-flex">
                          <Index.Box className="role-titles-main">
                            <Index.Typography className="role-titles">
                              Sahay
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="roles-data-main-flex">
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Read
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Create
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Update
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Delete
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                        xl: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="role-card-main">
                        <Index.Box className="role-card-inner-flex">
                          <Index.Box className="role-titles-main">
                            <Index.Typography className="role-titles">
                              Sub sahay
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="roles-data-main-flex">
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Read
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Create
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Update
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="roles-data-box">
                              <Index.Box className="add-role-flex">
                                <Index.Checkbox
                                  name="delete"
                                  className="check-box-role"
                                />
                                <Index.Box className="role-data-title">
                                  Delete
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                        xl: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="save-btn-main border-btn-main add-game-btn">
                          <Index.Button className="save-user-btn border-btn">
                            Submit
                          </Index.Button>
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                          >
                            Cancel
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
