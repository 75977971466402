import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import PagesIndex from "../../../PagesIndex";
import { useEffect, useState } from "react";
import {
  getAdminProfile,
  updateAdminProfile,
} from "../../../../redux/features/AddService";
import { imagePath } from "../../../../config/DataService";

const Item = styled(Index.Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
}));

function Profile() {
  const { adminData, adminProfileGet } = PagesIndex.useSelector(
    (state) => state.admin
  );

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  //disable button
  const [isDisabled, setIsDisabled] = useState(false);

  //all state
  // state for image upload
  const [image, setImage] = useState("");

  //initialValues
  let initialValues ;
  if(adminData?.roleName == "admin"){
    initialValues = {
      userName: adminProfileGet ? adminProfileGet?.name : "",
      fname: adminProfileGet ? adminProfileGet?.fname : "",
      lname: adminProfileGet ? adminProfileGet?.lname : "",
      email: adminProfileGet ? adminProfileGet?.email : "",
      mobileno: adminProfileGet ? adminProfileGet?.mobileno : "",
      address: adminProfileGet ? adminProfileGet?.address : "",
      image: adminProfileGet ? adminProfileGet?.image : "",
    };
  }else if(adminData?.roleName == "staff") {
    initialValues = {
      userName: adminProfileGet ? adminProfileGet?.name : "",
      email: adminProfileGet ? adminProfileGet?.email : "",
      mobileno: adminProfileGet ? adminProfileGet?.mobileno : "",
      image: adminProfileGet ? adminProfileGet?.image : "",
    }
  }else{
    initialValues = {
      userName: adminProfileGet ? adminProfileGet?.name : "",
      email: adminProfileGet ? adminProfileGet?.email : "",
      mobileno: adminProfileGet ? adminProfileGet?.mobileno : "",
      image: adminProfileGet ? adminProfileGet?.image : "",
    }
  }
  useEffect(() => {
    dispatch(getAdminProfile());
  }, []);

  // handle submit
  const handleFormSubmit = (values) => {
    const formData = new FormData();
    if (rollData === "admin") {
      if (adminData.id) {
        formData.append("id", adminData.id);
      }

      formData.append("fname", values?.fname);
      formData.append("lname", values?.lname);
      formData.append("email", values?.email);
      formData.append("mobileno", values?.mobileno);
      formData.append("address", values?.address);
      formData.append("image", image ? image : values?.image);
    } else {
      if (adminData.id) {
        formData.append("id", adminData.id);
      }
      formData.append("userName", values?.userName);
      formData.append("email", values?.email);
      formData.append("mobileno", values?.mobileno);
      formData.append("image", image ? image : values?.image);
    }
   
    setIsDisabled(true);
    dispatch(updateAdminProfile(formData)).then((res) => {
      setIsDisabled(false);
      if (res.payload === 200) {

        if (rollData === 'admin') {
          navigate(`/${rollData}/dashboard`);
          dispatch(getAdminProfile(adminData.id));
        }
        if (rollData === 'staff') {
          navigate(`/${rollData}/sahay-application`);
          dispatch(getAdminProfile(adminData.id));
        }
        if (rollData === 'Committee2') {
           navigate(`/${rollData}/sahay-application`);
          dispatch(getAdminProfile(adminData.id));
        }
      }
    });
  };

  return (
    <Index.Box className="p-15 background-ed profile-content flex-center  res-profile-mobile ">
      <Index.Box className=" h-100">
        <Index.Box className="card-center">
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
           validationSchema={PagesIndex.validationSchemaUpdateAdminProfile(adminData?.roleName)}
           
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              handleBlur,
              errors,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="card-main profile-card-main">
                  <Index.Box className="title-main mb-15 text-center">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Update Profile
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="profile-image text-center mb-20 user-profile-details">
                    {image ? (
                      <>
                        <img
                          src={URL.createObjectURL(image)}
                          alt="Image Preview"
                          className="profile-img mb-15"
                        />
                      </>
                    ) : (
                      <>
                        {adminProfileGet?.image ? (
                          <img
                            src={`${imagePath}${adminProfileGet?.image}`}
                            className="profile-img mb-15"
                          />
                        ) : (
                          <img
                            src={PagesIndex.Png.usericon}
                            className="profile-img mb-15"
                          />
                        )}
                      </>
                    )}
                    <Index.Box className="flex-center">
                      <Index.Box className="common-button grey-button change-profile user-edit-btn-details">
                        <Index.Button
                          variant="contained"
                          type="button"
                          className=""
                        >
                          <img
                            src={PagesIndex.Svg.pencil}
                            className="edit-profile-user"
                          />
                        </Index.Button>
                        <input
                          type="file"
                          className="change-profile-input"
                          accept="image/*"
                          name="image"
                          onChange={(event) => {
                            setImage(event.currentTarget.files[0]);
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="profile-inner">
                    <Index.Box className="">
                      <Index.Grid
                        container
                        columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                      >
                        {rollData === "admin" ? (
                          <>
                            <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="change-input-label"
                                  >
                                    First Name
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      name="fname"
                                      value={values.fname}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 21) {
                                          setFieldValue("fname", inputValue);
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      error={
                                        touched.fname && Boolean(errors.fname)
                                      }
                                      helperText={touched.fname && errors.fname}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    Last Name
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      name="lname"
                                      value={values.lname}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 21) {
                                          setFieldValue("lname", inputValue);
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      error={
                                        touched.lname && Boolean(errors.lname)
                                      }
                                      helperText={touched.lname && errors.lname}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid>
                          </>
                        ) : (
                          <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                            <Item className="dashboard-item">
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  User Name
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border mb-15">
                                  <Index.TextField
                                    name="userName"
                                    value={values.userName}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (inputValue.length <= 21) {
                                        setFieldValue("userName", inputValue);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder=""
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    error={
                                      touched.userName &&
                                      Boolean(errors.userName)
                                    }
                                    helperText={
                                      touched.userName && errors.userName
                                    }
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Item>
                          </Index.Grid>
                        )}
                        <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                          <Item className="dashboard-item">
                            <Index.Box>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Email
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border mb-15">
                                <Index.TextField
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder=""
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  name="email"
                                  error={touched.email && Boolean(errors.email)}
                                  helperText={touched.email && errors.email}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                          <Item className="dashboard-item">
                            <Index.Box>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Mobile Number
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border mb-15">
                                <Index.TextField
                                  value={values.mobileno}
                                  onChange={(e) => {
                                    !isNaN(e.target.value) && handleChange(e);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 10,
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder=""
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  name="mobileno"
                                  error={
                                    touched.mobileno && Boolean(errors.mobileno)
                                  }
                                  helperText={
                                    touched.mobileno && errors.mobileno
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Grid>
                        {rollData === "admin" ? (
                          <Index.Grid item xs={12}>
                            <Index.Box className="set-text-area mb-20">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Address
                              </Index.Typography>
                              <Index.Box className="set-textarea-box-top">
                                <Index.TextareaAutosize
                                  className="set-textarea-box textarea-details-user"
                                  aria-label="empty textarea"
                                  placeholder=""
                                  multiline
                                  name="address"
                                  type="text"
                                  onBlur={handleBlur}
                                  value={values.address}
                                  onChange={handleChange}
                                />
                              </Index.Box>
                              {errors?.address && touched.address && (
                                <p className="error-text">{errors?.address}</p>
                              )}
                            </Index.Box>
                          </Index.Grid>
                        ) : (
                          ""
                        )}
                      </Index.Grid>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                    <Index.Button
                      variant="contained"
                      // disableRipple
                      type="submit"
                      disabled={isDisabled}
                    >
                      Update Profile
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default Profile;
