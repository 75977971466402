export const sahayDocumentArray = [
  {
    subSahayName: "Arthik Sahay",
    documentData: [
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Rental Sahay",
    documentData: [
      {
        documentType: "Bhada chiti",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Senior Citizen Sahay",
    documentData: [
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Mahila Utkarsh Sahay",
    documentData: [
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Widow Sahay",
    documentData: [
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Jeevan Nirvah Sahay",
    documentData: [
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Viklang Utkarsh Sahay",
    documentData: [
      {
        documentType: "Handicap Certificate",
        file:[],
        isUpload: false,
      },
      {
        documentType: "School fee/Books/Uniform Orginal bill",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Medical bills/Prescription/Hospital bill",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Skill Development Sahay",
    documentData: [
      {
        documentType: "Repayment Applicable",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Mediclaim Premium Sahay",
    documentData: [
      {
        documentType: "Policy /Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Medicine Sahay",
    documentData: [
      {
        documentType: "Doctor Prescription",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Medicine Bill",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Medical Sahay",
    documentData: [
      {
        documentType: "Policy /Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Doctor Prescription",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Medicine Bill",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Hospital bill/ Report",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Hospital Discharge card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Heart Relief",
    documentData: [
      {
        documentType: "Policy /Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Doctor Prescription",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Medicine Bill",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Hospital bill/ Report",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Hospital Discharge card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Education Relief Form Jr kg Sr kg",
    documentData: [
      {
        documentType: "Last Mark Sheet",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Fee Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Education Relief Form New 1 to 4",
    documentData: [
      {
        documentType: "Last Mark Sheet",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Fee Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType:
          "School /College Bonafide Certificate with Fees Structure",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Education Relief Form 5 to 15",
    documentData: [
      {
        documentType: "Last Mark Sheet",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Fee Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType:
          "School /College Bonafide Certificate with Fees Structure",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Education Relief Form Further studies Post Graduation",
    documentData: [
      {
        documentType: "Last Mark Sheet",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Fee Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType:
          "College or Institute Bonafide Certificate with Fees Structure",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Education Relief Form Reloving Loan",
    documentData: [
      {
        documentType: "Repayment Applicable",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Last Mark Sheet",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType:
          "School /College Bonafide Certificate with Fees Structure",
        file:[],
        isUpload: false,
      },
      {
        documentType:
          "College or Institute Bonafide Certificate with Fees Structure",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
  {
    subSahayName: "Tutorial Releief Form New SSC & HSC",
    documentData: [
      {
        documentType: "Last Mark Sheet",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Cheque",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Fee Receipt",
        file:[],
        isUpload: false,
      },
      {
        documentType:
          "Tutorial Class / Institute Bonafide Certificate with Fees Structure",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Aadhar Card",
        file:[],
        isUpload: false,
      },
      {
        documentType: "Elecrticity Bill",
        file:[],
        isUpload: false,
      },
    ],
  },
];

// export const sahayDocumentArray = [
//     {
//       subSahayName: "Arthik Sahay",
//       documentData: [
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Rental Sahay",
//       documentData: [
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Bhada chiti",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Senior Citizen Sahay",
//       documentData: [
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Mahila Utkarsh Sahay",
//       documentData: [
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Widow Sahay",
//       documentData: [
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Jeevan Nirvah Sahay",
//       documentData: [
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Viklang Utkarsh Sahay",
//       documentData: [
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Handicap Certificate",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "School fee/Books/Uniform Orginal bill",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Medical bills/Prescription/Hospital bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Skill Development Sahay",
//       documentData: [
//         {
//           documentType: "Repayment Applicable",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Aadhar",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Mediclaim Premium Sahay",
//       documentData: [
//         {
//           documentType: "Policy /Receipt",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Light Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Medicine Sahay",
//       documentData: [
//         {
//           documentType: "Doctor Prescription",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Medicine Bill",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Medical Sahay",
//       documentData: [
//         {
//           documentType: "Policy /Receipt",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Doctor Prescription",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Medicine Bill",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Hospital bill/ Report",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Hospital Discharge card",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Heart Relief",
//       documentData: [
//         {
//           documentType: "Policy /Receipt",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Doctor Prescription",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Medicine Bill",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Hospital bill/ Report",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Hospital Discharge card",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Education Relief Form Jr kg Sr kg",
//       documentData: [
//         {
//           documentType: "Last Mark Sheet",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Fee Receipt",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Education Relief Form New 1 to 4",
//       documentData: [
//         {
//           documentType: "Last Mark Sheet",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Fee Receipt",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType:
//             "School /College Bonafide Certificate with Fees Structure",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Education Relief Form 5 to 15",
//       documentData: [
//         {
//           documentType: "Last Mark Sheet",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Fee Receipt",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType:
//             "School /College Bonafide Certificate with Fees Structure",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Education Relief Form Further studies Post Graduation",
//       documentData: [
//         {
//           documentType: "Last Mark Sheet",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Fee Receipt",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType:
//             "College or Institute Bonafide Certificate with Fees Structure",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Education Relief Form Reloving Loan",
//       documentData: [
//         {
//           documentType: "Repayment Applicable",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Last Mark Sheet",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType:
//             "School /College Bonafide Certificate with Fees Structure",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType:
//             "College or Institute Bonafide Certificate with Fees Structure",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },
//     {
//       subSahayName: "Tutorial Releief Form New SSC & HSC",
//       documentData: [
//         {
//           documentType: "Last Mark Sheet",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Cheque",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType: "Fee Receipt",
//           file:[],
//           isUpload: false,
//         },
//         {
//           documentType:
//             "Tutorial Class / Institute Bonafide Certificate with Fees Structure",
//           file:[],
//           isUpload: false,
//         },
//       ],
//     },

// ];
