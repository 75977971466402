import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./usermaster.css";
import {
  updateAdminUserMaster,
  addAdminUserMaster,
  getRoleMaster,
  getSubSahaylist,
} from "../../../../redux/features/AddService";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

export default function UserMasterAdd() {
  //useselector
  const { getRoleMasterData, getSubSahaydata } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  //all state

  const [showPassword, setShowPassword] = useState(false);
  const [subSahayId, setSubSahayId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rollId, setRollId] = useState();
  const [userRoleListData, setUserRoleListData] = useState([]);
  const [confirmshowPassword, setConfirmShowPassword] = React.useState(false);
  const [selectCommittee, setSelectCommittee] = useState("");
  const [loader, setLoader] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(null);
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();

  const dispatch = PagesIndex.useDispatch();

  let initialValues = {
    email: state?.data ? state?.data?.email : "",
    password: "",
    confirmPassword: "",
    name: state?.data ? state?.data?.name : "",
    mobileno: state?.data ? state?.data?.mobileno : "",
    role: rollId ? rollId : "",
    sahayId: subSahayId ? subSahayId : [],
    isSubmit: false,
  };

  const handleClickConfirmShowPassword = () =>
    setConfirmShowPassword((show) => !show);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const getSingleUserMaster = async () => {
    setLoading(true);
    try {
      const res = await DataService.post(
        `${Api.ADMIN_GET_SINGLE_USER_MASTER}/${state?.data?.id}`
      );
      if (res?.data?.status == 200) {
        setRollId(res?.data?.data?.userDetails?.role);
        setSubSahayId(res?.data?.data?.subsahayid);
      }
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    if (state?.edit) {
      getSingleUserMaster();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    dispatch(getSubSahaylist());
    setLoading(true);
    dispatch(getRoleMaster()).then((res) => {
      setUserRoleListData(res?.payload?.data);
      setLoading(false);
    });
  }, []);
  const handleBackButton = () => {
    navigate("/admin/user-master");
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    if (state?.edit) {
      let updateData = {
        id: state?.data?.id,
        name: values?.name,
        email: values?.email,

        mobileno: values?.mobileno,
        role: values?.role,

        sahayId: values?.sahayId?.filter((item) => item !== null),
      };

      dispatch(updateAdminUserMaster(updateData)).then((res) => {
        if (res?.payload == 200) {
          navigate("/admin/user-master");
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    } else {
      let addData = {
        name: values?.name,
        email: values?.email,
        password: values?.password,
        confirmPassword: values?.confirmPassword,
        mobileno: values?.mobileno,
        role: values?.role,

        sahayId: values?.sahayId ? values?.sahayId : [],
      };

      dispatch(addAdminUserMaster(addData)).then((res) => {
        if (res?.payload == 201) {
          navigate("/admin/user-master");
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    }
  };
  const handleMenuOpen = () => {
    setLoader(true);

    setLoadingTimeout(
      userRoleListData &&
        setTimeout(() => {
          setLoader(false);
        }, 1000)
    );
  };
  const handleMenuClose = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const rollPermissionNameData =
  rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("User Master_add") ||
    rollPermissionData?.permissionArray?.includes("User Master_edit")|| rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {state ? state?.edit : "Add"} User Master
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {!loading ? (
            <>
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={
                  state?.data
                    ? PagesIndex.validationSchemaUserMasterUpdate
                    : PagesIndex.validationSchemaUserMasterAdd
                }
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="user-master-add-page-list">
                      <Index.Grid container spacing={3}>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Email{" "}
                            <span
                              className="required-field"
                              style={{ color: "red" }}
                            >
                              {" "}
                              *{" "}
                            </span>
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              id="filled-hidden-label-normal"
                              placeholder="Email"
                              variant="filled"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="email"
                              value={values?.email}
                            />
                            {errors?.email && touched.email && (
                              <p className="error-text">{errors?.email}</p>
                            )}
                          </Index.Box>
                        </Index.Grid>
                        {!state?.data ? (
                          <>
                            {" "}
                            <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Password{" "}
                                <span
                                  className="required-field"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  *{" "}
                                </span>
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border password-border-list">
                                <Index.TextField
                                  hiddenLabel
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  placeholder="Password"
                                  className="admin-input-design input-placeholder "
                                  name="password"
                                  value={values?.password}
                                  type={showPassword ? "text" : "password"}
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props  ",
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props ",
                                  }}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {!showPassword ? (
                                            <Index.VisibilityOff />
                                          ) : (
                                            <Index.Visibility />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                />
                              </Index.Box>
                              {errors?.password && touched.password && (
                                <p className="error-text">{errors?.password}</p>
                              )}
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Confirm Password{" "}
                                <span
                                  className="required-field"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  *{" "}
                                </span>
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border password-border-list">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.confirmPassword}
                                  placeholder="Confirm Password"
                                  className="admin-input-design input-placeholder "
                                  name="confirmPassword"
                                  type={
                                    confirmshowPassword ? "text" : "password"
                                  }
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props  ",
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props ",
                                  }}
                                  // sx={{ mb: 3 }}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickConfirmShowPassword
                                          }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {!confirmshowPassword ? (
                                            <Index.VisibilityOff />
                                          ) : (
                                            <Index.Visibility />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                />
                              </Index.Box>
                              {errors?.confirmPassword &&
                                touched.confirmPassword && (
                                  <p className="error-text">
                                    {errors?.confirmPassword}
                                  </p>
                                )}
                            </Index.Grid>
                          </>
                        ) : null}
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            User Name{" "}
                            <span
                              className="required-field"
                              style={{ color: "red" }}
                            >
                              {" "}
                              *{" "}
                            </span>
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 25) {
                                  setFieldValue("name", inputValue);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values?.name}
                              id="filled-hidden-label-normal"
                              placeholder="User Name"
                              variant="filled"
                              type="text"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="name"
                            />
                          </Index.Box>
                          {errors?.name && touched.name && (
                            <p className="error-text">{errors?.name}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Mobile No{" "}
                            <span
                              className="required-field"
                              style={{ color: "red" }}
                            >
                              {" "}
                              *{" "}
                            </span>
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              onChange={(e) => {
                                setFieldValue(
                                  "mobileno",
                                  e.target.value?.slice(0, 10)
                                );
                              }}
                              onBlur={handleBlur}
                              value={values?.mobileno}
                              id="filled-hidden-label-normal"
                              placeholder="Mobile No"
                              variant="filled"
                              type="number"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="mobileno"
                            />
                          </Index.Box>
                          {errors?.mobileno && touched.mobileno && (
                            <p className="error-text">{errors?.mobileno}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            User Role{" "}
                            <span
                              className="required-field"
                              style={{ color: "red" }}
                            >
                              {" "}
                              *{" "}
                            </span>
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="role"
                              id="hgh"
                              value={values?.role}
                              onOpen={handleMenuOpen}
                              onClose={handleMenuClose}
                              onChange={(e) => {
                                setFieldValue("role", e.target.value);
                              }}
                            >
                              {loader ? (
                                <Index.Box className="loader-container">
                                  <CircularProgress className="small-circular-progress" />
                                </Index.Box>
                              ) : (
                                userRoleListData?.map((data) => (
                                  <Index.MenuItem
                                    key={data?.roleId}
                                    value={data?.roleId}
                                  >
                                    {data?.roleName}
                                  </Index.MenuItem>
                                ))
                              )}
                            </Index.Select>
                          </Index.Box>
                          {errors?.role && touched.role && (
                            <p className="error-text">{errors?.role}</p>
                          )}
                        </Index.Grid>

                        {getRoleMasterData?.data?.filter((data) => {
                          if (data?.roleId == values?.role) {
                            setSelectCommittee(data);
                            return data;
                          }
                          // })?.[0]?.roleName === "Committee" ? (
                        })?.[0]?.roleParentName == "committee" ? (
                          <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Sub Sahay
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.FormControl className="form-multiple-dropdowns">
                                <Index.Select
                                  name="sahayId"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={values?.sahayId || []}
                                  className="select-multiple-dropdowns"
                                  onChange={(e) => {
                                    setFieldValue("sahayId", e.target.value);
                                  }}
                                  renderValue={(selected) =>
                                    selected
                                      .map(
                                        (id) =>
                                          getSubSahaydata?.find(
                                            (item) => item?.Id === id
                                          )?.sahayName
                                      )
                                      .join(", ")
                                  }
                                >
                                  {getSubSahaydata?.map((name) => (
                                    <Index.MenuItem
                                      className="select-menu-dropdowns"
                                      key={name?.Id}
                                      value={name?.Id}
                                    >
                                      <Index.Checkbox
                                        checked={
                                          values?.sahayId?.indexOf(name?.Id) >
                                          -1
                                        }
                                        className="select-menu-checkbox"
                                      />
                                      <Index.ListItemText
                                        primary={name?.sahayName}
                                        className="select-menu-text-details"
                                      />
                                    </Index.MenuItem>
                                  ))}
                                </Index.Select>
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Grid>
                        ) : null}
                      </Index.Grid>
                      <Index.Box className="line" mt={3} mb={3}></Index.Box>
                      <Index.Box className="common-button blue-button flex-start save-btn res-set-search text-end-details">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disabled={values?.isSubmit}
                        >
                          Submit
                        </Index.Button>

                        <Index.Button
                          variant="contained"
                          onClick={handleBackButton}
                          className="back_button"
                        >
                          Back
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </>
          ) : (
            <>
              <Index.Box className="circular-loading-user-master">
                <PagesIndex.PageLoading colSpan={12} />
              </Index.Box>
            </>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
}
