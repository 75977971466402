import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import DataService, { imagePath } from "../../../../../config/DataService";
import {
  getSingleSahayApplication,
  updatePreviousData,
} from "../../../../../redux/features/AddService";
import { Api } from "../../../../../config/Api";
import { toast } from "react-toastify";
import PageLoading from "../../../../../components/common/pageLoading/PageLoading";

const PreviousSahayApplicationView = () => {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [getViewData, setGetViewData] = useState();
  const location = PagesIndex.useLocation();
  const editPreviousData = location?.state;
  const initialValues = {};
  const [loader, setLoader] = useState(true);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const handleBack = () => {
    navigate(`/${rollData}/sahay-application`, {
      state: { rowPreviousId: editPreviousData?.id, tabType: "previous" },
    });
  };

  // const getSingleSahayApplication = async () => {
  //   try {
  //     const response = await DataService.post(
  //       `${Api.GET_SINGLE_SAHAY_APPLICATION}/${state.applicationId}`
  //     );
  //     setGetViewData(response.data.data);
  //   } catch (error) {
  //     toast.error(error.response.data.message || error.message);
  //   }
  // };

  useEffect(() => {

    dispatch(updatePreviousData(editPreviousData?.applicationId)).then((data) => {
      if (data?.payload?.status) {
        setLoader(false);
        setGetViewData(data?.payload?.data);
      }
    });
  }, [editPreviousData?.id]);
  // useEffect(() => {
  //   getSingleSahayApplication();
  // }, []);
  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <Index.Box className="page-content-box">
          <Index.Box className="barge-common-box">
            <Index.Box>
              <Index.Box className="title-header-flex-button">
                <Index.Box className="common-button blue-button flex-start save-btn res-set-search">
                  <Index.Button
                    variant="contained"
                    onClick={() => handleBack()}
                  >
                    Back
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex">
                <Index.Box className="title-main mb-10">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    View Previous Sahay Application
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <PagesIndex.Formik initialValues={initialValues} enableReinitialize>
              {({ values }) => (
                <form>
                  <Index.Box className="">
                    <Index.Grid container spacing={3}>
                      {/* <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label "
                      >
                        Image
                      </Index.Typography>
                      <Index.Box className=" mb-5 mt-5">
                        {getViewData?.sahayApplication?.image && (
                          <img
                            style={{ width: "120px", height: "120" }}
                            src={`${imagePath}${getViewData?.sahayApplication?.image}`}
                            className="  mb-5 mt-5"
                          />
                        )}
                      </Index.Box>
                    </Index.Grid> */}
                      {getViewData?.documents ? (
                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex">
                              <Index.Box className="title-main ">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Documents
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Grid item xs={8}>
                            <Index.Box className="select-view-document-name">
                              {getViewData?.documents?.map((file, index) => (
                                <Index.Box
                                  className="document-name"
                                  key={index}
                                >
                                  <Index.Typography
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // Implement the logic to view the document here
                                      // You can use a modal or a new browser tab/window to show the document
                                      window.open(
                                        `${imagePath}${file.filename}`
                                      );
                                    }}
                                  >
                                    {file.document}
                                  </Index.Typography>
                                  {/* <Index.IconButton
                                onClick={() => {
                                  const updatedDocuments =
                                    values.documents.filter(
                                      (_, i) => i !== index
                                    );
                                  // setFieldValue("documents", updatedDocuments);
                                }}
                              >
                                <Index.ClearIcon className="muiico-cancel" />
                              </Index.IconButton> */}
                                </Index.Box>
                              ))}
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      ) : (
                        ""
                      )}
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Box className="title-header">
                          <Index.Box className="title-header-flex">
                            <Index.Box className="title-main ">
                              <Index.Typography
                                variant="p"
                                component="p"
                                className="page-title"
                              >
                                Basic Details
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Family ID
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="familyId"
                            disabled
                            value={getViewData?.sahayApplication?.familyId || "-"}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Name Of Applicant
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="familyId"
                            disabled
                            value={`${getViewData?.sahayApplication?.mem_fname || "-"} ${getViewData?.sahayApplication?.mem_mname || "-"} ${getViewData?.sahayApplication?.mem_lname || "-"}`}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Sahay Type
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="Addharcard No."
                            disabled
                            value={getViewData?.sahayApplication?.SahayTypeName || "-"}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Sub Sahay Type
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="Sahay Category"
                            disabled
                            value={
                              getViewData?.sahayApplication?.subSahayTypeName || "-"
                            }
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Year Of Sahay
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="Sahay Category"
                            disabled
                            value={`${getViewData?.sahayApplication?.toYear || "-"}-${getViewData?.sahayApplication?.yearFrom || "-"}`}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          {/* Total Claimed Sahay Amount */}
                          {/* Total Requested Sahay Amount */}
                          Approved Amount
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="Addharcard No."
                            disabled
                            value={`${getViewData?.sahayApplication?.SahayAmmount || "-"}`}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Repay Details
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="Addharcard No."
                            disabled
                            // value={`${getViewData?.sahayApplication?.repayDetails || "-"}`}
                            value={`${getViewData?.sahayApplication?.RepayDetails || "-"}`}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Start Date
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="Addharcard No."
                            disabled
                            value={getViewData?.sahayApplication?.startDate ? new Date(
                              getViewData?.sahayApplication?.startDate 
                            )?.toLocaleDateString("en-GB") : "-"}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          No Of Month
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="Addharcard No."
                            disabled
                            value={`${getViewData?.sahayApplication?.totalMonth || "-"}`}
                          />
                        </Index.Box>
                      </Index.Grid>

                      {/* table */}
                    </Index.Grid>

                    <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default PreviousSahayApplicationView;
