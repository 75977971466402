import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import { getSahayApplicationList } from "../../../../redux/features/AddService";
import PagesIndex from "../../../PagesIndex";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#eaac35",
    color: "#fffff",
    fontSize: "11px",
    fontFamily: "inter-semibold",
    padding: "3px 10px",
    lineHeight: "15px",
    borderRadius: "10px",
    textTransform: "capitalize",
  },
}));

//

const Listing = () => {
  // useSelectors
  const { getUserLoginDetails } = PagesIndex.useSelector(
    (state) => state.admin
  );

  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  // All States

  const [applicationListData, setApplicationListData] = useState();
  const [pageData, setPageData] = useState();
  const [rowPerPage, setRowPerPage] = useState("25");
  console.log("line39", pageData);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  //get userid
  const userId = getUserLoginDetails ? getUserLoginDetails?.mem_family_id : 0;
  const handleEdit = (row) => {
    navigate("/user/user-listing-edit", {
      state: { row: row, editSahay: "edit" },
    });
  };

  //view list
  const handleSahayApplicationView = (row) => {
    navigate("/user/user-listing-view", { state: row });
  };
  useEffect(() => {
    dispatch(getSahayApplicationList()).then((data) => {
      console.log("line54", data?.payload);
      if (data?.payload) {
        let filterdData = data?.payload?.filter((data) => {
          // return data.mem_family_id == userId;
          console.log(data,"Test datatata==");
          
          return data.familyId == userId || data.mem_family_id == userId ;
        });

        setApplicationListData(filterdData);
        setLoading(false);
      }
    });
  }, [userId]);


  console.log(userId,"userId+===")
  console.log(applicationListData, "applicationListData");
  //search start
  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(applicationListData);
  }, [applicationListData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(applicationListData);
    setSearchedData(
      applicationListData?.filter(
        (item) =>
          item?.mem_family_id?.toString().includes(searched.toLowerCase()) ||
          item?.mem_fname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.mem_lname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.Sahay_category?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.SahayAmmount?.toString().includes(searched.toLowerCase()) ||
          item?.AadharNo?.toString().includes(searched.toLowerCase())
      )
    );
  };

  //search end

  console.log("pageData", pageData);
  console.log("applicationListData", applicationListData);
  return (
    <>
      <Index.Box className="apply-sahay-main">
        <Sidebar />
        <Index.Box className="head-content">
          <Index.Box className="admin-panel-content user-admin-panel-content">
            {loading ? (
              <PagesIndex.PageLoading />
            ) : (
              <Index.Box className="page-content-box">
                <Index.Box className="barge-common-box">
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex">
                      <Index.Box className="plus-hidden-show-mobile">
                        <Index.Box className="title-main">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title"
                          >
                            Sahay Application
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <LightTooltip
                            title="Apply for sahay"
                            className="mobile-menu-show"
                          >
                            <PagesIndex.Link
                              to="/user/apply-for-sahay"
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                          </LightTooltip>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="d-flex align-items-center res-set-search">
                        <PagesIndex.Search className="search">
                          <PagesIndex.StyledInputBase
                            placeholder="Search"
                            onChange={(e) => requestSearch(e.target.value)}
                            inputProps={{ "aria-label": "search" }}
                          />
                        </PagesIndex.Search>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <PagesIndex.Link
                            to="/user/apply-for-sahay"
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button
                              variant="contained"
                              disableRipple
                              className="apply-sahay-btn"
                            >
                              <Index.ControlPointIcon />{" "}
                              <span>Apply for sahay</span>
                            </Index.Button>
                          </PagesIndex.Link>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container user-sahay-container"
                    >
                      <Index.Table
                        aria-label="simple table"
                        className="table-design-main barge-table user-sahay-table"
                      >
                        <Index.TableHead className="user-sahay-thead">
                          <Index.TableRow className="user-sahay-tr">
                            <Index.TableCell className="user-sahay-td">
                              Family Id
                            </Index.TableCell>
                            <Index.TableCell className="user-sahay-td">
                              User Name
                            </Index.TableCell>
                            <Index.TableCell
                              className="user-sahay-td"
                              align="left"
                            >
                              Sahay Category
                            </Index.TableCell>
                            <Index.TableCell
                              className="user-sahay-td"
                              align="left"
                            >
                              Sub Sahay Category
                            </Index.TableCell>
                            <Index.TableCell
                              className="user-sahay-td"
                              align="left"
                            >
                              Sahay Amount
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Addharcard No.
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Staff Status
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Committee Status
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Committee Remark
                            </Index.TableCell>
                            <Index.TableCell
                              className="user-sahay-td"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody
                          className={
                            !loading ? "user-sahay-tbody" : "loading-table"
                          }
                        >
                          {console.log("pageData", pageData)}
                          {pageData?.length ? (
                            pageData?.map((row, index) => {
                              return (
                                <Index.TableRow key={index}>
                                  <Index.TableCell align="left">
                                    {row?.mem_family_id? row?.mem_family_id : row?.familyId}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {row?.mem_fname} {row?.mem_lname}
                                  </Index.TableCell>

                                  <Index.TableCell align="left">
                                    {row?.Sahay_category}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {row?.SahayName}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {row?.claimedAmmount}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {row?.AadharNo}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    align="center"
                                    style={{
                                      color: `${
                                        row?.NewStatusColumn === "Approved"
                                          ? "green"
                                          : row?.NewStatusColumn === "Rejected"
                                          ? "red"
                                          : "grey"
                                      }`,
                                    }}
                                  >
                                    {row?.NewStatusColumn === null
                                      ? "-"
                                      : row?.NewStatusColumn}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    align="center"
                                    style={{
                                      color: `${
                                        row?.committeeStatus === "Approved"
                                          ? "green"
                                          : row?.committeeStatus === "Rejected"
                                          ? "red"
                                          : "grey"
                                      }`,
                                    }}
                                  >
                                    {row?.committeeStatus === null
                                      ? "-"
                                      : row?.committeeStatus}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    align="center"
                                    style={{
                                      color: `${
                                        row?.committeeRemark === "Approved"
                                          ? "green"
                                          : row?.committeeRemark === "Rejected"
                                          ? "red"
                                          : "grey"
                                      }`,
                                    }}
                                  >
                                    {row?.committeeRemark === null
                                      ? "-"
                                      : row?.committeeRemark}
                                  </Index.TableCell>

                                  <Index.TableCell align="center">
                                    <Index.Box className="icon-cell">
                                      <Index.IconButton
                                        onClick={() =>
                                          handleSahayApplicationView(row)
                                        }
                                      >
                                        <Index.Visibility className="muiico-view" />
                                      </Index.IconButton>
                                      {row?.NewStatusColumn.toLowerCase() ===
                                      "approved" ? (
                                        ""
                                      ) : (
                                        <Index.IconButton
                                          onClick={() => handleEdit(row)}
                                        >
                                          <Index.EditIcon className="muiico-edit" />
                                        </Index.IconButton>
                                      )}
                                      {/* <Index.IconButton
                                      // onClick={() => handleDeleteOpen(item)}
                                      >
                                        <Index.DeleteIcon className="muiico-delete" />
                                      </Index.IconButton> */}
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              );
                            })
                          ) : (
                            <PagesIndex.NoRecordFound colSpan={12} />
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-design flex-end">
                    <RowPerPageDropdown
                      rowPerPage={rowPerPage}
                      setRowPerPage={setRowPerPage}
                    />
                    <Index.Stack spacing={2}>
                      <PagesIndex.Pagination
                        fetchData={searchedData}
                        setPageData={setPageData}
                        pageData={pageData}
                        rowPerPage={rowPerPage}
                      />
                    </Index.Stack>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Listing;
