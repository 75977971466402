import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import DataService, { imagePath } from "../../../../config/DataService";
import { toast } from "react-toastify";
import { Api } from "../../../../config/Api";
import moment from "moment";
import dayjs from "dayjs";

const ApplyForSahayView = () => {
  const { sahayApplicationAdminSingleGet } = PagesIndex.useSelector(
    (state) => state.admin
  );
  const navigate = PagesIndex.useNavigate();

  const { state } = PagesIndex.useLocation();
  const [getViewData, setGetViewData] = useState(null);
  console.log("state++---", state);

  const initialValues = {};

  const getSingleSahayApplication = async () => {
    try {
      const response = await DataService.post(
        `${Api.GET_SINGLE_SAHAY_APPLICATION}/${state.id}`
        // `${Api.GET_SINGLE_SAHAY_APPLICATION}/${state.id[0]}`
      );
      setGetViewData(response.data.data);
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  };

  useEffect(() => {
    getSingleSahayApplication();
  }, []);

  //back button
  const handleBack = () => {
    navigate("/user/user-listing");
  };

  //Get current financial year
  function getCurrentFinancialYear() {
    var financialYear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      financialYear = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      financialYear = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
    return financialYear;
  }

  return (
    <>
      <Index.Box className="apply-sahay-main">
        <Sidebar />
        <Index.Box className="head-content">
          <Index.Box className="user-right">
            <Index.Box className="bottom-user-right">
              <Index.Box className="page-content-box">
                <Index.Box className="barge-common-box">
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex">
                      <Index.Box className="title-main mb-10">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="page-title"
                        >
                          View Sahay Application
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <PagesIndex.Formik
                    initialValues={initialValues}
                    enableReinitialize
                  >
                    {({ values }) => (
                      <form>
                        <Index.Box className="">
                          <Index.Grid container spacing={3}>
                            <Index.Grid item xs={12} sm={12} md={12}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label "
                              >
                                Image
                              </Index.Typography>
                              <Index.Box className=" mb-5 mt-5">
                                {getViewData?.application[0]?.image && (
                                  <img
                                    style={{ width: "120px", height: "120" }}
                                    src={`${imagePath}${getViewData?.application[0]?.image}`}
                                    className="  mb-5 mt-5"
                                  />
                                )}
                              </Index.Box>
                            </Index.Grid>
                            {getViewData?.document?.length > 0 ? (
                              <Index.Grid item xs={12} sm={12} md={12}>
                                <Index.Box className="title-header">
                                  <Index.Box className="title-header-flex">
                                    <Index.Box className="title-main ">
                                      <Index.Typography
                                        variant="p"
                                        component="p"
                                        className="page-title"
                                      >
                                        Documents
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                {console.log("GetviewData-=11", getViewData)}
                                <Index.Grid item xs={8}>
                                  <Index.Box className="select-view-document-name">
                                    <Index.Box className="select-view-document-name mt-select-doc">
                                      {/* <Index.Box className="select-view-document-name mt-select-doc">
                                  {getViewData?.document
                                    ?.map((data) =>
                                      data?.documents?.filter(
                                        (ele) => ele?.document !== "null"
                                      )
                                    )
                                    .map((item, fileIndex) => {
                                      return (
                                        <>
                                        {console.log("item++++===",item)}
                                          {item?.documentType  &&
                                            item?.documents?.length > 0 && (
                                              <Index.Box className="roles-data-box add-check-flex-doc">
                                                <Index.Box className="add-role-flex">
                                                  <Index.Box className="role-data-title">
                                                    {item.documentType}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                            )}
                                          {item?.documents
                                            ?.filter(
                                              (ele) => ele?.document !== "null"
                                            )
                                            .map((document, documentIndex) => (
                                              <Index.Box
                                              className="select-view-document-name mt-select-doc"
                                              key={documentIndex}
                                              >
                                                {console.log("item++++===document",document)}
                                                <Index.Box className="document-name">
                                                  <Index.Typography
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      window.open(
                                                        document instanceof Blob
                                                          ? URL.createObjectURL(
                                                              document
                                                            )
                                                          : `${imagePath}${document?.filename}`
                                                      );
                                                    }}
                                                  >
                                                    {document instanceof Blob
                                                      ? document.name
                                                      : document?.document}
                                                  </Index.Typography>
                                                  <Index.IconButton
                                                    onClick={() => {
                                                      console.log(
                                                        "onClickRemove",
                                                        document?.id
                                                      );
                                                      // Implement your logic to remove the document
                                                    }}
                                                  >
                                                    <Index.ClearIcon className="muiico-cancel" />
                                                  </Index.IconButton>
                                                </Index.Box>
                                              </Index.Box>
                                            ))}
                                        </>
                                      );
                                    })}
                                </Index.Box> */}

                                      {getViewData?.document
                                        ?.map((data) => ({
                                          documentType: data.documentType,
                                          documents: data.documents?.filter(
                                            (doc) => doc.document !== "null"
                                          ),
                                        }))
                                        ?.filter(
                                          (item) => item.documents?.length > 0
                                        )
                                        ?.map((item, fileIndex) => (
                                          <>
                                            <div>
                                              <Index.Box className="roles-data-box add-check-flex-doc">
                                                <Index.Box className="add-role-flex">
                                                  <Index.Box className="role-data-title">
                                                    {item.documentType}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>

                                              {/* Iterate over filtered documents and display them */}
                                              <div>
                                                {item.documents.map(
                                                  (document, documentIndex) => (
                                                    <Index.Box
                                                      className="select-view-document-name mt-select-doc"
                                                      key={documentIndex}
                                                    >
                                                      <Index.Box className="document-name">
                                                        <Index.Typography
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              document instanceof
                                                                Blob
                                                                ? URL.createObjectURL(
                                                                    document
                                                                  )
                                                                : `${imagePath}${document?.filename}`
                                                            );
                                                          }}
                                                        >
                                                          {document instanceof
                                                          Blob
                                                            ? document.name
                                                            : document?.document}
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            ) : (
                              ""
                            )}

                            <Index.Grid item xs={12} sm={12} md={12}>
                              <Index.Box className="title-header">
                                <Index.Box className="title-header-flex">
                                  <Index.Box className="title-main ">
                                    <Index.Typography
                                      variant="p"
                                      component="p"
                                      className="page-title"
                                    >
                                      Basic Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Family ID
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="familyId"
                                  disabled
                                  value={state?.mem_family_id}
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Addhar Card No.
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={getViewData?.application[0]?.AadharNo}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Sahay Category
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Sahay Category"
                                  disabled
                                  value={getViewData?.application[0]?.SahayName}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Sub Sahay Category
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Sahay Category"
                                  disabled
                                  value={
                                    getViewData?.application[0]?.subSahayName
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Year Of Sahay
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]?.Year || "-"
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Application Received Date
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border ">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                  sx={{ position: "absolute" }}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <Index.DatePicker
                                      name="applicationReceiveDate"
                                      // onChange={(value) => {
                                      //   setFieldValue(
                                      //     "applicationReceiveDate",
                                      //     moment(value.$d).format(
                                      //       "YYYY-MM-DD"
                                      //     )
                                      //   );
                                      // }}
                                      value={dayjs(
                                        getViewData?.application[0]
                                          ?.applicationReceiveDate
                                      )}
                                      format="YYYY-MM-DD"
                                      sx={{
                                        overflowY: "hidden",
                                        position: "relative",
                                        bottom: "8px",
                                        width: "100%",
                                      }}
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          error: false,
                                        },
                                      }}
                                    />
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                              </Index.Box>
                              {/* {errors?.applicationreceivedate &&
                                  touched.applicationreceivedate && (
                                    <p className="error-text">
                                      {errors?.applicationreceivedate}
                                    </p>
                                  )} */}
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Filing Date
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border ">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                  sx={{ position: "absolute" }}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <Index.DatePicker
                                      name="formFillingDate"
                                      // onChange={(value) => {
                                      //   setFieldValue(
                                      //     "formFillingDate",
                                      //     moment(value.$d).format(
                                      //       "YYYY-MM-DD"
                                      //     )
                                      //   );
                                      // }}
                                      value={dayjs(
                                        getViewData?.application[0]
                                          ?.formFillingDate
                                      )}
                                      format="YYYY-MM-DD"
                                      sx={{
                                        overflowY: "hidden",
                                        position: "relative",
                                        bottom: "8px",
                                        width: "100%",
                                      }}
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          error: false,
                                        },
                                      }}
                                    />
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                              </Index.Box>
                              {/* {errors?.applicationreceivedate &&
                                  touched.applicationreceivedate && (
                                    <p className="error-text">
                                      {errors?.applicationreceivedate}
                                    </p>
                                  )} */}
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={12} md={12}>
                              <Index.Box className="title-header">
                                <Index.Box className="title-header-flex">
                                  <Index.Box className="title-main ">
                                    <Index.Typography
                                      variant="p"
                                      component="p"
                                      className="page-title"
                                    >
                                      Family Members Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                First Name
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="User Name"
                                  disabled
                                  value={getViewData?.application[0]?.firstName}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Middle Name
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="User Name"
                                  disabled
                                  value={
                                    getViewData?.application[0]?.middleName
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Last Name
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="User Name"
                                  disabled
                                  value={getViewData?.application[0]?.lastName}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Relation
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={getViewData?.application[0]?.relation}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Age
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]?.age
                                      ? getViewData?.application[0]?.age
                                      : "----"
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Working Type
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]?.workingType
                                      ? getViewData?.application[0]?.workingType
                                      : "----"
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Monthly Income
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]?.monthlyIncome
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={12} md={12}>
                              <Index.Box className="title-header">
                                <Index.Box className="title-header-flex">
                                  <Index.Box className="title-main ">
                                    <Index.Typography
                                      variant="p"
                                      component="p"
                                      className="page-title"
                                    >
                                      Bank Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Bank Name
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={getViewData?.application[0]?.bankName}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Branch
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={getViewData?.application[0]?.branch}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Account Holder Name
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]?.HolderName
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Account Number
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={getViewData?.application[0]?.AccountNo}
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                IFSC code
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={getViewData?.application[0]?.IFSCCode}
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={12} md={12}>
                              <Index.Box className="title-header">
                                <Index.Box className="title-header-flex">
                                  <Index.Box className="title-main ">
                                    <Index.Typography
                                      variant="p"
                                      component="p"
                                      className="page-title"
                                    >
                                      Sahay Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>

                            {getViewData?.application[0]?.mediclaimAmmount !==
                              0 && (
                              <>
                                {" "}
                                {/* <Index.Grid item xs={12} sm={6} md={5} lg={3}>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    Mediclaim Policy Amount
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      autoComplete="off"
                                      name="Addharcard No."
                                      disabled
                                      value={
                                        getViewData?.application[0]
                                          ?.mediclaimAmmount
                                      }
                                    />
                                  </Index.Box>
                                </Index.Grid>{" "} */}
                              </>
                            )}

                            {getViewData?.application[0]?.SahayAmmount !==
                              0 && (
                              <Index.Grid item xs={12} sm={6} md={3}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Sahay Amount
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    autoComplete="off"
                                    name="Sahay Amount"
                                    disabled
                                    value={
                                      getViewData?.application[0]?.SahayAmmount
                                    }
                                  />
                                </Index.Box>
                              </Index.Grid>
                            )}

                            {getViewData?.application[0]?.rentSahayAmmount !==
                              0 && (
                              <>
                                <Index.Grid item xs={12} sm={6} md={3}>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    Rent Amount For Rent Sahay
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      autoComplete="off"
                                      name="Addharcard No."
                                      disabled
                                      value={
                                        getViewData?.application[0]
                                          ?.rentSahayAmmount
                                      }
                                    />
                                  </Index.Box>
                                </Index.Grid>
                              </>
                            )}

                            {getViewData?.application[0]?.claimedAmmount !==
                              0 && (
                              <>
                                <Index.Grid item xs={6} sm={4} md={4} lg={4}>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {/* Claimed Sahay Amount */}
                                    Total Requested Sahay Amount
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      autoComplete="off"
                                      name="Addharcard No."
                                      disabled
                                      value={
                                        getViewData?.application[0]
                                          ?.claimedAmmount
                                      }
                                    />
                                  </Index.Box>
                                </Index.Grid>{" "}
                              </>
                            )}

                            <Index.Grid item xs={12} md={12}>
                              <Index.Box className="title-header">
                                <Index.Box className="title-header-flex res-title-header-flex">
                                  <Index.Box className="title-main">
                                    <Index.Typography
                                      variant="p"
                                      component="p"
                                      className="page-title"
                                    >
                                      Family Members Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="">
                                <Index.TableContainer
                                  component={Index.Paper}
                                  className="table-container"
                                >
                                  <Index.Table
                                    aria-label="simple table"
                                    className="table-design-main barge-table "
                                  >
                                    <Index.TableHead>
                                      <Index.TableRow>
                                        <Index.TableCell>Name</Index.TableCell>
                                        <Index.TableCell align="left">
                                          {" "}
                                          Age
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          {" "}
                                          Relation
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          {" "}
                                          Working Type
                                        </Index.TableCell>
                                        {/* <Index.TableCell align="left">
                                          {" "}
                                          Monthly Income
                                        </Index.TableCell> */}
                                      </Index.TableRow>
                                    </Index.TableHead>
                                    <Index.TableBody>
                                      {getViewData?.relationRecords?.length ? (
                                        getViewData?.relationRecords?.map(
                                          (data, index) => {
                                            return (
                                              <Index.TableRow key={index}>
                                                <Index.TableCell>
                                                  {data?.name}
                                                </Index.TableCell>

                                                <Index.TableCell align="left">
                                                  {data?.age}
                                                </Index.TableCell>
                                                <Index.TableCell align="left">
                                                  {data?.relation}
                                                </Index.TableCell>
                                                <Index.TableCell align="left">
                                                  {data?.workingType}
                                                </Index.TableCell>
                                                {/* <Index.TableCell align="left">
                                                  {data?.monthlyIncome}
                                                </Index.TableCell> */}
                                              </Index.TableRow>
                                            );
                                          }
                                        )
                                      ) : (
                                        <Index.TableRow>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="no-data-in-list"
                                            colSpan={6}
                                            align="center"
                                          >
                                            No data available
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      )}
                                    </Index.TableBody>
                                  </Index.Table>
                                </Index.TableContainer>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} md={12}>
                              <Index.Box className="title-header">
                                <Index.Box className="title-header-flex res-title-header-flex">
                                  <Index.Box className="title-main">
                                    <Index.Typography
                                      variant="p"
                                      component="p"
                                      className="page-title"
                                    >
                                      Trust
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="">
                                <Index.TableContainer
                                  component={Index.Paper}
                                  className="table-container"
                                >
                                  <Index.Table
                                    aria-label="simple table"
                                    className="table-design-main barge-table "
                                  >
                                    <Index.TableHead>
                                      <Index.TableRow>
                                        <Index.TableCell align="left">
                                          Trust Name
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          {" "}
                                          Account
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    </Index.TableHead>
                                    <Index.TableBody>
                                      {getViewData?.trustRecords?.length ? (
                                        getViewData?.trustRecords?.map(
                                          (data, index) => {
                                            return (
                                              <Index.TableRow key={index}>
                                                <Index.TableCell align="left">
                                                  {data?.trustName}
                                                </Index.TableCell>

                                                <Index.TableCell align="left">
                                                  {data?.ammount}
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            );
                                          }
                                        )
                                      ) : (
                                        <Index.TableRow>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="no-data-in-list"
                                            colSpan={2}
                                            align="center"
                                          >
                                            {/* No trust available */}
                                            No data available
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      )}
                                    </Index.TableBody>
                                  </Index.Table>
                                </Index.TableContainer>
                              </Index.Box>
                            </Index.Grid>
                            {/* table */}
                          </Index.Grid>
                          <Index.Box sx={{ marginTop: "40px" }}>
                            <Index.Box className="title-header-flex text-end-details">
                              <Index.Box className="common-button blue-button flex-start save-btn  ">
                                <Index.Button
                                  variant="contained"
                                  onClick={() => handleBack()}
                                >
                                  Back
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="line" mt={3} mb={3}></Index.Box>
                        </Index.Box>
                      </form>
                    )}
                  </PagesIndex.Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ApplyForSahayView;
