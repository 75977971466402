import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../forgotPassword/ForgotPassword.css";
import { forgotpasswordAdmin } from "../../../../redux/features/AddService";

const ForgotPassword = () => {
  const dispatch = PagesIndex.useDispatch();
  const navigate = Index.useNavigate();

  let initialValues = {
    email: "",
    isSubmit: false,
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    let data = {
      email: values?.email,
    };
    dispatch(forgotpasswordAdmin(data)).then((res) => {
      if (res.payload !== undefined) {
        setTimeout(() => {
          navigate("/admin/verify-otp", {
            state: {
              emailData: values?.email,
            },
          });
          setFieldValue("isSubmit", false);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2 text-center">
                  <img src={PagesIndex.Png.sahay_log} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Forgot Password
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={
                          PagesIndex.validationSchemaForgotpassword
                        }
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <Index.Box className="box-login-text1 bluebox-text1">
                              <Index.Typography component="p" className="">
                                Enter your email
                              </Index.Typography>
                            </Index.Box>
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Enter email"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="email"
                              autoComplete="off"
                              onBlur={handleBlur}
                              // onFocus={() => setLoading(false)}
                              value={values.email}
                              onChange={handleChange}
                              helperText={touched.email && errors.email}
                              error={Boolean(errors.email && touched.email)}
                              sx={{ mb: 3 }}
                            />
                            <Index.Box className="orange-btn login-btn login-btn-main">
                              <Index.Button
                                variant="contained"
                                type="submit"
                                disableRipple
                                disabled={values.isSubmit}
                              >
                                Submit
                              </Index.Button>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </PagesIndex.Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ForgotPassword;
