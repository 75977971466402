import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import Index from "../../../../container/Index";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import PagesIndex from "../../../PagesIndex";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import dayjs from "dayjs";

import * as yup from "yup";
import {
  getFamilyIds,
  getSahayTypeList,
  getSahaySubTypeList,
  getFamilyMemberById,
  applyForSahayAPI,
  listYearOfSahay,
  bankAccountsList,
  getRelationMasterList,
  getListOfFamilyId,
  getVastiPatrakListOfFamilyId,
  updateSahayApplication,
  updateApplyForSahayDocuments,
  getMohallalist,
  getAllFamilyIdWithMohollaNameList,
  getFamilyMemberMobileNumberList,
} from "../../../../redux/features/AddService";
import {
  validationSchemaApplyForSahay,
  validationSchemaApplyForSahayBankMember,
} from "../../../../validation/Validation";
import { Autocomplete, CircularProgress, Typography } from "@mui/material";
import dataService, { imagePath } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { toast } from "react-toastify";
import { FieldArray } from "formik";
import { sahayDocumentArray } from "./DocumentsPermissions";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const applySahayValidation = yup.object().shape({
  AadharNo: yup
    .number()
    .typeError("Only Number allowed")
    .required("Aadhar No Required"),
});

const familyIncomeAmountList = [
  { id: 1, ammount: "Less than 5 lakh" },
  { id: 2, ammount: "5 lakh or above" },
];

const electricBillAmountList = [
  { id: 1, ammount: "0 to 1000" },
  { id: 2, ammount: "1001 to 2500" },
  { id: 3, ammount: "2501 to 3500" },
  { id: 4, ammount: "3501 and Above" },
];
const workingTypeList = [
  { id: 1, name: "Business" },
  { id: 2, name: "House Wife" },
  { id: 3, name: "Job" },
  { id: 4, name: "Retired" },
  { id: 5, name: "Student" },
  { id: 6, name: "Not Applicable" },
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ariaLabel = { "aria-label": "description" };

const ApplyForSahayAdmin = () => {
  const {
    getAdminLoginDetails,
    getUserLoginDetails,
    sahayApplicationAdminSingleGet,
    getRelationList,
    getMohalla,
  } = PagesIndex.useSelector((state) => state.admin);

  const theme = useTheme();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const editPreviousData = location?.state;
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  //all state
  const [singleSahayAppicationData, setSingleSahayAppicationData] = useState(
    {}
  );
  const [radioButtonFamily, setRadioButtonFamily] = React.useState(
    location?.state?.editSahay?.vastipatrak == 0
      ? "NonVastrikpatrak"
      : "Vastrikpatrak"
  );
  const [personName, setPersonName] = React.useState([]);
  const [documentError, setDocumentError] = React.useState(false);
  const [listYearOfSahayData, setListYearOfSahayData] = useState([]);
  const [bankAccountsListData, setBankAccountsListData] = useState([]);
  const [applicantId, setApplicantId] = useState();
  const [familyIdsList, setFamilyIdsList] = useState();
  const [sahayTypeList, setSahayTypeList] = useState();
  const [sahaySubTypeList, setSahaySubTypeList] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [sahayTypecondition, setSahayTypeCondition] = useState();
  const [sahayCondition, setSahayCondition] = useState();
  const [singlaFamilyId, setSingleFamilyId] = useState();
  const [getDocument, setGetDocument] = useState({});
  const [selectApplicant, setSelectApplicant] = useState();
  const [getSingleSubSahay, setGetSingleSubSahay] = useState();
  const [applicantSelectedMember, setApplicantSelectedMember] = useState([]);
  const [applicantAllMember, setApplicantAllMember] = useState([]);
  const [familyListData, setFamilyListData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [formPageLoader, setFormPageLoader] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(null);
  const [familyIdForApplicant, setFamilyIdForApplicant] = useState("");
  const [editDocumentData, setEditDocumentData] = useState([]);
  const [demoDocument, setDemoDocument] = useState("");
  const [filterByMohollaName, setFilterByMohollaName] = useState("");
  const [filterByMobileNumber, setFilterByMobileNumber] = useState("");
  const [mobileNumberList, setMobileNumberList] = useState([]);
  const [editSingleApplicantData, setEditSingleApplicantData] = useState();
  const [Mohholaid, setMohholaid] = useState(null);
  const [valuesDat, setValues] = useState([]);

  const EducationSubsahayValue = [
    1015, 1017, 1010, 1007, 1006, 1008, 1009, 1010, 1011, 1012, 1013, 1019,
    1018, 1020, 1021, 1022, 1023,
  ];

  const [modalOpen, setModalOpen] = useState(false);

  const { rollPermissionData } = useSelector((state) => state.admin);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  //Edit Application State
  const handleMenuOpen = () => {
    setLoader(true);

    setLoadingTimeout(
      familyListData &&
        setTimeout(() => {
          setLoader(false);
        }, 2000)
    );
  };
  const handleMenuClose = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  let familyMemberName =
    getUserLoginDetails &&
    getUserLoginDetails?.data?.mem_fname +
      " " +
      getUserLoginDetails?.data?.mem_mname +
      " " +
      getUserLoginDetails?.data?.mem_lname;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [alignment, setAlignment] = React.useState("web");

  const getFamilyMemberIds = (status) => {
    dispatch(getFamilyIds(status)).then((res) => {
      setFamilyIdsList(
        res?.payload?.map((ele, i) => {
          return {
            familyId: ele?.familyId,
            firstName: ele?.firstName,
            lastName: ele?.lastName,
            middleName: ele?.middleName,
            fullName:
              ele?.firstName + " " + ele?.middleName + " " + ele?.lastName,
          };
        })
      );
    });
  };

  const getSahayTypeData = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  const getSahaySubTypeData = (sahayTypeId) => {
    dispatch(getSahaySubTypeList(sahayTypeId)).then((res) => {
      setSahaySubTypeList(res?.payload);
    });
  };

  const setMemberDeatils = (applicantId, setFieldValue) => {
    const member =
      familyMembers &&
      familyMembers?.filter((member) => member?.id == applicantId);
    if (member?.[0]) {
      setFieldValue(
        "memberName",
        member?.[0]?.firstName +
          " " +
          member?.[0]?.middleName +
          " " +
          member?.[0]?.lastName
      );
      setFieldValue("age", member?.[0]?.age ? member?.[0]?.age : 0);
      setFieldValue("workingType", member?.[0]?.workingType);
      setFieldValue("relation", member?.[0]?.relation);
      setFieldValue("relation_id", member?.[0]?.relation_id);
      setFieldValue(
        "monthlyIncome",
        member?.[0]?.AnnualIncome && member?.[0]?.AnnualIncome !== null
          ? member?.[0]?.AnnualIncome
          : 0
      );
      setFieldValue(
        "firstNameApplicant",
        member?.[0]?.firstName + " " + member?.[0]?.lastName
      );
    }
  };

  useEffect(() => {
    if (
      location?.state?.editSahay?.applicationId &&
      singleSahayAppicationData?.application?.[0]
    ) {
      const applicantId = location?.state?.editSahay?.applicantId;
      setMemberDeatils();
    }
  }, [
    singleSahayAppicationData?.application,
    location?.state?.editSahay?.applicationId,
  ]);

  useEffect(() => {
    getFamilyMemberIds(true);
    getSahayTypeData();
    dispatch(getRelationMasterList());
  }, []);

  const resetImage = (setFieldValue) => {
    setFieldValue("passportPic", "");
    setSelectedImage("");
  };

  console.log("editSingleApplicantData", editSingleApplicantData);
  //Get current financial year
  function getCurrentFinancialYear() {
    var financialYear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      financialYear = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      financialYear = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
    return financialYear;
  }

  let initialValues;
  console.log("singleSahayAppicationData===+++++", singleSahayAppicationData);

  if (Object.keys(singleSahayAppicationData).length) {
    initialValues = {
      familyId: singleSahayAppicationData
        ? location?.state?.editSahay?.familyId
        : "",
      vastiPatrak: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.vastipatrak == 0
          ? "false"
          : "true"
        : "true",
      AadharNo: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.AadharNo
        : "",
      SahayType: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.SahayType
        : "",
      subSahayType: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.subSahayTypeId
        : "",
      subSahayName: singleSahayAppicationData?.document?.[0]
        ? singleSahayAppicationData?.document?.[0]?.subSahayName
        : "",
      applicantId: singleSahayAppicationData
        ? +singleSahayAppicationData?.application[0]?.applicantId
        : "",
      beneficiaryName: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.beneficiaryName
        : "",
      memberName: editSingleApplicantData ? editSingleApplicantData?.name : "",
      relation: editSingleApplicantData
        ? editSingleApplicantData?.relation
        : "",
      relation_id: editSingleApplicantData
        ? editSingleApplicantData?.relation_id
        : "",
      // age: editSingleApplicantData ? editSingleApplicantData?.age : "",
      age: singleSahayAppicationData
        ? singleSahayAppicationData?.application?.[0]?.age
        : "",
      workingType: singleSahayAppicationData
        ? singleSahayAppicationData?.application?.[0]?.workingType
        : "",
      monthlyIncome: singleSahayAppicationData
        ? singleSahayAppicationData?.application?.[0]?.monthlyIncome
        : "",
      threeMonthBillAmount: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.threeMonthBillAmount
        : "",
      bankName: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.bankName
        : "",
      branch: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.branch
        : "",
      HolderName: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.HolderName
        : "",
      IFSCCode: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.IFSCCode
        : "",
      formFillingDate: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.formFillingDate
        : "",
      applicationReceiveDate: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.applicationReceiveDate
        : "",
      AccountNo: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.AccountNo
        : "",
      // isMediclaim: singleSahayAppicationData
      //   ? singleSahayAppicationData?.application[0]?.isMediclaim == true
      //     ? "Yes"
      //     : "No"
      //   : "No",
      mediclaimAmmount: singleSahayAppicationData
        ? "0"
        : // ? singleSahayAppicationData?.application[0]?.mediclaimAmmount
          "0",
      isSahay: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.isSahay == true
          ? "Yes"
          : "No"
        : "No",
      trust: singleSahayAppicationData
        ? singleSahayAppicationData?.trustRecords?.length
          ? "yes"
          : "no"
        : "no",
      SahayAmmount: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.SahayAmmount
        : "",
      rentSahayAmmount: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.rentSahayAmmount
        : "",
      claimedAmmount: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.claimedAmmount
        : 0,
      // trustName: "",
      // ammount: "",
      yearOfSahay: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.Year
        : "",
      bankMasterId: "",
      typeOfDisease: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.disease
        : "",
      documents: singleSahayAppicationData
        ? singleSahayAppicationData?.document?.map((ele) => {
            return {
              // id: ele?.id ? ele?.id : 0,
              file: ele?.isUpload
                ? ele?.documents?.filter((data) => ![null,'null'].includes(data?.document) )
                : [],
              // filePath: ele?.isUpload ?  ele?.documents.map((data)=>data?.filename ): [],
              subSahayName: ele?.subSahayNme ? ele?.subSahayName : "",
              isUpload: ele?.isUpload ? ele?.isUpload : false,
              documentType: ele?.documentType ? ele?.documentType : "",
            };
          })
        : [],
        
      passportPic: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.image
        : "",
      remark: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.remark
        : "",
      isMediclaim: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.isMediclaim == true
          ? "Yes"
          : "No"
        : "No",
      mediclaimPremium: singleSahayAppicationData
        ? singleSahayAppicationData?.application[0]?.mediclaimPremium
        : 0,
      isSubmit: false,
      dataArray: singleSahayAppicationData
        ? singleSahayAppicationData?.trustRecords?.map((ele) => {
            return {
              trustName: ele ? ele?.trustName : "",
              ammount: ele ? ele?.ammount : 0,
              id: ele ? ele?.id : "",
            };
          })
        : [
            {
              trustName: "",
              ammount: 0,
            },
          ],
      relationRecords: singleSahayAppicationData
        ? familyMembers
            ?.filter?.((ele) => {
              return (
                ele?.id != singleSahayAppicationData?.application[0]?.applicantId
              );
            })
            ?.map((ele) => {
              // if (
              //   ele?.id !== singleSahayAppicationData?.application[0].applicantId
              // ) {
              // }
              return {
                id: ele?.id,
                // firstName: ele?.name,
                firstName: `${ele?.firstName} ${ele?.middleName} ${ele?.lastName}`,
                lastName: "",
                middleName: "",
                age: ele?.age ? ele?.age : 0,
                relation_id: +ele?.relation_id,
                workingType: ele?.workingType,
                AnnualIncome: ele?.monthlyIncome ? ele?.monthlyIncome : 0,
              };
            })
        : [
            {
              firstName: "",
              lastName: "",
              middleName: "",
              age: 0,
              relation_id: "",
              workingType: "",
              AnnualIncome: 0,
            },
          ],
    };
  } else {
    initialValues = {
      familyId: "",
      formFillingDate: "",
      applicationReceiveDate: "",
      vastiPatrak: "true",
      AadharNo: "",
      SahayType: "",
      subSahayType: "",
      applicantId: "",
      beneficiaryName: "",
      memberName: "",
      relation: "",
      relation_id: "",
      age: "",
      workingType: "",
      monthlyIncome: "",
      threeMonthBillAmount: "",
      bankName: "",
      branch: "",
      HolderName: "",
      IFSCCode: "",
      AccountNo: "",
      isMediclaim: "No",
      mediclaimAmmount: "0",
      isSahay: "No",
      trust: "no",
      SahayAmmount: "",
      rentSahayAmmount: "",
      claimedAmmount: 0,
      // trustName: "",
      // ammount: "",
      yearOfSahay: getCurrentFinancialYear(),
      bankMasterId: "",
      typeOfDisease: "",
      documents: [],
      passportPic: "",
      remark: "",
      isMediclaim: "No",
      mediclaimPremium: 0,
      isSubmit: false,
      dataArray: [
        {
          trustName: "",
          ammount: 0,
        },
      ],
      relationRecords: [
        {
          firstName: "",
          lastName: "",
          middleName: "",
          age: 0,
          relation_id: "",
          workingType: "",
          AnnualIncome: 0,
        },
      ],
    };
  }

  //Get moholla name
  const getAllMohollaNameList = () => {};
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    dispatch(listYearOfSahay()).then((data) => {
      if (data?.payload) {
        const filteredData = data?.payload?.filter((ele) => {
          return ele?.fromYear == currentYear && ele?.toYear == currentYear + 1;
        });
        if (filteredData?.length) {
          setListYearOfSahayData(filteredData);
        } else {
          setListYearOfSahayData([]);
        }
      } else {
        setListYearOfSahayData([]);
      }
    });
    dispatch(getMohallalist());
  }, []);

  useEffect(() => {
    if (applicantId) {
      dispatch(bankAccountsList(applicantId)).then((data) => {
        if (data?.payload) {
          setBankAccountsListData(data?.payload);
        } else {
          setBankAccountsListData([]);
        }
      });
    }
  }, [applicantId]);

  const handleBack = () => {
    navigate(`/${rollData}/sahay-application`, {
      state: { prevState: location?.state?.editSahay?.lastStatus },
    });
  };

  //handle submit

  const handleFormSubmit = (values, { setFieldValue }) => {
   

    handleOpenModal();
    setFormPageLoader(true);
    setFieldValue("isSubmit", true);
    const data = new FormData();
    let id = getAdminLoginDetails?.id;

    data.append("typeOfDisease", "");

    if (getSingleSubSahay == 1017) {
      data.append(
        "typeOfDisease",
        values?.typeOfDisease ? values?.typeOfDisease : ""
      );
    }

    data.append("applicationReceiveDate", values?.applicationReceiveDate);
    data.append("formFillingDate", values?.formFillingDate);
    data.append("familyId", +values?.familyId);
    data.append("yearType", "currentYear");
    data.append("AadharNo", +values?.AadharNo);
    data.append("SahayType", +values?.SahayType);
    data.append("subSahayType", +values?.subSahayType);
    data.append("appliedBy", +id);
    data.append("applicantId", +values?.applicantId);
    data.append("beneficiaryName", +values?.beneficiaryName);
    data.append("threeMonthBillAmount", values?.threeMonthBillAmount);
    data.append("bankName", values?.bankName);
    data.append("branch", values?.branch);
    data.append("HolderName", values?.HolderName);
    data.append("IFSCCode", values?.IFSCCode);
    data.append("AccountNo", +values?.AccountNo);
    data.append("isMediclaim", values?.isMediclaim == "No" ? false : true);
    data.append("mediclaimAmmount", +values?.mediclaimAmmount);
    data.append("mediclaimPremium", +values?.mediclaimPremium);
    data.append("isSahay", values?.isSahay == "No" ? false : true);
    data.append("vastiPatrak", radioButtonFamily == "Vastrikpatrak" ? 1 : 0);
    data.append("SahayAmmount", +values?.SahayAmmount);
    data.append("rentSahayAmmount", +values?.rentSahayAmmount);
    data.append(
      "claimedAmmount",

      +values?.claimedAmmount
    );
    data.append("trustName", "TEST");
    data.append("yearOfSahay", values?.yearOfSahay);
    data.append(
      "monthlyIncome",
      values?.monthlyIncome ? values?.monthlyIncome : ""
    );
    data.append("name", values?.memberName);
    data.append("age", values?.age || 0);
    data.append("workingType", values?.workingType);
    data.append("relation", values?.relation_id);
    data.append("remark", values?.remark);

    // if (values?.documents?.length == 0) {
    //   setDocumentError(true);
    //   return 0;
    // }
    if (location?.state?.editSahay?.applicationId) {
      const documentData = new FormData();
      const sahayId = singleSahayAppicationData?.application[0]?.sahayId;
      documentData.append("sahayId", sahayId);
      if (values?.documents?.length > 0) {
        let uploadIndex = 0;

        console.log("values?.documents", values?.documents);
        values?.documents
          ?.sort((a, b) => {
            if (a.file !== "" && b.file === "") {
              return -1;
            } else if (b.file !== "" && a.file === "") {
              return 1;
            } else {
              return 0;
            }
          })
          ?.forEach((ele) => {
            console.log("ele+++==1", ele);
            if (ele?.file?.length > 0) {
              ele.file?.forEach((dataEle) => {
                console.log("dataEle++===", dataEle);
                if (dataEle instanceof Blob) {
                  documentData.append(
                    `documentsArray[${uploadIndex}][id]`,
                    ele?.id
                  );
                  if (dataEle) {
                    documentData.append(
                      `documentsArray[${uploadIndex}][newUpload]`,
                      1
                    );
                  } else {
                    documentData.append(
                      `documentsArray[${uploadIndex}][newUpload]`,
                      0
                    );
                  }
                  documentData.append(
                    `documentsArray[${uploadIndex}][documentType]`,
                    ele?.documentType
                  );
                  documentData.append(
                    `documentsArray[${uploadIndex}][file]`,
                    dataEle instanceof Blob ? dataEle : demoDocument
                  );

                  documentData.append(
                    `documentsArray[${uploadIndex}][subSahayName]`,
                    values?.subSahayName
                  );
                  documentData.append(
                    `documentsArray[${uploadIndex}][isUpload]`,
                    ele?.isUpload ? 1 : 0
                  );
                  uploadIndex++;
                }
              });
            } else {
              documentData.append(
                `documentsArray[${uploadIndex}][id]`,
                ele?.id
              );

              documentData.append(
                `documentsArray[${uploadIndex}][newUpload]`,
                0
              );

              documentData.append(
                `documentsArray[${uploadIndex}][documentType]`,
                ele?.documentType
              );
              documentData.append(
                `documentsArray[${uploadIndex}][file]`,
                demoDocument
              );

              documentData.append(
                `documentsArray[${uploadIndex}][subSahayName]`,
                values?.subSahayName
              );
              documentData.append(
                `documentsArray[${uploadIndex}][isUpload]`,
                0
              );
              uploadIndex++;
            }
          });
      }

      if (values?.documents?.length > 0) {
        let oldIndex = 0;
        values?.documents?.forEach((ele) => {
          console.log("ele+++==2", ele);
          ele?.file?.forEach((data) => {
            if (!(data instanceof Blob) && ele?.isUpload == true) {
              // documentData.append(`oldDocumentsArray[${oldIndex}][id]`, ele?.id);
              documentData.append(
                `oldDocumentsArray[${oldIndex}][newUpload]`,
                0
              );
              documentData.append(
                `oldDocumentsArray[${oldIndex}][documentType]`,
                ele?.documentType
              );
              documentData.append(
                `oldDocumentsArray[${oldIndex}][id]`,
                data?.id
              );
              documentData.append(
                `oldDocumentsArray[${oldIndex}][file]`,
                data.filename
              );
              documentData.append(
                `oldDocumentsArray[${oldIndex}][subSahayName]`,
                values?.subSahayName
              );
              documentData.append(
                `oldDocumentsArray[${oldIndex}][isUpload]`,
                ele?.isUpload ? 1 : 0
              );
              oldIndex++;
            }
          });
        });
      }

      //// THis is for update document /////


      dispatch(updateApplyForSahayDocuments(documentData)).then((ele) => {
        if (ele?.payload?.status == 200 || ele?.payload?.status == 201) {
        }
      });
    } else {
      let uploadIndex = 0;
      values?.documents?.forEach((ele) => {
        console.log("ele+++==3", ele);
        // if(ele?.file?.length > 0){
        // if (ele?.file == "" || ele?.file instanceof Blob)
        //  {

        data.append(
          `documentsArray[${uploadIndex}][sequenceNo]`,
          uploadIndex + 1
        );
        data.append(
          `documentsArray[${uploadIndex}][documentType]`,
          ele?.documentType
        );

        // ele?.file?.length > 0  && data.append(`documentsArray[0][file]`, demoDocument);

        ele?.file?.length > 0
          && ele?.file?.forEach((ele) => {
              data.append(
                `documentsArray[${uploadIndex}][file]`,
                ele instanceof Blob ? ele : ele.filename
              );
            })
           data.append(`documentsArray[${uploadIndex}][file]`, demoDocument);
        data.append(
          `documentsArray[${uploadIndex}][subSahayName]`,
          values?.subSahayName
        );
        data.append(
          `documentsArray[${uploadIndex}][isUpload]`,
          ele?.isUpload ? 1 : 0
        );
        uploadIndex++;
        // }
        // }
      });
    }

    {
      bankAccountsListData &&
        bankAccountsListData?.length > 0 &&
        data.append("bankMasterId", +values?.bankMasterId);
    }

    // data.append("passportPic", values?.passportPic ? values?.passportPic : null);
    data.append("passportPic", values?.passportPic ? values?.passportPic : "");

    {
      sahayCondition == "yes" &&
        values?.dataArray?.forEach((ele, i) => {
          data.append(`trustRecords[${i}][trustName]`, ele.trustName);
          data.append(`trustRecords[${i}][ammount]`, ele.ammount);
        });
    }

    let newArr = [];

    values?.relationRecords?.filter(async (ele) => {
      await newArr.push({
        middleName: ele.middleName,
        age: ele.age,
        relation: ele.relation_id,
        workingType: ele.workingType,
        monthlyIncome: ele.monthlyIncome,
      });
    });

    data.append(`relationRecords[0][name]`, values?.memberName);
    data.append(`relationRecords[0][age]`, values?.age ? values?.age : 0);
    data.append(`relationRecords[0][workingType]`, values?.workingType);
    data.append(`relationRecords[0][relation]`, values?.relation_id);
    data.append(
      `relationRecords[0][monthlyIncome]`,
      values?.monthlyIncome ? values?.monthlyIncome : 0
    );

    values?.relationRecords?.forEach((ele, i) => {
      if (location?.state?.editSahay?.applicationId) {
        data.append(`relationRecords[${i + 1}][id]`, ele.id);
      }
      data.append(`relationRecords[${i + 1}][name]`, ele.firstName);
      data.append(`relationRecords[${i + 1}][age]`, ele.age ? ele.age : 0);
      data.append(`relationRecords[${i + 1}][relation]`, ele.relation_id);
      data.append(
        `relationRecords[${i + 1}][monthlyIncome]`,
        ele.AnnualIncome ? ele.AnnualIncome : 0
      );
      data.append(`relationRecords[${i + 1}][workingType]`, ele.workingType);
    });
    if (location?.state?.editSahay) {
      //Update Sahay API
      let updateId = location?.state?.editSahay?.applicationId;
      let updateData = {
        data: data,
        id: updateId,
      };
      dispatch(updateSahayApplication(updateData)).then((res) => {
        if (res?.payload?.status == 200 || 201) {
          handleCloseModal();
          setFormPageLoader(false);
          navigate(`/${rollData}/sahay-application`, {
            state: {
              activeTab: 1,
              prevState: location?.state?.editSahay?.lastStatus,
            },
          });
          // setTimeout(() => {
          //   setFieldValue("isSubmit", false);
          // }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
            setFormPageLoader(false);
            handleCloseModal();
          }, 1000);
        }
      });
    } else {
      dispatch(applyForSahayAPI(data)).then((res) => {
        if (res?.payload?.status == 201) {
          handleCloseModal();
          setFormPageLoader(false);
          navigate(`/${rollData}/sahay-application`, {
            state: { activeTab: 1 },
          });
          // setTimeout(() => {
          //   setFieldValue("isSubmit", false);
          // }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
            setFormPageLoader(false);
            handleCloseModal();
          }, 1000);
        }
      });
    }
  };
  useEffect(() => {
    // dispatch(getVastiPatrakListOfFamilyId(radioButtonFamily)).then((res) => {
    //   if (res?.payload) {
    //     setFamilyListData(res?.payload?.data);
    //   } else {
    //     setFamilyListData([]);
    //   }
    // });
    dispatch(
      getAllFamilyIdWithMohollaNameList({
        radioButtonFamily,
        filterByMohollaName,
        filterByMobileNumber,
      })
    ).then((res) => {
      if (res?.payload) {
        setFamilyListData(res?.payload?.data);
      } else {
        setFamilyListData([]);
      }
    });
  }, [radioButtonFamily, filterByMohollaName, filterByMobileNumber]);

  //get mobile number list

  const getMobileNumberList = () => {
    try {
      const type = radioButtonFamily == "Vastrikpatrak" ? 1 : 0;

      dispatch(
        getFamilyMemberMobileNumberList({
          type,
        })
      ).then((res) => {
        if (res?.payload) {
          setMobileNumberList(res?.payload?.data);
        } else {
          setMobileNumberList([]);
        }
      });
    } catch (error) {}
  };
  useEffect(() => {
    getMobileNumberList();
  }, [radioButtonFamily]);

  useEffect(() => {
    let familyId;
    if (editPreviousData?.editSahay) {
      familyId = editPreviousData?.editSahay?.familyId
        ? editPreviousData?.editSahay?.familyId
        : familyIdForApplicant;
    } else {
      familyId = editPreviousData?.familyId
        ? editPreviousData?.familyId
        : familyIdForApplicant;
    }

    let data;
    if (radioButtonFamily === "Vastrikpatrak") {
      data = {
        familyId: familyId,
        VastiPatra: true,
      };
    } else {
      data = {
        familyId: familyId,
        VastiPatra: false,
      };
    }
    dispatch(getFamilyMemberById(data)).then((res) => {
      setFamilyMembers(res?.payload);
      const singleFID = familyListData?.find((ele) => {
        return ele?.family_id == familyId;
      });
      if (singleFID) {
        setSingleFamilyId(singleFID);
      }
    });
  }, [
    familyIdForApplicant,
    editPreviousData?.familyId,
    location?.state?.editSahay?.applicantId,
    location?.state?.editSahay?.familyId,
    familyListData,
  ]);

  //Get single sahay application
  const getSingleSahayApplication = async () => {
    setPageLoader(true);
    const id = location?.state?.editSahay?.applicationId;

    //Single Api call
    try {
      await dataService
        .post(`${Api.GET_SINGLE_SAHAY_APPLICATION}/${id}`)
        .then((res) => {
          if (res?.data?.status === 200) {
            setSingleSahayAppicationData(res?.data?.data);
            setSahayCondition(
              res?.data?.data?.trustRecords?.length ? "yes" : "no"
            );

            //get Sub sahay list data
            const sahayTypeId = res?.data?.data?.application[0]?.SahayType;
            getSahaySubTypeData(sahayTypeId);
            //Set applicant mumber details
            setGetSingleSubSahay(
              res?.data?.data?.application[0]?.subSahayTypeId
            );
            const member =
              familyMembers &&
              familyMembers?.filter((member) => {
                return (
                  member?.id == res?.data?.data?.application[0]?.applicantId
                );
              });
            if (member) {
              setApplicantSelectedMember(member ? member : []);
            }
            setTimeout(() => {
              setPageLoader(false);
            }, 1000);
          } else {
            setPageLoader(false);
          }
        });
    } catch (error) {
      // setPageLoader(false)
      // toast.error(error.response.data.message || error.message);
    }
  };

  //Document Uploads Sub sahay wise
  const uploadDocuments = (id, setFieldValue) => {
    const subSahayFilter = sahaySubTypeList?.filter((ele) => {
      return ele?.Id == id;
    });
    const filterData = subSahayFilter?.filter((ele) => {
      const subSahayMatch = sahayDocumentArray?.filter((subSahay) => {
        return subSahay?.subSahayName === ele?.SahayName;
      });

      console.log("subSahayMatchDoc", subSahayMatch);
      if (
        subSahayMatch?.length > 0 &&
        subSahayMatch?.[0]?.documentData?.length > 0
      ) {
        setFieldValue("documents", subSahayMatch?.[0]?.documentData);
        setFieldValue("subSahayName", subSahayMatch?.[0]?.subSahayName);
      } else {
        setFieldValue("documents", []);
      }
    });
  };

  //Single applicant
  const fetchSingleSelectedApplicant = (applicantId) => {
    const member =
      familyMembers &&
      familyMembers?.filter((member) => member?.id == applicantId);

    if (member?.[0]) {
      setEditSingleApplicantData({
        name:
          member?.[0]?.firstName +
          " " +
          member?.[0]?.middleName +
          " " +
          member?.[0]?.lastName,

        age: member?.[0]?.age ? member?.[0]?.age : 0,
        workingType: member?.[0]?.workingType,
        relation: member?.[0]?.relation,
        relation_id: member?.[0]?.relation_id,
      });
    }
  };

  useEffect(() => {
    if (location?.state?.editSahay?.applicationId) {
      getSingleSahayApplication();
    }
  }, [radioButtonFamily]);

  useEffect(() => {
    if (
      location?.state?.editSahay?.applicationId &&
      singleSahayAppicationData?.application?.[0]
    ) {
      const id = singleSahayAppicationData?.application?.[0]?.applicantId;
      fetchSingleSelectedApplicant(id);
      setMohholaid(singleSahayAppicationData?.application?.[0]?.mohollo_id);
    }
  }, [
    location?.state?.editSahay?.applicationId,
    singleSahayAppicationData?.application?.[0],
  ]);

  useEffect(() => {
    const file = {
      name: "demo.png",
      lastModified: 1701162357663,
      lastModifiedDate: new Date(
        "Tue Nov 28 2023 14:35:57 GMT+0530 (India Standard Time)"
      ),
      webkitRelativePath: "",
      size: 8595,
      type: "image/png",
    };
    const fileData = new File([new Blob([file])], file.name, {
      type: file.type,
    });
    setDemoDocument(fileData);
  }, []);

  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes(
      "Current Sahay Application_add"
    ) ||
    rollPermissionData?.permissionArray?.includes(
      "Current Sahay Application_edit"
    )
    //  || rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main abc">
              {location.pathname !== "/admin/apply-for-sahay-admin"}

              <Index.Box className="head-content">
                <Index.Box
                  className={`user-right ${
                    location.pathname == "/admin/apply-for-sahay-admin"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    className={`bottom-user-right ${
                      location.pathname == "/admin/apply-for-sahay-admin"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={
                          bankAccountsListData &&
                          bankAccountsListData?.length == 0
                            ? validationSchemaApplyForSahay
                            : validationSchemaApplyForSahayBankMember
                        }
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          setFieldValue,
                          handleBlur,
                          errors,
                          isValid,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            {console.log(
                              "documentData----values?.documents",
                              values?.documents
                            )}

                            <Index.Box className="main-in-row">
                              <Index.Box className="user-basic user-heading">
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  {location?.state?.editSahay?.applicationId
                                    ? "Edit apply for sahay"
                                    : "Apply for sahay"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="main-in-row">
                              <Index.Box
                                className="user-basic"
                                sx={{ display: "flex" }}
                              >
                                <Index.Typography
                                  component="p"
                                  className="head-apply"
                                >
                                  Basic Details
                                </Index.Typography>
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  ({" "}
                                  {getUserLoginDetails?.vastipatrak
                                    ? "Member"
                                    : "Non Member"}{" "}
                                  )
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box
                                sx={{ width: 1 }}
                                className="grid-main"
                              >
                                <Index.Box
                                  display="grid"
                                  className="multiple-row"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                >
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 12",
                                      md: "span 12",
                                      lg: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.FormControl
                                      sx={{ marginBottom: "15px" }}
                                    >
                                      <Index.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="family_radio_button"
                                        // value={values?.family_radio_button}
                                        value={radioButtonFamily}
                                        defaultValue="Vastrikpatrak"
                                        onChange={(e) => {
                                          handleChange(e);
                                          setRadioButtonFamily(e.target.value);
                                          setFilterByMobileNumber("");
                                          setFilterByMohollaName("");
                                        }}
                                      >
                                        <Index.FormControlLabel
                                          value="Vastrikpatrak"
                                          control={<Index.Radio />}
                                          label="Vastrikpatrak Member"
                                          disabled={
                                            location?.state?.editSahay
                                              ?.applicationId
                                          }
                                        />
                                        <Index.FormControlLabel
                                          value="NonVastrikpatrak"
                                          control={<Index.Radio />}
                                          label="Non Vastrikpatrak Member"
                                          disabled={
                                            location?.state?.editSahay
                                              ?.applicationId
                                          }
                                        />
                                      </Index.RadioGroup>
                                      <Index.Box className="sahay-multiple-input-main"></Index.Box>
                                    </Index.FormControl>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 3",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 3",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="sahay-multiple-input-main moholla-filter-main">
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        className="multiple-autocomplet-control"
                                        options={getMohalla || []}
                                        getOptionLabel={(option) =>
                                          option?.mohollo_name
                                        }
                                        disabled={
                                          singleSahayAppicationData
                                            ?.application?.[0]
                                        }
                                        onChange={(value, newvalue) => {
                                          setFilterByMohollaName(
                                            newvalue?.mohollo_id
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            {...params}
                                            label="Moholla Name"
                                          />
                                        )}
                                        value={
                                          Mohholaid == null
                                            ? getMohalla &&
                                              getMohalla?.length > 0 &&
                                              filterByMohollaName
                                              ? getMohalla.find(
                                                  (item) =>
                                                    item.mohollo_id ==
                                                    filterByMohollaName
                                                )
                                              : null
                                            : getMohalla?.find(
                                                (item) =>
                                                  item.mohollo_id == Mohholaid
                                              )
                                        }
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 3",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 3",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="sahay-multiple-input-main moholla-filter-main">
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        className="multiple-autocomplet-control"
                                        options={mobileNumberList || []}
                                        getOptionLabel={(option) =>
                                          option?.mobile_number
                                        }
                                        disabled={
                                          singleSahayAppicationData
                                            ?.application?.[0]
                                        }
                                        onChange={(value, newvalue) => {
                                          setFilterByMobileNumber(
                                            newvalue?.mobile_number
                                          );
                                        }}
                                        filterOptions={(
                                          options,
                                          { inputValue }
                                        ) => {
                                          const inputLowerCase =
                                            inputValue?.toLowerCase();
                                          const uniqueMobileNumber = new Set();
                                          return options?.filter((option) => {
                                            const mobileNumber =
                                              option?.mobile_number
                                                ?.toString()
                                                ?.toLowerCase();
                                            // Check unique
                                            if (
                                              uniqueMobileNumber?.has(
                                                mobileNumber
                                              )
                                            ) {
                                              return false;
                                            }
                                            // Check search
                                            if (
                                              mobileNumber?.includes(
                                                inputLowerCase
                                              )
                                            ) {
                                              uniqueMobileNumber?.add(
                                                mobileNumber
                                              );
                                              return true;
                                            }
                                            return false;
                                          });
                                        }}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            {...params}
                                            label="Mobile Number"
                                          />
                                        )}
                                        value={
                                          mobileNumberList &&
                                          mobileNumberList?.length > 0 &&
                                          filterByMobileNumber
                                            ? mobileNumberList.find(
                                                (item) =>
                                                  item.mobile_number ==
                                                  filterByMobileNumber
                                              )
                                            : null
                                        }
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>

                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    {location.pathname ==
                                    `/${rollData}/apply-for-sahay-admin` ? (
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          FamilyId
                                        </Index.Typography>
                                        <Index.Box className="sahay-multiple-input-main">
                                          <Autocomplete
                                            disablePortal
                                            className="multiple-autocomplet-control"
                                            id="combo-box-demo"
                                            options={familyListData}
                                            onOpen={handleMenuOpen}
                                            onClose={handleMenuClose}
                                            loading={loader}
                                            getOptionLabel={(option) =>
                                              // option?.family_id?.toString()
                                              `${option?.family_id?.toString()}  ${
                                                option?.firstName
                                              } ${option?.middleName} ${
                                                option?.lastName
                                              }`
                                            }
                                            onChange={(value, newvalue) => {
                                              setMohholaid(
                                                newvalue?.mohollo_id
                                              );

                                              setFieldValue(
                                                "familyId",
                                                newvalue?.family_id
                                              );
                                              setSahayTypeCondition(
                                                newvalue?.family_id
                                              );
                                              getSahaySubTypeData(
                                                newvalue?.family_id
                                              );
                                              setFamilyIdForApplicant(
                                                newvalue?.family_id
                                              );
                                              // setFilter({year:newvalue?.SubSahayWiseAmt?.Year})
                                            }}
                                            renderInput={(params) => (
                                              <Index.TextField
                                                {...params}
                                                placeholder="Select FamilyId"
                                                InputProps={{
                                                  ...params.InputProps,
                                                  endAdornment: (
                                                    <React.Fragment>
                                                      {/* {loader ? <CircularProgress color="inherit" size={20} /> : null} */}
                                                      {
                                                        params.InputProps
                                                          .endAdornment
                                                      }
                                                    </React.Fragment>
                                                  ),
                                                }}
                                              />
                                            )}
                                          />
                                        </Index.Box>
                                        {/* <Index.FormControl
                                          sx={{ width: "100%" }}
                                          className="main-select"
                                        >
                                        
                                          <Index.Select
                                            className="select-drop"
                                            displayEmpty
                                            onOpen={handleMenuOpen}
                                            onClose={handleMenuClose}
                                            value={values?.familyId}
                                            onChange={(e) => {
                                              setSahayTypeCondition(
                                                e.target.value
                                              );
                                              setFieldValue(
                                                "familyId",
                                                e.target.value
                                              );
                                              getSahaySubTypeData(
                                                e.target.value
                                              );
                                              setFamilyIdForApplicant(
                                                e.target.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            input={<OutlinedInput />}
                                            MenuProps={MenuProps}
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                          >
                                          
                                            {loader ? (
                                              <Index.Box className="loader-container">
                                                <CircularProgress className="small-circular-progress" />
                                              </Index.Box>
                                            ) : (
                                              familyListData?.map((ele) => (
                                                <Index.MenuItem
                                                  key={ele?.family_id}
                                                  value={ele?.family_id}
                                                >
                                                  {ele?.family_id}
                                                </Index.MenuItem>
                                              ))
                                            )}
                                          </Index.Select>
                                        </Index.FormControl> */}
                                        {errors?.familyId &&
                                          touched?.familyId && (
                                            <p className="error-text">
                                              {errors?.familyId}
                                            </p>
                                          )}
                                      </Index.Box>
                                    ) : (
                                      <>
                                        <Index.Box className="lable-input-grp">
                                          <Index.Typography
                                            component="p"
                                            className="in-label"
                                          >
                                            FamilyId*
                                          </Index.Typography>
                                          <Index.TextField
                                            inputProps={ariaLabel}
                                            className="basic-input input-width"
                                            value={
                                              singlaFamilyId &&
                                              `${singlaFamilyId?.family_id} ${singlaFamilyId?.firstName} ${singlaFamilyId?.middleName} ${singlaFamilyId?.lastName}`
                                            }
                                          />
                                        </Index.Box>{" "}
                                      </>
                                    )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Aadhar Card Number*
                                      </Index.Typography>
                                      <Index.TextField
                                        type="string"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="AadharNo"
                                        value={values?.AadharNo}
                                        onChange={(e) => {
                                          const inputValue =
                                            e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                          setFieldValue(
                                            "AadharNo",
                                            inputValue.slice(0, 12)
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.AadharNo && touched?.AadharNo && (
                                      <p className="error-text">
                                        {errors?.AadharNo}
                                      </p>
                                    )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Sahay Type*
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.SahayType}
                                          onChange={(e) => {
                                            setSahayTypeCondition(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "SahayType",
                                              e.target.value
                                            );
                                            getSahaySubTypeData(e.target.value);
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {sahayTypeList?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.Id}
                                              value={ele?.Id}
                                            >
                                              {ele?.Sahay_category}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.SahayType &&
                                        touched?.SahayType && (
                                          <p className="error-text">
                                            {errors?.SahayType}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Sub Sahay Type*
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.subSahayType}
                                          onChange={(e) => {
                                            setGetSingleSubSahay(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "subSahayType",
                                              e.target.value
                                            );
                                            setFieldValue("documents", []);
                                            uploadDocuments(
                                              e.target.value,
                                              setFieldValue
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {sahaySubTypeList?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.Id}
                                              value={ele?.Id}
                                            >
                                              {ele?.SahayName}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.subSahayType &&
                                        touched?.subSahayType && (
                                          <p className="error-text">
                                            {errors?.subSahayType}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  {/* <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Year Of Sahay*
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.yearOfSahay}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "yearOfSahay",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {listYearOfSahayData &&
                                            listYearOfSahayData
                                              ?.sort(
                                                (a, b) => a.toYear - b.toYear
                                              )
                                              .map((ele) => (
                                                <Index.MenuItem
                                                  key={ele?.id}
                                                  value={ele?.id}
                                                >
                                                  {ele?.fromYear}-{ele?.toYear}
                                                </Index.MenuItem>
                                              ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.yearOfSahay &&
                                        touched?.yearOfSahay && (
                                          <p className="error-text">
                                            {errors?.yearOfSahay}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid> */}
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Year Of Sahay*
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        type="string"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="yearOfSahay"
                                        value={values?.yearOfSahay}
                                      />
                                    </Index.Box>
                                    {errors?.yearOfSahay &&
                                      touched?.yearOfSahay && (
                                        <p className="error-text">
                                          {errors?.yearOfSahay}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  {console.log(
                                    "valus++.applicationReceiveDate",
                                    values?.applicationReceiveDate
                                  )}
                                  {console.log(
                                    "valus++.formFillingDate",
                                    values?.formFillingDate
                                  )}
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Application Received Date*
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <Index.DatePicker
                                            name="applicationReceiveDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "applicationReceiveDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(
                                              values?.applicationReceiveDate
                                            )}
                                            disableFuture
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.applicationReceiveDate &&
                                      touched.applicationReceiveDate && (
                                        <p className="error-text">
                                          {errors?.applicationReceiveDate}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Filing Date*
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <Index.DatePicker
                                            name="formFillingDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "formFillingDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(
                                              values?.formFillingDate
                                            )}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.formFillingDate &&
                                      touched.formFillingDate && (
                                        <p className="error-text">
                                          {errors?.formFillingDate}
                                        </p>
                                      )}
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="main-in-row">
                              <Index.Box className="user-basic">
                                <Index.Typography
                                  component="p"
                                  className="head-apply"
                                >
                                  Family Members Details
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups family-detail"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Name Of Applicant*
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.applicantId}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "applicantId",
                                              e.target.value
                                            );
                                            if (location?.state?.editSahay) {
                                              setFieldValue("AadharNo", "");
                                            }
                                            setMemberDeatils(
                                              e.target.value,
                                              setFieldValue
                                            );

                                            setFieldValue("bankName", "");
                                            setFieldValue("branch", "");
                                            setFieldValue("HolderName", "");
                                            setFieldValue("IFSCCode", "");
                                            setFieldValue("AccountNo", "");

                                            setApplicantId(e.target.value);
                                            setApplicantSelectedMember(
                                              familyMembers?.filter((item) => {
                                                return (
                                                  item?.id == e.target.value
                                                );
                                              })
                                            );
                                            setApplicantAllMember(
                                              familyMembers?.filter((item) => {
                                                return (
                                                  item?.id != e.target.value
                                                );
                                              })
                                            );
                                            setFieldValue(
                                              "relationRecords",
                                              familyMembers?.filter((item) => {
                                                return (
                                                  item?.id !== e.target.value
                                                );
                                              })
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {familyMembers?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.id}
                                              value={ele?.id}
                                            >
                                              {`${ele?.firstName} ${ele.middleName} ${ele.lastName}`}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.applicantId &&
                                        touched?.applicantId && (
                                          <p className="error-text">
                                            {errors?.applicantId}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Beneficiary Name*
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.beneficiaryName}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "beneficiaryName",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {familyMembers?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.id}
                                              value={ele?.id}
                                            >
                                              {`${ele?.firstName} ${ele?.middleName} ${ele?.lastName}`}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.beneficiaryName &&
                                        touched?.beneficiaryName && (
                                          <p className="error-text">
                                            {errors?.beneficiaryName}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Name*
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="memberName"
                                        value={values?.memberName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {errors?.memberName &&
                                        touched?.memberName && (
                                          <p className="error-text">
                                            {errors?.memberName}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Relation
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="relation"
                                        value={values?.relation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {errors?.relation &&
                                        touched?.relation && (
                                          <p className="error-text">
                                            {errors?.relation}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Age*
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="age"
                                        value={values?.age}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {errors?.age && touched?.age && (
                                        <p className="error-text">
                                          {errors?.age}
                                        </p>
                                      )}
                                    </Index.Box>
                                  </Index.Grid>
                                  {/* <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Working Type*
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="workingType"
                                        value={values?.workingType}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {errors?.workingType &&
                                        touched?.workingType && (
                                          <p className="error-text">
                                            {errors?.workingType}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Monthly Income
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="monthlyIncome"
                                        value={values?.monthlyIncome}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Electric Bill amount
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="threeMonthBillAmount"
                                        value={values?.threeMonthBillAmount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {errors?.threeMonthBillAmount &&
                                        touched?.threeMonthBillAmount && (
                                          <p className="error-text">
                                            {errors?.threeMonthBillAmount}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid> */}
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Working Type
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          name="workingType"
                                          value={values?.workingType}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {workingTypeList?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.id}
                                              value={ele?.name}
                                            >
                                              {ele?.name}
                                            </Index.MenuItem>
                                          ))}
                                          {/* {workingTypeList?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.id}
                                              value={ele?.name}
                                            >
                                              {ele?.name}
                                            </Index.MenuItem>
                                          ))} */}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.workingType &&
                                        touched?.workingType && (
                                          <p className="error-text">
                                            {errors?.workingType}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Family Income
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          name="monthlyIncome"
                                          value={values?.monthlyIncome}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {familyIncomeAmountList?.map(
                                            (ele) => (
                                              <Index.MenuItem
                                                key={ele?.id}
                                                value={ele?.ammount}
                                              >
                                                {ele?.ammount}
                                              </Index.MenuItem>
                                            )
                                          )}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.monthlyIncome &&
                                        touched?.monthlyIncome && (
                                          <p className="error-text">
                                            {errors?.monthlyIncome}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Electric Bill amount
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          name="threeMonthBillAmount"
                                          value={values?.threeMonthBillAmount}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {electricBillAmountList?.map(
                                            (ele) => (
                                              <Index.MenuItem
                                                key={ele?.id}
                                                value={ele?.ammount}
                                              >
                                                {ele?.ammount}
                                              </Index.MenuItem>
                                            )
                                          )}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.threeMonthBillAmount &&
                                        touched?.threeMonthBillAmount && (
                                          <p className="error-text">
                                            {errors?.threeMonthBillAmount}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="main-in-row row-bank">
                              <Index.Box className="user-basic">
                                <Index.Typography
                                  component="p"
                                  className="head-apply"
                                >
                                  Bank Details
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp bank-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                  {bankAccountsListData &&
                                    bankAccountsListData?.length > 0 && (
                                      <>
                                        <Index.Grid
                                          item
                                          xs={12}
                                          sm={4}
                                          md={4}
                                          lg={4}
                                        >
                                          <Index.Box className="lable-input-grp">
                                            <Index.Typography
                                              component="p"
                                              className="in-label"
                                            >
                                              Bank Account
                                            </Index.Typography>
                                            <Index.FormControl
                                              sx={{ width: "100%" }}
                                              className="main-select"
                                            >
                                              <Index.Select
                                                className="select-drop"
                                                displayEmpty
                                                value={values?.bankMasterId}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "bankMasterId",
                                                    e.target.value
                                                  );

                                                  let data =
                                                    bankAccountsListData?.filter(
                                                      (ele) => {
                                                        return (
                                                          ele?.id ==
                                                          e.target.value
                                                        );
                                                      }
                                                    );

                                                  if (data.length > 0) {
                                                    setFieldValue(
                                                      "bankName",
                                                      data[0]?.bankName
                                                    );
                                                    setFieldValue(
                                                      "branch",
                                                      data[0]?.branch
                                                    );
                                                    setFieldValue(
                                                      "HolderName",
                                                      data[0]?.HolderName
                                                    );
                                                    setFieldValue(
                                                      "IFSCCode",
                                                      data[0]?.IFSCCode
                                                    );
                                                    setFieldValue(
                                                      "AccountNo",
                                                      data[0]?.AccountNo
                                                    );
                                                  }
                                                }}
                                                onBlur={handleBlur}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                              >
                                                {bankAccountsListData &&
                                                  bankAccountsListData?.map(
                                                    (ele) => (
                                                      <Index.MenuItem
                                                        key={ele?.id}
                                                        value={ele?.id}
                                                      >
                                                        {ele?.bankName}
                                                      </Index.MenuItem>
                                                    )
                                                  )}
                                              </Index.Select>
                                            </Index.FormControl>
                                            {errors?.bankMasterId &&
                                              touched?.bankMasterId && (
                                                <p className="error-text">
                                                  {errors?.bankMasterId}
                                                </p>
                                              )}
                                          </Index.Box>
                                        </Index.Grid>
                                      </>
                                    )}
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Bank Name*
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="bankName"
                                        value={values?.bankName}
                                        onChange={(e) => {
                                          const uppercaseValue =
                                            e.target.value.toUpperCase();
                                          handleChange(e.target.name)(
                                            uppercaseValue
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.bankName && touched?.bankName && (
                                      <p className="error-text">
                                        {errors?.bankName}
                                      </p>
                                    )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Branch*
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="branch"
                                        value={values?.branch}
                                        onChange={(e) => {
                                          const uppercaseValue =
                                            e.target.value.toUpperCase();
                                          handleChange(e.target.name)(
                                            uppercaseValue
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.branch && touched?.branch && (
                                      <p className="error-text">
                                        {errors?.branch}
                                      </p>
                                    )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Account Holder Name*
                                      </Index.Typography>
                                      <Index.TextField
                                        type="text"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="HolderName"
                                        value={values?.HolderName}
                                        onChange={(e) => {
                                          let uppercaseValue =
                                            e.target.value.toUpperCase();
                                          if (uppercaseValue.length > 51) {
                                            uppercaseValue =
                                              uppercaseValue.slice(0, 51);
                                          }
                                          handleChange(e);
                                          setFieldValue(
                                            "HolderName",
                                            uppercaseValue
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.HolderName &&
                                      touched?.HolderName && (
                                        <p className="error-text">
                                          {errors?.HolderName}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Account Number*{" "}
                                      </Index.Typography>
                                      <Index.TextField
                                        type="string"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="AccountNo"
                                        value={values?.AccountNo}
                                        onChange={(e) => {
                                          const inputValue =
                                            e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                          setFieldValue(
                                            "AccountNo",
                                            inputValue.slice(0, 18)
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.AccountNo &&
                                      touched?.AccountNo && (
                                        <p className="error-text">
                                          {errors?.AccountNo}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        IFSC Code*
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="IFSCCode"
                                        value={values?.IFSCCode}
                                        onChange={(e) => {
                                          const uppercaseValue =
                                            e.target.value.toUpperCase();
                                          handleChange(e.target.name)(
                                            uppercaseValue.slice(0, 11)
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.IFSCCode && touched?.IFSCCode && (
                                      <p className="error-text">
                                        {errors?.IFSCCode}
                                      </p>
                                    )}
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>

                            {getSingleSubSahay == 1017 ? (
                              <Index.Box
                                className="main-in-row row-bank"
                                sx={{ marginTop: "15px" }}
                              >
                                <Index.Box className="user-basic">
                                  <Index.Typography
                                    component="p"
                                    sx={{ fontSize: "12px" }}
                                    // className="head-apply"
                                  >
                                    Type Of Disease
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  sx={{ flexGrow: 1 }}
                                  className="al-form-groups fam-in-grp bank-in-grp"
                                >
                                  <Index.Grid container spacing={2}>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.TextField
                                          inputProps={ariaLabel}
                                          className="basic-input input-width"
                                          name="typeOfDisease"
                                          value={values?.typeOfDisease}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </Index.Box>
                                    </Index.Grid>
                                  </Index.Grid>
                                </Index.Box>
                              </Index.Box>
                            ) : null}
                            <Index.Box className="main-in-row row-bank">
                              <Index.Box
                                sx={{ flexGrow: 1, marginTop: "20px" }}
                                className="al-form-groups fam-in-grp bank-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                  {!EducationSubsahayValue.includes(
                                    getSingleSubSahay
                                  ) && (
                                    <>
                                      {/* <Index.Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                      >
                                        <Index.Box className="lable-input-grp">
                                          <Index.Typography
                                            component="p"
                                            className="in-label"
                                          >
                                            Mediclaim Policy Amount
                                          </Index.Typography>
                                          <Index.TextField
                                            inputProps={ariaLabel}
                                            className="basic-input input-width"
                                            name="mediclaimAmmount"
                                            value={values?.mediclaimAmmount}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                          />
                                        </Index.Box>
                                      </Index.Grid> */}
                                    </>
                                  )}
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        {/* Total Claimed Sahay Amount */}
                                        Total Requested Sahay Amount
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="claimedAmmount"
                                        value={values?.claimedAmmount}
                                        onChange={(e) => {
                                          const valueData =
                                            e.target.value?.slice(0, 7);
                                          setFieldValue(
                                            "claimedAmmount",
                                            valueData
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.claimedAmmount &&
                                      touched?.claimedAmmount && (
                                        <p className="error-text">
                                          {errors?.claimedAmmount}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  {getSingleSubSahay != 60 &&
                                    getSingleSubSahay != 56 &&
                                    values?.isSahay == "yes" && (
                                      <Index.Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                      >
                                        <Index.Box className="lable-input-grp">
                                          <Index.Typography
                                            component="p"
                                            className="in-label"
                                          >
                                            Sahay Amount
                                          </Index.Typography>
                                          <Index.TextField
                                            type="number"
                                            inputProps={ariaLabel}
                                            className="basic-input input-width"
                                            name="SahayAmmount"
                                            value={values?.SahayAmmount}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                          />
                                        </Index.Box>
                                      </Index.Grid>
                                    )}
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    {getSingleSubSahay == 56 ? (
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Rent Amount For Rent Sahay
                                        </Index.Typography>
                                        <Index.TextField
                                          inputProps={ariaLabel}
                                          className="basic-input input-width"
                                          name="rentSahayAmmount"
                                          value={values?.rentSahayAmmount}
                                          onChange={handleChange}
                                          // onBlur={handleBlur}
                                        />
                                      </Index.Box>
                                    ) : null}
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>

                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp mb-0px-sahay"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={8} md={8} lg={8}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Remark
                                      </Index.Typography>
                                      {/* <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="remark"
                                        value={values?.remark}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                      /> */}
                                      <Index.Textarea
                                        style={{
                                          fontSize: "15px",
                                          color: "black",
                                          padding: "4px",
                                          minHeight: "50px",
                                          width: "100%",
                                        }}
                                        minRows={4}
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="remark"
                                        value={values?.remark}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>

                              <Index.Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                                className="al-form-groups fam-in-grp"
                              ></Index.Grid>

                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups family-detail fam-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                  {getSingleSubSahay !== 1014 ? (
                                    <>
                                      <Index.Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                      >
                                        <Index.Box className="lable-input-grp">
                                          <Index.Typography
                                            component="p"
                                            className="in-label"
                                          >
                                            Does The Applicant Take Mediclaim
                                            Premium Sahay
                                          </Index.Typography>
                                          <Index.ToggleButtonGroup
                                            color="primary"
                                            value={values?.isSahay}
                                            exclusive
                                            onChange={(e) => {
                                              setFieldValue(
                                                "isSahay",
                                                e.target.value
                                              );
                                            }}
                                            aria-label="Platform"
                                            className="toggle-switch-main"
                                          >
                                            <Index.ToggleButton value={"Yes"}>
                                              Yes
                                            </Index.ToggleButton>
                                            <Index.ToggleButton value={"No"}>
                                              No
                                            </Index.ToggleButton>
                                          </Index.ToggleButtonGroup>
                                        </Index.Box>
                                      </Index.Grid>
                                      <Index.Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                      >
                                        <Index.Box className="lable-input-grp">
                                          <Index.Typography
                                            component="p"
                                            className="in-label"
                                          >
                                            Does The Applicant Have A Mediclaim
                                            Policys
                                          </Index.Typography>
                                          <Index.ToggleButtonGroup
                                            color="primary"
                                            value={values?.isMediclaim}
                                            exclusive
                                            onChange={(e) => {
                                              setFieldValue(
                                                "isMediclaim",
                                                e.target.value
                                              );
                                            }}
                                            aria-label="Platform"
                                            className="toggle-switch-main"
                                          >
                                            <Index.ToggleButton value="Yes">
                                              Yes
                                            </Index.ToggleButton>
                                            <Index.ToggleButton value="No">
                                              No
                                            </Index.ToggleButton>
                                          </Index.ToggleButtonGroup>
                                        </Index.Box>
                                      </Index.Grid>
                                    </>
                                  ) : null}
                                </Index.Grid>
                              </Index.Box>

                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups family-detail fam-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={4}
                                  >
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                        sx={{ fontSize: "12px" }}
                                      >
                                        Does applicant took sahay from any trust
                                      </Index.Typography>
                                      <Index.ToggleButtonGroup
                                        color="primary"
                                        value={values?.trust}
                                        exclusive
                                        onChange={(e) => {
                                          setSahayCondition(e.target.value);
                                          setFieldValue(
                                            "trust",
                                            e.target.value
                                          );
                                        }}
                                        aria-label="Platform"
                                        className="toggle-switch-main"
                                      >
                                        <Index.ToggleButton value="yes">
                                          Yes
                                        </Index.ToggleButton>
                                        <Index.ToggleButton value="no">
                                          No
                                        </Index.ToggleButton>
                                      </Index.ToggleButtonGroup>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>

                              {sahayCondition == "yes" ? (
                                <>
                                  <Index.Box
                                    sx={{ flexGrow: 1 }}
                                    className="al-form-groups fam-in-grp"
                                  >
                                    <Index.Grid container spacing={2}>
                                      {/* fieldarraystart */}
                                      <Index.Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                      >
                                        <PagesIndex.FieldArray name="dataArray">
                                          {({ push, remove }) => (
                                            <div className="trust-main-div">
                                              <Index.Box className="common-button  flex-end save-btn flex-start-admin">
                                                <Index.Button
                                                  variant="contained"
                                                  className="comman-btn-add-details"
                                                  onClick={() =>
                                                    push({
                                                      trustName: "",
                                                      ammount: 0,
                                                    })
                                                  }
                                                >
                                                  <Index.ControlPointIcon className="plus-add" />{" "}
                                                  Add Trust
                                                </Index.Button>
                                              </Index.Box>

                                              {values?.dataArray?.map(
                                                (data, index) => (
                                                  <Index.Grid
                                                    container
                                                    spacing={2}
                                                    key={index}
                                                    mb={2}
                                                  >
                                                    <Index.Grid
                                                      item
                                                      xs={12}
                                                      sm={4}
                                                      md={4}
                                                      lg={4}
                                                    >
                                                      <Index.Box className="lable-input-grp">
                                                        <Index.Typography
                                                          component="p"
                                                          className="in-label"
                                                        >
                                                          Trust Name
                                                        </Index.Typography>
                                                        <Index.TextField
                                                          inputProps={ariaLabel}
                                                          className="basic-input input-width"
                                                          onBlur={handleBlur}
                                                          name={`dataArray.${index}.trustName`}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          value={
                                                            values.dataArray[
                                                              index
                                                            ].trustName
                                                          }
                                                        />
                                                      </Index.Box>
                                                      {errors?.trustName &&
                                                        touched?.trustName && (
                                                          <p className="error-text">
                                                            {errors?.trustName}
                                                          </p>
                                                        )}
                                                    </Index.Grid>
                                                    <Index.Grid
                                                      item
                                                      xs={12}
                                                      sm={4}
                                                      md={4}
                                                      lg={4}
                                                    >
                                                      <Index.Box className="lable-input-grp">
                                                        <Index.Typography
                                                          component="p"
                                                          className="in-label"
                                                        >
                                                          Amount
                                                        </Index.Typography>
                                                        <Index.TextField
                                                          type="number"
                                                          inputProps={ariaLabel}
                                                          className="basic-input input-width"
                                                          onBlur={handleBlur}
                                                          name={`dataArray.${index}.ammount`}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          value={
                                                            values.dataArray[
                                                              index
                                                            ].ammount
                                                          }
                                                        />
                                                      </Index.Box>
                                                      {errors?.ammount &&
                                                        touched?.ammount && (
                                                          <p className="error-text">
                                                            {errors?.ammount}
                                                          </p>
                                                        )}
                                                    </Index.Grid>

                                                    {index > 0 && (
                                                      <Index.Grid
                                                        item
                                                        xs={12}
                                                        sm={4}
                                                        md={2}
                                                        lg={2}
                                                      >
                                                        <Index.Box className="common-button  ">
                                                          <Index.Button
                                                            variant="contained"
                                                            className="remove-details-content"
                                                            onClick={() =>
                                                              remove(index)
                                                            }
                                                          >
                                                            <RemoveIcon />
                                                          </Index.Button>
                                                        </Index.Box>{" "}
                                                      </Index.Grid>
                                                    )}
                                                  </Index.Grid>
                                                )
                                              )}
                                            </div>
                                          )}
                                        </PagesIndex.FieldArray>
                                      </Index.Grid>
                                    </Index.Grid>
                                  </Index.Box>
                                </>
                              ) : null}
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp mb-0px-sahay"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp ">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Passport Size Photo
                                      </Index.Typography>
                                      <Index.Box className="select-position">
                                        <input
                                          onBlur={handleBlur}
                                          type="file"
                                          name="passportPic"
                                          className="ori-input"
                                          onChange={(e) => {
                                            setFieldValue(
                                              "passportPic",
                                              e.target?.files?.[0]
                                            );
                                            setSelectedImage(
                                              e.target?.files?.[0]
                                            );
                                          }}
                                        />

                                        <Index.Box className="select-file">
                                          Upload Your Photo
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>

                                    {errors?.passportPic &&
                                      touched?.passportPic && (
                                        <p className="error-text">
                                          {errors?.passportPic}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp ">
                                      <Index.Box className="select-view">
                                        <img
                                          src={
                                            selectedImage
                                              ? URL.createObjectURL(
                                                  selectedImage
                                                )
                                              : values?.passportPic
                                              ? `${imagePath}${values?.passportPic}`
                                              : "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
                                          }
                                        />
                                        <Index.Box
                                          className="select-remove"
                                          onClick={() =>
                                            resetImage(setFieldValue)
                                          }
                                        >
                                          Remove
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                              {/* <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    mb={2}
                                  >
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Add Multiple Document*
                                      </Index.Typography>
                                      <Index.Box className="select-position">
                                        <input
                                          type="file"
                                          name="documents"
                                          multiple
                                          className="ori-input"
                                          onChange={(event) => {
                                            const files = event.target.files;
                                            setFieldValue("documents", [
                                              ...values.documents,
                                              ...files,
                                            ]);
                                          }}
                                        />

                                        <Index.Box className="select-file">
                                          Upload Your Document
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                    {errors?.documents &&
                                      touched?.documents && (
                                        <p className="error-text">
                                          {errors?.documents}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={8}>
                                    <Index.Box className="select-view-document-name">
                                      {values.documents.map((file, index) => (
                                        <Index.Box
                                          className="document-name"
                                          key={index}
                                        >
                                          <Index.Typography
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              // Implement the logic to view the document here
                                              // You can use a modal or a new browser tab/window to show the document
                                              window.open(
                                                file instanceof Blob
                                                  ? URL.createObjectURL(file)
                                                  : `${imagePath}${file?.document}`
                                              );
                                            }}
                                          >
                                            {file instanceof Blob
                                              ? file.name
                                              : file.document}
                                          </Index.Typography>
                                          <Index.IconButton
                                            onClick={() => {
                                              const updatedDocuments =
                                                values.documents.filter(
                                                  (_, i) => i !== index
                                                );
                                              setFieldValue(
                                                "documents",
                                                updatedDocuments
                                              );
                                            }}
                                          >
                                            <Index.ClearIcon className="muiico-cancel" />
                                          </Index.IconButton>
                                        </Index.Box>
                                      ))} 
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box> */}
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Add Multiple Document*
                                      </Index.Typography>
                                      <Index.Box
                                        display="grid"
                                        className="display-row-add-user"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                      >
                                        {values?.documents?.length > 0 && (
                                          <FieldArray name="documentData">
                                            {({ push, remove }) => (
                                              <>
                                                {values?.documents.map(
                                                  (document, index) => (
                                                    <Index.Box
                                                      gridColumn={{
                                                        xs: "span 3",
                                                        sm: "span 3",
                                                        md: "span 3",
                                                        lg: "span 3",
                                                        xl: "span 3",
                                                      }}
                                                      className="grid-column"
                                                      key={
                                                        document.documentType
                                                      }
                                                    >
                                                      <Index.Box className="roles-data-box add-check-flex-doc">
                                                        <Index.Box className="add-role-flex">
                                                          <Index.Checkbox
                                                            className="check-box-role"
                                                            checked={
                                                              document?.isUpload
                                                            }
                                                            onChange={() => {
                                                              setFieldValue(
                                                                `documents[${index}].isUpload`,
                                                                !document.isUpload
                                                              );
                                                              setFieldValue(
                                                                `documents[${index}].file`,
                                                                []
                                                              );
                                                            }}

                                                            // name={`${subSahay.subSahayName}-${document.documentType}`}
                                                          />
                                                          <Index.Box className="role-data-title">
                                                            {
                                                              document.documentType
                                                            }
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                      {console.log(
                                                        "Test-Document",
                                                        values.documents
                                                      )}
                                                      {document?.isUpload && (
                                                        <>
                                                          <Index.Box className="select-position">
                                                            <input
                                                              multiple
                                                              type="file"
                                                              name="documents"
                                                              // multiple
                                                              className="ori-input"
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                const files =
                                                                  event.target
                                                                    .files;
                                                                const allDocuments =
                                                                  [
                                                                    ...values
                                                                      .documents[
                                                                      index
                                                                    ].file,
                                                                    ...files,
                                                                  ]; // Concatenating existing files with newly selected files
                                                                console.log(
                                                                  allDocuments,
                                                                  "niru"
                                                                );
                                                                setFieldValue(
                                                                  `documents[${index}].file`,
                                                                  allDocuments
                                                                );
                                                              }}
                                                              onBlur={(
                                                                event
                                                              ) => {
                                                                if (
                                                                  values
                                                                    .documents[
                                                                    index
                                                                  ].isUpload
                                                                ) {
                                                                  handleBlur(
                                                                    event
                                                                  );
                                                                }
                                                              }}
                                                            />

                                                            <Index.Box className="select-file">
                                                              Upload Your
                                                              Document
                                                            </Index.Box>
                                                            {console.log(
                                                              "errorsssss++--",
                                                              errors
                                                            )}

                                                            {errors
                                                              ?.documents?.[
                                                              index
                                                            ]?.file && (
                                                              <p className="error-text">
                                                                {
                                                                  errors
                                                                    ?.documents?.[
                                                                    index
                                                                  ]?.file
                                                                }
                                                              </p>
                                                            )}
                                                          </Index.Box>
                                                          {console.log(
                                                            "values?.documents***",
                                                            values.documents
                                                          )}
                                                          {document.file
                                                            ?.length > 0 &&
                                                            document?.file?.map(
                                                              (
                                                                item,
                                                                fileIndex
                                                              ) => {
                                                                return (
                                                                  <Index.Box className="select-view-document-name mt-select-doc">
                                                                    <Index.Box className="document-name">
                                                                      <Index.Typography
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                          window.open(
                                                                            item instanceof
                                                                              Blob
                                                                              ? URL.createObjectURL(
                                                                                  item
                                                                                )
                                                                              : // : `${imagePath}${values.documents[index].filePath}`
                                                                                `${imagePath}${item?.filename}`
                                                                          );
                                                                        }}
                                                                      >
                                                                        {item instanceof
                                                                        Blob
                                                                          ? item.name
                                                                          : item?.document}
                                                                        {/* // : values
                                                                          //     .documents[
                                                                          //     index
                                                                          //   ]
                                                                          //     .file} */}
                                                                      </Index.Typography>
                                                                      <Index.IconButton
                                                                        onClick={() => {
                                                                          console.log(
                                                                            "onClickRemove",
                                                                            item?.id
                                                                          );
                                                                          const updatedFiles =
                                                                            document.file?.filter(
                                                                              (
                                                                                ele,
                                                                                i
                                                                              ) =>
                                                                                i !==
                                                                                fileIndex
                                                                            );
                                                                          setFieldValue(
                                                                            `documents[${index}].file`,
                                                                            updatedFiles
                                                                          );
                                                                        }}
                                                                      >
                                                                        <Index.ClearIcon className="muiico-cancel" />
                                                                      </Index.IconButton>
                                                                    </Index.Box>
                                                                  </Index.Box>
                                                                );
                                                              }
                                                            )}
                                                        </>
                                                      )}
                                                    </Index.Box>
                                                  )
                                                )}
                                              </>
                                            )}
                                          </FieldArray>
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>

                              <Index.Box className="field-table">
                                <Index.Grid>
                                  <Index.Box className="">
                                    <Index.TableContainer
                                      component={Index.Paper}
                                      className="table-container edit-drop-container"
                                    >
                                      <Index.Table
                                        aria-label="simple table"
                                        className="table-design-main barge-table edit-drop-table"
                                      >
                                        <Index.TableHead className="edit-drop-head">
                                          <Index.TableRow>
                                            <Index.TableCell align="left">
                                              Name
                                            </Index.TableCell>
                                            <Index.TableCell align="left">
                                              Relation
                                            </Index.TableCell>
                                            <Index.TableCell align="left">
                                              Age
                                            </Index.TableCell>
                                            <Index.TableCell align="left">
                                              Working Type
                                            </Index.TableCell>
                                            {/* <Index.TableCell align="left">
                                              Monthly Income
                                            </Index.TableCell> */}
                                          </Index.TableRow>
                                        </Index.TableHead>
                                        <Index.TableBody>
                                          <Index.TableRow>
                                            <Index.TableCell
                                              align="left"
                                              className="tablecell"
                                            >
                                              <Index.TextField
                                                placeholder="Name"
                                                small
                                                disabled
                                                inputProps={ariaLabel}
                                                className="tablecell-field"
                                                value={
                                                  // applicantSelectedMember[0]
                                                  //   ? applicantSelectedMember[0]
                                                  //       ?.firstName +
                                                  //     " " +
                                                  //     applicantSelectedMember[0]
                                                  //       ?.middleName +
                                                  //     " " +
                                                  //     applicantSelectedMember[0]
                                                  //       ?.lastName
                                                  //   : ""
                                                  values?.memberName
                                                }
                                              />
                                            </Index.TableCell>
                                            <Index.TableCell
                                              align="left"
                                              className="tablecell"
                                            >
                                              <Index.TextField
                                                placeholder="Relation"
                                                small
                                                inputProps={ariaLabel}
                                                className="tablecell-field"
                                                disabled
                                                value={
                                                  // applicantSelectedMember[0]
                                                  //   ?.relation
                                                  //   ? "Applicant"
                                                  //   : ""
                                                  values?.relation
                                                    ? "Applicant"
                                                    : ""
                                                }
                                              />
                                            </Index.TableCell>

                                            <Index.TableCell
                                              align="left"
                                              className="tablecell"
                                            >
                                              <Index.TextField
                                                placeholder="Age"
                                                small
                                                disabled
                                                inputProps={ariaLabel}
                                                className="tablecell-field"
                                                value={
                                                  // applicantSelectedMember[0]
                                                  //   ?.age
                                                  values?.age
                                                }
                                              />
                                            </Index.TableCell>
                                            <Index.TableCell
                                              align="left"
                                              className="tablecell"
                                            >
                                              <Index.TextField
                                                small
                                                inputProps={ariaLabel}
                                                name="workingType"
                                                className="tablecell-field"
                                                onChange={handleChange}
                                                value={
                                                  // applicantSelectedMember[0]
                                                  //   ?.workingType
                                                  values?.workingType
                                                }
                                                disabled
                                                placeholder="Job"
                                              />
                                            </Index.TableCell>
                                            {/* <Index.TableCell
                                              align="left"
                                              className="tablecell"
                                            >
                                              <Index.TextField
                                                small
                                                disabled
                                                name="monthlyIncome"
                                                inputProps={ariaLabel}
                                                className="tablecell-field"
                                                value={values?.monthlyIncome}
                                                onChange={handleChange}
                                                placeholder="Monthly Income"
                                              />
                                            </Index.TableCell> */}
                                          </Index.TableRow>

                                          <PagesIndex.FieldArray name="relationRecords">
                                            {({ push, remove }) => (
                                              <>
                                                <>
                                                  {values?.relationRecords?.map(
                                                    (data, index) => (
                                                      <Index.TableRow>
                                                        <Index.TableCell
                                                          align="left"
                                                          className="tablecell"
                                                        >
                                                          <Index.TextField
                                                            placeholder="Name"
                                                            small
                                                            inputProps={
                                                              ariaLabel
                                                            }
                                                            name={`relationRecords.${index}.firstName`}
                                                            className="tablecell-field"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            value={
                                                              values
                                                                .relationRecords[
                                                                index
                                                              ].firstName +
                                                              " " +
                                                              values
                                                                .relationRecords[
                                                                index
                                                              ].middleName +
                                                              " " +
                                                              values
                                                                .relationRecords[
                                                                index
                                                              ].lastName
                                                            }
                                                          />
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                          align="left"
                                                          className="tablecell"
                                                        >
                                                          <Index.Box className="input-design-div with-border max-content-ellipsis">
                                                            <Index.Select
                                                              hiddenLabel
                                                              fullWidth
                                                              id="hgh"
                                                              value={
                                                                values
                                                                  .relationRecords[
                                                                  index
                                                                ].relation_id
                                                              }
                                                              name={`relationRecords.${index}.relation_id`}
                                                              onBlur={
                                                                handleBlur
                                                              }
                                                              onChange={
                                                                handleChange
                                                              }
                                                            >
                                                              {getRelationList &&
                                                                getRelationList?.map(
                                                                  (option) => (
                                                                    <Index.MenuItem
                                                                      noWrap
                                                                      key={
                                                                        option.relation_id
                                                                      }
                                                                      value={
                                                                        option.relation_id
                                                                      }
                                                                      name={
                                                                        option.relation_name
                                                                      }
                                                                    >
                                                                      {
                                                                        option.relation_name
                                                                      }
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                            </Index.Select>
                                                          </Index.Box>
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                          align="left"
                                                          className="tablecell"
                                                        >
                                                          <Index.TextField
                                                            placeholder="Age"
                                                            small
                                                            inputProps={
                                                              ariaLabel
                                                            }
                                                            className="tablecell-field"
                                                            value={
                                                              values
                                                                .relationRecords[
                                                                index
                                                              ].age
                                                            }
                                                            name={`relationRecords.${index}.age`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                          />
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                          align="left"
                                                          className="tablecell"
                                                        >
                                                          {/* <Index.TextField
                                                            placeholder="Job"
                                                            small
                                                            inputProps={
                                                              ariaLabel
                                                            }
                                                            className="tablecell-field"
                                                            value={
                                                              values
                                                                .relationRecords[
                                                                index
                                                              ].workingType
                                                            }
                                                            name={`relationRecords.${index}.workingType`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                          /> */}
                                                          <Index.Box className="input-design-div with-border max-content-ellipsis">
                                                            <Index.Select
                                                              hiddenLabel
                                                              fullWidth
                                                              id="hghds"
                                                              name={`relationRecords.${index}.workingType`}
                                                              value={
                                                                values
                                                                  .relationRecords[
                                                                  index
                                                                ].workingType
                                                              }
                                                              onChange={
                                                                handleChange
                                                              }
                                                              onBlur={
                                                                handleBlur
                                                              }
                                                            >
                                                              {workingTypeList?.map(
                                                                (ele) => (
                                                                  <Index.MenuItem
                                                                    key={
                                                                      ele?.id
                                                                    }
                                                                    value={
                                                                      ele?.name
                                                                    }
                                                                  >
                                                                    {ele?.name}
                                                                  </Index.MenuItem>
                                                                )
                                                              )}
                                                            </Index.Select>
                                                          </Index.Box>
                                                        </Index.TableCell>
                                                        {/* <Index.TableCell
                                                          align="left"
                                                          className="tablecell"
                                                        >
                                                          <Index.TextField
                                                            type="number"
                                                            small
                                                            inputProps={
                                                              ariaLabel
                                                            }
                                                            className="tablecell-field"
                                                            placeholder="Monthly Income"
                                                            value={
                                                              values
                                                                .relationRecords[
                                                                index
                                                              ].AnnualIncome
                                                            }
                                                            name={`relationRecords.${index}.AnnualIncome`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                          />
                                                        </Index.TableCell> */}
                                                      </Index.TableRow>
                                                    )
                                                  )}
                                                </>
                                              </>
                                            )}
                                          </PagesIndex.FieldArray>
                                        </Index.TableBody>
                                      </Index.Table>
                                    </Index.TableContainer>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Box>

                              {/* <Index.Box
                                  sx={{ flexGrow: 1 }}
                                  className="al-form-groups fam-in-grp"
                                >
                                  <Index.Grid container spacing={2}>
                                    <Index.Grid item xs={6}>
                                      <Index.Box className="lable-input-grp ">
                                        <Index.Box className="select-position">
                                          <Index.Box
                                            className={
                                              values.isSubmit === false
                                                ? "apply-now"
                                                : "disable-now"
                                            }
                                          >
                                            <button
                                              type="submit"
                                              disabled={values.isSubmit}
                                            >
                                              {location?.state?.editSahay?.applicationId
                                                ? "Update"
                                                : "Apply Now"}
                                            </button>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Grid>
                                    <Index.Grid item xs={6}>
                                      <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                        <Index.Button
                                          variant="contained"
                                          onClick={() => handleBack()}
                                        >
                                          Back
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.Grid>
                                  </Index.Grid>
                                </Index.Box> */}
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-position">
                                      <Index.Box
                                        className={
                                          values.isSubmit === false
                                            ? "apply-now"
                                            : "disable-now"
                                        }
                                      >
                                        <button
                                          type="submit"
                                          disabled={values.isSubmit}
                                        >
                                          {location?.state?.editSahay
                                            ?.applicationId
                                            ? "Update"
                                            : "Apply Now"}
                                        </button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                    <Index.Button
                                      variant="contained"
                                      onClick={() => handleBack()}
                                    >
                                      Back
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </form>
                        )}
                      </PagesIndex.Formik>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
      <Index.Dialog
        open={modalOpen}
        className="background_loader"
        onClose={handleCloseModal}
        aria-labelledby="transparent-modal-title"
        fullWidth
      >
        {formPageLoader && <PageLoading />}
      </Index.Dialog>
    </>
  );
};

export default ApplyForSahayAdmin;
