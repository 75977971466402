import Index from "../../../../Index";
import React, { useEffect, useState } from "react";
import PagesIndex from "../../../../PagesIndex";
import {
  deleteYearOfSahay,
  getYearOfSahayMasterList,
  multipleDeleteYearOfSahay,
} from "../../../../../redux/features/AddService";
import { STATUSES } from "../../../../../redux/features/adminslice/AdminSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import PageLoading from "../../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
const Yearofsahay = () => {
  //useselector
  const { yearOfSahayMasterList, status } = PagesIndex.useSelector(
    (state) => state.admin
  );

  //dispatch navigate
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  //all state
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState(true);
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  let deleteText = "year of sahay";
  //mui click menu
  const handleClickMenu = (e, index) => {
    setAnchorEl(e.currentTarget);
  };

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();

  //mui clode menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // update sahay
  const handleSahayYearEdit = (row) => {
    navigate(`/${rollData}/year-of-sahay/add`, {
      state: row,
    });
  };

  // open delete model
  const handleDeleteOpen = (data) => {
    setRowId(data);
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  // close delete model
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  // handle sahay year delete
  const handleSahayYearDelete = () => {
    setLoader(false);
    dispatch(deleteYearOfSahay(rowId)).then((res) => {
      if (res?.payload?.status === 200) {
        setDeleteOpen(false);
        dispatch(getYearOfSahayMasterList());
        setLoader(false);
      } else {
        setLoader(false);
      }
      setDeleteOpen(false);
    });
  };

  useEffect(() => {
    dispatch(getYearOfSahayMasterList()).then((res) => {
      if (res?.payload) {
        setLoader(false);
      }
      setLoader(false);
    });
  }, []);

  //search start
  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(yearOfSahayMasterList);
  }, [yearOfSahayMasterList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(yearOfSahayMasterList);
    setSearchedData(
      yearOfSahayMasterList?.filter(
        (item) =>
          item?.fromYear.toString().includes(searched.toLowerCase()) ||
          item?.toYear.toString().includes(searched.toLowerCase())
      )
    );
  };

  //search end

  const handleCheckboxChange = (itemId) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId));
    } else {
      setSelectedRows([...selectedRows, itemId]);
    }
  };

  const handleAllDelete = async () => {
    const urlEncodedData = new URLSearchParams();

    selectedRows?.forEach((id, i) => {
      urlEncodedData.append(`ids[0][id${i + 1}]`, id);
    });

    dispatch(multipleDeleteYearOfSahay(urlEncodedData)).then((res) => {
      if (res.payload == 200) {
        dispatch(getYearOfSahayMasterList());
      }
    });

    setSelectedRows([]);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allItemIds = pageData.map((item) => item.id);
      setSelectedRows(allItemIds);
    }
    setSelectAll(!selectAll);
  };

  

  if (
    rollPermissionData?.permissionArray?.includes("Year Of Sahay Master_view")|| rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Year Of Sahay Master
                      </Index.Typography>
                    </Index.Box>
                    {rollPermissionData?.permissionArray?.includes(
                      "Year Of Sahay Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <Index.Box className="mobile-menu-show-hover">
                            <PagesIndex.Link
                              to={`/${rollData}/year-of-sahay/add`}
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                            <Index.Box className="hover-tooltip-custom">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="title-tooltip"
                              >
                                Add Year
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollPermissionData?.permissionArray?.includes(
                      "Year Of Sahay Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <Index.Link
                            to={`/${rollData}/year-of-sahay/add`}
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button variant="contained" disableRipple>
                              <Index.ControlPointIcon className="plus-add" />
                              Add Year
                            </Index.Button>
                          </Index.Link>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                    {/* {selectedRows.length ? (
                  <Index.Box className="common-button blue-button flex-end save-btn">
                    <Index.Button variant="contained" onClick={handleAllDelete}>
                      Multiple Delete
                    </Index.Button>
                  </Index.Box>
                ) : null} */}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="sahay-table">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container year-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table year-table"
                  >
                    <Index.TableHead className="year-head">
                      <Index.TableRow className="year-tr">
                        <Index.TableCell className="year-th">
                          FromYear
                        </Index.TableCell>
                        <Index.TableCell className="year-th">
                          ToYear
                        </Index.TableCell>
                        <Index.TableCell className="year-th" align="left">
                          Created Date{" "}
                        </Index.TableCell>
                        <Index.TableCell className="year-th" align="left">
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody
                      className={
                        status !== STATUSES.LOADING
                          ? "year-tbody"
                          : "loading-table"
                      }
                    >
                      {status !== STATUSES.LOADING ? (
                        pageData?.length ? (
                          pageData?.map((row, index) => (
                            <Index.TableRow className="year-tr" key={index}>
                              <Index.TableCell className="year-td" align="left">
                                {row.fromYear}
                              </Index.TableCell>

                              <Index.TableCell className="year-td" align="left">
                                {row.toYear}
                              </Index.TableCell>
                              <Index.TableCell className="year-td" align="left">
                                {moment
                                  .utc(row?.created_at ? row?.created_at : null)
                                  .format("YYYY-MM-DD, h:mm:ss A")}
                              </Index.TableCell>
                              <Index.TableCell
                                className="year-td"
                                align="left"
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignContent: "space-around",
                                }}
                              >
                                {rollPermissionData?.permissionArray?.includes(
                                  "Year Of Sahay Master_edit"
                                ) || rollPermissionNameData === "admin" ? (
                                  <>
                                    <Index.IconButton
                                      onClick={() => {
                                        handleSahayYearEdit(row);
                                      }}
                                    >
                                      <Index.EditIcon className="muiico-edit" />
                                    </Index.IconButton>
                                  </>
                                ) : (
                                  ""
                                )}

                                {rollPermissionData?.permissionArray?.includes(
                                  "Year Of Sahay Master_delete"
                                ) || rollPermissionNameData === "admin" ? (
                                  <>
                                    <Index.IconButton
                                      onClick={() => {
                                        handleDeleteOpen(row.id);
                                      }}
                                    >
                                      <Index.DeleteIcon className="muiico-delete" />
                                    </Index.IconButton>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                        ) : (
                          <PagesIndex.NoRecordFound colSpan={12} />
                        )
                      ) : (
                        <PagesIndex.PageLoading colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <PagesIndex.Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleSahayYearDelete}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
};

export default Yearofsahay;
