import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import { getRepaymentData } from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import moment from "moment";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import { Autocomplete } from "@mui/material";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import dataService from "../../../../config/DataService";
import { ApiOutlined } from "@mui/icons-material";
import { Api } from "../../../../config/Api";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import { useReactToPrint } from "react-to-print";

export default function RepaymentReport() {
  const { getRepaymentDataList } = PagesIndex.useSelector(
    (state) => state?.admin
  );
  const fileName = "Repayment Report CSV";
  const [excel, setExcel] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rowPerPage, setRowPerPage] = useState("25");
  const dispatch = PagesIndex.useDispatch();
  const [filter, setFilter] = useState("");
  const [filterApplicantName, setFilterApplicantName] = useState("");
  const [filterSubSahay, setFilterSubSahay] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [filterReportData, setFilterReportData] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);

  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(getRepaymentData()).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false);
      }
      setLoader(false);
    });
  }, [filter, filterApplicantName, filterSubSahay]);

  //Filter Data
  useEffect(() => {
    if (filter || filterApplicantName || filterSubSahay) {
      const filterData = getRepaymentDataList?.data?.data?.filter((ele) => {
        if (filter && filterApplicantName && filterSubSahay) {
          return (
            ele?.FID == filter &&
            ele?.ApplicantName == filterApplicantName &&
            ele?.SubSahayName == filterSubSahay
          );
        } else if (filter && filterApplicantName) {
          return (
            ele?.FID == filter && ele?.ApplicantName == filterApplicantName
          );
        } else if (filter && filterSubSahay) {
          return ele?.FID == filter && ele?.SubSahayName == filterSubSahay;
        } else if (filterApplicantName && filterSubSahay) {
          return (
            ele?.ApplicantName == filterApplicantName &&
            ele?.SubSahayName == filterSubSahay
          );
        } else if (filter || filterApplicantName || filterSubSahay) {
          return (
            ele?.FID == filter ||
            ele?.ApplicantName == filterApplicantName ||
            ele?.SubSahayName == filterSubSahay
          );
        } else {
          return [];
        }
      });
      setFilterReportData(filterData);
    } else {
      setFilterReportData([]);
    }
  }, [filter, filterApplicantName, filterSubSahay]);

  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  useEffect(() => {
    if (filterReportData?.length > 0) {
      const customHeadings = filterReportData?.map((item) => ({
        Fid: item?.FID,
        "Applicant name": item?.ApplicantName,
        "Beneficiary Name": item?.BeneficiaryName,
        "Sub Sahay Name": item?.SubSahayName,
        "Start Date": item?.StartDate,
        Remark: item?.Remark,
        Status: item?.Status,
      }));

      setExcel(customHeadings);
    }
  }, [filterReportData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(filterReportData);
    setSearchedData(
      filterReportData?.filter((item) =>
        item?.ApplicantName?.toString()
          ?.toLowerCase()
          .includes(searched?.toLowerCase())
      )
    );
  };
  //get Sub sahay list
  const getSahaySubTypeData = async () => {
    try {
      const res = await dataService.get(Api.GET_ALL_SUB_SAHAY_LIST);
      if (res?.data?.status) {
        setSahaySubTypeList(res?.data?.Data);
      } else {
        setSahaySubTypeList([]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSahaySubTypeData();
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const navigate = PagesIndex.useNavigate();
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Repayment Report_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Repayment Report
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="grid-row  sahay-multiple-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="app-report-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <PagesIndex.Search
                          className="search sahay-multiple-search"
                          onChange={(e) => requestSearch(e.target.value)}
                        >
                          <PagesIndex.StyledInputBase placeholder="Search" />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={getRepaymentDataList?.data?.data || []}
                          getOptionLabel={(option) => option.FID?.toString()}
                          onChange={(value, newvalue) => {
                            setFilter(newvalue?.FID);
                          }}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue?.toLowerCase();
                            const uniqueFamilyId = new Set();
                            return options?.filter((option) => {
                              const familyId =
                                option?.FID?.toString()?.toLowerCase();
                              // Check unique
                              if (uniqueFamilyId?.has(familyId)) {
                                return false;
                              }
                              // Check search
                              if (familyId?.includes(inputLowerCase)) {
                                uniqueFamilyId?.add(familyId);
                                return true;
                              }
                              return false;
                            });
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Fid" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={getRepaymentDataList?.data?.data || []}
                          getOptionLabel={(option) => option.ApplicantName}
                          onChange={(value, newvalue) => {
                            setFilterApplicantName(newvalue?.ApplicantName);
                          }}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue?.toLowerCase();
                            const uniqueApplicantName = new Set();
                            return options?.filter((option) => {
                              const applicantName =
                                option?.ApplicantName?.toLowerCase();
                              // Check unique
                              if (uniqueApplicantName?.has(applicantName)) {
                                return false;
                              }
                              // Check search
                              if (applicantName?.includes(inputLowerCase)) {
                                uniqueApplicantName?.add(applicantName);
                                return true;
                              }
                              return false;
                            });
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              label="Applicant Name"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahaySubTypeList || []}
                          getOptionLabel={(option) => option?.SahayName}
                          onChange={(value, newvalue) => {
                            setFilterSubSahay(newvalue?.SahayName);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sub sahay" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    {filterReportData?.length > 0 && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button  multiple-download-btn  res-blue-button"
                            >
                              <FileExcel apiData={excel} fileName={fileName} />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <Index.Button onClick={handlePrint}>
                                Print
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container user-master-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table re-payment-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Fid
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Applicant Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Benificiary name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sub Sahay Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Start Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Remark
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Status
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length &&
                      (filter || filterApplicantName || filterSubSahay) ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow className="user-master-tr">
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.FID || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.ApplicantName || "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.BeneficiaryName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.SubSahayName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {moment(data?.StartDate || "-").format(
                                  "DD-MM-YYYY"
                                ) == "Invalid date"
                                  ? "-"
                                  : moment(data?.StartDate || "-").format(
                                      "DD-MM-YYYY"
                                    )}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.Remark || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.Status || "-"}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            <PrintReport
              componentRef={componentRef}
              tableData={excel}
              reportName={[{ type: "RepaymentReport" }]}
            />
          </Index.Box>
        </>
      )}
    </>
  );
}
