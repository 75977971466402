import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getBankMasterList } from "../../../../redux/features/AddService";
import { STATUSES } from "../../../../redux/features/adminslice/AdminSlice";
const BankMasterList = () => {
  const { bankMasterList, status } = PagesIndex.useSelector(
    (state) => state.admin
  );

  const dispatch = PagesIndex.useDispatch();

  //all state
  const [searchedData, setSearchedData] = useState([]);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    dispatch(getBankMasterList());
  }, []);

  //search start
  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(bankMasterList);
  }, [bankMasterList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(bankMasterList);
    setSearchedData(
      bankMasterList?.filter(
        (item) =>
          item?.HolderName.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.bankName
            .toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.branch
            .toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.AccountNo.toString().includes(searched.toLowerCase()) ||
          item?.IFSCCode.toString()
            .toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
  };
  //search end

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bank Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search
                  className="search"
                  onChange={(e) => requestSearch(e.target.value)}
                >
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container bank-table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table bank-table"
              >
                <Index.TableHead className="bank-table-head">
                  <Index.TableRow className="bank-table-tr">
                    <Index.TableCell className="bank-table-th">
                      User Name
                    </Index.TableCell>
                    <Index.TableCell className="bank-table-th" align="left">
                      Bank Name
                    </Index.TableCell>
                    <Index.TableCell className="bank-table-th" align="left">
                      {" "}
                      Branch
                    </Index.TableCell>
                    <Index.TableCell className="bank-table-th" align="left">
                      Account Holder Name
                    </Index.TableCell>
                    <Index.TableCell className="bank-table-th" align="left">
                      Account Number
                    </Index.TableCell>
                    <Index.TableCell className="bank-table-th" align="left">
                      IFSC Code
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody
                  className={
                    status !== STATUSES.LOADING
                      ? "bank-table-tbody"
                      : "loading-table"
                  }
                >
                  {status !== STATUSES.LOADING ? (
                    pageData?.length ? (
                      pageData?.map((row, index) => (
                        <Index.TableRow key={index} className="bank-table-tr">
                          <Index.TableCell
                            className="bank-table-td"
                            align="left"
                          >
                            {row.HolderName}
                          </Index.TableCell>

                          <Index.TableCell
                            className="bank-table-td"
                            align="left"
                          >
                            {row.bankName}
                          </Index.TableCell>
                          <Index.TableCell
                            className="bank-table-td"
                            align="left"
                          >
                            {row.branch}
                          </Index.TableCell>
                          <Index.TableCell
                            className="bank-table-td"
                            align="left"
                          >
                            {row.HolderName}
                          </Index.TableCell>
                          <Index.TableCell
                            className="bank-table-td"
                            align="left"
                          >
                            {row.AccountNo}
                          </Index.TableCell>
                          <Index.TableCell
                            className="bank-table-td"
                            align="left"
                          >
                            {row.IFSCCode}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={searchedData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default BankMasterList;
