import React from "react";
import Index from "../../../Index";
import PropTypes from "prop-types";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import PagesIndex from "../../../PagesIndex";
import UserProfile from "../profile/UserProfile";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box className="user-setting-details">
          <Index.Typography className="user-setting-child">
            {children}
          </Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserSetting() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //

  const [confirmshowPassword, setConfirmShowPassword] = React.useState(false);

  const handleClickConfirmShowPassword = () =>
    setConfirmShowPassword((show) => !show);

  const [ShoNewPassword, setShowNewPassword] = React.useState(false);

  const handleClickShowNewPassword = () =>
    setConfirmShowPassword((show) => !show);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Index.Box className="apply-sahay-main">
        <Sidebar />

        <Index.Box className="head-content">
          <Index.Box className="admin-panel-content user-admin-panel-content">
            <Index.Box className="page-content-box">
              <Index.Box className="barge-common-box">
                <Index.Box className="user-setting-content">
                  <Index.Box
                    sx={{ width: "100%" }}
                    className="user-setting-tab-details"
                  >
                    <Index.Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      className="user-setting-tab-divider"
                    >
                      <Index.Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        className="user-setting-tabs"
                      >
                        <Index.Tab
                          label="Update Profile"
                          {...a11yProps(0)}
                          className="user-setting-tab"
                        />
                      </Index.Tabs>
                    </Index.Box>
                    <CustomTabPanel
                      value={value}
                      index={0}
                      className="user-setting-custom-panel"
                    >
                      <Index.Box className="update-profile-components">
                        {/* <PagesIndex.UserProfile/> */}
                        <UserProfile />
                      </Index.Box>
                    </CustomTabPanel>
                    <CustomTabPanel
                      value={value}
                      index={1}
                      className="user-setting-custom-panel"
                    >
                      <Index.Box className="change-pass-tab-details">
                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Old Password
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border password-border-list">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="Old Password"
                                className="admin-input-design input-placeholder "
                                name="oldPassword"
                                type={showPassword ? "text" : "password"}
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props  ",
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props ",
                                }}
                                onChange={handleChange}
                                // sx={{ mb: 3 }}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  ),
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              New Password
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border password-border-list">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="Old Password"
                                className="admin-input-design input-placeholder "
                                name="oldPassword"
                                type={ShoNewPassword ? "text" : "password"}
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props  ",
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props ",
                                }}
                                onChange={handleChange}
                                // sx={{ mb: 3 }}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {ShoNewPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  ),
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Confirm Password
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border password-border-list">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="Old Password"
                                className="admin-input-design input-placeholder "
                                name="oldPassword"
                                type={confirmshowPassword ? "text" : "password"}
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props  ",
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props ",
                                }}
                                onChange={handleChange}
                                // sx={{ mb: 3 }}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickConfirmShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {confirmshowPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  ),
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                        <Index.Box className="line" mt={3} mb={3}></Index.Box>
                        <Index.Box className="common-button blue-button flex-start save-btn res-set-search text-end-details">
                          <Index.Button variant="contained" type="submit">
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </CustomTabPanel>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
