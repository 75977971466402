import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authenticate from "../container/admin/auth/login/Login";
import Dashboard from "../components/admin/dashboard/Dashboard";
import DashboardLayout from "../container/admin/pages/dashboardLayout/DashboardLayout";
import ForgotPassword from "../container/admin/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../container/admin/auth/resetPassword/ResetPassword";
import Profile from "../container/admin/pages/profile/Profile";
import ChangePassword from "../container/admin/pages/ChangePassword/ChangePassword";
import SignUp from "../container/admin/auth/signUp/SignUp";
import NewMemberList from "../container/admin/pages/newMember/NewMemberList";
import BankMasterList from "../container/admin/pages/bankmaster/BankMasterList";
import PrivayPolicy from "../container/admin/pages/cms/PrivayPolicy";
import TermsCondition from "../container/admin/pages/cms/TermsCondition";
import Contactus from "../container/admin/pages/cms/Contactus";
import SahaytypeMasterlist from "../container/admin/pages/sahayTypeMaster/SahaytypeMasterlist";
import Subsahaytype from "../container/admin/pages/subsahaytype/Subsahaytype";

import NewMemberAdd from "../container/admin/pages/newMember/NewMemberAdd";
import AddYearOfSahay from "../container/admin/pages/yearofsahay/add/AddYearOfSahay";
import Yearofsahay from "../container/admin/pages/yearofsahay/view/Yearofsahay";
import ApplyForSahay from "../container/user/pages/applyforsahay/ApplyForSahay";
import Listing from "../container/user/pages/listing/Listing";

import SahaytypeAdd from "../container/admin/pages/sahayTypeMaster/SahaytypeAdd";
import SubsahaytypeList from "../container/admin/pages/subsahaytype/SubsahaytypeList";
import SahayApplications from "../container/admin/pages/sahayApplications/SahayApplications";

import UserLogin from "../container/user/auth/login/Login";
import VerifyOTP from "../container/user/auth/login/OTP";
import OTP from "../container/user/auth/login/OTP";

import UserDashboardLayout from "../components/user/dashboard/UserDashboradLayout";
import UserProfile from "../container/user/pages/profile/UserProfile";
import ViewNewMember from "../container/admin/pages/newMember/viewMember/ViewNewMember";
import SahayApplicationView from "../container/admin/pages/sahayApplications/viewDetails/SahayApplicationView";
import ApplyForSahayView from "../container/user/pages/listing/ApplyForSahayView";

import PrivateRoutes from "./UsePrivateRoutes";
import AdminPrivateRoutes from "./AdminPrivateRoutes";
import AdminOtp from "../container/admin/auth/otp/OTP";
import EditApplyForSahay from "../container/user/pages/listing/EditApplyForSahay";
import RoleMasterList from "../container/admin/pages/rollmaster/RoleMasterList";
import RoleMasterAdd from "../container/admin/pages/rollmaster/RoleMasterAdd";
import UserMasterList from "../container/admin/pages/userMaster/UserMasterList";
import UserMasterAdd from "../container/admin/pages/userMaster/UserMasterAdd";
import ViewUserMaster from "../container/admin/pages/userMaster/ViewUserMaster";
import UserSetting from "../container/user/pages/userSetting/UserSetting";
import RolePermission from "../container/admin/pages/rolepermission/RolePermission";
import PreviousApplyForSahay from "../container/user/pages/applyforsahay/PreviousApplyForSahay";
import PreviousSahayApplicationView from "../container/admin/pages/sahayApplications/viewDetails/PreviousSahayApplicationView";
import EditCurrentApplyForSahay from "../container/user/pages/applyforsahay/EditCurrentApplyForSahay";
import DisbursementList from "../container/admin/pages/Disbursement/DisbursementList";
import AddDisbursement from "../container/admin/pages/Disbursement/AddDisbursement";
import ViewDisbursementData from "../container/admin/pages/Disbursement/ViewDisbursementData";
import ApplyForSahayAdmin from "../container/admin/pages/AddSahayAplicationAdmin/AddSahayApplicationAdmin";
import FamilyReport from "../container/admin/pages/familyReport/FamilyReport";
import ApplicationReport from "../container/admin/pages/applicationReport/ApplicationReport";
import CommiteReport from "../container/admin/pages/commiteReport/CommiteReport";
import SahayWiseDetails from "../container/admin/pages/sahayWiseDetails/SahayWiseDetails";
import EscReport from "../container/admin/pages/escReort/EscReport";
import DisbursementReport from "../container/admin/pages/disbursementReport/DisbursementReport";
import RepaymentReport from "../container/admin/pages/repaymentReport/RepaymentReport";
import FamilyReportData from "../container/admin/pages/familyReportData/FamilyReportData";
import AdminDashboard from "../container/admin/pages/dashboard/AdminDashboard";
import ActivityLog from "../container/admin/pages/activityLog/ActivityLog";
import NewReport from "../container/admin/pages/NewReport/NewReport";
import DisbursementSummaryList from "../container/admin/pages/disbursementSummary/DisbursementSummaryList";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/login" element={<Authenticate />} />
        <Route path="/user/verify-otp" element={<OTP />} />

        {/* Auth routes */}
        <Route path="/" element={<UserLogin />} />
        <Route path="/admin/forgot-password" element={<ForgotPassword />} />
        <Route path="/admin/verify-otp" element={<AdminOtp />} />
        <Route path="/admin/reset-password" element={<ResetPassword />} />

        {/* Pages routes */}
        {/* <Route element={<AdminPrivateRoutes />}> */}
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/:roll" element={<DashboardLayout />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="type-master" element={<SahaytypeMasterlist />} />
            <Route path="type-master-add" element={<SahaytypeAdd />} />
            <Route path="subtype-master" element={<SubsahaytypeList />} />
            <Route path="subtype-master-add" element={<Subsahaytype />} />
            <Route path="user-master" element={<UserMasterList />} />
            <Route path="user-master-add" element={<UserMasterAdd />} />
            <Route path="user-master-view" element={<ViewUserMaster />} />
            <Route path="apply-for-sahay" element={<ApplyForSahay />} />
            {/* <Route path="admin/dashboard" element={<AdminDashboard />} /> */}

            <Route
              path="apply-for-sahay-admin"
              element={<ApplyForSahayAdmin />}
            />
            <Route
              path="edit-apply-for-sahay-admin"
              element={<ApplyForSahayAdmin />}
            />
            <Route
              path="apply-for-previous-sahay"
              element={<PreviousApplyForSahay />}
            />
            <Route
              path="edit-apply-for-previous-sahay"
              element={<PreviousApplyForSahay />}
            />
            <Route
              path="edit-apply-for-current-sahay"
              element={<EditCurrentApplyForSahay />}
            />
            <Route path="role-permission" element={<RolePermission />} />
            <Route path="new-member">
              <Route path="list" element={<NewMemberList />} />
              <Route path="add" element={<NewMemberAdd />} />
              <Route path="view" element={<ViewNewMember />} />
            </Route>
            <Route path="bank-master" element={<BankMasterList />} />
            <Route path="sahay-application" element={<SahayApplications />} />
            <Route
              path="sahay-application-view"
              element={<SahayApplicationView />}
            />
            <Route
              path="previous-sahay-application-view"
              element={<PreviousSahayApplicationView />}
            />
            <Route
              path="edit-previous-sahay-application-view"
              element={<PreviousSahayApplicationView />}
            />
            <Route
              path="disbursement-application"
              element={<DisbursementList />}
            />
            <Route
              path="add-disbursement-application"
              element={<AddDisbursement />}
            />
            <Route
              path="edit-disbursement-application"
              element={<AddDisbursement />}
            />
            <Route
              path="view-disbursement"
              element={<ViewDisbursementData />}
            />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="update-profile" element={<Profile />} />
            <Route path="year-of-sahay">
              <Route path="view" element={<Yearofsahay />} />
              <Route path="add" element={<AddYearOfSahay />} />
            </Route>
            <Route path="role-master">
              <Route path="list" element={<RoleMasterList />} />
              <Route path="add" element={<RoleMasterAdd />} />
            </Route>
            <Route path="admin/activity-log" element={<ActivityLog />} />

            <Route path="family-report" element={<FamilyReport />} />
            <Route path="family-wise-report" element={<FamilyReportData />} />
            <Route path="sahay-wise-report" element={<SahayWiseDetails />} />
            <Route path="commite-report" element={<CommiteReport />} />
            <Route path="repayment-report" element={<RepaymentReport />} />
            <Route path="newreport-report" element={<NewReport />} />
            <Route
              path="disbursement-report"
              element={<DisbursementReport />}
            />
              <Route
              path="disbursement-summary"
              element={<DisbursementSummaryList />}
            />
            <Route path="esc-report" element={<EscReport />} />
            <Route path="application-report" element={<ApplicationReport />} />
            <Route path="terms-and-condition" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivayPolicy />} />
            <Route path="contact-us" element={<Contactus />} />
          </Route>
        </Route>
        {/* </Route> */}
        <Route element={<PrivateRoutes />}>
          <Route path="/user" element={<UserDashboardLayout />}>
            <Route path="user-listing" element={<Listing />}></Route>
            <Route path="user-listing-view" element={<ApplyForSahayView />} />
            <Route path="user-listing-edit" element={<EditApplyForSahay />} />
            <Route path="apply-for-sahay" element={<ApplyForSahay />} />
            {/* <Route path="update-profile" element={<UserProfile />} /> */}
            <Route path="setting" element={<UserSetting />} />

            <Route path="update-profile" element={<UserProfile />} />
          </Route>
        </Route>

        {/* <Route path="/:role" element={<DashboardLayout  />}>
        <Route path="contact-us" element={<Contactus />} />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
