import React from "react";
import Index from "../../../../Index";
import "../yearOfSahay.css";
import PagesIndex from "../../../../PagesIndex";
import {
  addYearOfSahay,
  updateYearOfSahay,
} from "../../../../../redux/features/AddService";
import { useSelector } from "react-redux";

const AddYearOfSahay = () => {
  //location dispatch navigate
  const location = PagesIndex.useLocation();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  // initialValues
  const initialValues = {
    fromYear: location?.state ? location?.state.fromYear.toString() : "",
    toYear: location?.state ? location?.state?.toYear.toString() : "",
    isSubmit: false,
  };
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  // handle form submit
  const handleFormSubmit = (data, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    let id = location?.state?.id;
    let datas = {
      fromYear: data.fromYear,
      toYear: data.toYear,
    };
    if (id) {
      datas["id"] = id;
      dispatch(updateYearOfSahay(datas, navigate)).then((data) => {
        if (data?.payload?.data?.status == 200) {
          navigate(`/${rollData}/year-of-sahay/view`);
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    } else {
      dispatch(addYearOfSahay(datas)).then((data) => {
        if (data?.payload?.data?.status == 201) {
          navigate(`/${rollData}/year-of-sahay/view`);
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    }
  };

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
  rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Year Of Sahay Master_add") ||
    rollPermissionData?.permissionArray?.includes("Year Of Sahay Master_edit")|| rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex">
            <Index.Box className="title-main mb-10">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Add year of sahay
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="add-sahay-year">
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaYearOfSahay}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleFocus,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Grid container spacing={2}>
                  <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      From*
                    </Index.Typography>
                    <Index.Box className="date-picker-details-content">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                        className="date-picker-provider"
                      >
                        <Index.DemoContainer
                          components={["DatePicker"]}
                          className="date-picker-container"
                        >
                          <Index.DatePicker
                            className="date-picker-inner"
                            format="YYYY"
                            views={["year"]}
                            onBlur={handleBlur}
                            value={PagesIndex.dayjs(values.fromYear)}
                            onFocus={handleFocus}
                            onChange={(e) => {
                              setFieldValue(
                                "fromYear",
                                PagesIndex.moment(e.$d).format("YYYY")
                              );
                            }}
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                    {errors.fromYear && touched.fromYear && (
                      <p className="error-text">{errors.fromYear}</p>
                    )}
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      To*
                    </Index.Typography>
                    <Index.Box className="date-picker-details-content">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                        className="date-picker-provider"
                      >
                        <Index.DemoContainer
                          components={["DatePicker"]}
                          className="date-picker-container"
                        >
                          <Index.DatePicker
                            className="date-picker-inner"
                            format="YYYY"
                            views={["year"]}
                            onBlur={handleBlur}
                            value={PagesIndex.dayjs(values.toYear)}
                            onFocus={handleFocus}
                            onChange={(e) => {
                              setFieldValue(
                                "toYear",
                                PagesIndex.moment(e.$d).format("YYYY")
                              );
                            }}
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                    {errors.toYear && touched.toYear && (
                      <p className="error-text">{errors.toYear}</p>
                    )}
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={12} md={4} lg={3}>
                    <Index.Box className="">
                      <Index.Box
                        mt={3}
                        className="common-button blue-button flex-start save-btn res-set-search-add"
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="add-shaya-year-btn"
                          disabled={values.isSubmit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Grid>
                </Index.Grid>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default AddYearOfSahay;
