import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import { getCommiteData } from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import { Autocomplete, OutlinedInput } from "@mui/material";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { Api } from "../../../../config/Api";
import dataService from "../../../../config/DataService";
import { useReactToPrint } from "react-to-print";
import PrintReport from "../../../../components/common/printReport/PrintReport";

export default function CommiteReport() {
  const { getCommiteDataList } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  const fileName = "Committee Report CSV";
  const [excel, setExcel] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rowPerPage, setRowPerPage] = useState("25");
  const dispatch = PagesIndex.useDispatch();
  const [filter, setFilter] = useState("");
  const [filterSubSahayName, setFilterSubSahayName] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [filterReportData, setFilterReportData] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  // const [statusStaffFilter, setStatusStaffFilter] = useState("");
  const [statusCommitteeFilter, setStatusCommitteeFilter] = useState("");
  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  useEffect(() => {
    if (filterReportData?.length > 0) {
      const customHeadings = filterReportData?.map((item) => ({
        Fid: item?.FID,
        "SubSahay Name": item?.SubSahayName,
        "Applicant Name": item?.ApplicantName,
        "Beneficiary Name": item?.BeneficiaryName,
        Age: item?.Age,
        " No Of Family Member": item?.FamilyMemberCount,
        Area: item?.Area,
        "Moholla Name": item?.MohollaName,
        Income: item?.Income,
        Mediclaim: item?.Mediclaim,
        Disease: item?.Disease,
        "Last Year Sahay": item?.LastYearSahay,
        "Current Year Sahay": item?.CurrentYearSahay,
        "Requested Sahay": item?.requestedAmount,
        Sanction: item?.Santion,
      }));

      setExcel(customHeadings);
    }
  }, [filterReportData]);

  useEffect(() => {
    dispatch(getCommiteData()).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false);
      }
      setLoader(false);
    });
  }, [filter, filterSubSahayName, statusCommitteeFilter]);

  //Filter Data
  // useEffect(() => {
  //   if (filter || filterSubSahayName) {
  //     const filterData = getCommiteDataList?.data?.filter((ele) => {
  //       if (filter && filterSubSahayName) {
  //         return ele?.FID == filter && ele?.SubSahayName == filterSubSahayName;
  //       } else if (filter || filterSubSahayName) {
  //         return ele?.FID == filter || ele?.SubSahayName == filterSubSahayName;
  //       } else {
  //         return [];
  //       }
  //     });
  //     setFilterReportData(filterData);
  //   } else {
  //     setFilterReportData([]);
  //   }
  // }, [filter, filterSubSahayName]);

  useEffect(() => {
    const filters = {
      FID: filter,
      SubSahayName: filterSubSahayName,
      // newStatusColumn: statusStaffFilter,
      committeeStatus: statusCommitteeFilter,
    };

    const filterData = getCommiteDataList?.data?.filter((ele) => {
      console.log("EEEEEEEEE", ele);

      if (Object.values(filters).some(Boolean)) {
        return Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          return ele[key] == value;
        });
      } else {
        return true;
      }
    });

    setFilterReportData(filterData);
  }, [filter, filterSubSahayName, statusCommitteeFilter]);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(filterReportData);
    setSearchedData(
      filterReportData?.filter(
        (item) =>
          item?.ApplicantName?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.Area?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
  };

  //get Sub sahay list
  const getSahaySubTypeData = async () => {
    try {
      const res = await dataService.get(Api.GET_ALL_SUB_SAHAY_LIST);
      if (res?.data?.status) {
        setSahaySubTypeList(res?.data?.Data);
      } else {
        setSahaySubTypeList([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSahaySubTypeData();
  }, []);

  const navigate = PagesIndex.useNavigate();

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Committee Report_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Committee Report
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="grid-row  sahay-multiple-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="app-report-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <PagesIndex.Search
                          className="search sahay-multiple-search"
                          onChange={(e) => requestSearch(e.target.value)}
                        >
                          <PagesIndex.StyledInputBase placeholder="Search" />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahaySubTypeList}
                          getOptionLabel={(option) => option?.SahayName}
                          onChange={(value, newvalue) => {
                            setFilterSubSahayName(newvalue?.SahayName);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sub Sahay" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={getCommiteDataList?.data || []}
                          getOptionLabel={(option) => option.FID?.toString()}
                          onChange={(value, newvalue) => {
                            setFilter(newvalue?.FID);
                          }}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue?.toLowerCase();
                            const uniqueFamilyId = new Set();
                            return options?.filter((option) => {
                              const familyId =
                                option?.FID?.toString()?.toLowerCase();
                              // Check unique
                              if (uniqueFamilyId?.has(familyId)) {
                                return false;
                              }
                              // Check search
                              if (familyId?.includes(inputLowerCase)) {
                                uniqueFamilyId?.add(familyId);
                                return true;
                              }
                              return false;
                            });
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Fid" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>

                    {/* <Index.Box className="lable-input-grp pending-button-sahay">
                      <Index.FormControl
                        sx={{ width: "100%" }}
                        className="main-select"
                      >
                        {/* <Index.InputLabel id="status-select-label">Staff Status</Index.InputLabel> 
                        <Index.Select
                          className="select-drop"
                          // displayEmpty
                          value={statusStaffFilter}
                          onChange={(e) => {
                            setStatusStaffFilter(e.target.value);
                          }}
                          MenuProps={MenuProps}
                          label="Staff Status"
                        >
                          <Index.MenuItem value={"Approved"}>
                            Approved
                          </Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box> */}
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="committee-status-autocomplete"
                          className="multiple-autocomplet-control"
                          options={[
                            { label: "Pending", value: "Pending" },
                            { label: "Approved", value: "Approved" },
                            { label: "Rejected", value: "Rejected" },
                            { label: "Hold", value: "Hold" },
                          ]}
                          getOptionLabel={(option) => option.label}
                          onChange={(value, newvalue) => {
                            setStatusCommitteeFilter(newvalue?.value);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              label="Committee Status"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    {filterReportData?.length > 0 && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <FileExcel apiData={excel} fileName={fileName} />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <Index.Button onClick={handlePrint}>
                                Print
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container user-master-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table commit-report-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Fid
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sub Sahay
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Committee Status
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Applicant Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Benificiary name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Age
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          No Of Family Member
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Area
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Moholla Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Income
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Mediclaim
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Disease
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Last Year Sahay
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Current Year Sahay
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Requested Sahay
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sanction
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length &&
                      (filter ||
                        filterSubSahayName ||
                        statusCommitteeFilter) ? (
                        pageData
                          ?.filter((ele) => {
                            return ele;
                            // return filter ? ele?.FID == filter : []
                          })
                          ?.map((data, index) => (
                            <Index.TableRow className="user-master-tr">
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-role-name">
                                  {data?.FID || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-details-name">
                                  {data?.SubSahayName || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-details-name">
                                  {data?.committeeStatus || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-details-name">
                                  {data?.ApplicantName || "-"}
                                </Index.Box>
                              </Index.TableCell>

                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-email-name">
                                  {data?.BeneficiaryName || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.Age || "-"}
                                </Index.Box>
                              </Index.TableCell>

                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.FamilyMemberCount}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.Area || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.MohollaName || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.Income || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.Mediclaim ? "Yes" : "No"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.Disease === "undefined" ||
                                  data?.Disease === null
                                    ? "-"
                                    : data?.Disease}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.LastYearSahay || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.CurrentYearSahay || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.requestedAmount || "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {data?.Santion || "-"}
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={14} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            <PrintReport
              componentRef={componentRef}
              tableData={excel}
              reportName={[{ type: "CommitteeReport" }]}
            />
          </Index.Box>
        </>
      )}
    </>
  );
}
