import React from 'react'
import Index from '../../Index'
import defaultLayout from './defaultLayout.css'
import Png from '../../../assets/Png'

const Sidebar = () => {
  return (
    <>
      <Index.Box className="user-sidebar-main">
        <Index.Box className="user-logo-mian">
          <Index.Box className="user-logo">
            <img src={Png.userlogo} />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

export default Sidebar