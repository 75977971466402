import usericon from "./images/png/user.png";

import profileimg from "./images/png/profileimg.png";
import arrow from "./images/png/arrow.png";

import card1 from "./images/png/card1.png";
import card2 from "./images/png/card2.png";
import card3 from "./images/png/card3.png";
import card4 from "./images/png/card4.png";
import invoice from "./images/png/invoice.png";
import invoice2 from "./images/png/invoice2.png";

import sahay_log from "./images/png/shay_logo.png";
import userlogo from "./images/png/user-logo.png";
import image_not_found from "./images/png/not-found-logo.png";
import user_icon_default from "./images/png/default-user-image.png";
import Numberusers from "./images/png/Number-of-users.png";
import Received from "./images/png/Received-applications.png";
import Pending from "./images/png/Pending-applications.png";
import Approved from "./images/png/Approved-applications.png";
import Awarded from "./images/png/Awarded-applicants.png";
import amount from "./images/png/Awarded -amount.png";

const Png = {
  // user_logo,
  image_not_found,

  userlogo,
  sahay_log,

  invoice,
  invoice2,
  card1,
  card2,
  card3,
  card4,
  usericon,

  arrow,
  profileimg,
  user_icon_default,
  Numberusers,
  Received,
  amount,
  Awarded,
  Approved,
  Pending,
};

export default Png;
