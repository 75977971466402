import React, { useState } from "react";
import "./Login.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { loginUser } from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";

function Login() {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  // Initital values
  let initialValues = {
    number: "",
    vastipatrak: true,
    isSubmit: false,
  };
  // Login
  const handleFormSubmit = (values, { setFieldValue }) => {
    let data = {
      number: values?.number,
      vastipatrak: values?.vastipatrak,
    };
    setFieldValue("isSubmit", true);
    dispatch(loginUser(data, navigate)).then((res) => {
      if (res.payload !== undefined) {
        setTimeout(() => {
          navigate("/user/verify-otp", { state: { number: values.number } });
          setFieldValue("isSubmit", false);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2 text-center">
                  <img src={PagesIndex.Png.sahay_log} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Welcome back!
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="box-login-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Login your account.
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={PagesIndex.validationSchemaUserLogin}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Mobile Number"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="number"
                              data-testid="email-input"
                              autoComplete="off"
                              onBlur={handleBlur}
                              value={values.number}
                              onChange={(e) => {
                                setFieldValue(
                                  "number",
                                  e.target.value.slice(0, 10)
                                );
                              }}
                              helperText={touched.number && errors.number}
                              error={Boolean(errors.number && touched.number)}
                            />

                            <Index.Box className="orange-btn login-btn login-btn-main">
                              <Index.Button
                                type="submit"
                                data-testid="button"
                                variant="contained"
                                disabled={values.isSubmit}
                              >
                                Login
                              </Index.Button>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </PagesIndex.Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default Login;
