import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../../config/Api";
import DataService, { API_ENDPOINT } from "../../config/DataService";
import { toast } from "react-toastify";
import axios from "axios";

export const loginUser = createAsyncThunk("admin/loginUser", async (data) => {
  try {
    const response = await DataService.post(Api.USER_LOGIN, data);

    if (response.data.status == 200) {
      toast.success(response.data.message);
    }
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message || error.message);
  }
});

//admin login
export const loginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
  try {
    const response = await DataService.post(Api.ADMIN_LOGIN, data);
    console.log(response, "response");
    console.log(
      response?.data?.data?.findData,
      "response?.data?.data?.findData"
    );
    if (response.data.status == 200) {
      console.log("Res.data", response?.data?.data);
      toast.success(response.data.message);
      localStorage.setItem(
        "token",
        JSON.stringify(response?.data?.data?.token)
      );
      localStorage.setItem(
        "RollName",
        JSON.stringify(response?.data?.data?.findData?.parentRoleName)
      );
      DataService.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.data?.token}`;
    }
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message || error.message);
  }
  // return response.data.data;
  // } catch (error) {
  //   toast.error(error.response.data.message || error.message);
  // }
});

//admin login
// export const loginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
//   try {
//     const response = await DataService.post(Api.ADMIN_LOGIN, data);
//     if (response.data.status == 200) {
//       toast.success(response.data.message);
//       localStorage.setItem(
//         "token",
//         JSON.stringify(response?.data?.data?.token)
//       );
//       DataService.defaults.headers.common[
//         "Authorization"
//       ] = `Bearer ${response?.data?.token}`;
//     }
//     return response.data.data;
//   } catch (error) {
//     toast.error(error.response.data.message || error.message);
//   }
// });

//forgot password admin
export const forgotpasswordAdmin = createAsyncThunk(
  "admin/forgotpasswordAdmin",
  async (data) => {
    try {
      const response = await DataService.post(Api.FORGOT_PASSWORD, data);
      if (response.data.status === 200) {
        toast.success(response.data.message);

        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// reset password admin
// export const resetpasswordAdmin = createAsyncThunk(
//   "admin/resetpasswordAdmin",
//   async (data) => {
//     console.log(data.token, "sjdhfg");
//     const headers = {
//       Authorization: `Bearer ${data.token}`,
//     };
//     console.log(headers,"headers");
//     try {
//       let reqData = {
//         newPassword: data.newPassword,
//         confirmPassword: data.confirmPassword,
//       };

//       const response = await DataService.post(Api.RESET_PASSWORD, reqData, {
//         headers
//       });
//       if (response.data.status === 200) {
//         toast.success(response.data.message);
//       }
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       // toast.error(error.response.data.message || error.message);
//     }
//   }
// );
export const resetpasswordAdmin = createAsyncThunk(
  "admin/resetpasswordAdmin",
  async (data) => {
    console.log("Token received:", data.token);
    if (!data.token) {
      console.error("Token is missing or null");
      throw new Error("Token is missing or null");
    }

    const headers = {
      Authorization: `Bearer ${data.token}`,
    };
    console.log("Headers:", headers);

    try {
      let reqData = {
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      };

      const response = await axios.post(API_ENDPOINT + Api.RESET_PASSWORD, reqData,{
                headers
              });

      console.log("Responsee:", response);

      if (response.data.status === 200) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response?.data?.message || error.message);
    }
  }
);

// change password admin
export const changepasswordAdmin = createAsyncThunk(
  "admin/changepasswordAdmin",
  async (data) => {
    try {
      let reqData = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      };
      // const response = await DataService.post(
      //   `${Api.CHANGE_PASSWORD}/${data.id}`,
      //   reqData
      // );
      const response = await DataService.post(Api.CHANGE_PASSWORD, reqData);
      if (response.data.status == 200) {
        toast.success(response.data.message);
      }
      return response.data.status;
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);

// admin logout
export const logoutAdmin = createAsyncThunk("admin/logoutAdmin", async (id) => {
  try {
    const response = await DataService.post(`${Api.ADMIN_LOGOUT}/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    // toast.error(error.response.data.message || error.message);
  }
});

// get sahay list

export const getSahaylist = createAsyncThunk("admin/getSahaylist", async () => {
  try {
    const res = await DataService.get(Api.ADMIN_GET_SAHAYTYPE);
    console.log("line160", res);
    return res.data;
  } catch (error) {
    // toast.error(error.response.data.message || error.message);
  }
});

// update sahay list
export const getSahaylistEdit = createAsyncThunk(
  "admin/getSahaylistEdit",
  async (data) => {
    try {
      const res = await DataService.post(Api.ADMIN_GET_SAHAYTYPE, data);
      if (res.data.status == 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      if (error?.response?.data?.status !== 409) {
        console.log(error);
        // toast.error(error.response.data.message || error.message);
      } else {
        return error?.response.data;
      }
    }
  }
);

// add sahay list
export const getSahaylistAdd = createAsyncThunk(
  "admin/getSahaylistAdd",
  async (data) => {
    try {
      const res = await DataService.post(Api.ADMIN_GET_SAHAYTYPE, data);
      if (res.data.status == 201) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      if (error?.response?.data?.status !== 409) {
        console.log(error);
        // toast.error(error.response.data.message || error.message);
      } else {
        return error?.response.data;
      }
    }
  }
);

//delete sahay list
export const getSahaylistDelete = createAsyncThunk(
  "admin/getSahaylistDelete",
  async (id) => {
    try {
      const res = await DataService.delete(
        `${Api.ADMIN_GET_SAHAYTYPE}?id=${id}`
      );
      console.log("resssss", res);
      if (res.data.status === 201) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);

// get sub sahay list
export const getSubSahaylist = createAsyncThunk(
  "admin/getSubSahaylist",
  async () => {
    try {
      const res = await DataService.get(Api.ADMIN_ADD_SUBSAHAYTYPE);
      console.log("line235", res);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// update sub sahay list
export const subSahaylistEdit = createAsyncThunk(
  "admin/subSahaylistEdit",
  async (data) => {
    try {
      const res = await DataService.post(Api.ADMIN_ADD_SUBSAHAYTYPE, data);
      if (res.data.status == 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      if (error?.response?.data?.status !== 409) {
        console.log(error);
        // toast.error(error.response.data.message || error.message);
      } else {
        return error?.response.data;
      }
    }
  }
);

// add sub sahay list
export const subSahaylistAdd = createAsyncThunk(
  "admin/subSahaylistAdd",
  async (data) => {
    try {
      const res = await DataService.post(Api.ADMIN_ADD_SUBSAHAYTYPE, data);
      if (res.data.status == 201) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      if (error?.response?.data?.status !== 409) {
        console.log(error);
        // toast.error(error.response.data.message || error.message);
      } else {
        return error?.response.data;
      }
    }
  }
);

//sub sahay list delete

export const subSahaylistDelete = createAsyncThunk(
  "admin/subSahaylistDelete",
  async (id) => {
    try {
      const res = await DataService.delete(
        `${Api.ADMIN_ADD_SUBSAHAYTYPE}?id=${id}`
      );
      if (res.data.status == 201) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

//get bank master list
export const getBankMasterList = createAsyncThunk(
  "admin/getBankMasterList",
  async () => {
    try {
      const res = await DataService.get(Api.GET_ALL_BANKMASTER_LIST);

      // return res.data.data.recordset;
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get mohalla list

export const getMohallalist = createAsyncThunk(
  "admin/getMohallalist",
  async () => {
    try {
      const res = await DataService.get(Api.GETMOHALLAMASTERLIST);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get year of sahay master list
export const getYearOfSahayMasterList = createAsyncThunk(
  "admin/getYearOfSahayMasterList",
  async () => {
    try {
      const res = await DataService.get(Api.GET_ALL_YEAR_OF_SAHAY);

      return res.data.data.filter((item) => {
        return item.isDelete == 0;
      });
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get blood master list
export const getBloodmasterList = createAsyncThunk(
  "admin/getBloodmasterList",
  async () => {
    try {
      const res = await DataService.get(Api.GETBLOODMASTERLIST);

      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//add year of sahay

export const addYearOfSahay = createAsyncThunk(
  "admin/addYearOfSahay",
  async (data) => {
    try {
      const response = await DataService.post(Api.ADD_YEAR_OF_SAHAY, data);
      if (response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response;
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);

//update year of sahay
export const updateYearOfSahay = createAsyncThunk(
  "admin/updateYearOfSahay",
  async (data, navigate) => {
    try {
      const response = await DataService.post(
        `${Api.UPDATE_YEAR_OF_SAHAY}${data.id}`,
        data
      );
      if (response.data.status === 200) {
        toast.success(response.data.message);
      }
      return response;
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);

//get maritial status list
export const getMaritalstatusList = createAsyncThunk(
  "admin/getMaritalstatusList",
  async () => {
    try {
      const res = await DataService.get(Api.GETMARITALSTATUSLIST);

      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//delete year of sahay
export const deleteYearOfSahay = createAsyncThunk(
  "admin/deleteYearOfSahay",
  async (id) => {
    try {
      const response = await DataService.post(
        `${Api.DELETE_YEAR_OF_SAHAY}${id}`
      );
      if (response.data.status === 200 || response.data.status === 201) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);

// get gnyati master list
export const getGnyatiMasterList = createAsyncThunk(
  "admin/getGnyatiMasterList",
  async () => {
    try {
      const res = await DataService.get(Api.GETGNYATIMASTERLIST);

      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get relation master list
export const getRelationMasterList = createAsyncThunk(
  "admin/getRelationMasterList",
  async () => {
    try {
      const res = await DataService.get(Api.GETRELATIONMASTERLIST);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get state master list

export const getStateMasterList = createAsyncThunk(
  "admin/getStateMasterList",
  async (id) => {
    try {
      const res = await DataService.get(Api.GETSTATEMASTERLIST);
      console.log(res, "res123");
      const stateData = res.data.data.filter((ele, i) => {
        return ele.ds_country == id;
      });

      return stateData;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get country master list
export const getCountryMasterList = createAsyncThunk(
  "admin/getCountryMasterList",
  async () => {
    try {
      const res = await DataService.get(Api.GETCOUNTRYMASTERLIST);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get city master list

export const getCityMasterList = createAsyncThunk(
  "admin/getCityMasterList",
  async (id) => {
    try {
      const res = await DataService.get(Api.GETCITYMASTERLIST);
      const cityData = res.data.data.filter((ele, i) => {
        return ele.city_state_id == id;
      });
      return cityData;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get new member list
export const getNewmemberlist = createAsyncThunk(
  "admin/getNewmemberlist",
  async () => {
    try {
      const res = await DataService.get(Api.getNewmemberlist);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// add family head
export const addFamilyHead = createAsyncThunk(
  "admin/addFamilyHead",
  async (data, navigate) => {
    try {
      const res = await DataService.post(Api.ADDFAMILYHEAD, data);
      if (res?.data?.status === 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error.message);
    }
  }
);

//add family member
export const addFamilyMember = createAsyncThunk(
  "admin/addFamilyMember",
  async (data, navigate) => {
    try {
      const res = await DataService.post(Api.ADDFAMILYMEMBER, data);
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        // toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error.message);
    }
  }
);

//get new member data list
export const getNewMemberDatalist = createAsyncThunk(
  "admin/getNewMemberDatalist",
  async (checkVastikpatrak) => {
    try {
      const res = await DataService.get(
        `${Api.GETDATALIST}?status=${checkVastikpatrak}`
      );
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get sahay application list
export const getSahayApplicationList = createAsyncThunk(
  "admin/getSahayApplicationList",
  async () => {
    try {
      const res = await DataService.post(Api.GET_ALL_SAHAY_APPLICATIONS);
      console.log("ap133", res);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get sahay application admin
export const getSahayApplicationListAdmin = createAsyncThunk(
  "admin/getSahayApplicationListAdmin",
  async () => {
    try {
      const res = await DataService.get(Api.ADMIN_SAHAY_APPICATION_LIST, {
        params: { yearType: "currentYear" },
      });
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const getFamilyIds = createAsyncThunk(
  "admin/getFamilyIds",
  async (VastiPatra) => {
    console.log(VastiPatra, 9898);
    try {
      const res = await DataService.get(
        `${Api.GET_FAMILY_IDS_LIST}?VastiPatra=${VastiPatra}`
      );
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get sahay type list
export const getSahayTypeList = createAsyncThunk(
  "admin/getSahayTypeList",
  async () => {
    try {
      const res = await DataService.get(`${Api.GET_SAHAY_TYPE_LIST}`);

      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get CMS
export const getCMSList = createAsyncThunk("admin/getCMSList", async () => {
  try {
    const res = await DataService.get(Api.GET_CMS_LIST);

    return res.data.data;
  } catch (error) {
    // toast.error(error.response.data.message || error.message);
  }
});

//update terms and condition  in cms
export const updateCMSTermsAndCondition = createAsyncThunk(
  "admin/updateCMSTermsAndCondition",
  async (data) => {
    try {
      const res = await DataService.post(`${Api.UPDATE_CMS_DATA}/${data.id}`, {
        title: data.title,
        description: data.description,
        type: data.type,
      });
      toast.success(res.data.message);

      return res.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//update contact us in cms
export const updateContactUs = createAsyncThunk(
  "admin/updateContactUs",
  async (data) => {
    try {
      const res = await DataService.post(`${Api.UPDATE_CMS_DATA}/${data.id}`, {
        email: data.email,
        mobileno: data.mobileno,
        title: data.title,
        description: data.description,
        type: data.type,
      });
      toast.success(res.data.message);
      return res.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get sahay type master list
export const getSahaySubTypeList = createAsyncThunk(
  "admin/getSahaySubTypeList",
  async (sahayTypeId) => {
    try {
      const res = await DataService.get(
        `${Api.GET_SAHAY_SUB_TYPE_LIST}/${sahayTypeId}`
      );
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get family member

export const getFamilyMemberById = createAsyncThunk(
  "admin/getFamilyMemberById",
  async (data) => {
    try {
      const res = await DataService.post(
        `${Api.GET_FAMILYMEMBERS_BY_FAMILY_ID}`,
        data
      );
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//apply for sahay
export const applyForSahayAPI = createAsyncThunk(
  "admin/applyForSahayAPI",
  async (data) => {
    try {
      const res = await DataService.post(`${Api.APPLY_FOR_SAHAY}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(res?.data?.message);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);
//Update for sahay Application
export const updateSahayApplication = createAsyncThunk(
  "admin/updateSahayApplication",
  async (data) => {
    try {
      const res = await DataService.post(
        `${Api.UPDATE_SAHAY_APPLICATION}${data?.id}`,
        data?.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(res?.data?.message);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

//year of sahay list
export const listYearOfSahay = createAsyncThunk(
  "admin/listYearOfSahay",
  async () => {
    try {
      const res = await DataService.get(Api.LIST_YEAR_OF_SAHAY);
      return res?.data?.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//bank account list
export const bankAccountsList = createAsyncThunk(
  "admin/bankAccountsList",
  async (id) => {
    try {
      const res = await DataService.get(`${Api.BANK_ACCOUNTS_LIST}/${id}`);
      return res?.data?.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//View New Member

export const getAllFamilyMemberByIdView = createAsyncThunk(
  "admin/getAllFamilyMemberByIdView",
  async (id) => {
    try {
      const res = await DataService.post(Api.GET_ALL_FAMILY_MEMBER_BY_ID, id);
      return res?.data?.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// new member get update data
export const getUSerProfileUpdate = createAsyncThunk(
  "admin/getUSerProfileUpdate",
  async (data) => {
    try {
      const res = await DataService.post(Api.GETUSERPROFILEUPDATE, data);
      return res.data.data;
    } catch (error) {}
  }
);

// new member update
export const updateFamilyMember = createAsyncThunk(
  "admin/updateFamilyMember",
  async (data) => {
    try {
      const res = await DataService.post(Api.UPDATEFAMILYMEMBER, data);

      if (res.data.status == 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// get single sahay application

export const getSingleSahayApplication = createAsyncThunk(
  "admin/getSingleSahayApplication",
  async (id) => {
    try {
      const response = await DataService.post(
        `${Api.GET_SINGLE_SAHAY_APPLICATION}/${id}`
      );
      return response.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get and update admin profile

export const getAdminProfile = createAsyncThunk(
  "admin/getAdminProfile",
  // async (id) => {
  async () => {
    try {
      // const response = await DataService.post(`${Api.GET_ADMIN_PROFILE}/${id}`);
      const response = await DataService.post(Api.GET_ADMIN_PROFILE);
      return response.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const updateAdminProfile = createAsyncThunk(
  "admin/updateAdminProfile",
  async (data) => {
    console.log("====================================");
    console.log("Data", data);
    console.log("====================================");
    try {
      // const res = await DataService.post(
      //   `${Api.GET_ADMIN_PROFILE_UPDATE}/${data.id}`,
      //   data.formData
      // );
      const res = await DataService.post(Api.GET_ADMIN_PROFILE_UPDATE, data);
      if (res.data.status === 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// new member delete list

export const newMemberDelete = createAsyncThunk(
  "admin/newMemberDelete",
  async (data) => {
    let addData = {
      vastipatrak: data.vastipatrak,
    };

    try {
      const res = await DataService.post(
        `${Api.NEW_MEMBER_DELETE_LIST}/${data.rowId}`,
        addData
      );
      if (res.data.status === 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

//multiple delete

export const multipleDeleteSahay = createAsyncThunk(
  "admin/multipleDeleteSahay",
  async (data) => {
    try {
      const res = await DataService.post(
        Api.MULTIPLEDELETE_SAHAY_TYPE_LIST,
        data
      );
      if (res.data.status === 201) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const multipleDeleteSubSahay = createAsyncThunk(
  "admin/multipleDeleteSubSahay",
  async (data) => {
    try {
      const res = await DataService.post(
        Api.MULTIPLEDELETE_SAHAY_SUB_TYPE_LIST,
        data
      );
      if (res.data.status === 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const multipleDeleteNewMember = createAsyncThunk(
  "admin/multipleDeleteNewMember",
  async (data) => {
    try {
      const res = await DataService.post(
        Api.MULTIPLEDELETE_NEW_MEMBER_LIST,
        data
      );
      if (res.data.status === 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const multipleDeleteYearOfSahay = createAsyncThunk(
  "admin/multipleDeleteYearOfSahay",
  async (data) => {
    try {
      const res = await DataService.post(
        Api.MULTIPLEDELETE_YEAR_OF_SAHAY,
        data
      );
      if (res.data.status === 200) {
        toast.success(res.data.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

//user profile data get for profile data update
export const getUserProfileData = createAsyncThunk(
  "admin/getUserProfileData",

  async (data) => {
    try {
      const response = await DataService.post(Api.GET_SINGLE_USER_DATA, data);
      return response.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// admin otp

export const getAdminOtpData = createAsyncThunk(
  "admin/getAdminOtpData",

  async (data) => {
    console.log("DAtacheck", data);
    try {
      const response = await DataService.post(Api.GET_ADMIN_OTP_DATA, data);
      console.log("response", response);

      if (response.data.status === 200) {
        toast.success(response.data.message);
      }
      if (response.data.status !== 200) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log("error.Response.data.message", error.response.data.message);
      toast.error(error.response.data.message || error.message);
    }
  }
);

// view new member by id
export const getAllFamilyMembersById = createAsyncThunk(
  "admin/getAllFamilyMembersById",
  async (id) => {
    try {
      const res = await DataService.post(Api.GET_FAMILY_MEMBER_BY_ID, id);
      return res?.data?.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//ROLE master get api
export const getRoleMaster = createAsyncThunk(
  "admin/getRoleMaster",
  async () => {
    try {
      const res = await DataService.get(Api.ADMIN_GET_ROLE_MASTER);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getParentRoleMaster = createAsyncThunk(
  "admin/getParentRole",
  async () => {
    try {
      const res = await DataService.get(Api.ADMIN_GET_PARENT_ROLE_MASTER);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// create user master in admin side
export const getAdminUserMaster = createAsyncThunk(
  "admin/getAdminUserMaster",
  async () => {
    try {
      const res = await DataService.get(Api.ADMIN_GET_USER_MASTER);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getReportData = createAsyncThunk("admin/reportData", async () => {
  try {
    const res = await DataService.get(Api.GET_REPORT_DATA);
    console.log(res, 88);
    return res.data;
  } catch (error) {
    // toast.error(error.response.data.message || error.message);
  }
});

export const getSubSahayReportData = createAsyncThunk(
  "admin/getSubSahayReportData",
  async () => {
    try {
      const res = await DataService.get(Api.GET_SUB_SAHAY_WISE_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const getSubSahayReport = createAsyncThunk(
  "admin/getSubSahayRepor",
  async (data) => {
    try {
      const res = await DataService.get(Api.GET_SUB_SAHAY_REPORT_DATA, {
        params: { sahayYear: data },
      });
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const getSahayDetailsData = createAsyncThunk(
  "admin/getSahayDetailsData",
  async () => {
    try {
      const res = await DataService.get(Api.GET_SAHAY_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getFamilyWiseReport = createAsyncThunk(
  "admin/getFamilyWiseReport",
  async () => {
    try {
      const res = await DataService.get(Api.GET_FAMILY_WISE_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getSahayDetailsReport = createAsyncThunk(
  "admin/getSahayDetailsReport",
  async () => {
    try {
      const res = await DataService.get(Api.GET_SUB_SAHAY_WISE_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getRepaymentData = createAsyncThunk(
  "admin/getRepaymentData",
  async () => {
    try {
      const res = await DataService.get(Api.GET_REPAYMENT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getCommiteData = createAsyncThunk(
  "admin/getCommiteData",
  async () => {
    try {
      const res = await DataService.get(Api.GET_COMMITE_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getEscReportData = createAsyncThunk(
  "admin/getEscReportData",
  async () => {
    try {
      const res = await DataService.get(Api.GET_ESC_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getDisbursementData = createAsyncThunk(
  "admin/getDisbursementData",
  async () => {
    try {
      const res = await DataService.get(Api.GET_DISBURSEMRNT_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// Disbursement Summary

export const getDisbursementSummary = createAsyncThunk(
  "admin/getDisbursementSummary",
  async (data) => {
    try {
      const res = await DataService.get(Api.GET_DISBURSEMRNT_SUMMARY_DATA, {
        params: data,
      });
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
// add user master

export const addAdminUserMaster = createAsyncThunk(
  "admin/addAdminUserMaster",

  async (data) => {
    try {
      const res = await DataService.post(Api.ADMIN_ADD_USER_MASTER, data);
      if (res?.data?.status == 201) {
        toast.success(res?.data?.message);
      }
      return res.data.status;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

//update user master

export const updateAdminUserMaster = createAsyncThunk(
  "admin/updateAdminUserMaster",

  async (data) => {
    let updateData = {
      email: data?.email,
      mobileno: data?.mobileno,
      name: data?.name,
      role: data?.role,
      sahayId: data?.sahayId,
    };

    try {
      const res = await DataService.post(
        `${Api.ADMIN_UPDATE_USER_MASTER}/${data?.id}`,
        updateData
      );
      if (res?.data?.status == 200) {
        toast.success(res?.data?.message);
      }
      return res.data.status;
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);
//update user master

export const activeDeactiveAdminUserMaster = createAsyncThunk(
  "admin/activeDeactiveAdminUserMaster",

  async (data) => {
    try {
      const res = await DataService.post(
        Api.ADMIN_ACTIVE_DEACTIVE_USER_MASTER,
        data
      );
      if (res?.data?.status == 200) {
        toast.success(res?.data?.message);
      }
      return res?.data?.status;
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);
//APIS FOR PREVIOUS SAHAY DATA

export const addPreviousSahayData = createAsyncThunk(
  "admin/addPreviousSahayData",

  async (data) => {
    // console.log(updateData,"addservice data")
    // const staffToken =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidHlwZSI6IlN0YWZmIiwiaWF0IjoxNjk1NzE5Mjg2fQ.hIiYM6wZOBFiI8VyYF2qRp6jftNB0hoPUhSl2WpCU5k";
    // const headers = {
    //   Authorization: `Bearer ${staffToken}`,
    // };
    try {
      const res = await DataService.post(
        `${Api.ADD_PREVIOUS_SAHAY_DATA}`,
        data
        // {
        //   headers,
        // }
      );
      if ((res?.data?.status == 201) == 200) {
        toast.success(res?.data?.message);
      }
      return res.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getVastiPatrakListOfFamilyId = createAsyncThunk(
  "admin/getVastiPatrakListOfFamilyId",

  async (data) => {
    console.log(data, "addservice data");
    const vastidata = { data: data };

    try {
      const res = await DataService.post(
        Api.VASTIPATRAK_FAMILY_ID_LIST,
        vastidata
      );
      if (res?.data?.status == 201) {
        toast.success(res?.data?.message);
      }
      return res.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getListOfFamilyId = createAsyncThunk(
  "admin/getListOfFamilyId",

  async () => {
    // console.log(updateData,"addservice data")

    try {
      const res = await DataService.get(Api.FAMILY_ID_LIST);
      if (res?.data?.status == 201) {
        toast.success(res?.data?.message);
      }
      return res.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const getPreviousSahayApplicationListAdmin = createAsyncThunk(
  "admin/getPreviousSahayApplicationListAdmin",
  async () => {
    // const staffToken =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidHlwZSI6IlN0YWZmIiwiaWF0IjoxNjk1NzE5Mjg2fQ.hIiYM6wZOBFiI8VyYF2qRp6jftNB0hoPUhSl2WpCU5k";
    // const headers = {
    //   Authorization: `Bearer ${staffToken}`,
    // };

    try {
      const res = await DataService.get(
        Api.GET_PREVIOUS_DATA_LIST,
        { params: { yearType: "previousYear" } }
        // {
        //   headers,
        // }
      );
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const updatePreviousData = createAsyncThunk(
  "admin/updatePreviousData",

  async (id) => {
    console.log(id, "addservice data");
    // const staffToken =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidHlwZSI6IlN0YWZmIiwiaWF0IjoxNjk1NzE5Mjg2fQ.hIiYM6wZOBFiI8VyYF2qRp6jftNB0hoPUhSl2WpCU5k";
    // const headers = {
    //   Authorization: `Bearer ${staffToken}`,
    // };
    try {
      const res = await DataService.get(
        Api.GET_UPDATED_PREVIOUS_DATA,
        {
          params: { applicationId: id },
        }
        // { headers }
      );
      if (res?.data?.status == 201 || 200) {
        // toast.success(res?.data?.message);
      }
      return res.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//get sahay application admin
export const getCurrentSahayApplicationListStaff = createAsyncThunk(
  "admin/getCurrentSahayApplicationListStaff",
  async () => {
    try {
      const res = await DataService.get(Api.GET_PREVIOUS_DATA_LIST, {
        params: { yearType: "currentYear" },
      });
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
//get sahay application admin
export const getCommitteeCurrentSahayApplicationListStaff = createAsyncThunk(
  "admin/getCommitteeCurrentSahayApplicationListStaff",
  async (statusData) => {
    try {
      const res = await DataService.get(Api.GET_Current_Coommittee_DATA_LIST, {
        params: { yearType: "currentYear" },
      });
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getCommitteePreviousSahayApplicationListStaff = createAsyncThunk(
  "admin/getCommitteePreviousSahayApplicationListStaff",
  async () => {
    try {
      const res = await DataService.get(Api.GET_Current_Coommittee_DATA_LIST, {
        params: { yearType: "previousYear" },
      });
      console.log("data123", res);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
///// GEt Disbursement ////////////////

export const getDisbursementDataList = createAsyncThunk(
  "admin/getDisbursementDataList",
  async () => {
    try {
      const res = await DataService.get(Api.GET_DISBURSEMENT_DATA_LIST);
      return res?.data?.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getDisbursementDataListAdmin = createAsyncThunk(
  "admin/getDisbursementDataListAdmin",
  async () => {
    try {
      const res = await DataService.get(Api.GET_DISBURSEMENT_DATA_LIST_ADMIN);
      return res?.data?.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const addDisbursementData = createAsyncThunk(
  "admin/addDisbursementData",

  async (data) => {
    console.log(data, "addservice_data");
    // const staffToken =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidHlwZSI6IlN0YWZmIiwiaWF0IjoxNjk1NzE5Mjg2fQ.hIiYM6wZOBFiI8VyYF2qRp6jftNB0hoPUhSl2WpCU5k";
    // const headers = {
    //   Authorization: `Bearer ${staffToken}`,
    // };
    try {
      const res = await DataService.post(
        `${Api.ADD_DISBURSEMENT_DATA}`,
        data
        // {
        //   headers,
        // }
      );
      if (res?.data?.status == 201) {
        if (res?.data?.data?.insertedCount >= 0) {
          if (
            res?.data?.data?.insertedCount > 0 &&
            res?.data?.data?.errorsCount <= 0
          ) {
            toast.success(res?.data?.message);
          }
        } else {
          toast.success(res?.data?.message);
        }
      }
      console.log("res.data", res.data.data.insertedCount);
      return res.data;
    } catch (error) {
      console.log(error);

      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const addSingleDisbursementData = createAsyncThunk(
  "admin/addSingleDisbursementData",

  async (data) => {
    try {
      const res = await DataService.post(`${Api.ADD_SINGLE_DISBURSMENT}`, data);
      if (res?.data?.status == 201 || 200) {
        toast.success(res?.data?.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);
export const editSingleDisbursementData = createAsyncThunk(
  "admin/editSingleDisbursementData",

  async (data) => {
    try {
      const res = await DataService.post(
        `${Api.EDIT_SINGLE_DISBURSMENT}`,
        data
      );
      if (res?.data?.status == 200 || 201) {
        toast.success(res?.data?.message);
      }
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const getCurrentSahayApplicationListAdmin = createAsyncThunk(
  "admin/getCurrentSahayApplicationListAdmin",
  async () => {
    try {
      const res = await DataService.get(Api.GET_PREVIOUS_DATA_LIST_ADMIN, {
        params: { yearType: "currentYear" },
      });
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getPreviousSahayApplicationListAdminNew = createAsyncThunk(
  "admin/getPreviousSahayApplicationListAdminNew",
  async () => {
    try {
      const res = await DataService.get(Api.GET_PREVIOUS_DATA_LIST_ADMIN, {
        params: { yearType: "previousYear" },
      });
      console.log("res1320", res.data.data);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const getNotification = createAsyncThunk(
  "admin/getNotification",
  async () => {
    try {
      const res = await DataService.get(Api.GET_NOTIFICATION);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const uploadCSVData = createAsyncThunk(
  "admin/uploadCSVData",

  async (data) => {
    try {
      const res = await DataService.post(
        `${Api.UPLOAD_CSV_DATA_BULK_IMPORT}`,
        data
      );
      console.log("RESPONSEprevious", res);
      //Test
      if (res?.data?.data?.insertedDocumentsCount > 0) {
        toast.success(
          `${res?.data?.data?.insertedDocumentsCount} Application inserted`
        );
        return res?.data;
      }
      return res?.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const uploadCurrentCSVData = createAsyncThunk(
  "admin/uploadCurrentCSVData",

  async (data) => {
    try {
      const res = await DataService.post(
        `${Api.UPLOAD_CURRENT_CSV_DATA_BULK_IMPORT}`,
        data
      );
      console.log("RESPONSE+++", res?.data?.data);
      if (res?.data?.data?.insertedDocumentsCount > 0) {
        toast.success(
          `${res?.data?.data?.insertedDocumentsCount} Application inserted`
        );
        return res?.data;
      }
      return res?.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || error.message);
    }
  }
);
export const uploadFamilyMemberData = createAsyncThunk(
  "admin/uploadFamilyMemberData",

  async (data) => {
    try {
      const res = await DataService.post(
        Api.UPLOAD_FAMILY_MEMBER_CSV_DATA_BULK_IMPORT,
        data
      );
      console.log("RESPONSE", res?.data?.data);

      if (res?.data?.data?.insertedDocumentsCount > 0) {
        toast.success(
          `${res?.data?.data?.insertedDocumentsCount} Application inserted`
        );
        return res?.data;
      }
      return res?.data;
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//////////////////////// PREVIOUS YEAR SAHAY LIST IN SAHAY APPLICATION VIEW //////////////////////////

export const getPreviousSahayApplicationListViewSahay = createAsyncThunk(
  "admin/getPreviousSahayApplicationListViewSahay",
  async (data) => {
    console.log("data----", data);
    try {
      const res = await DataService.get(Api.GET_PREVIOUS_SAHAY_IN_VIEW_SAHAY, {
        params: { applicantId: data?.applicantId },
      });
      console.log("data123", res);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

//Family ID List All
export const getAllFamilyIDs = createAsyncThunk(
  "admin/getAllFamilyIDs",
  async (data) => {
    try {
      const res = await DataService.get(Api.GET_ALL_FAMITY_ID_LIST);
      if (res?.data?.status == 200) {
        return res.data;
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const getActiveDeativeUser = createAsyncThunk(
  "admin/getActiveDeativeUser",
  async () => {
    try {
      const res = await DataService.get(Api.ACTIVE_DEACTIVE_USER_DETAILS);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const updateApplyForSahayDocuments = createAsyncThunk(
  "admin/updateApplyForSahayDocuments",
  async (data) => {
    try {
      const res = await DataService.post(
        Api.UPDATE_APPLY_FOR_SAHAY_DOCUMENTS,
        data
      );
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// Get Moholla name list
export const getAllFamilyIdWithMohollaNameList = createAsyncThunk(
  "admin/getAllFamilyIdWithMohollaNameList",
  async (data) => {
    console.log(data, "khgfjhfjgf");
    try {
      const res = await DataService.get(
        Api.GET_ALL_FAMILY_ID_WITH_MOHOLLA_NAME_LIST,
        {
          params: {
            MohollaId: data.filterByMohollaName,
            status: data.radioButtonFamily,
            mobileNumber: data.filterByMobileNumber,
          },
        }
      );
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
// Get Mobile number list
export const getFamilyMemberMobileNumberList = createAsyncThunk(
  "admin/getFamilyMemberMobileNumberList",
  async ({ type }) => {
    try {
      const res = await DataService.get(
        Api.GET_ALL_FAMILY_ID_WITH_MOBILE_NUMBER_LIST,
        {
          params: { vastiPatrak: type },
        }
      );
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get sahay list

export const getAdminDashboardDetails = createAsyncThunk(
  "admin/getAdminDashboardDetails",
  async () => {
    try {
      const res = await DataService.get(Api.GET_ALL_ADMIN_DASHBOARD_LIST);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// get activity list

export const getActivityList = createAsyncThunk(
  "admin/getActivityList",
  async () => {
    try {
      const res = await DataService.get(Api.GET_ALL_ADMIN_ACTIVITY_LIST);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);


export const getNewReportData = createAsyncThunk(
  "admin/getNewReportData",
  async () => {
    try {
      const res = await DataService.get(Api.GET_NEW_REPORT_DATA);
      console.log(res, 88);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);