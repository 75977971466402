import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import {
  getSahaySubTypeList,
  getSahayTypeList,
  getSubSahayReport,
  listYearOfSahay,
} from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import { Autocomplete } from "@mui/material";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import dataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { useReactToPrint } from "react-to-print";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import { useNavigate } from "react-router-dom";

export default function ApplicationReport() {
  const { getSubSahayReportList } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  const Navigate = useNavigate();
  const fileName = "Sub Sahay Wise Application CSV";
  const [excel, setExcel] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowPerPage, setRowPerPage] = useState("25");
  const dispatch = PagesIndex.useDispatch();
  const [filter, setFilter] = useState("");
  const [sahayFilter, setSahayFilter] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [sahayTypeList, setSahayTypeList] = useState([]);
  const [yearOfSahayList, setYearOfSahayList] = useState([]);
  const [filterReportData, setFilterReportData] = useState([]);
  const [listData, setListData] = useState([]);
  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (filterYear) {
      // setLoader(true);
      dispatch(getSubSahayReport(filterYear)).then((res) => {
        if (res?.payload?.status == 200) {
          setListData(res?.payload?.data?.data);
          setLoader(false);
        } else {
          setListData([]);
        }
        setLoader(false);
      });
    }
  }, [filterYear]);

  // Filter Data
  useEffect(() => {
    if (filter || filterYear || sahayFilter) {
      const filterData = listData?.filter((ele) => {
        if (sahayFilter && filter && filterYear) {
          return ele.SubSahayName == filter;
        }
        if (sahayFilter && filter) {
          return ele.SubSahayName == filter;
        } else if (sahayFilter && filterYear) {
          return ele.SahayCategory == sahayFilter;
        } else if (filterYear || sahayFilter) {
          return sahayFilter ? ele.SahayCategory == sahayFilter : ele;
        } else {
          return [];
        }
      });
      setFilterReportData(filterData);
    } else {
      setFilterReportData([]);
    }
  }, [filter, filterYear, listData, sahayFilter]);

  //   useEffect(() => {
  //     const filters = {
  //       SubSahayName: filter,
  //       SahayCategory: sahayFilter,
  //       filterYear: filterYear,

  //     };

  //     const filterData = data?.filter((ele) => {
  //         if (Object.values(filters).some(Boolean)) {
  //             return Object.entries(filters).every(([key, value]) => {
  //                 if (!value) return true;
  //                 return ele[key] == value;
  //             });
  //         } else {
  //             return true;
  //         }
  //     });

  //     setFilterReportData(filterData);
  // }, [
  //     filter,
  //     filterYear,
  //     filterSahay,
  //     filterSubSahay,
  //     filterElectricityBill,
  //     filterFamilyIncome,
  //     selectDateData,
  // ]);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(filterReportData);
    setSearchedData(
      filterReportData?.filter(
        (item) =>
          item?.SubSahayName?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.NoOfApplication?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (filterReportData?.length > 0) {
      const customHeadings = filterReportData?.map((item) => ({
        "Sub Sahay Name": item?.SubSahayName,
        "No Of Application": item?.NoOfApplication,
      }));
      const customPrintHeadings = filterReportData?.map((item) => ({
        "Sahay Name": item?.SahayCategory,
        "SubSahay Name": item?.SubSahayName,
        "Year": `${item?.yearOfSahay?.fromYear} - ${item?.yearOfSahay?.toYear}`,
        "No Of Applications": item?.NoOfApplication,
        "Pending No Of Applications": item?.Pending?.NoOfApplication,
        "Pending Amount": item?.Pending?.Amount,
        "Approve No Of Applications": item?.Approve?.NoOfApplication,
        "Approve Amount": item?.Approve?.Amount,
        "Rejected No Of Applications": item?.Rejected?.NoOfApplication,
        "Rejected Amount": item?.Rejected?.Amount,
        "Disbursement No Of Applications": item?.DisBusment?.NoOfApplication,
        "Disbursement Amount": item?.DisBusment?.Amount,
      }));

      // setExcel(customHeadings);
      setExcel(customPrintHeadings);
      setPrintData(customPrintHeadings);
    }
  }, [filterReportData]);

  //get Sub sahay list
  const getSahaySubTypeData = async (id) => {
    dispatch(getSahaySubTypeList(id)).then((res) => {
      if (res?.payload) {
        setSahaySubTypeList(res?.payload);
      } else {
        setSahaySubTypeList([]);
      }
    });
    // try {
    //   const res = await dataService.get(`${Api.GET_ALL_SUB_SAHAY_LIST}/${id}`);
    //   if (res?.data?.status) {
    //     setSahaySubTypeList(res?.data?.Data);
    //   } else {
    //     setSahaySubTypeList([]);
    //   }
    // } catch (error) {}
  };

  const fetchAllYearOfSahay = () => {
    //Get current financial year
    function getCurrentFinancialYear() {
      var financialYear = "";
      var today = new Date();
      if (today.getMonth() + 1 <= 3) {
        financialYear = today.getFullYear() - 1 + "-" + today.getFullYear();
      } else {
        financialYear = today.getFullYear() + "-" + (today.getFullYear() + 1);
      }
      return financialYear;
    }
    const financialYear = getCurrentFinancialYear();
    const filterFromYear = financialYear.split("-")[0];
    const filterToYear = financialYear.split("-")[1];

    // const currentYear = new Date().getFullYear();
    dispatch(listYearOfSahay()).then((data) => {
      if (data?.payload) {
        const filterCurrYear = data?.payload?.filter((ele) => {
          return ele?.fromYear == filterFromYear && ele?.toYear == filterToYear;
          // return ele?.fromYear == currentYear && ele?.toYear == currentYear + 1;
        });
        setFilterYear(filterCurrYear?.[0]?.id || "");
        // setFilterYear("");
        setYearOfSahayList(data?.payload);
      } else {
        setYearOfSahayList([]);
      }
    });
  };

  //Fetch Sahay List

  const fetchSahayTypeList = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  useEffect(() => {
    fetchSahayTypeList();
    fetchAllYearOfSahay();
  }, []);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes(
      "Sub Sahay Wise Application Report_view"
    ) ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    Navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Sub Sahay Wise Application Report
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-row sahay-multiple-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="app-report-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <PagesIndex.Search
                          className="search sahay-multiple-search"
                          onChange={(e) => requestSearch(e.target.value)}
                        >
                          <PagesIndex.StyledInputBase placeholder="Search" />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahayTypeList || []}
                          getOptionLabel={(option) => option?.Sahay_category}
                          onChange={(value, newvalue) => {
                            setSahaySubTypeList([]);
                            setFilter("");
                            setSahayFilter(newvalue?.Sahay_category);
                            getSahaySubTypeData(newvalue?.Id);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sahay Name" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahaySubTypeList}
                          getOptionLabel={(option) => option?.SahayName}
                          onChange={(value, newvalue) => {
                            setFilter(newvalue?.SahayName);
                          }}
                          value={
                            sahaySubTypeList &&
                            sahaySubTypeList.length > 0 &&
                            filter
                              ? sahaySubTypeList.find(
                                  (item) => item.SahayName === filter
                                )
                              : null
                          }
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              label="Sub Sahay Name"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={yearOfSahayList || []}
                          getOptionLabel={(option) =>
                            `${option?.fromYear}-${option?.toYear}`
                          }
                          value={
                            yearOfSahayList[
                              yearOfSahayList.findIndex((e) => e.id == 3102)
                            ]
                          }
                          onChange={(value, newvalue) => {
                            setFilterYear(newvalue?.id);
                          }}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue
                              ?.toLowerCase()
                              ?.replace(/\s/g, "");
                            const uniqueYear = new Set();
                            return options?.filter((option) => {
                              const fromYear = option?.fromYear
                                ?.toString()
                                ?.toLowerCase()
                                ?.replace(/\s/g, "");
                              const toYear = option?.toYear
                                ?.toString()
                                ?.toLowerCase()
                                ?.replace(/\s/g, "");
                              const year = `${fromYear}-${toYear}`;
                              // Check unique
                              if (uniqueYear?.has(year)) {
                                return false;
                              }
                              // Check search
                              if (year?.includes(inputLowerCase)) {
                                uniqueYear?.add(year);
                                return true;
                              }
                              return false;
                            });
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sahay Year" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    {filterReportData?.length > 0 && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <FileExcel apiData={excel} fileName={fileName} />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <Index.Button onClick={handlePrint}>
                                Print
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container user-master-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table user-master-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Sahay Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sub Sahay Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Year
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          No of Application
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Pending
                          <Index.Table
                            aria-label="simple table"
                            className="table-design-main barge-table user-master-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                <Index.TableCell>
                                  No of application
                                </Index.TableCell>
                                <Index.TableCell>Amount</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Approve
                          <Index.Table
                            aria-label="simple table"
                            className="table-design-main barge-table user-master-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                <Index.TableCell>
                                  No of application
                                </Index.TableCell>
                                <Index.TableCell>Amount</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>

                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Rejected
                          <Index.Table
                            aria-label="simple table"
                            className="table-design-main barge-table user-master-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                <Index.TableCell>
                                  No of application
                                </Index.TableCell>
                                <Index.TableCell>Amount</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>

                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Disbursement
                          <Index.Table
                            aria-label="simple table"
                            className="table-design-main barge-table user-master-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                <Index.TableCell>
                                  No of application
                                </Index.TableCell>
                                <Index.TableCell>Amount</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length &&
                      (filter || filterYear || sahayFilter) ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow className="user-master-tr">
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.SahayCategory || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.SubSahayName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {`${data?.yearOfSahay?.fromYear} - ${data?.yearOfSahay?.toYear}` ||
                                  "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.NoOfApplication || "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                <Index.Table
                                  aria-label="simple table"
                                  className="table-design-main barge-table user-master-table-inner"
                                >
                                  <Index.TableBody>
                                    <Index.TableRow>
                                      <Index.TableCell>
                                        {data?.Pending?.NoOfApplication}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.Pending?.Amount}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                <Index.Table
                                  aria-label="simple table"
                                  className="table-design-main barge-table user-master-table-inner"
                                >
                                  <Index.TableBody>
                                    <Index.TableRow>
                                      <Index.TableCell>
                                        {data?.Approve?.NoOfApplication}
                                      </Index.TableCell>

                                      <Index.TableCell>
                                        {data?.Approve?.Amount}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                <Index.Table
                                  aria-label="simple table"
                                  className="table-design-main barge-table user-master-table-inner"
                                >
                                  <Index.TableBody>
                                    <Index.TableRow>
                                      <Index.TableCell>
                                        {data?.Rejected?.NoOfApplication}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.Rejected?.Amount}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                <Index.Table
                                  aria-label="simple table"
                                  className="table-design-main barge-table user-master-table-inner"
                                >
                                  <Index.TableBody>
                                    <Index.TableRow>
                                      <Index.TableCell>
                                        {data?.DisBusment?.NoOfApplication}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.DisBusment?.Amount}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            {/* <PrintReport
              componentRef={componentRef}
              tableData={printData}
              reportName={[
                { name: "", type: "ApplicationReport" },
                { name: "Pending" },
                { name: "Approve" },
                { name: "Rejected" },
                { name: "Disbursement" },
              ]}
            /> */}
            <PrintReport
              componentRef={componentRef}
              tableData={printData}
              reportName={[
                { name: "", type: "ApplicationReport" },
                { name: "Pending" },
                { name: "Approve" },
                { name: "Rejected" },
                { name: "Disbursement" },
              ]}
            />
            ;
          </Index.Box>
        </>
      )}
    </>
  );
}
