import React, { useEffect } from "react";
import DataService from "../config/DataService";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getActiveDeativeUser } from "../redux/features/AddService";

const PrivateRoutes = () => {
  const { userToken } = useSelector((state) => state.admin);
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const { pathname } = useLocation();
  console.log("PathName",pathname);
  useEffect(() => {
    dispatch(getActiveDeativeUser()).then((res) => {
      console.log("ress===",res);
      if (res?.payload?.status === 200) {
        if (
          res?.payload?.data?.isActive === false ||
          res?.payload?.data?.isDelete === true 
        ) {
        
          navigate("/");
        }
      }
    });
  }, [pathname]);

  const accessToken = (token) => {
    if (!token) return false;
    DataService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return true;
  };
  // useEffect(() => {
  //   if (token) {
  //     DataService.defaults.headers.common[
  //       "Authorization"
  //     ] = `Bearer ${userToken}`;
  //   }
  // }, [userToken]);
  return accessToken(userToken) ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace={true} />
  );
};

export default PrivateRoutes;
