import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import Index from "../../../../container/Index";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import PagesIndex from "../../../PagesIndex";
import RemoveIcon from "@mui/icons-material/Remove";

import * as yup from "yup";
import {
  getFamilyIds,
  getSahayTypeList,
  getSahaySubTypeList,
  getFamilyMemberById,
  applyForSahayAPI,
  listYearOfSahay,
  bankAccountsList,
  getRelationMasterList,
} from "../../../../redux/features/AddService";
import {
  validationSchemaApplyForSahay,
  validationSchemaApplyForSahayBankMember,
} from "../../../../validation/Validation";
import { Typography } from "@mui/material";
import DataService, { imagePath } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ariaLabel = { "aria-label": "description" };

const EditCurrentApplyForSahay = () => {
  const { state } = PagesIndex.useLocation();

  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);

  const listingData = state?.row;

  const {
    getUserLoginDetails,

    getRelationList,
  } = PagesIndex.useSelector((state) => state.admin);

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  //all state

  const [listYearOfSahayData, setListYearOfSahayData] = useState([]);
  const [bankAccountsListData, setBankAccountsListData] = useState([]);
  const [applicantId, setApplicantId] = useState();

  const [sahayTypeList, setSahayTypeList] = useState();
  const [sahaySubTypeList, setSahaySubTypeList] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [selectedImage, setSelectedImage] = useState();

  const [sahayCondition, setSahayCondition] = useState();
  const [singlaFamilyId, setSingleFamilyId] = useState();


  const [getSingleSubSahay, setGetSingleSubSahay] = useState();
  const [applicantSelectedMember, setApplicantSelectedMember] = useState([]);

  const EducationSubsahayValue = [
    1015, 1017, 1010, 1007, 1006, 1008, 1009, 1010, 1011, 1012, 1013, 1019,
    1018, 1020, 1021, 1022, 1023,
  ];

  const getFamilyMemberIds = (status) => {
    dispatch(getFamilyIds(status)).then((res) => {});
  };

  const getSahayTypeData = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  const getSahaySubTypeData = (sahayTypeId) => {
    if (sahayTypeId) {
      dispatch(getSahaySubTypeList(sahayTypeId)).then((res) => {
        setSahaySubTypeList(res?.payload);
      });
    }
  };

  const setMemberDeatils = (applicantId, setFieldValue) => {
    const member =
      familyMembers &&
      familyMembers?.filter((member) => member?.id == applicantId);
    if (member?.[0]) {
      setFieldValue(
        "memberName",
        member?.[0]?.firstName +
          " " +
          member?.[0]?.middleName +
          " " +
          member?.[0]?.lastName
      );
      setFieldValue("age", member?.[0]?.age);
      setFieldValue("workingType", member?.[0]?.workingType);
      setFieldValue("relation", member?.[0]?.relation);
      setFieldValue("relation_id", member?.[0]?.relation_id);
      setFieldValue("monthlyIncome", member?.[0]?.annualIncome);
      setFieldValue("id", member?.[0]?.id);
      setFieldValue(
        "firstNameApplicant",
        member?.[0]?.firstName + " " + member?.[0]?.lastName
      );
    }
  };

  const getSingleSahayApplication = async () => {
    setLoading(true);
    try {
      const response = await DataService.post(
        `${Api.GET_SINGLE_SAHAY_APPLICATION}/${listingData?.id}`
      );
      if (response?.data?.status == 200) {
        console.log(response?.data?.data?.application, "editCurrentSahay");
        setLoading(false);
        setEditData(response.data.data);
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  };

  console.log("editData+++++", editData);

  useEffect(() => {
    getFamilyMemberIds(true);
    getSahayTypeData();
    dispatch(getRelationMasterList());

    getSingleSahayApplication();
    // let familyId = localStorage.getItem("familyId")
    //   ? localStorage.getItem("familyId")
    //   : 0;
    let familyId = listingData?.familyId ? listingData?.familyId : 0;
    const data = {
      familyId: familyId,
      VastiPatra: getUserLoginDetails?.vastipatrak,
    };
    setSingleFamilyId(familyId);
    dispatch(getFamilyMemberById(data)).then((res) => {
      setFamilyMembers(res?.payload);
    });

    dispatch(listYearOfSahay()).then((data) => {
      if (data?.payload) {
        setListYearOfSahayData(data?.payload);
      } else {
        setListYearOfSahayData([]);
      }
    });
  }, []);

  useEffect(() => {
    if (editData?.application?.[0]?.sahayTypeId !== undefined) {
      getSahaySubTypeData(editData?.application?.[0]?.sahayTypeId);
    }
  }, [editData?.application?.[0]?.sahayTypeId !== undefined]);

  const resetImage = (setFieldValue) => {
    setFieldValue("passportPic", "");
    setSelectedImage("");
  };

  let initialValues = {
    vastiPatrak: "true",
    formFillingDate: editData
      ? editData?.application?.[0]?.formFillingDate
      : "",
    applicationReceiveDate: editData
      ? editData?.application?.[0]?.applicationReceiveDate
      : "",
    AadharNo: editData ? editData?.application?.[0]?.AadharNo : "",
    SahayType: editData ? editData?.application?.[0]?.sahayTypeId : "",
    subSahayType: editData ? editData?.application?.[0]?.subSahayTypeId : "",
    applicantId: editData ? editData?.application?.[0]?.applicantId : "",
    memberName: editData ? editData?.application?.[0]?.Name : "",
    relation: editData ? editData?.application?.[0]?.relation : "",
    relation_id: editData ? editData?.application?.[0]?.relation_id : "",
    age: editData ? editData?.application?.[0]?.age : "",
    workingType: editData ? editData?.application?.[0]?.WorkingType : "",
    monthlyIncome: editData ? editData?.application?.[0]?.monthlyIncome : 0,
    id: "",
    bankName: editData ? editData?.application?.[0]?.bankName : "",
    branch: editData ? editData?.application?.[0]?.branch : "",
    HolderName: editData ? editData?.application?.[0]?.HolderName : "",
    IFSCCode: editData ? editData?.application?.[0]?.IFSCCode : "",
    formFillingDate: editData ? editData?.application[0]?.formFillingDate : "",
    applicationReceiveDate: editData
      ? editData?.application[0]?.applicationReceiveDate
      : "",
    AccountNo: editData ? editData?.application?.[0]?.AccountNo : "",
    // isMediclaim: "No",
    mediclaimAmmount: editData
      ? editData?.application?.[0]?.mediclaimAmmount
      : 0,
    isSahay: "No",
    trust: "no",
    SahayAmmount: editData ? editData?.application?.[0]?.SahayAmmount : 0,
    rentSahayAmmount: editData
      ? editData?.application?.[0]?.rentSahayAmmount
      : 0,
    claimedAmmount: editData ? editData?.application?.[0]?.claimedAmmount : 0,
    trustName: "",
    ammount: "",
    yearOfSahay: editData ? editData?.application?.[0]?.yearOfSahayId : "",
    bankMasterId: "",
    typeOfDisease: "",
    documents: editData ? editData?.document : [],
    passportPic: editData ? editData?.application?.[0]?.image : "",
    remark: editData ? editData?.application?.[0]?.remark : "",
    isMediclaim: editData
      ? editData?.application?.[0]?.isMediclaim == true && "Yes"
      : "No",
    mediclaimPremium: editData ? editData?.application[0]?.mediclaimPremium : 0,
    isSubmit: false,

    // const filteredData = data.filter((_, index) => index !== 0);

    relationRecords: editData
      ? editData?.relationRecords
          ?.filter?.((ele) => {
            return ele?.name !== editData?.application[0].Name;
          })
          ?.map((ele) => {
            if (ele?.name !== editData?.application[0].Name) {
              console.log(ele, "elefilter");
            }
            return {
              firstName: ele ? ele?.name : "",
              lastName: "",
              middleName: "",
              age: ele ? ele?.age : "",
              relation_id: ele ? +ele?.relationId : "",
              workingType: ele ? ele?.workingType : "",
              AnnualIncome: ele ? ele?.monthlyIncome : "",
            };
          })
      : [
          {
            firstName: "",
            lastName: "",
            middleName: "",
            age: "",
            relation_id: "",
            workingType: "",
            AnnualIncome: 0,
          },
        ],

    dataArray: editData
      ? editData?.trustRecords?.map((ele) => {
          return {
            trustName: ele ? ele?.trustName : "",
            ammount: ele ? ele?.ammount : "",
            id: ele ? ele?.id : "",
          };
        })
      : [
          {
            trustName: "",
            ammount: 0,
          },
        ],
  };

  // useEffect(() => {
  //   let familyId = localStorage.getItem("familyId")
  //     ? localStorage.getItem("familyId")
  //     : 0;
  //   const data = {
  //     familyId: familyId,
  //     VastiPatra: getUserLoginDetails?.vastipatrak,
  //   };
  //   setSingleFamilyId(familyId);
  //   dispatch(getFamilyMemberById(data)).then((res) => {
  //     setFamilyMembers(res?.payload);
  //   });

  //   dispatch(listYearOfSahay()).then((data) => {
  //     if (data?.payload) {
  //       setListYearOfSahayData(data?.payload);
  //     } else {
  //       setListYearOfSahayData([]);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (applicantId) {
      dispatch(bankAccountsList(applicantId)).then((data) => {
        if (data?.payload) {
          setBankAccountsListData(data?.payload);
        } else {
          setBankAccountsListData([]);
        }
      });
    }
  }, [applicantId]);

  const handleBack = () => {
    navigate("/admin/sahay-application");
  };

  const SahayApplicationUpdate = async (data, id) => {
    try {
      const res = await DataService.post(
        `${Api.USER_SAHAYAPPLICATION_UPDATE}/${id}`,
        data
      );

      if (res?.data?.status == 200) {
        toast.success(res.data.message);
        navigate("/admin/edit-apply-for-current-sahay");
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  };

  //handle submit

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const data = new FormData();

    let id = getUserLoginDetails?.mem_id;
    data.append("applicationReceiveDate", values?.applicationReceiveDate);
    data.append("formFillingDate", values?.formFillingDate);
    data.append("AadharNo", +values?.AadharNo);
    data.append("SahayType", +values?.SahayType);
    data.append("subSahayType", +values?.subSahayType);
    data.append("appliedBy", +id);
    data.append("applicantId", +values?.applicantId);
    data.append("bankName", values?.bankName);
    data.append("branch", values?.branch);
    data.append("HolderName", values?.HolderName);
    data.append("IFSCCode", values?.IFSCCode);
    data.append("AccountNo", +values?.AccountNo);
    data.append("isMediclaim", values?.isMediclaim == "No" ? false : true);
    data.append("mediclaimAmmount", +values?.mediclaimAmmount);
    data.append("mediclaimPremium", +values?.mediclaimPremium);
    data.append("isSahay", values?.isSahay == "No" ? false : true);
    data.append("vastiPatrak", 1);
    data.append("SahayAmmount", +values?.SahayAmmount);
    data.append("rentSahayAmmount", +values?.rentSahayAmmount);
    data.append(
      "claimedAmmount",
      getSingleSubSahay == 1014
        ? values?.mediclaimAmmount
        : values?.claimedAmmount
    );
    data.append("trustName", "TEST");
    data.append("yearOfSahay", +values?.yearOfSahay);
    data.append(
      "monthlyIncome",
      values?.monthlyIncome ? values?.monthlyIncome : 0
    );
    data.append("name", values?.memberName);
    data.append("age", values?.age);
    data.append("workingType", values?.workingType);
    data.append("relation", values?.relation_id);
    data.append("remark", values?.remark ? values?.remark : "");

    values?.documents?.length > 0 &&
      values?.documents?.forEach((ele) => {
        if (ele?.document) {
          data.append("documents", ele?.document);
        } else {
          data.append("documents", ele);
        }
      });
    {
      bankAccountsListData &&
        bankAccountsListData?.length > 0 &&
        data.append("bankMasterId", +values?.bankMasterId);
    }
    if (values?.passportPic) {
      data.append("image", values?.passportPic);
    }

    // {
    // sahayCondition == "yes" &&
    values?.dataArray?.forEach((ele, i) => {
      if (ele?.id) {
        data.append(`trustRecords[${i}][id]`, ele?.id);
      }
      data.append(`newTrustRecords[${i}][trustName]`, ele?.trustName);
      data.append(`newTrustRecords[${i}][ammount]`, ele?.ammount);
    });
    // }

    let newArr = [];

    values?.relationRecords?.filter(async (ele) => {
      await newArr.push({
        middleName: ele.middleName,
        age: ele.age,
        relation: ele.relation_id,
        workingType: ele.workingType,
        monthlyIncome: ele.monthlyIncome,
      });
    });
    data.append(`relationRecords[0][id]`, values?.id);

    data.append(`relationRecords[0][name]`, values?.memberName);
    data.append(`relationRecords[0][age]`, values?.age);
    data.append(`relationRecords[0][workingType]`, values?.workingType);
    data.append(`relationRecords[0][relation]`, values?.relation_id);
    data.append(
      `relationRecords[0][monthlyIncome]`,
      values?.monthlyIncome ? values?.monthlyIncome : 0
    );

    values?.relationRecords?.forEach((ele, i) => {
      data.append(`relationRecords[${i + 1}][name]`, ele.firstName);
      data.append(`relationRecords[${i + 1}][age]`, ele.age);
      data.append(`relationRecords[${i + 1}][relation]`, ele.relation_id);
      data.append(
        `relationRecords[${i + 1}][monthlyIncome]`,
        ele.AnnualIncome ? ele.AnnualIncome : 0
      );
      data.append(`relationRecords[${i + 1}][workingType]`, ele.workingType);
      data.append(`relationRecords[${i + 1}][id]`, ele?.id);
    });

    SahayApplicationUpdate(data, listingData?.id);
  };

  return (
    <>
      <div>
        {" "}
        {/* {!loading ? ( */}
        <Index.Box className="apply-sahay-main">
          {/* <Sidebar /> */}
          {!loading ? (
            <Index.Box className="head-content">
              <Index.Box className="user-right">
                <Index.Box className="bottom-user-right">
                  <Index.Box className="user-apply-content">
                    <PagesIndex.Formik
                      enableReinitialize
                      onSubmit={handleFormSubmit}
                      initialValues={initialValues}
                      validationSchema={
                        bankAccountsListData &&
                        bankAccountsListData?.length == 0
                          ? validationSchemaApplyForSahay
                          : validationSchemaApplyForSahayBankMember
                      }
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        touched,
                        setFieldValue,
                        handleBlur,
                        errors,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Index.Box className="main-in-row">
                            <Index.Box className="user-basic user-heading">
                              <Index.Typography
                                component="h1"
                                className="head-apply"
                              >
                                Edit sahay
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="main-in-row">
                            <Index.Box
                              className="user-basic"
                              sx={{ display: "flex" }}
                            >
                              <Index.Typography
                                component="p"
                                className="head-apply"
                              >
                                Basic Details
                              </Index.Typography>
                              <Index.Typography
                                component="h1"
                                className="head-apply"
                              >
                                ({" "}
                                {getUserLoginDetails?.vastipatrak
                                  ? "Member"
                                  : "Non Member"}{" "}
                                )
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      FamilyId
                                    </Index.Typography>
                                    <Index.TextField
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      value={singlaFamilyId && singlaFamilyId}
                                    />
                                  </Index.Box>
                                </Index.Grid>
                                
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Aadhar Card Number
                                    </Index.Typography>
                                    <Index.TextField
                                      type="string"
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="AadharNo"
                                      value={values?.AadharNo}
                                      onChange={(e) => {
                                        const inputValue =
                                          e.target.value.replace(/\D/g, "");
                                        setFieldValue(
                                          "AadharNo",
                                          inputValue.slice(0, 12)
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                  {errors?.AadharNo && touched?.AadharNo && (
                                    <p className="error-text">
                                      {errors?.AadharNo}
                                    </p>
                                  )}
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Sahay Type
                                    </Index.Typography>
                                    <Index.FormControl
                                      sx={{ width: "100%" }}
                                      className="main-select"
                                    >
                                      <Index.Select
                                        className="select-drop"
                                        displayEmpty
                                        value={values?.SahayType}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "SahayType",
                                            e.target.value
                                          );
                                          getSahaySubTypeData(e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        input={<OutlinedInput />}
                                        MenuProps={MenuProps}
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        {sahayTypeList?.map((ele) => (
                                          <Index.MenuItem
                                            key={ele?.Id}
                                            value={ele?.Id}
                                          >
                                            {ele?.Sahay_category}
                                          </Index.MenuItem>
                                        ))}
                                      </Index.Select>
                                    </Index.FormControl>
                                    {errors?.SahayType &&
                                      touched?.SahayType && (
                                        <p className="error-text">
                                          {errors?.SahayType}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Sub Sahay Type
                                    </Index.Typography>
                                    <Index.FormControl
                                      sx={{ width: "100%" }}
                                      className="main-select"
                                    >
                                      <Index.Select
                                        className="select-drop"
                                        displayEmpty
                                        value={values?.subSahayType}
                                        onChange={(e) => {
                                          setGetSingleSubSahay(e.target.value);
                                          setFieldValue(
                                            "subSahayType",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        input={<OutlinedInput />}
                                        MenuProps={MenuProps}
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        {sahaySubTypeList?.map((ele) => (
                                          <Index.MenuItem
                                            key={ele?.Id}
                                            value={ele?.Id}
                                          >
                                            {ele?.SahayName}
                                          </Index.MenuItem>
                                        ))}
                                      </Index.Select>
                                    </Index.FormControl>
                                    {errors?.subSahayType &&
                                      touched?.subSahayType && (
                                        <p className="error-text">
                                          {errors?.subSahayType}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Year Of Sahay
                                    </Index.Typography>
                                    <Index.FormControl
                                      sx={{ width: "100%" }}
                                      className="main-select"
                                    >
                                      <Index.Select
                                        className="select-drop"
                                        displayEmpty
                                        value={values?.yearOfSahay}
                                        onChange={
                                          // handleChange
                                          (e) => {
                                            setFieldValue(
                                              "yearOfSahay",
                                              e.target.value
                                            );
                                          }
                                        }
                                        onBlur={handleBlur}
                                        input={<OutlinedInput />}
                                        MenuProps={MenuProps}
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        {listYearOfSahayData &&
                                          listYearOfSahayData?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.id}
                                              value={ele?.id}
                                            >
                                              {ele?.fromYear}-{ele?.toYear}
                                            </Index.MenuItem>
                                          ))}
                                      </Index.Select>
                                    </Index.FormControl>
                                    {errors?.yearOfSahay &&
                                      touched?.yearOfSahay && (
                                        <p className="error-text">
                                          {errors?.yearOfSahay}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="main-in-row">
                            <Index.Box className="user-basic">
                              <Index.Typography
                                component="p"
                                className="head-apply"
                              >
                                Family Members Details
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups family-detail"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Name Of Applicant
                                    </Index.Typography>
                                    <Index.FormControl
                                      sx={{ width: "100%" }}
                                      className="main-select"
                                    >
                                      <Index.Select
                                        className="select-drop"
                                        displayEmpty
                                        value={values?.applicantId}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "applicantId",
                                            e.target.value
                                          );

                                          setMemberDeatils(
                                            e.target.value,
                                            setFieldValue
                                          );

                                          setFieldValue("bankName", "");
                                          setFieldValue("branch", "");
                                          setFieldValue("HolderName", "");
                                          setFieldValue("IFSCCode", "");
                                          setFieldValue("AccountNo", "");

                                          setApplicantId(e.target.value);
                                          setApplicantSelectedMember(
                                            familyMembers?.filter((item) => {
                                              return item?.id == e.target.value;
                                            })
                                          );

                                          setFieldValue(
                                            "relationRecords",
                                            familyMembers?.filter((item) => {
                                              return (
                                                item?.id !== e.target.value
                                              );
                                            })
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        input={<OutlinedInput />}
                                        MenuProps={MenuProps}
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        {familyMembers?.map((ele) => (
                                          <Index.MenuItem
                                            key={ele?.id}
                                            value={ele?.id}
                                          >
                                            {ele?.firstName}
                                          </Index.MenuItem>
                                        ))}
                                      </Index.Select>
                                    </Index.FormControl>
                                    {errors?.applicantId &&
                                      touched?.applicantId && (
                                        <p className="error-text">
                                          {errors?.applicantId}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Name
                                    </Index.Typography>
                                    <Index.TextField
                                      disabled
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="memberName"
                                      value={values?.memberName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors?.memberName &&
                                      touched?.memberName && (
                                        <p className="error-text">
                                          {errors?.memberName}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Relation
                                    </Index.Typography>
                                    <Index.TextField
                                      disabled
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="relation"
                                      value={values?.relation}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors?.relation && touched?.relation && (
                                      <p className="error-text">
                                        {errors?.relation}
                                      </p>
                                    )}
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Age
                                    </Index.Typography>
                                    <Index.TextField
                                      disabled
                                      type="number"
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="age"
                                      value={values?.age}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors?.age && touched?.age && (
                                      <p className="error-text">
                                        {errors?.age}
                                      </p>
                                    )}
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Working Type
                                    </Index.Typography>
                                    <Index.TextField
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="workingType"
                                      value={values?.workingType}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors?.workingType &&
                                      touched?.workingType && (
                                        <p className="error-text">
                                          {errors?.workingType}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Monthly Income
                                    </Index.Typography>
                                    <Index.TextField
                                      type="number"
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="monthlyIncome"
                                      value={values?.monthlyIncome}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {/* {errors?.monthlyIncome &&
                                    touched?.monthlyIncome && (
                                      <p className="error-text">
                                        {errors?.monthlyIncome}
                                      </p>
                                    )} */}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="main-in-row row-bank">
                            <Index.Box className="user-basic">
                              <Index.Typography
                                component="p"
                                className="head-apply"
                              >
                                Bank Details
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp bank-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                {bankAccountsListData &&
                                  bankAccountsListData?.length > 0 && (
                                    <>
                                      <Index.Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                      >
                                        <Index.Box className="lable-input-grp">
                                          <Index.Typography
                                            component="p"
                                            className="in-label"
                                          >
                                            Bank Account
                                          </Index.Typography>
                                          <Index.FormControl
                                            sx={{ width: "100%" }}
                                            className="main-select"
                                          >
                                            <Index.Select
                                              className="select-drop"
                                              displayEmpty
                                              value={values?.bankMasterId}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "bankMasterId",
                                                  e.target.value
                                                );

                                                let data =
                                                  bankAccountsListData?.filter(
                                                    (ele) => {
                                                      return (
                                                        ele?.id ==
                                                        e.target.value
                                                      );
                                                    }
                                                  );

                                                if (data.length > 0) {
                                                  setFieldValue(
                                                    "bankName",
                                                    data[0]?.bankName
                                                  );
                                                  setFieldValue(
                                                    "branch",
                                                    data[0]?.branch
                                                  );
                                                  setFieldValue(
                                                    "HolderName",
                                                    data[0]?.HolderName
                                                  );
                                                  setFieldValue(
                                                    "IFSCCode",
                                                    data[0]?.IFSCCode
                                                  );
                                                  setFieldValue(
                                                    "AccountNo",
                                                    data[0]?.AccountNo
                                                  );
                                                }
                                              }}
                                              onBlur={handleBlur}
                                              input={<OutlinedInput />}
                                              // renderValue={(selected) => {
                                              //   if (selected.length === 0) {
                                              //     return <em>Dropdown</em>;
                                              //   }

                                              //   return selected.join(", ");
                                              // }}
                                              MenuProps={MenuProps}
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                            >
                                              {bankAccountsListData &&
                                                bankAccountsListData?.map(
                                                  (ele) => (
                                                    <Index.MenuItem
                                                      key={ele?.id}
                                                      value={ele?.id}
                                                    >
                                                      {ele?.bankName}
                                                    </Index.MenuItem>
                                                  )
                                                )}
                                            </Index.Select>
                                          </Index.FormControl>
                                          {errors?.bankMasterId &&
                                            touched?.bankMasterId && (
                                              <p className="error-text">
                                                {errors?.bankMasterId}
                                              </p>
                                            )}
                                        </Index.Box>
                                      </Index.Grid>
                                    </>
                                  )}
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Bank Name
                                    </Index.Typography>
                                    <Index.TextField
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="bankName"
                                      value={values?.bankName}
                                      onChange={(e) => {
                                        const uppercaseValue =
                                          e.target.value.toUpperCase();
                                        handleChange(e.target.name)(
                                          uppercaseValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                  {errors?.bankName && touched?.bankName && (
                                    <p className="error-text">
                                      {errors?.bankName}
                                    </p>
                                  )}
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Branch
                                    </Index.Typography>
                                    <Index.TextField
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="branch"
                                      value={values?.branch}
                                      onChange={(e) => {
                                        const uppercaseValue =
                                          e.target.value.toUpperCase();
                                        handleChange(e.target.name)(
                                          uppercaseValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                  {errors?.branch && touched?.branch && (
                                    <p className="error-text">
                                      {errors?.branch}
                                    </p>
                                  )}
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Account Holder Name
                                    </Index.Typography>
                                    <Index.TextField
                                      type="text"
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="HolderName"
                                      value={values?.HolderName}
                                      onChange={(e) => {
                                        const uppercaseValue =
                                          e.target.value.toUpperCase();
                                        handleChange(e.target.name)(
                                          uppercaseValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                  {errors?.HolderName &&
                                    touched?.HolderName && (
                                      <p className="error-text">
                                        {errors?.HolderName}
                                      </p>
                                    )}
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Account Number{" "}
                                    </Index.Typography>
                                    <Index.TextField
                                      type="string"
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="AccountNo"
                                      value={values?.AccountNo}
                                      onChange={(e) => {
                                        const inputValue =
                                          e.target.value.replace(/\D/g, "");
                                        setFieldValue(
                                          "AccountNo",
                                          inputValue.slice(0, 18)
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                  {errors?.AccountNo && touched?.AccountNo && (
                                    <p className="error-text">
                                      {errors?.AccountNo}
                                    </p>
                                  )}
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      IFSC Code
                                    </Index.Typography>
                                    <Index.TextField
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="IFSCCode"
                                      value={values?.IFSCCode}
                                      onChange={(e) => {
                                        const uppercaseValue =
                                          e.target.value.toUpperCase();
                                        handleChange(e.target.name)(
                                          uppercaseValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                  {errors?.IFSCCode && touched?.IFSCCode && (
                                    <p className="error-text">
                                      {errors?.IFSCCode}
                                    </p>
                                  )}
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </Index.Box>
                          {getSingleSubSahay == 1017 ? (
                            <Index.Box
                              className="main-in-row row-bank"
                              sx={{ marginTop: "15px" }}
                            >
                              <Index.Box className="user-basic">
                                <Index.Typography
                                  component="p"
                                  sx={{ fontSize: "12px" }}
                                  // className="head-apply"
                                >
                                  Type Of Disease
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp bank-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="typeOfDisease"
                                        value={values?.typeOfDisease}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {/* {errors?.IFSCCode && (
                                  <p className="error-text">
                                    {errors?.IFSCCode}
                                  </p>
                                )} */}
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>
                          ) : null}
                          <Index.Box className="main-in-row row-bank">
                            <Index.Box
                              sx={{ flexGrow: 1, marginTop: "20px" }}
                              className="al-form-groups fam-in-grp bank-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                {/* {!EducationSubsahayValue.includes(
                                getSingleSubSahay
                              ) && ( */}
                                {/* {editData?.application?.[0]
                                  ?.mediclaimAmmount !== 0 && ( */}
                                {!EducationSubsahayValue.includes(
                                  getSingleSubSahay
                                ) && (
                                  <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                    {/* <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Mediclaim Policy Amount
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="mediclaimAmmount"
                                        value={values?.mediclaimAmmount}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                      />
                                    </Index.Box> */}

                                    {/* {errors?.mediclaimAmmount && touched?.mediclaimAmmount && (
                                      <p className="error-text">
                                        {errors?.mediclaimAmmount}
                                      </p>
                                    )} */}
                                  </Index.Grid>
                                )}
                                {/* )} */}
                                {/* {getSingleSubSahay != 1010 ? ( */}
                                {/* {editData?.application?.[0]?.claimedAmmount !==
                                  0 && ( */}

                                {getSingleSubSahay != 1010 ? (
                                  <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        {/* Total Claimed Sahay Amount */}
                                        Total Requested Sahay Amount
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled={
                                          getSingleSubSahay == 1014
                                            ? true
                                            : false
                                        }
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="claimedAmmount"
                                        value={
                                          getSingleSubSahay == 1014
                                            ? values?.mediclaimAmmount
                                            : values?.claimedAmmount
                                        }
                                        // value={values?.mediclaimAmmount}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 7) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {/* {errors?.claimedAmmount &&
                                    touched?.claimedAmmount && (
                                      <p className="error-text">
                                        {errors?.claimedAmmount}
                                      </p>
                                    )} */}
                                  </Index.Grid>
                                ) : null}

                                {/* )} */}

                                {/* ) : null} */}
                                {/* {
               
                                getSingleSubSahay != 1014 &&
                                  getSingleSubSahay != 1015 &&
                                  getSingleSubSahay != 1010 &&
                                  values?.isSahay == "Yes" && ( */}

                                {/* {editData?.application?.[0]?.SahayAmmount !==
                                  0 && ( */}

                                {getSingleSubSahay != 1014 &&
                                  getSingleSubSahay != 1015 &&
                                  getSingleSubSahay != 1010 &&
                                  values?.isSahay == "Yes" && (
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Sahay Amount
                                        </Index.Typography>
                                        <Index.TextField
                                          type="number"
                                          inputProps={ariaLabel}
                                          className="basic-input input-width"
                                          name="SahayAmmount"
                                          value={values?.SahayAmmount}
                                          onChange={handleChange}
                                          // onBlur={handleBlur}
                                        />
                                      </Index.Box>
                                      {/* {errors?.SahayAmmount &&
                                    touched?.SahayAmmount && (
                                      <p className="error-text">
                                        {errors?.SahayAmmount}
                                      </p>
                                    )} */}
                                    </Index.Grid>
                                  )}

                                {/* )} */}

                                {/* )
                              } */}
                                {/* {editData?.application?.[0]
                                  ?.rentSahayAmmount !== 0 && ( */}
                                {getSingleSubSahay == 1010 && (
                                  <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                    {/* {getSingleSubSahay == 1010 ? ( */}
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Rent Amount For Rent Sahay
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="rentSahayAmmount"
                                        value={values?.rentSahayAmmount}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {/* ) : null} */}
                                    {/* {errors?.rentSahayAmmount && (
                                  <p className="error-text">
                                    {errors?.rentSahayAmmount}
                                  </p>
                                )} */}
                                  </Index.Grid>
                                )}

                                {/* )} */}
                              </Index.Grid>
                            </Index.Box>
                            {/* 
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={8}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Remark
                                    </Index.Typography>
                                    <Index.TextField
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="remark"
                                      value={values?.remark}
                                      onChange={handleChange}
                                      // onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box> */}

                            {/* <Index.Grid
                              item
                              xs={4}
                              className="al-form-groups fam-in-grp"
                            ></Index.Grid> */}

                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups family-detail fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                {getSingleSubSahay !== 1014 ? (
                                  <>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Does The Applicant Take Mediclaim
                                          Premium Sahay
                                        </Index.Typography>
                                        <Index.ToggleButtonGroup
                                          color="primary"
                                          value={values?.isSahay}
                                          exclusive
                                          onChange={(e) => {
                                            setFieldValue(
                                              "isSahay",
                                              e.target.value
                                            );
                                          }}
                                          aria-label="Platform"
                                          className="toggle-switch-main"
                                        >
                                          <Index.ToggleButton value={"Yes"}>
                                            Yes
                                          </Index.ToggleButton>
                                          <Index.ToggleButton value={"No"}>
                                            No
                                          </Index.ToggleButton>
                                        </Index.ToggleButtonGroup>
                                      </Index.Box>
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Does The Applicant Have A Mediclaim
                                          Policys
                                        </Index.Typography>
                                        <Index.ToggleButtonGroup
                                          color="primary"
                                          value={values?.isMediclaim}
                                          exclusive
                                          onChange={(e) => {
                                            setFieldValue(
                                              "isMediclaim",
                                              e.target.value
                                            );
                                          }}
                                          aria-label="Platform"
                                          className="toggle-switch-main"
                                        >
                                          <Index.ToggleButton value="Yes">
                                            Yes
                                          </Index.ToggleButton>
                                          <Index.ToggleButton value="No">
                                            No
                                          </Index.ToggleButton>
                                        </Index.ToggleButtonGroup>
                                      </Index.Box>
                                    </Index.Grid>
                                  </>
                                ) : null}
                              </Index.Grid>
                            </Index.Box>

                            {/* <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups family-detail fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={4}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                      sx={{ fontSize: "12px" }}
                                    >
                                      Does applicant took sahay from any trust
                                    </Index.Typography>
                                    <Index.ToggleButtonGroup
                                      color="primary"
                                      value={values?.trust}
                                      exclusive
                                      onChange={(e) => {
                                        setSahayCondition(e.target.value);
                                        setFieldValue("trust", e.target.value);
                                      }}
                                      aria-label="Platform"
                                      className="toggle-switch-main"
                                    >
                                      <Index.ToggleButton value="yes">
                                        Yes
                                      </Index.ToggleButton>
                                      <Index.ToggleButton value="no">
                                        No
                                      </Index.ToggleButton>
                                    </Index.ToggleButtonGroup>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box> */}

                            {/* {sahayCondition == "yes" && ( */}
                            <>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                  {/* fieldarraystart */}
                                  <Index.Grid item xs={12}>
                                    <PagesIndex.FieldArray name="dataArray">
                                      {({ push, remove }) => (
                                        <div className="trust-main-div">
                                          <Index.Box className="common-button  mb-10px flex-end save-btn">
                                            <Index.Button
                                              variant="contained"
                                              onClick={() =>
                                                push({
                                                  trustName: "",
                                                  ammount: 0,
                                                })
                                              }
                                            >
                                              <Index.ControlPointIcon /> Add
                                              Trust
                                            </Index.Button>
                                          </Index.Box>

                                          {values?.dataArray?.map(
                                            (data, index) => (
                                              <Index.Grid
                                                container
                                                spacing={2}
                                                key={index}
                                                mb={2}
                                              >
                                                <Index.Grid
                                                  item
                                                  xs={6}
                                                  sm={4}
                                                  md={4}
                                                  lg={3}
                                                >
                                                  <Index.Box className="lable-input-grp">
                                                    <Index.Typography
                                                      component="p"
                                                      className="in-label"
                                                    >
                                                      Trust Name
                                                    </Index.Typography>
                                                    <Index.TextField
                                                      inputProps={ariaLabel}
                                                      className="basic-input input-width"
                                                      onBlur={handleBlur}
                                                      name={`dataArray.${index}.trustName`}
                                                      onChange={handleChange}
                                                      value={
                                                        values.dataArray[index]
                                                          .trustName
                                                      }
                                                    />
                                                  </Index.Box>
                                                  {errors?.trustName &&
                                                    touched?.trustName && (
                                                      <p className="error-text">
                                                        {errors?.trustName}
                                                      </p>
                                                    )}
                                                </Index.Grid>
                                                <Index.Grid
                                                  item
                                                  xs={6}
                                                  sm={4}
                                                  md={4}
                                                  lg={3}
                                                >
                                                  <Index.Box className="lable-input-grp">
                                                    <Index.Typography
                                                      component="p"
                                                      className="in-label"
                                                    >
                                                      Amount
                                                    </Index.Typography>
                                                    <Index.TextField
                                                      type="number"
                                                      inputProps={ariaLabel}
                                                      className="basic-input input-width"
                                                      onBlur={handleBlur}
                                                      name={`dataArray.${index}.ammount`}
                                                      onChange={handleChange}
                                                      value={
                                                        values.dataArray[index]
                                                          .ammount
                                                      }
                                                    />
                                                  </Index.Box>
                                                  {errors?.ammount &&
                                                    touched?.ammount && (
                                                      <p className="error-text">
                                                        {errors?.ammount}
                                                      </p>
                                                    )}
                                                </Index.Grid>

                                                {index > 0 && (
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={4}
                                                    md={4}
                                                    lg={3}
                                                  >
                                                    <Index.Box className="common-button mt-details-remove-pro ">
                                                      <Index.Button
                                                        variant="contained"
                                                        onClick={() =>
                                                          remove(index)
                                                        }
                                                      >
                                                        <RemoveIcon />
                                                      </Index.Button>
                                                    </Index.Box>{" "}
                                                  </Index.Grid>
                                                )}
                                              </Index.Grid>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </PagesIndex.FieldArray>
                                  </Index.Grid>

                                  {/* fieldarrayend */}
                                </Index.Grid>
                              </Index.Box>
                            </>
                            {/* )} */}
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={12} sm={6} md={5} lg={3}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Passport Size Photo
                                    </Index.Typography>
                                    <Index.Box className="select-position">
                                      <input
                                        type="file"
                                        name="passportPic"
                                        className="ori-input"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "passportPic",
                                            e.target.files[0]
                                          );
                                          setSelectedImage(e.target.files[0]);
                                        }}
                                      />

                                      <Index.Box className="select-file">
                                        Upload Your Photo
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  {errors?.passportPic &&
                                    touched?.passportPic && (
                                      <p className="error-text">
                                        {errors?.passportPic}
                                      </p>
                                    )}
                                </Index.Grid>

                                <Index.Grid item xs={12} sm={6} md={5} lg={3}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-view">
                                      {/* src=
                                      {selectedImage
                                        ? URL.createObjectURL(selectedImage)
                                        : `${imagePath}${editData?.application?.[0]?.image}`
                                        ? `${imagePath}${editData?.application?.[0]?.image}`
                                        : "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"} */}
                                      <img
                                        src={
                                          selectedImage
                                            ? URL.createObjectURL(selectedImage)
                                            : values?.passportPic
                                            ? `${imagePath}${values.passportPic}`
                                            : PagesIndex.Png.user_icon_default
                                        }
                                      />
                                      <Index.Box
                                        className="select-remove"
                                        onClick={() =>
                                          resetImage(setFieldValue)
                                        }
                                      >
                                        Remove
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                            {/* {errors?.passportPic && touched?.passportPic && (
                            <p className="error-text">{errors?.passportPic}</p>
                          )} */}
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp multiple-doc"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={5}
                                  lg={4}
                                  mb={2}
                                >
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Add Multiple Document
                                    </Index.Typography>
                                    <Index.Box className="select-position">
                                      <input
                                        type="file"
                                        name="documents"
                                        multiple
                                        className="ori-input"
                                        onChange={(event) => {
                                          const files = event.target.files;

                                          setFieldValue("documents", [
                                            ...values.documents,
                                            ...files,
                                          ]);
                                        }}
                                      />

                                      <Index.Box className="select-file">
                                        Upload Your Document
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  {errors?.documents && touched?.documents && (
                                    <p className="error-text">
                                      {errors?.documents}
                                    </p>
                                  )}
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={12} md={12} lg={8}>
                                  <Index.Box className="select-view-document-name">
                                    {values?.documents?.map((file, index) => (
                                      <Index.Box
                                        className="document-name"
                                        key={index}
                                      >
                                        <Index.Typography
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            window.open(
                                              file?.name
                                                ? URL.createObjectURL(file)
                                                : `${imagePath}${file?.document}`
                                            );
                                          }}
                                        >
                                          {file?.document
                                            ? file?.document
                                            : file?.name}
                                        </Index.Typography>

                                        <Index.IconButton
                                          onClick={() => {
                                            const updatedDocuments =
                                              values.documents.filter(
                                                (_, i) => i !== index
                                              );
                                            setFieldValue(
                                              "documents",
                                              updatedDocuments
                                            );
                                          }}
                                        >
                                          <Index.ClearIcon className="muiico-cancel" />
                                        </Index.IconButton>
                                      </Index.Box>
                                    ))}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>

                            {/* {errors?.documents && touched?.documents && (
                            <p className="error-text">{errors?.documents}</p>
                          )} */}
                            <Index.Box className="field-table">
                              <Index.Grid>
                                <Index.Box className="">
                                  <Index.TableContainer
                                    component={Index.Paper}
                                    className="table-container edit-drop-container"
                                  >
                                    <Index.Table
                                      aria-label="simple table"
                                      className="table-design-main barge-table edit-drop-table"
                                    >
                                      <Index.TableHead className="edit-drop-head">
                                        <Index.TableRow className="edit-drop-tr">
                                          <Index.TableCell
                                            align="left"
                                            className="edit-drop-td"
                                          >
                                            Name
                                          </Index.TableCell>
                                          <Index.TableCell
                                            align="left"
                                            className="edit-drop-td"
                                          >
                                            Relation
                                          </Index.TableCell>
                                          <Index.TableCell
                                            align="left"
                                            className="edit-drop-td"
                                          >
                                            Age
                                          </Index.TableCell>
                                          <Index.TableCell
                                            align="left"
                                            className="edit-drop-td"
                                          >
                                            Job
                                          </Index.TableCell>
                                          <Index.TableCell
                                            align="left"
                                            className="edit-drop-td"
                                          >
                                            Monthly Income
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableHead>
                                      <Index.TableBody className="edit-drop-tbody">
                                        <Index.TableRow className="edit-drop-tr">
                                          <Index.TableCell
                                            align="left"
                                            className="tablecell edit-drop-td"
                                          >
                                            <Index.TextField
                                              placeholder="Name"
                                              small
                                              disabled
                                              inputProps={ariaLabel}
                                              className="tablecell-field"
                                              value={
                                                values?.memberName
                                                  ? values?.memberName
                                                  : applicantSelectedMember[0]
                                                  ? applicantSelectedMember[0]
                                                      ?.firstName +
                                                    " " +
                                                    applicantSelectedMember[0]
                                                      ?.middleName +
                                                    " " +
                                                    applicantSelectedMember[0]
                                                      ?.lastName
                                                  : ""
                                              }
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            align="left"
                                            className="tablecell edit-drop-td"
                                          >
                                            <Index.TextField
                                              placeholder="Relation"
                                              small
                                              inputProps={ariaLabel}
                                              className="tablecell-field"
                                              disabled
                                              value={
                                                applicantSelectedMember[0]
                                                  ?.relation
                                                  ? "Applicant"
                                                  : values?.relation
                                              }
                                            />
                                          </Index.TableCell>

                                          <Index.TableCell
                                            align="left"
                                            className="tablecell edit-drop-td"
                                          >
                                            <Index.TextField
                                              placeholder="Age"
                                              small
                                              disabled
                                              inputProps={ariaLabel}
                                              className="tablecell-field"
                                              value={
                                                values?.age
                                                  ? values?.age
                                                  : applicantSelectedMember[0]
                                                      ?.age
                                              }
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            align="left"
                                            className="tablecell edit-drop-td"
                                          >
                                            <Index.TextField
                                              small
                                              inputProps={ariaLabel}
                                              name="workingType"
                                              className="tablecell-field"
                                              onChange={handleChange}
                                              value={
                                                values?.workingType
                                                  ? values?.workingType
                                                  : applicantSelectedMember[0]
                                                      ?.workingType
                                              }
                                              disabled
                                              placeholder="Job"
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            align="left"
                                            className="tablecell edit-drop-td"
                                          >
                                            <Index.TextField
                                              small
                                              disabled
                                              name="monthlyIncome"
                                              inputProps={ariaLabel}
                                              className="tablecell-field"
                                              // value={
                                              //   applicantSelectedMember[0]
                                              //     ?.AnnualIncome
                                              // }
                                              value={values?.monthlyIncome}
                                              onChange={handleChange}
                                              placeholder="Monthly Income"
                                            />
                                          </Index.TableCell>
                                        </Index.TableRow>

                                        <PagesIndex.FieldArray name="relationRecords">
                                          {({ push, remove }) => (
                                            <>
                                              <>
                                                {values?.relationRecords?.map(
                                                  (data, index) => (
                                                    <Index.TableRow className="edit-drop-tr">
                                                      <Index.TableCell
                                                        align="left"
                                                        className="tablecell edit-drop-td"
                                                      >
                                                        <Index.TextField
                                                          placeholder="Name"
                                                          small
                                                          inputProps={ariaLabel}
                                                          name={`relationRecords.${index}.firstName`}
                                                          className="tablecell-field"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          value={
                                                            values
                                                              .relationRecords[
                                                              index
                                                            ].firstName
                                                            // +
                                                            // " " +
                                                            // values
                                                            //   .relationRecords[
                                                            //   index
                                                            // ].middleName +
                                                            // " " +
                                                            // values
                                                            //   .relationRecords[
                                                            //   index
                                                            // ].lastName
                                                          }
                                                        />
                                                      </Index.TableCell>
                                                      <Index.TableCell
                                                        align="left"
                                                        className="tablecell edit-drop-td"
                                                      >
                                                        <Index.Box className="input-design-div with-border">
                                                          <Index.Select
                                                            hiddenLabel
                                                            fullWidth
                                                            id="hgh"
                                                            name={`relationRecords.${index}.relation_id`}
                                                            onBlur={handleBlur}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            value={
                                                              values
                                                                .relationRecords[
                                                                index
                                                              ].relation_id
                                                            }
                                                          >
                                                            {getRelationList &&
                                                              getRelationList?.map(
                                                                (option) => (
                                                                  <Index.MenuItem
                                                                    key={
                                                                      option.relation_id
                                                                    }
                                                                    value={
                                                                      option.relation_id
                                                                    }
                                                                    name={
                                                                      option.relation_name
                                                                    }
                                                                  >
                                                                    {
                                                                      option.relation_name
                                                                    }
                                                                  </Index.MenuItem>
                                                                )
                                                              )}
                                                          </Index.Select>
                                                        </Index.Box>
                                                      </Index.TableCell>

                                                      <Index.TableCell
                                                        align="left"
                                                        className="tablecell edit-drop-td"
                                                      >
                                                        <Index.TextField
                                                          placeholder="Age"
                                                          small
                                                          inputProps={ariaLabel}
                                                          className="tablecell-field"
                                                          value={
                                                            values
                                                              .relationRecords[
                                                              index
                                                            ].age
                                                          }
                                                          name={`relationRecords.${index}.age`}
                                                          onChange={
                                                            handleChange
                                                          }
                                                        />
                                                      </Index.TableCell>
                                                      <Index.TableCell
                                                        align="left"
                                                        className="tablecell edit-drop-td"
                                                      >
                                                        <Index.TextField
                                                          placeholder="Job"
                                                          small
                                                          inputProps={ariaLabel}
                                                          className="tablecell-field"
                                                          value={
                                                            values
                                                              .relationRecords[
                                                              index
                                                            ].workingType
                                                          }
                                                          name={`relationRecords.${index}.workingType`}
                                                          onChange={
                                                            handleChange
                                                          }
                                                        />
                                                      </Index.TableCell>
                                                      <Index.TableCell
                                                        align="left"
                                                        className="tablecell edit-drop-td"
                                                      >
                                                        <Index.TextField
                                                          type="number"
                                                          small
                                                          inputProps={ariaLabel}
                                                          className="tablecell-field"
                                                          placeholder="Monthly Income"
                                                          value={
                                                            values
                                                              .relationRecords[
                                                              index
                                                            ].AnnualIncome
                                                          }
                                                          name={`relationRecords.${index}.AnnualIncome`}
                                                          onChange={
                                                            handleChange
                                                          }
                                                        />
                                                      </Index.TableCell>
                                                    </Index.TableRow>
                                                  )
                                                )}
                                              </>
                                            </>
                                          )}
                                        </PagesIndex.FieldArray>
                                      </Index.TableBody>
                                    </Index.Table>
                                  </Index.TableContainer>
                                </Index.Box>
                              </Index.Grid>
                            </Index.Box>
                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-position">
                                      <Index.Box className="apply-now">
                                        <button
                                          type="submit"
                                          // disabled={values.isSubmit}
                                        >
                                          Update
                                        </button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                    <Index.Button
                                      variant="contained"
                                      onClick={() => handleBack()}
                                    >
                                      Back
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </Index.Box>{" "}
                        </form>
                      )}
                    </PagesIndex.Formik>

                    <Index.Box className="title-header-flex "></Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ) : (
            <Index.Box className="circular-loading">
              <Index.CircularProgress />
            </Index.Box>
            // <PagesIndex.PageLoading colSpan={12} />
          )}
        </Index.Box>
        {/* // ) : ( // <h1>Loading...</h1>
        // )} */}
      </div>
    </>
  );
};
export default EditCurrentApplyForSahay;
