import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import {
  getSahaySubTypeList,
  getSahayTypeList,
  getSubSahayReportData,
} from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import SahayMultiPleTableList from "./SahayMultiPleTableList";
import { useReactToPrint } from "react-to-print";
import { Autocomplete } from "@mui/material";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import Pagination from "../../../../components/common/Pagination";
import { Api } from "../../../../config/Api";
import dataService from "../../../../config/DataService";
import moment from "moment";
import dayjs from "dayjs";

const familyIncomeAmountList = [
  { id: 1, ammount: "Less than 5 lakh" },
  { id: 2, ammount: "5 lakh or above" },
];

const electricBillAmountList = [
  { id: 1, ammount: "0 to 1000" },
  { id: 2, ammount: "1001 to 2500" },
  { id: 3, ammount: "2501 to 3500" },
  { id: 4, ammount: "3501 and Above" },
];

export default function SahayWiseDetails() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { getSubSahayReportDataList } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [excel, setExcel] = useState([]);
  const [filterSubSahayName, setFilterSubSahayName] = useState("");
  const [filter, setFilter] = useState("");
  const [filterSubSahay, setFilterSubSahay] = useState("");
  const [filterSahay, setFilterSahay] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [sahayTypeList, setSahayTypeList] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [filterReportData, setFilterReportData] = useState([]);
  const [filterFamilyIncome, setFilterFamilyIncome] = useState("");
  const [filterElectricityBill, setFilterElectricityBill] = useState("");
  const [selectDateData, setSelectDateData] = useState("");

  console.log("filterFamilyIncome", filterFamilyIncome);
  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // //Filter Data

  // useEffect(() => {
  //   if (
  //     filter ||
  //     filterYear ||
  //     filterSahay ||
  //     filterSubSahay ||
  //     filterFamilyIncome ||
  //     filterElectricityBill ||
  //     selectDateData
  //   ) {
  //     const filterData = data?.filter((ele) => {
  //       // Check filters in sequence based on probability
  //       if (
  //         filter &&
  //         filterSahay &&
  //         filterSubSahay &&
  //         filterFamilyIncome &&
  //         filterElectricityBill &&
  //         filterYear &&
  //         selectDateData
  //       ) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SahayName == filterSahay &&
  //           ele.SubSahayName == filterSubSahay &&
  //           ele.familyIncome == filterFamilyIncome &&
  //           ele.electricityBill == filterElectricityBill &&
  //           ele.applicationReceiveDate == selectDateData &&
  //           ele.Year == filterYear
  //         );
  //       } else if (selectDateData && filter) {
  //         return (
  //           ele.FID == filter && ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (selectDateData && filterFamilyIncome) {
  //         return (
  //           ele.familyIncome == filterFamilyIncome &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (selectDateData && filterElectricityBill) {
  //         return (
  //           ele.electricityBill == filterElectricityBill &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (selectDateData && filterYear) {
  //         return (
  //           ele.Year == filterYear &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filterYear) {
  //         return ele.Year == filterYear;
  //       } else if (filter) {
  //         return ele.FID == filter;
  //       } else if (selectDateData && filterSahay && filterSubSahay) {
  //         return (
  //           ele.SubSahayName == filterSubSahay &&
  //           ele.SahayName == filterSahay &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filter && filterSahay) {
  //         return ele.FID == filter && ele.SahayName == filterSahay;
  //       } else if (filterYear && filterSahay && filterSubSahay) {
  //         return (
  //           ele.Year == filterYear &&
  //           ele.SahayName == filterSahay &&
  //           ele.SubSahayName == filterSubSahay
  //         );
  //       } else if (filter && filterSahay && filterYear) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SahayName == filterSahay &&
  //           ele.Year == filterYear
  //         );
  //       } else if (filter && filterSahay) {
  //         return ele.FID == filter && ele.SahayName == filterSahay;
  //       } else if (filterSahay) {
  //         return ele.SahayName == filterSahay;
  //       } else if (filterYear && filterSahay) {
  //         return ele.Year == filterYear && ele.SahayName == filterSahay;
  //       } else if (filterSahay && filterSubSahay) {
  //         return (
  //           ele.SahayName == filterSahay && ele.SubSahayName == filterSubSahay
  //         );
  //       } else if (filterYear && filterSahay) {
  //         return ele.Year == filterYear && ele.SahayName == filterSahay;
  //       } else if (selectDateData) {
  //         return ele.applicationReceiveDate == selectDateData;
  //       } else if (filterFamilyIncome && filterElectricityBill) {
  //         return (
  //           ele.familyIncome == filterFamilyIncome &&
  //           ele.electricityBill == filterElectricityBill
  //         );
  //       } else if (
  //         selectDateData &&
  //         filterFamilyIncome &&
  //         filterElectricityBill
  //       ) {
  //         return (
  //           ele.applicationReceiveDate == selectDateData &&
  //           ele.familyIncome == filterFamilyIncome &&
  //           ele.electricityBill == filterElectricityBill
  //         );
  //       } else if (filterYear && filterSahay && filter) {
  //         return (
  //           ele.Year == filterYear &&
  //           ele.SahayName == filterSahay &&
  //           ele.FID == filter
  //         );
  //       } else if (
  //         filter ||
  //         filterYear ||
  //         filterSahay ||
  //         filterSubSahay ||
  //         filterFamilyIncome ||
  //         filterElectricityBill ||
  //         selectDateData
  //       ) {
  //         return (
  //           ele.FID == filter ||
  //           ele.SahayName == filterSahay ||
  //           ele.Year == filterYear ||
  //           ele.familyIncome == filterFamilyIncome ||
  //           ele.electricityBill == filterElectricityBill ||
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else {
  //         return true; // Return true for all elements if no filters are provided
  //       }
  //     });
  //     setFilterReportData(filterData);
  //     console.log("filterData", filterData);
  //   } else {
  //     setFilterReportData(); // Set the original data if no filters are provided
  //   }
  // }, [
  //   filter,
  //   filterYear,
  //   filterSahay,
  //   filterSubSahay,
  //   filterElectricityBill,
  //   filterFamilyIncome,
  //   selectDateData,
  // ]);

  // useEffect(() => {
  //   if (
  //     filter ||
  //     filterYear ||
  //     filterSahay ||
  //     filterSubSahay ||
  //     filterFamilyIncome ||
  //     filterElectricityBill ||
  //     selectDateData
  //   ) {
  //     const filterData = data?.filter((ele) => {
  //       // Check filters in sequence based on probability
  //       if (
  //         filter &&
  //         filterSahay &&
  //         filterSubSahay &&
  //         filterFamilyIncome &&
  //         filterElectricityBill &&
  //         filterYear &&
  //         selectDateData
  //       ) {
  //         return (
  //           ele.FID === filter &&
  //           ele.SahayName === filterSahay &&
  //           ele.SubSahayName === filterSubSahay &&
  //           ele.familyIncome === filterFamilyIncome &&
  //           ele.electricityBill === filterElectricityBill &&
  //           ele.Year === filterYear &&
  //           ele.applicationReceiveDate === selectDateData
  //         );
  //       } else if (filter && filterSahay && filterSubSahay) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SahayName == filterSahay &&
  //           ele.SubSahayName == filterSubSahay
  //         );
  //       } else if (filterSahay && filterSubSahay) {
  //         return (
  //           ele.SahayName == filterSahay && ele.SubSahayName == filterSubSahay
  //         );
  //       }
  //       //  else if (filterElectricityBill) {
  //       //   return ele.electricityBill === filterElectricityBill;
  //       // }
  //       else if (
  //         filter &&
  //         filterSahay &&
  //         filterSubSahay &&
  //         filterFamilyIncome
  //       ) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SubSahayName == filterSubSahay &&
  //           ele.familyIncome == filterFamilyIncome
  //         );
  //       } else if (
  //         filterSahay &&
  //         filterFamilyIncome &&
  //         filterElectricityBill &&
  //         filterYear &&
  //         selectDateData
  //       ) {
  //         return (
  //           ele.SahayName == filterSahay &&
  //           ele.familyIncome === filterFamilyIncome &&
  //           ele.electricityBill === filterElectricityBill &&
  //           ele.Year === filterYear &&
  //           ele.applicationReceiveDate === selectDateData
  //         );
  //       } else if (filter && filterSahay) {
  //         return ele.FID == filter && ele.SahayName == filterSahay;
  //       } else if (filterFamilyIncome && filterElectricityBill) {
  //         return (
  //           ele.familyIncome == filterFamilyIncome &&
  //           ele.electricityBill == filterElectricityBill
  //         );
  //       } else if (filter && filterSahay && selectDateData) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SahayName == filterSahay &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filter && filterSubSahay && filterFamilyIncome) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SubSahayName == filterSubSahay &&
  //           ele.familyIncome == filterFamilyIncome
  //         );
  //       } else if (selectDateData && filter) {
  //         return (
  //           ele.FID == filter && ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (selectDateData && filterFamilyIncome) {
  //         return (
  //           ele.familyIncome == filterFamilyIncome &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (selectDateData && filterElectricityBill) {
  //         return (
  //           ele.electricityBill == filterElectricityBill &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (selectDateData && filterYear) {
  //         return (
  //           ele.Year == filterYear &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filterYear) {
  //         return ele.Year == filterYear;
  //       } else if (filter) {
  //         return ele.FID == filter;
  //       } else if (selectDateData && filterSahay && filterSubSahay) {
  //         return (
  //           ele.SubSahayName == filterSubSahay &&
  //           ele.SahayName == filterSahay &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filterYear && filterSahay && filterSubSahay) {
  //         return (
  //           ele.Year == filterYear &&
  //           ele.SahayName == filterSahay &&
  //           ele.SubSahayName == filterSubSahay
  //         );
  //       } else if (filter && filterSahay && filterYear) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SahayName == filterSahay &&
  //           ele.Year == filterYear
  //         );
  //       } else if (filter && filterSahay) {
  //         return ele.FID == filter && ele.SahayName == filterSahay;
  //       } else if (filterSahay) {
  //         return ele.SahayName == filterSahay;
  //       } else if (filterYear && filterSahay) {
  //         return ele.Year == filterYear && ele.SahayName == filterSahay;
  //       } else if (filterSahay && filterSubSahay) {
  //         return (
  //           ele.SahayName == filterSahay && ele.SubSahayName == filterSubSahay
  //         );
  //       } else if (filterYear && filterSahay) {
  //         return ele.Year == filterYear && ele.SahayName == filterSahay;
  //       } else if (selectDateData) {
  //         return ele.applicationReceiveDate == selectDateData;
  //       } else if (
  //         selectDateData &&
  //         filterFamilyIncome &&
  //         filterElectricityBill
  //       ) {
  //         return (
  //           ele.applicationReceiveDate == selectDateData &&
  //           ele.familyIncome == filterFamilyIncome &&
  //           ele.electricityBill == filterElectricityBill
  //         );
  //       } else if (filterYear && filterSahay && filter) {
  //         return (
  //           ele.Year == filterYear &&
  //           ele.SahayName == filterSahay &&
  //           ele.FID == filter
  //         );
  //       } else if (
  //         filter ||
  //         filterSahay ||
  //         filterYear ||
  //         filterSubSahay ||
  //         filterFamilyIncome ||
  //         filterElectricityBill ||
  //         selectDateData
  //       ) {
  //         return (
  //           ele.FID == filter ||
  //           ele.SahayName == filterSahay ||
  //           ele.Year == filterYear ||
  //           ele.familyIncome == filterFamilyIncome ||
  //           ele.electricityBill == filterElectricityBill ||
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else {
  //         return true; // Return true for all elements if no filters are provided
  //       }
  //     });
  //     setFilterReportData(filterData);
  //     console.log("filterData", filterData);
  //   } else {
  //     setFilterReportData(); // Set the original data if no filters are provided
  //   }
  // }, [
  //   filter,
  //   filterYear,
  //   filterSahay,
  //   filterSubSahay,
  //   filterElectricityBill,
  //   filterFamilyIncome,
  //   selectDateData,
  // ]);

  useEffect(() => {
    const filters = {
      FID: filter,
      Year: filterYear,
      SahayName: filterSahay,
      SubSahayName: filterSubSahay,
      familyIncome: filterFamilyIncome,
      electricityBill: filterElectricityBill,
      applicationReceiveDate: selectDateData,
    };

    const filterData = data?.filter((ele) => {
      if (Object.values(filters).some(Boolean)) {
        return Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          return ele[key] == value;
        });
      } else {
        return true;
      }
    });

    setFilterReportData(filterData);
  }, [
    filter,
    filterYear,
    filterSahay,
    filterSubSahay,
    filterElectricityBill,
    filterFamilyIncome,
    selectDateData,
  ]);

  console.log(
    "filter",
    filter,
    "filterSubSahayName",
    filterSubSahayName,
    "filterYear",
    filterYear,
    "pageData",
    pageData,
    "searchedData",
    searchedData
  );

  console.log("filterData-filterReportData", filterReportData);

  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  const fileName = "Sahay Detail Sahay Wise CSV";
  useEffect(() => {
    const customHeadings = filterReportData?.map((item) => ({
      Fid: item?.FID,
      "Applicant name": item?.ApplicantName,
      "Sahay Name": item?.SahayName,
      "Sub Sahay Name": item?.SubSahayName,
      "Beneficiary Name": item?.BeneficiaryName,
      "Moholla Name": item?.MohollaName,
      Area: item?.Area,
      "Requested Sahay": item?.SahayAmount,
      "Approve Amount": item?.ApproveAmount,
      Year: item?.Year,
      "Application Date Received": item?.applicationReceiveDate,
      "Application Entered Date": item?.ApplicationEnteredDate,
      "Committee Approval Date": item?.committeeApprovalDate,
      "Filing Date": item?.formFillingDate,
      "Electricity Bill": item?.electricityBill,
      "Family Income": item?.familyIncome,
    }));

    setExcel(customHeadings);
  }, [filterReportData]);

  const requestSearch = (searched) => {
    console.log("Searched_data", searched);

    if (!searched) return setSearchedData(filterReportData);

    setSearchedData(
      filterReportData?.filter(
        (item) =>
          item?.ApplicantName?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase())
        //    ||
        // item?.Area?.toString()
        //   ?.toLowerCase()
        //   .includes(searched?.toLowerCase())
      )
    );
  };

  console.log("searchedData", searchedData);

  //get Sub sahay list
  const getSahaySubTypeData = async (id) => {
    dispatch(getSahaySubTypeList(id)).then((res) => {
      if (res?.payload) {
        setSahaySubTypeList(res?.payload);
      } else {
        setSahaySubTypeList([]);
      }
    });
  };
  //Sahay Type list
  const fetchSahayTypeList = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  useEffect(() => {
    fetchSahayTypeList();
    getSahaySubTypeData();
  }, []);

  useEffect(() => {
    dispatch(getSubSahayReportData()).then((res) => {
      if (res?.payload?.status == 200) {
        setData(res?.payload?.data);
        setLoader(false);
      }
      setLoader(false);
    });
  }, []);

  console.log("searchedData", searchedData);
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes(
      "Sahay Detail Sahay Wise Report_view"
    ) ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Sahay Detail Sahay Wise
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="table-list">
                <Index.Box className="grid-row sahay-multiple-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="multiple-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <PagesIndex.Search
                            className="search sahay-multiple-search"
                            onChange={(e) => requestSearch(e.target.value)}
                          >
                            <PagesIndex.StyledInputBase placeholder="Search" />
                          </PagesIndex.Search>
                        </Index.Box>
                      </Index.Box>
                      {console.log("setSelectDateData", selectDateData)}

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Autocomplete
                            disablePortal
                            className="multiple-autocomplet-control"
                            id="combo-box-demo"
                            options={data || []}
                            getOptionLabel={(option) => option?.FID?.toString()}
                            onChange={(value, newvalue) => {
                              setFilter(newvalue?.FID);
                            }}
                            filterOptions={(options, { inputValue }) => {
                              const inputLowerCase = inputValue?.toLowerCase();
                              const uniqueFamilyId = new Set();
                              return options?.filter((option) => {
                                const familyId =
                                  option?.FID?.toString()?.toLowerCase();
                                // Check unique
                                if (uniqueFamilyId?.has(familyId)) {
                                  return false;
                                }
                                // Check search
                                if (familyId?.includes(inputLowerCase)) {
                                  uniqueFamilyId?.add(familyId);
                                  return true;
                                }
                                return false;
                              });
                            }}
                            renderInput={(params) => (
                              <Index.TextField {...params} label="Fid" />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="multiple-autocomplet-control"
                            options={sahayTypeList || []}
                            getOptionLabel={(option) => option?.Sahay_category}
                            onChange={(value, newvalue) => {
                              setSahaySubTypeList([]);
                              setFilterSubSahay("");
                              // setFilter("");
                              setFilterSahay(newvalue?.Sahay_category);
                              getSahaySubTypeData(newvalue?.Id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField {...params} label="Sahay Name" />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="multiple-autocomplet-control"
                            options={sahaySubTypeList}
                            getOptionLabel={(option) => option?.SahayName}
                            onChange={(value, newvalue) => {
                              setFilterSubSahay(newvalue?.SahayName);
                            }}
                            value={
                              sahaySubTypeList &&
                              sahaySubTypeList.length > 0 &&
                              filterSubSahay
                                ? sahaySubTypeList.find(
                                    (item) => item.SahayName === filterSubSahay
                                  )
                                : null
                            }
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                label="Sub Sahay Name"
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="multiple-autocomplet-control"
                            options={familyIncomeAmountList || []}
                            getOptionLabel={(option) => option?.ammount}
                            onChange={(value, newvalue) => {
                              // setSahaySubTypeList([]);
                              // setFilterSubSahay("");
                              // setFilter("");
                              setFilterFamilyIncome(newvalue?.ammount);
                              // getSahaySubTypeData(newvalue?.Id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                label="Family Income"
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="multiple-autocomplet-control"
                            options={electricBillAmountList || []}
                            getOptionLabel={(option) => option?.ammount}
                            onChange={(value, newvalue) => {
                              // setSahaySubTypeList([]);
                              // setFilterSubSahay("");
                              // setFilter("");
                              setFilterElectricityBill(newvalue?.ammount);
                              // getSahaySubTypeData(newvalue?.Id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                label="Electricity Bill"
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="multiple-autocomplet-control"
                            options={data || []}
                            getOptionLabel={(option) => option?.Year}
                            onChange={(value, newvalue) => {
                              setFilterYear(newvalue?.Year);
                            }}
                            filterOptions={(options, { inputValue }) => {
                              const inputLowerCase = inputValue?.toLowerCase();
                              const uniqueYear = new Set();
                              return options?.filter((option) => {
                                const Year =
                                  option?.Year?.toString()?.toLowerCase();
                                // Check unique
                                if (uniqueYear?.has(Year)) {
                                  return false;
                                }
                                // Check search
                                if (Year?.includes(inputLowerCase)) {
                                  uniqueYear?.add(Year);
                                  return true;
                                }
                                return false;
                              });
                            }}
                            renderInput={(params) => (
                              <Index.TextField {...params} label="Year" />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                            sx={{ position: "absolute" }}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DatePicker
                                label="Application Receive Date"
                                name="applicationReceiveDate"
                                onChange={(value) => {
                                  setSelectDateData(
                                    moment(value.$d).format("YYYY-MM-DD")
                                  );
                                }}
                                // renderInput={(params) => (
                                //   <Index.TextField
                                //       {...params}
                                //       label="Application Receive Date"
                                //   />
                                // )}
                                format="YYYY-MM-DD"
                                sx={{
                                  // overflowY: "hidden",
                                  // position: "relative",
                                  bottom: "4px",
                                  width: "100%",
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    error: false,
                                  },
                                }}
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>

                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="multiple-autocomplet-control"
                            options={familyIncomeAmountList || []}
                            getOptionLabel={(option) => console.log("optionsssss",option)}
                            onChange={(value, newvalue) => {
                              // setFilterIncome(newvalue?.Year);
                            }}
                            filterOptions={(options, { inputValue }) => {
                              const inputLowerCase = inputValue?.toLowerCase();
                              const uniqueYear = new Set();
                              return options?.filter((option) => {
                                const Year =
                                  option?.ammount?.toString()?.toLowerCase();
                                // Check unique
                                if (uniqueYear?.has(Year)) {
                                  return false;
                                }
                                // Check search
                                if (Year?.includes(inputLowerCase)) {
                                  uniqueYear?.add(Year);
                                  return true;
                                }
                                return false;
                              });
                            }}
                            renderInput={(params) => (
                              <Index.TextField {...params} label="Family Income" />
                            )}
                          />
                        </Index.Box>
                      </Index.Box> */}

                      {/* <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                            <Index.Box className="sahay-multiple-input-main">
                                <Autocomplete
                                    disablePortal
                                    className="multiple-autocomplet-control"
                                    id="combo-box-demo"
                                    options={sahaySubTypeList}
                                    getOptionLabel={(option) => option?.SahayName}
                                    onChange={(value, newvalue) => {
                                        setFilterSubSahayName(newvalue?.SahayName)
                                    }}
                                    renderInput={(params) => <Index.TextField {...params} label="Sub Sahay" />}
                                />
                            </Index.Box>
                        </Index.Box> */}
                      {filterReportData?.length > 0 &&
                        searchedData?.length > 0 && (
                          <>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 4",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main sahay-multiple-input-main">
                                <Index.Box
                                  Box
                                  className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                                >
                                  <FileExcel
                                    apiData={excel}
                                    fileName={fileName}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 4",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="sahay-multiple-input-main">
                                <Index.Box
                                  Box
                                  className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                                >
                                  <Index.Button onClick={handlePrint}>
                                    Print
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </>
                        )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container user-master-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table multiple-tablelist-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Fid
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Applicant Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sahay Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sub Sahay Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Benificiary name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Moholla Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Area
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Requested Sahay
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Approve Amount
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Year
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Application Date Received
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Application Entered Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Committee Approval Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Filing Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Electricity Bill
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Family Income
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {console.log("filterData-pageData", pageData)}
                      {pageData?.length &&
                      (filter ||
                        filterYear ||
                        filterSahay ||
                        filterSubSahay ||
                        filterElectricityBill ||
                        filterFamilyIncome ||
                        selectDateData) ? (
                        pageData
                          // searchedData
                          // ?.filter((ele) => {
                          //   // return filter || filterSubSahayName ? ele.FID == filter || ele.ApplicantName == filterSubSahayName : ele
                          //   if (filter && filterSubSahayName) {
                          //     return (
                          //       ele?.FID == filter &&
                          //       ele?.SubSahayName == filterSubSahayName
                          //     );
                          //   } else if (filter) {
                          //     return ele?.FID == filter;
                          //   } else {
                          //     return [];
                          //   }
                          // })
                          ?.map((data, index) => {
                            return (
                              <Index.TableRow
                                className="user-master-tr"
                                key={index}
                              >
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-role-name">
                                    {data?.FID || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-details-name">
                                    {data?.ApplicantName || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-details-name">
                                    {data?.SahayName || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-details-name">
                                    {data?.SubSahayName || "-"}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-email-name">
                                    {data?.BeneficiaryName || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.MohollaName || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.Area || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.SahayAmount || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.ApproveAmount || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.Year || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.applicationReceiveDate || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.ApplicationEnteredDate || "-"}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.committeeApprovalDate || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.formFillingDate || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.electricityBill || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name">
                                    {data?.familyIncome || "-"}
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={16} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
                {filterReportData?.length > 0 && searchedData?.length > 0 && (
                  <Index.Box className="multiple-total-text-main">
                    <Index.Typography className="multiple-total-text">
                      {/* Total Amount : {tableData?.totalAmount} */}
                      Total Amount :
                      {searchedData.length > 0 &&
                        searchedData?.reduce((acc, curr) => {
                          console.log("accc, currr", acc, curr);

                          let total;
                          total = acc + curr?.SahayAmount;
                          return total;
                        }, 0)}
                    </Index.Typography>
                  </Index.Box>
                )}

                {/* {filterReportData?.length > 0 && searchedData?.length > 0 && ( */}
                <Index.Box className="pagination-design flex-end">
                  <RowPerPageDropdown
                    rowPerPage={rowPerPage}
                    setRowPerPage={setRowPerPage}
                  />
                  <Index.Stack spacing={2}>
                    <Pagination
                      fetchData={searchedData}
                      setPageData={setPageData}
                      pageData={pageData}
                      rowPerPage={rowPerPage}
                    />
                  </Index.Stack>
                </Index.Box>
                {/* )} */}
                <Index.Box sx={{ display: "none" }}>
                  <PrintReport
                    componentRef={componentRef}
                    tableData={excel}
                    reportName={[{ type: "SahayWiseReport" }]}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
}

// useEffect(() => {
//   if (
//     filter ||
//     filterYear ||
//     filterSahay ||
//     filterSubSahay ||
//     filterFamilyIncome ||
//     filterElectricityBill
//   ) {
//     const filterData = data?.filter((ele) => {
//       if (
//         filterSubSahay &&
//         filterSahay &&
//         filter &&
//         filterYear &&
//         filterFamilyIncome &&
//         filterElectricityBill
//       ) {
//         return (
//           ele?.FID == filter &&
//           ele.SubSahayName == filterSubSahay &&
//           ele?.Year == filterYear &&
//           ele?.familyIncome == filterFamilyIncome &&
//           ele?.electricityBill == filterElectricityBill
//         );
//       } else if (filterSubSahay && filterSahay && filterYear) {
//         return ele.SubSahayName == filterSubSahay && ele?.Year == filterYear;
//       } else if (filterSubSahay && filterSahay && filter) {
//         return ele.SubSahayName == filterSubSahay && ele.FID == filter;
//       } else if (filterSahay && filterSubSahay) {
//         return ele.SubSahayName == filterSubSahay;
//       } else if (filterSahay && filter) {
//         return ele.SahayName == filterSahay && ele.FID == filter;
//       } else if (filterYear && filter) {
//         return ele.Year == filterYear && ele.FID == filter;
//       } else if (filterYear && filterSahay) {
//         return ele.Year == filterYear && ele.SahayName == filterSahay;
//       } else if (filter && filterSahay && filterYear) {
//         return (
//           ele.FID == filter &&
//           ele.SahayName == filterSahay &&
//           ele.Year == filterYear
//         );
//       } else if (
//         filter ||
//         filterYear ||
//         filterSahay ||
//         filterSubSahay ||
//         filterFamilyIncome ||
//         filterElectricityBill
//       ) {
//         return (
//           ele?.FID == filter ||
//           ele.SahayName == filterSahay ||
//           ele?.Year == filterYear ||
//           ele?.FamilyIncome == filterFamilyIncome ||
//           ele?.familyIncome == filterFamilyIncome ||
//           ele?.electricityBill == filterElectricityBill
//         );
//       } else {
//         return [];
//       }
//     });
//     setFilterReportData(filterData);
//   } else {
//     setFilterReportData([]);
//   }
// }, [
//   filter,
//   filterYear,
//   filterSahay,
//   filterSubSahay,
//   filterElectricityBill,
//   filterFamilyIncome,
// ]);

// useEffect(() => {
//   if (
//     filter ||
//     filterYear ||
//     filterSahay ||
//     filterSubSahay ||
//     filterFamilyIncome ||
//     filterElectricityBill ||
//     selectDateData
//   ) {
//     const filterData = data?.filter((ele) => {
//       console.log(
//         "filterReportData++FID===",
//         ele?.applicationReceiveDate,
//         "filterSubSahay===",
//         selectDateData
//       );
//       if (
//         filter &&
//         filterYear &&
//         filterSahay &&
//         filterSubSahay &&
//         filterFamilyIncome &&
//         filterElectricityBill &&
//         selectDateData
//       ) {
//         return (
//           ele.FID == filter &&
//           ele.SubSahayName == filterSubSahay &&
//           ele.Year == filterYear &&
//           ele.familyIncome == filterFamilyIncome &&
//           ele.electricityBill == filterElectricityBill &&
//           ele?.applicationReceiveDate == selectDateData
//         );
//       } else if (filterYear && filterSahay && filterSubSahay) {
//         return (
//           ele.Year == filterYear &&
//           ele.SahayName == filterSahay &&
//           ele.SubSahayName == filterSubSahay
//         );
//       } else if (filterYear && filterSahay && filter) {
//         return (
//           ele.Year == filterYear &&
//           ele.SahayName == filterSahay &&
//           ele.FID == filter
//         );
//       } else if (filterSahay && filterSubSahay) {
//         return (
//           ele.SahayName == filterSahay && ele.SubSahayName == filterSubSahay
//         );
//       } else if (filterSahay && filterSubSahay) {
//         return (
//           ele.SahayName == filterSahay && ele.SubSahayName == filterSubSahay
//         );
//       } else if (selectDateData) {
//         return ele.applicationReceiveDate == selectDateData;
//       } else if (filterSahay && filter) {
//         return ele.SahayName == filter && ele.FID == filter;
//       } else if (filterFamilyIncome && filterElectricityBill) {
//         return (
//           ele.familyIncome == filterFamilyIncome &&
//           ele.electricityBill == filterElectricityBill
//         );
//       } else if (
//         selectDateData &&
//         filterFamilyIncome &&
//         filterElectricityBill
//       ) {
//         return (
//           ele.applicationReceiveDate == selectDateData &&
//           ele.familyIncome == filterFamilyIncome &&
//           ele.electricityBill == filterElectricityBill
//         );
//       } else if (filterYear && filterSahay) {
//         return ele.Year == filterYear && ele.SahayName == filterSahay;
//       } else if (filter && filterSahay && filterYear) {
//         return (
//           ele.FID == filter &&
//           ele.SahayName == filterSahay &&
//           ele.Year == filterYear
//         );
//       } else if (
//         filter ||
//         filterYear ||
//         filterSahay ||
//         filterSubSahay ||
//         filterFamilyIncome ||
//         filterElectricityBill ||
//         selectDateData
//       ) {
//         return (
//           ele.FID == filter ||
//           ele.SahayName == filterSahay ||
//           ele.Year == filterYear ||
//           ele.familyIncome == filterFamilyIncome ||
//           ele.electricityBill == filterElectricityBill ||
//           ele.applicationReceiveDate == selectDateData
//         );
//       } else {
//         return true; // Return true for all elements if no filters are provided
//       }
//     });
//     setFilterReportData(filterData);
//   } else {
//     setFilterReportData(); // Set the original data if no filters are provided
//   }
// }, [
//   filter,
//   filterYear,
//   filterSahay,
//   filterSubSahay,
//   filterElectricityBill,
//   filterFamilyIncome,
//   selectDateData,
// ]);

// console.log(
//   "filterReportData++FID===",
//   ele?.applicationReceiveDate,
//   "filterSubSahay===",
//   selectDateData
// );
