import React from "react";
import Index from "../../Index";

const NoRecordFound = (props) => {
  return (
    <Index.TableRow>
      <Index.TableCell colSpan={props.colSpan}>
        <Index.Typography className="table-data-not-found">
          Record not found
        </Index.Typography>
      </Index.TableCell>
    </Index.TableRow>
  );
};

export default NoRecordFound;
