import React, { useState } from "react";
import "../../../user/auth/login/Login.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import {
  getAdminOtpData,
  loginUser,
} from "../../../../redux/features/AddService";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import OTPInput from "react-otp-input";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { userLoginToken } from "../../../../redux/features/adminslice/AdminSlice";

function AdminOtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = PagesIndex.useLocation();
  const data = PagesIndex.useLocation();
  // Initital values
  let initialValues = {
    otp: "",
    isSubmit: false,
  };
  // Login
  const handleFormSubmit = async (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const value = { otp: +values.otp, email: location.state.emailData };

    dispatch(getAdminOtpData(value)).then((res) => {
      if (res?.payload?.status == 200) {
        navigate("/admin/reset-password", {
          state: { token: res?.payload?.token },
        });
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2 text-center">
                  <img src={PagesIndex.Png.sahay_log} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Verify Otp!
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="box-login-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Please Enter Otp.
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={PagesIndex.validationSchemaAdminOtp}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          setFieldValue,
                          handleSubmit,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <OTPInput
                              name="otp"
                              numInputs={4}
                              inputStyle="otp-verification-input"
                              containerStyle="otp-verification-input-wrap"
                              inputType="number"
                              value={values.otp}
                              onBlur={handleBlur}
                              onChange={(file) => setFieldValue("otp", file)}
                              renderInput={(props) => <input {...props} />}
                            />
                            {errors?.otp && touched.otp && (
                              <p className="error-text">{errors?.otp}</p>
                            )}

                            <Index.Box className="orange-btn login-btn login-btn-main">
                              <Index.Button
                                type="submit"
                                data-testid="button"
                                variant="contained"
                                disabled={values.isSubmit}
                              >
                                verify Otp
                              </Index.Button>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </PagesIndex.Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default AdminOtp;
