import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { resetpasswordAdmin } from "../../../../redux/features/AddService";

function ResetPassword() {
  const { adminOtpData } = PagesIndex.useSelector((state) => state.admin);

  const dispatch = PagesIndex.useDispatch();
  const navigate = Index.useNavigate();

  let resetId = 1;

  //all state
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  // Initital values
  let initialValues = {
    newPassword: "",
    confirmPassword: "",
    isSubmit: false,
  };

  // reset password submit
  const handleResetFormSubmit = (values, { setFieldValue }) => {
    console.log("Resssssss");
    setFieldValue("isSubmit", true);
    const data = {
      ...values,
      token: adminOtpData?.token,
    };
    dispatch(resetpasswordAdmin(data)).then((res) => {

      console.log("Resssssss",res?.payload);
      if (res.payload?.status == 200) {
        setTimeout(() => {
          navigate("/admin/login");
          setFieldValue("isSubmit", true);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", true);
        }, 1000);
      }
    });
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2 text-center">
                  <img src={PagesIndex.Png.sahay_log} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Reset Password
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="box-login-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Please enter your new password
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleResetFormSubmit}
                        initialValues={initialValues}
                        validationSchema={
                          PagesIndex.validationSchemaResetPassword
                        }
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              placeholder="New Password"
                              className="admin-input-design input-placeholder "
                              name="newPassword"
                              type={showNewPassword ? "text" : "password"}
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{ className: "add-formlabel" }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              onBlur={handleBlur}
                              value={values.newPassword}
                              onChange={handleChange}
                              helperText={
                                touched.newPassword && errors.newPassword
                              }
                              error={Boolean(
                                errors.newPassword && touched.newPassword
                              )}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowNewPassword}
                                      edge="end"
                                    >
                                      {showNewPassword ? (
                                        <Index.VisibilityOff />
                                      ) : (
                                        <Index.Visibility />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              placeholder="Confirm Password"
                              className="admin-input-design input-placeholder password"
                              name="confirmPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{ className: "add-formlabel" }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              error={Boolean(
                                errors.confirmPassword &&
                                  touched.confirmPassword
                              )}
                              sx={{ mb: 3 }}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <Index.VisibilityOff />
                                      ) : (
                                        <Index.Visibility />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                            <Index.Box
                              className="orange-btn login-btn login-btn-main"
                              sx={{ mt: 4 }}
                            >
                              <Index.Button
                                variant="contained"
                                type="submit"
                                // disabled={values.isSubmit}
                              >
                                Submit
                              </Index.Button>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </PagesIndex.Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default ResetPassword;
