import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import {
  activeDeactiveAdminUserMaster,
  getAdminUserMaster,
} from "../../../../redux/features/AddService";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";

export default function UserMasterList() {
  const { getAdminUserMasterData, adminData } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();


  const [deleteOpen, setDeleteOpen] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [rowId, setRowId] = useState("");
  const [loader, setLoader] = useState(true);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  let deleteText = "User";

  const [searchedData, setSearchedData] = useState([]);
  const { state } = PagesIndex.useLocation();
  const RowIdData = state?.rowID;

  useEffect(() => {
    dispatch(getAdminUserMaster()).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false);
      }
      setLoader(false);
    });
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(getAdminUserMasterData?.data);
  }, [getAdminUserMasterData?.data]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(getAdminUserMasterData?.data);
    setSearchedData(
      getAdminUserMasterData?.data?.filter(
        (item) =>
          item?.UserRole?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.name
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.email
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.mobileno
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
  };

  const deleteRollMaster = async () => {
    try {
      const res = await DataService.post(
        `${Api.ADMIN_DELETE_USER_MASTER}/${rowId}`
      );

      if (res?.data?.status == 200) {
        toast.success(res?.data?.message);

        dispatch(getAdminUserMaster());
      }
    } catch (error) {
      toast.error(error?.res?.data?.message || error.message);
    }
  };

  const handleUserMasterView = (data) => {
    navigate(`/${rollData}/user-master-view`, { state: data });
    // navigate("/admin/user-master-view", { state: data });
  };

  const handleUserMasterEdit = (data) => {
    navigate(`/${rollData}/user-master-add`, { state: { edit: "Edit", data } });
    // navigate("/admin/user-master-add", { state: { edit: "Edit", data } });
  };
  const handleDeleteOpen = (id) => {
    setRowId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    deleteRollMaster();

    setDeleteOpen(false);
  };

  //Active Deactive USer
  const activeDeactiveUser = (status, id) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("isActive", status == true ? 1 : 0);
    dispatch(activeDeactiveAdminUserMaster(urlencoded)).then((res) => {
      if (res?.payload == 200) {
        dispatch(getAdminUserMaster());
      }
    });
  };

  

  if (rollPermissionData?.permissionArray?.includes("User Master_view")|| rollPermissionNameData === "admin") {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        User Master
                      </Index.Typography>
                    </Index.Box>
                    {rollPermissionData?.permissionArray?.includes(
                      "User Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <Index.Box className="mobile-menu-show-hover">
                            <PagesIndex.Link
                              to={`/${rollData}/user-master-add`}
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                            <Index.Box className="hover-tooltip-custom">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="title-tooltip"
                              >
                                Add User
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>

                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase placeholder="Search" />
                    </PagesIndex.Search>

                    {rollPermissionData?.permissionArray?.includes(
                      "User Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <PagesIndex.Link
                            to={`/${rollData}/user-master-add`}
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button variant="contained" disableRipple>
                              <Index.ControlPointIcon className="plus-add" />
                              Add User
                            </Index.Button>
                          </PagesIndex.Link>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container user-master-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table user-master-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          User Role
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          User Name{" "}
                        </Index.TableCell>

                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          Email{" "}
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          Mobile Number
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          Type
                        </Index.TableCell>
                        {/* <Index.TableCell className="user-master-th" align="left">
                      Sub Sahay
                    </Index.TableCell> */}
                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow
                            className={`user-master-tr ${
                              data.id === RowIdData ? "highlighted-row" : ""
                            }`}
                          >
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.UserRole}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.name}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.email}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.mobileno}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box
                                className="user-number-name"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {data?.parentRole}
                              </Index.Box>
                            </Index.TableCell>
                            {/* <Index.TableCell className="user-master-td">
                          <Index.Box className="user-number-name">
                            {data?.subsahayname?.join(", ")}
                          </Index.Box>
                        </Index.TableCell> */}
                            <Index.TableCell
                              className="user-master-td"
                              align="center"
                            >
                              <Index.Box classname="user-flex-master">
                                {adminData?.roleName == "admin" && (
                                  <>
                                    <Index.Switch
                                      checked={
                                        data?.isActive == true
                                          ? true
                                          : data?.isActive == null
                                          ? false
                                          : false
                                      }
                                      onChange={(e, a) => {
                                        activeDeactiveUser(a, data?.id);
                                      }}
                                    />
                                  </>
                                )}
                                {rollPermissionData?.permissionArray?.includes(
                                  "User Master_view"
                                ) || rollPermissionNameData === "admin" ? (
                                  <>
                                    <Index.IconButton
                                      onClick={() => handleUserMasterView(data)}
                                    >
                                      <Index.Visibility className="muiico-view" />
                                    </Index.IconButton>
                                  </>
                                ) : (
                                  ""
                                )}
                                {rollPermissionData?.permissionArray?.includes(
                                  "User Master_edit"
                                ) || rollPermissionNameData === "admin" ? (
                                  <>
                                    <Index.IconButton>
                                      <Index.EditIcon
                                        className="muiico-edit"
                                        onClick={() =>
                                          handleUserMasterEdit(data)
                                        }
                                      />
                                    </Index.IconButton>
                                  </>
                                ) : (
                                  ""
                                )}
                                {rollPermissionData?.permissionArray?.includes(
                                  "User Master_delete"
                                ) || rollPermissionNameData === "admin" ? (
                                  <>
                                    <Index.IconButton
                                      onClick={() => handleDeleteOpen(data?.id)}
                                    >
                                      <Index.DeleteIcon className="muiico-delete" />
                                    </Index.IconButton>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end ">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
}
