import Index from "../../../Index";
import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import {
  getSahaylist,
  getSahaylistDelete,
  multipleDeleteSahay,
  subSahaylistDelete,
} from "../../../../redux/features/AddService";
import { STATUSES } from "../../../../redux/features/adminslice/AdminSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";

const SahaytypeMasterlist = () => {
  const { getSahaydata, status, loading } = PagesIndex.useSelector(
    (state) => state.admin
  );

  // all state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [currentRows, setCurrentRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [currentData, setCurrentData] = useState(null);
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [SahaytypeMasterlist, setSahaytypeMasterlist] = useState([]);
  const [searchedData, setSearchedData] = React.useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState(true);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  let deleteText = "sahay type";
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();
  const handleClickMenu = (e, item, index) => {
    setActionIndex(index);
    setCurrentData(item);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSahaytypeEdit = (editData) => {
    navigate(`/${rollData}/type-master-add`, {
      state: { editData, edit: "Edit" },
    });
  };

  useEffect(() => {
    dispatch(getSahaylist()).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setSahaytypeMasterlist(res?.payload?.data);
      }
      setLoader(false);
    });
  }, []);

  const handleDeleteOpen = (data) => {
    setRowId(data.Id);
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    dispatch(getSahaylistDelete(rowId)).then((data) => {
      if (data?.payload == 201) {
        setLoader(false);
        dispatch(getSahaylist()).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            setSahaytypeMasterlist(res?.payload?.data);
          }
          setLoader(false);
        });
      }
    });
    setDeleteOpen(false);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allItemIds = pageData.map((item) => item.Id);
      setSelectedRows(allItemIds);
    }
    setSelectAll(!selectAll);
  };
  useEffect(() => {
    setSearchedData(SahaytypeMasterlist);
  }, [SahaytypeMasterlist]);

  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(SahaytypeMasterlist);
    setSearchedData(
      SahaytypeMasterlist.filter((item) =>
        item?.Sahay_category.toLowerCase().includes(searched.toLowerCase())
      )
    );
  };

  


  if (rollPermissionData?.permissionArray?.includes("Sahay Type Master_view")|| rollPermissionNameData === "admin") {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }


  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Sahay Type Master
                      </Index.Typography>
                    </Index.Box>
                    {rollPermissionData?.permissionArray?.includes(
                      "Sahay Type Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <Index.Box className="mobile-menu-show-hover">
                            <PagesIndex.Link
                              to={`/${rollData}/type-master-add`}
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                            <Index.Box className="hover-tooltip-custom">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="title-tooltip"
                              >
                                Add Sahay
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollPermissionData?.permissionArray?.includes(
                      "Sahay Type Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <Index.Button
                            onClick={() =>
                              navigate(`/${rollData}/type-master-add`)
                            }
                            variant="contained"
                          >
                            <Index.ControlPointIcon className="plus-add" />
                            Add Sahay
                          </Index.Button>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                    {/* {selectedRows.length ? (
                  <Index.Box className="common-button blue-button flex-end save-btn">
                    <Index.Button variant="contained" onClick={handleAllDelete}>
                      Multiple Delete
                    </Index.Button>
                  </Index.Box>
                ) : null} */}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="sahay-table">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container sahay-type-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table sahay-type-table"
                  >
                    <Index.TableHead className="sahay-type-head">
                      <Index.TableRow className="sahay-type-tr">
                        <Index.TableCell className="sahay-type-th">
                          Sahay Type
                        </Index.TableCell>
                        <Index.TableCell className="sahay-type-th" align="left">
                          Created Date{" "}
                        </Index.TableCell>
                        {rollPermissionData?.permissionArray?.includes(
                          "Sahay Type Master_edit"
                        ) ||
                        rollPermissionData?.permissionArray?.includes(
                          "Sahay Type Master_delete"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.TableCell
                              className="sahay-type-th"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </>
                        ) : (
                          ""
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody
                      className={
                        status !== STATUSES.LOADING
                          ? "sahay-type-body"
                          : "loading-table"
                      }
                    >
                      {
                        // status !== STATUSES.LOADING ? (

                        pageData?.length ? (
                          pageData?.map((item, index) => (
                            <Index.TableRow
                              key={item.id}
                              className="sahay-type-tr"
                            >
                              <Index.TableCell
                                align="left"
                                className="sahay-type-td"
                              >
                                {item?.Sahay_category}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="sahay-type-td"
                              >
                                {moment
                                  .utc(
                                    item?.created_at ? item?.created_at : null
                                  )
                                  .format("YYYY-MM-DD, h:mm:ss A")}
                              </Index.TableCell>
                              {rollPermissionData?.permissionArray?.includes(
                                "Sahay Type Master_edit"
                              ) ||
                              rollPermissionData?.permissionArray?.includes(
                                "Sahay Type Master_delete"
                              ) ||
                              rollPermissionNameData === "admin" ? (
                                <>
                                  <Index.TableCell
                                    className="sahay-type-td"
                                    align="center"
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      alignContent: "space-around",
                                    }}
                                  >
                                    {rollPermissionData?.permissionArray?.includes(
                                      "Sahay Type Master_edit"
                                    ) || rollPermissionNameData === "admin" ? (
                                      <>
                                        <Index.IconButton
                                          onClick={() =>
                                            handleSahaytypeEdit(item)
                                          }
                                        >
                                          <Index.EditIcon className="muiico-edit" />
                                        </Index.IconButton>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {rollPermissionData?.permissionArray?.includes(
                                      "Sahay Type Master_delete"
                                    ) || rollPermissionNameData === "admin" ? (
                                      <>
                                        <Index.IconButton
                                          onClick={() => handleDeleteOpen(item)}
                                        >
                                          <Index.DeleteIcon className="muiico-delete" />
                                        </Index.IconButton>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.TableCell>
                                </>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          ))
                        ) : (
                          <PagesIndex.NoRecordFound colSpan={12} />
                          // <Index.TableRow>
                          //   <Index.TableCell
                          //     component="td"
                          //     variant="td"
                          //     scope="row"
                          //     className="no-data-in-list"
                          //     colSpan={15}
                          //     align="center"
                          //   >
                          //     No data available
                          //   </Index.TableCell>
                          // </Index.TableRow>
                        )
                      }

                      {/* ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )} */}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <PagesIndex.Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
};

export default SahaytypeMasterlist;
