import React, { useState } from "react";
import "./Login.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { loginAdmin } from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import {
  userLoginDetails,
  getPermissionData,
  adminLoginDetails,
} from "../../../../redux/features/adminslice/AdminSlice";

function Login() {
  const { token } = PagesIndex.useSelector((state) => state.admin);
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  // state for login button disabled
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Initital values
  let initialValues = {
    email: "",
    password: "",
    isSubmit: false,
  };

  // Login
  const handleFormSubmit = (values, { setFieldValue }) => {
    let data = {
      email: values?.email,
      password: values?.password,
    };
    setFieldValue("isSubmit", true);

    console.log(data, 555);

    dispatch(loginAdmin(data)).then((res) => {
      console.log(res, 555);

      if (res.payload !== undefined) {
        dispatch(adminLoginDetails(res.payload?.findData));
        dispatch(userLoginDetails());
        dispatch(getPermissionData());
        const rollData = JSON.parse(
          localStorage?.getItem("RollName")
        )?.toLowerCase();
        if (rollData !== "admin") {
          navigate(`/${rollData}/sahay-application`);
        } else
          setTimeout(() => {
            navigate(`/${rollData}/dashboard`);
            setFieldValue("isSubmit", false);
          }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2 text-center">
                  <img src={PagesIndex.Png.sahay_log} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Welcome back!
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="box-login-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Login your account.
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={PagesIndex.validationSchemaLogin}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Email or username"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="email"
                              data-testid="email-input"
                              autoComplete="off"
                              onBlur={handleBlur}
                              value={values.email}
                              onChange={handleChange}
                              helperText={touched.email && errors.email}
                              error={Boolean(errors.email && touched.email)}
                            />
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Password"
                              variant="filled"
                              className="admin-input-design input-placeholder password"
                              name="password"
                              data-testid="email-input"
                              aria-label="password"
                              type={showPassword ? "text" : "password"}
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{ className: "add-formlabel" }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={handleChange}
                              helperText={touched.password && errors.password}
                              error={Boolean(
                                errors.password && touched.password
                              )}
                              sx={{ mb: 3 }}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Index.Visibility />
                                      ) : (
                                        <Index.VisibilityOff />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                            <Index.Box
                              className="box-login-text forgot bluebox-text"
                              sx={{ mt: 3 }}
                            >
                              <PagesIndex.Link to="/admin/forgot-password">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="forgot_password"
                                >
                                  Forgot password?
                                </Index.Typography>
                              </PagesIndex.Link>
                            </Index.Box>
                            <Index.Box className="orange-btn login-btn login-btn-main">
                              <Index.Button
                                type="submit"
                                data-testid="button"
                                variant="contained"
                                // disableRipple
                                disabled={values.isSubmit}
                              >
                                Login
                              </Index.Button>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </PagesIndex.Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default Login;
