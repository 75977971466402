import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./routes/Routers";
import "../src/assets/css/global.css";
import "../src/assets/css/global.responsive.css";
import '../src/container/admin/pages/userMaster/usermaster.css'
import '../src/container/admin/pages/rolepermission/rolepermission.css'
import '../src/container/user/pages/userSetting/usersetting.css'

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routers />
    </div>
  );
}

export default App;
