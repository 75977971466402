import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import DataService, { imagePath } from "../../../../../config/DataService";
import {
  getFamilyMemberById,
  getPreviousSahayApplicationListViewSahay,
  getSingleSahayApplication,
} from "../../../../../redux/features/AddService";
import { Api } from "../../../../../config/Api";
import { toast } from "react-toastify";
import PageLoading from "../../../../../components/common/pageLoading/PageLoading";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";
import dayjs from "dayjs";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const ariaLabel = { "aria-label": "description" };
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const SahayApplicationView = () => {
  const [previousListData, setPreviousListData] = useState([]);

  const [singlaFamilyId, setSingleFamilyId] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [editSingleApplicantData, setEditSingleApplicantData] = useState();

  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [getViewData, setGetViewData] = useState(null);
  const [loader, setLoader] = useState(true);
  const initialValues = {};
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const editPreviousData = state;

  const handleBack = () => {
    navigate(`/${rollData}/sahay-application`, {
      state: {
        rowId: state.applicationId,
        tabType: "current",
        prevState: state?.lastStatus,
      },
    });
  };

  const getSingleSahayApplication = async () => {
    try {
      await DataService.post(
        `${Api.GET_SINGLE_SAHAY_APPLICATION}/${state.applicationId}`
      ).then((res) => {
        if (res.data.status === 200) {
          setLoader(false);
          setGetViewData(res?.data?.data);

          fetchSingleApplicant(res.data.data.application[0]);
        }
      });
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  };

  const previousYearSahayList = () => {
    const data = {
      applicantId: state?.applicantId,
    };
    dispatch(getPreviousSahayApplicationListViewSahay(data)).then((res) => {
      if (res) {
        setLoader(false);
        setPreviousListData(res?.payload);
      }
      setLoader(false);
    });
  };

  //Single applicant
  const fetchSingleSelectedApplicant = (memberData) => {
    const member =
      memberData &&
      memberData?.filter(
        (member) => member?.id == editPreviousData?.applicantId
      );

    if (member?.[0]) {
      setEditSingleApplicantData({
        name:
          member?.[0]?.firstName +
          " " +
          member?.[0]?.middleName +
          " " +
          member?.[0]?.lastName,

        age: member?.[0]?.age ? member?.[0]?.age : 0,
        workingType: member?.[0]?.workingType,
        relation: member?.[0]?.relation,
        relation_id: member?.[0]?.relation_id,
      });
    }
  };

  //fetch single applicant
  const fetchSingleApplicant = (familydata) => {
    let familyId;
    if (editPreviousData?.editSahay) {
      familyId = editPreviousData?.editSahay?.familyId;
    } else {
      familyId = editPreviousData?.familyId;
    }

    let data;
    if (familydata?.vastipatrak == "0") {
      data = {
        familyId: familyId,
        VastiPatra: false,
      };
    } else {
      data = {
        familyId: familyId,
        VastiPatra: true,
      };
    }
    setSingleFamilyId(familyId);

    dispatch(getFamilyMemberById(data)).then((res) => {
      if (res?.payload) {
        setFamilyMembers(res?.payload);
        fetchSingleSelectedApplicant(res?.payload);
      }
    });
  };

  useEffect(() => {
    getSingleSahayApplication();
    previousYearSahayList();
  }, []);

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box>
                <Index.Box className="title-header-flex-button">
                  <Index.Box className="common-button blue-button flex-start save-btn res-set-search">
                    <Index.Button
                      variant="contained"
                      onClick={() => handleBack()}
                    >
                      Back
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      View Sahay Application
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <PagesIndex.Formik
                initialValues={initialValues}
                enableReinitialize
              >
                {({ values }) => (
                  <form>
                    <Index.Box className="">
                      <Index.Grid container spacing={3}>
                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label "
                          >
                            Image
                          </Index.Typography>
                          <Index.Box className=" mb-5 mt-5">
                            {getViewData?.application[0]?.image && (
                              <img
                                style={{ width: "120px", height: "120" }}
                                src={`${imagePath}${getViewData?.application[0]?.image}`}
                                className="  mb-5 mt-5"
                              />
                            )}
                          </Index.Box>
                        </Index.Grid>
                        {getViewData?.document?.length > 0 ? (
                          <Index.Grid item xs={12} sm={12} md={12}>
                            <Index.Box className="title-header">
                              <Index.Box className="title-header-flex">
                                <Index.Box className="title-main ">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Documents
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {console.log("GetviewData-=11", getViewData)}
                            <Index.Grid item xs={8}>
                              <Index.Box className="select-view-document-name">
                                <Index.Box className="select-view-document-name mt-select-doc">
                                  {/* <Index.Box className="select-view-document-name mt-select-doc">
                                  {getViewData?.document
                                    ?.map((data) =>
                                      data?.documents?.filter(
                                        (ele) => ele?.document !== "null"
                                      )
                                    )
                                    .map((item, fileIndex) => {
                                      return (
                                        <>
                                        {console.log("item++++===",item)}
                                          {item?.documentType  &&
                                            item?.documents?.length > 0 && (
                                              <Index.Box className="roles-data-box add-check-flex-doc">
                                                <Index.Box className="add-role-flex">
                                                  <Index.Box className="role-data-title">
                                                    {item.documentType}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                            )}
                                          {item?.documents
                                            ?.filter(
                                              (ele) => ele?.document !== "null"
                                            )
                                            .map((document, documentIndex) => (
                                              <Index.Box
                                              className="select-view-document-name mt-select-doc"
                                              key={documentIndex}
                                              >
                                                {console.log("item++++===document",document)}
                                                <Index.Box className="document-name">
                                                  <Index.Typography
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      window.open(
                                                        document instanceof Blob
                                                          ? URL.createObjectURL(
                                                              document
                                                            )
                                                          : `${imagePath}${document?.filename}`
                                                      );
                                                    }}
                                                  >
                                                    {document instanceof Blob
                                                      ? document.name
                                                      : document?.document}
                                                  </Index.Typography>
                                                  <Index.IconButton
                                                    onClick={() => {
                                                      console.log(
                                                        "onClickRemove",
                                                        document?.id
                                                      );
                                                      // Implement your logic to remove the document
                                                    }}
                                                  >
                                                    <Index.ClearIcon className="muiico-cancel" />
                                                  </Index.IconButton>
                                                </Index.Box>
                                              </Index.Box>
                                            ))}
                                        </>
                                      );
                                    })}
                                </Index.Box> */}

                                  {console.log("GetviewData", getViewData)}

                                  {getViewData?.document
                                    ?.map((data) => ({
                                      documentType: data.documentType,
                                      documents: data.documents?.filter(
                                        (doc) => doc.document !== "null"
                                      ),
                                    }))
                                    ?.filter(
                                      (item) => item.documents?.length > 0
                                    )
                                    ?.map((item, fileIndex) => (
                                      <>
                                        <div>
                                          <Index.Box className="roles-data-box add-check-flex-doc">
                                            <Index.Box className="add-role-flex">
                                              <Index.Box className="role-data-title">
                                                {item.documentType}
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>

                                          {/* Iterate over filtered documents and display them */}
                                          <div>
                                            {item.documents.map(
                                              (document, documentIndex) => (
                                                <Index.Box
                                                  className="select-view-document-name mt-select-doc"
                                                  key={documentIndex}
                                                >
                                                  <Index.Box className="document-name">
                                                    <Index.Typography
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        window.open(
                                                          document instanceof
                                                            Blob
                                                            ? URL.createObjectURL(
                                                                document
                                                              )
                                                            : `${imagePath}${document?.filename}`
                                                        );
                                                      }}
                                                    >
                                                      {document instanceof Blob
                                                        ? document.name
                                                        : document?.document}
                                                    </Index.Typography>
                                                  </Index.Box>
                                                </Index.Box>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        ) : (
                          ""
                        )}
                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex">
                              <Index.Box className="title-main ">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Sahay Details
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        {/* {getViewData?.application[0]?.mediclaimAmmount !==
                          0 && (
                          <>
                            {" "}
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Mediclaim Policy Amount
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]
                                      ?.mediclaimAmmount
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>{" "}
                          </>
                        )} */}

                        {/* {getViewData?.application[0]?.SahayAmmount !== 0 && (
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Sahay Amount
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                name="Sahay Amount"
                                disabled
                                value={
                                  getViewData?.application[0]?.SahayAmmount
                                }
                              />
                            </Index.Box>
                          </Index.Grid>
                        )} */}

                        {/* {getViewData?.application[0]?.rentSahayAmmount !==
                          0 && (
                          <>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Rent Amount For Rent Sahay
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]
                                      ?.rentSahayAmmount
                                      ? getViewData?.application[0]
                                          ?.rentSahayAmmount
                                      : "---"
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                          </>
                        )} */}

                        {getViewData?.application[0]?.claimedAmmount !== 0 && (
                          <>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {/* Claimed Sahay Amount */}
                                Total Requested Sahay Amount
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]?.claimedAmmount
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>{" "}
                          </>
                        )}
                        {getViewData?.application[0]?.approveAmount &&
                        getViewData?.application[0]?.approveAmount !== 0 ? (
                          <>
                            <Index.Grid item xs={12} sm={6} md={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {/* Claimed Sahay Amount */}
                                Approve Amount*
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="Addharcard No."
                                  disabled
                                  value={
                                    getViewData?.application[0]?.approveAmount
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex">
                              <Index.Box className="title-main ">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Basic Details
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Family ID
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="familyId"
                              disabled
                              value={state?.familyId}
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Addhar Card No.
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={getViewData?.application[0]?.AadharNo}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Sahay Category
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Sahay Category"
                              disabled
                              value={getViewData?.application[0]?.SahayName}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Sub Sahay Category
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Sahay Category"
                              disabled
                              value={getViewData?.application[0]?.subSahayName}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Year Of Sahay
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={
                                getViewData?.application[0]?.Year
                                  ? getViewData?.application[0]?.Year
                                  : getViewData?.application[0]?.fromYear ===
                                    null
                                  ? "---"
                                  : ` ${getViewData?.application[0]?.fromYear}-${getViewData?.application[0]?.toYear}`
                              }
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={8} md={8}>
                          <Index.Box className="lable-input-grp">
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Remark
                            </Index.Typography>
                            {/* <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="remark"
                                        value={values?.remark}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                      /> */}
                            <Index.TextareaAutosize
                              style={{
                                fontSize: "15px",
                                color: "black",
                                padding: "4px",
                                minHeight: "50px",
                                width: "100%",
                              }}
                              minRows={1}
                              inputProps={ariaLabel}
                              className="basic-input input-width"
                              name="remark"
                              value={getViewData?.application[0]?.remark}

                              // onBlur={handleBlur}
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Application Received Date
                          </Index.Typography>

                          <Index.Box className="input-design-div with-border ">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                              sx={{ position: "absolute" }}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DatePicker
                                  className="admin-input-design input-placeholder"
                                  disabled
                                  name="applicationReceiveDate"
                                  // onChange={(value) => {
                                  //   setFieldValue(
                                  //     "applicationReceiveDate",
                                  //     moment(value.$d).format(
                                  //       "YYYY-MM-DD"
                                  //     )
                                  //   );
                                  // }}
                                  value={dayjs(
                                    getViewData?.application[0]
                                      ?.applicationReceiveDate
                                  )}
                                  format="YYYY-MM-DD"
                                  sx={{
                                    overflowY: "hidden",
                                    position: "relative",
                                    bottom: "8px",
                                    width: "100%",
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: false,
                                    },
                                  }}
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Filing Date
                          </Index.Typography>

                          <Index.Box className="input-design-div with-border ">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                              sx={{ position: "absolute" }}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DatePicker
                                  disabled
                                  className="admin-input-design input-placeholder"
                                  name="formFillingDate"
                                  // onChange={(value) => {
                                  //   setFieldValue(
                                  //     "formFillingDate",
                                  //     moment(value.$d).format(
                                  //       "YYYY-MM-DD"
                                  //     )
                                  //   );
                                  // }}
                                  value={dayjs(
                                    getViewData?.application[0]?.formFillingDate
                                  )}
                                  format="YYYY-MM-DD"
                                  sx={{
                                    overflowY: "hidden",
                                    position: "relative",
                                    bottom: "8px",
                                    width: "100%",
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: false,
                                    },
                                  }}
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex">
                              <Index.Box className="title-main ">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Family Members Details
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            First Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="User Name"
                              disabled
                              value={getViewData?.application[0]?.firstName}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Middle Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="User Name"
                              disabled
                              value={getViewData?.application[0]?.middleName}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Last Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="User Name"
                              disabled
                              value={getViewData?.application[0]?.lastName}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Relation
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={
                                editSingleApplicantData
                                  ? editSingleApplicantData?.relation
                                  : getViewData?.application[0]?.relation
                              }
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Age
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={
                                getViewData
                                  ? getViewData?.application[0]?.age
                                  : editSingleApplicantData?.age
                              }
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Working Type
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              // value={getViewData?.application[0]?.workingType}
                              value={
                                getViewData
                                  ? getViewData?.application?.[0]?.workingType
                                  : editSingleApplicantData?.workingType
                              }
                            />
                          </Index.Box>
                        </Index.Grid>
                        {/* <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Monthly Income
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={
                                getViewData?.application[0]?.monthlyIncome
                                  ? getViewData?.application[0]?.monthlyIncome
                                  : "---"
                              }
                            />
                          </Index.Box>
                        </Index.Grid> */}
                        {getViewData?.application[0]?.disease && (
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Type Of Disease
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                name="Addharcard No."
                                disabled
                                value={
                                  getViewData?.application[0]?.disease
                                    ? getViewData?.application[0]?.disease
                                    : "---"
                                }
                              />
                            </Index.Box>
                          </Index.Grid>
                        )}

                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex">
                              <Index.Box className="title-main ">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Bank Details
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Bank Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={getViewData?.application[0]?.bankName}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Branch
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={getViewData?.application[0]?.branch}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Account Holder Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={getViewData?.application[0]?.HolderName}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Account Number
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={getViewData?.application[0]?.AccountNo}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            IFSC code
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={getViewData?.application[0]?.IFSCCode}
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} md={12}>
                          <Index.Box className="title-header" mt={5}>
                            <Index.Box className="title-header-flex res-title-header-flex">
                              <Index.Box className="title-main">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Family Members Details
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                aria-label="simple table"
                                className="table-design-main barge-table "
                              >
                                <Index.TableHead>
                                  <Index.TableRow>
                                    <Index.TableCell>Name</Index.TableCell>
                                    <Index.TableCell align="left">
                                      {" "}
                                      Age
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {" "}
                                      Relation
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {" "}
                                      Working Type
                                    </Index.TableCell>
                                    {/* <Index.TableCell align="left">
                                      {" "}
                                      Monthly Income
                                    </Index.TableCell> */}
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody>
                                  {/* {getViewData?.relationRecords?.length ? (
                                    getViewData?.relationRecords */}
                                  {familyMembers?.length ? (
                                    familyMembers
                                      ?.filter((ele) => {
                                        return (
                                          ele?.id !=
                                          getViewData?.application?.[0]
                                            ?.applicantId
                                        );
                                      })
                                      ?.map((data, index) => {
                                        return (
                                          <Index.TableRow key={index}>
                                            <Index.TableCell>
                                              {/* {data?.name} */}
                                              {`${data?.firstName} ${data?.middleName} ${data?.lastName}` ||
                                                "-"}
                                            </Index.TableCell>

                                            <Index.TableCell align="left">
                                              {data?.age || "-"}
                                            </Index.TableCell>
                                            <Index.TableCell align="left">
                                              {data?.relation || "-"}
                                            </Index.TableCell>
                                            <Index.TableCell align="left">
                                              {data?.workingType || "-"}
                                              {/* {data?.WorkingType} */}
                                            </Index.TableCell>
                                            {/* <Index.TableCell align="left">
                                              {data?.monthlyIncome}
                                            </Index.TableCell> */}
                                          </Index.TableRow>
                                        );
                                      })
                                  ) : (
                                    <Index.TableRow>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="no-data-in-list"
                                        colSpan={6}
                                        align="center"
                                      >
                                        No data available
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )}
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} md={12}>
                          <Index.Box className="title-header" mt={5}>
                            <Index.Box className="title-header-flex res-title-header-flex">
                              <Index.Box className="title-main">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Trust
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                aria-label="simple table"
                                className="table-design-main barge-table "
                              >
                                <Index.TableHead>
                                  <Index.TableRow>
                                    <Index.TableCell align="left">
                                      Trust Name
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {" "}
                                      Account
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody>
                                  {getViewData?.trustRecords?.length ? (
                                    getViewData?.trustRecords?.map(
                                      (data, index) => {
                                        return (
                                          <Index.TableRow key={index}>
                                            <Index.TableCell align="left">
                                              {data?.trustName}
                                            </Index.TableCell>

                                            <Index.TableCell align="left">
                                              {data?.ammount}
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Index.TableRow>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="no-data-in-list"
                                        colSpan={2}
                                        align="center"
                                      >
                                        {/* No trust available */}
                                        No data available
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )}
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>
                        </Index.Grid>

                        {rollData.toLowerCase() === "committee" ? (
                          <Index.Grid item xs={12} md={12}>
                            <Index.Box className="title-header" mt={5}>
                              <Index.Box className="title-header-flex res-title-header-flex">
                                <Index.Box className="title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Previous Year Sahay
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="">
                              <Index.TableContainer
                                component={Index.Paper}
                                className="table-container"
                              >
                                <Index.Table
                                  aria-label="simple table"
                                  className="table-design-main barge-table "
                                >
                                  <Index.TableHead>
                                    <Index.TableRow>
                                      <Index.TableCell className="sahay-appli-tr">
                                        Family Id
                                      </Index.TableCell>
                                      <Index.TableCell className="sahay-appli-tr">
                                        User Name
                                      </Index.TableCell>
                                      <Index.TableCell className="sahay-appli-tr">
                                        Type Of Sahay
                                      </Index.TableCell>
                                      <Index.TableCell className="sahay-appli-tr">
                                        Sub Type Of Sahay
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Sahay Amount
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Year Of Sahay
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Start Date
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        No. Of Month
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Repay Details
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableHead>
                                  <Index.TableBody>
                                    {previousListData?.length ? (
                                      previousListData?.map((row, index) => {
                                        return (
                                          <Index.TableRow key={index}>
                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {row?.familyId}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {`${row?.firstName} ${row?.middleName} ${row?.lastName}`}
                                            </Index.TableCell>

                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {row?.SahayName}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {row?.Sahay_category}
                                            </Index.TableCell>

                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {row?.sahayAmmount}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {row?.toYear
                                                ? `${row?.fromYear}- ${row?.toYear}`
                                                : "---"}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {row?.startDate
                                                ? new Date(
                                                    row?.startDate
                                                  )?.toLocaleDateString("en-GB")
                                                : "-"}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              align="left"
                                              className="sahay-appli-td"
                                            >
                                              {row?.numberOfMonths !== null
                                                ? row?.numberOfMonths
                                                : "----"}
                                            </Index.TableCell>

                                            <LightTooltip
                                              title={row?.repayDetails}
                                            >
                                              <Index.TableCell
                                                align="left"
                                                className="sahay-appli-td"
                                              >
                                                {row.repayDetails !== null
                                                  ? row.repayDetails
                                                      // ?.split(" ")
                                                      ?.slice(0, 15) + "......"
                                                  : // ?.join(" ")+ "..."
                                                    "----"}
                                              </Index.TableCell>
                                            </LightTooltip>
                                          </Index.TableRow>
                                        );
                                      })
                                    ) : (
                                      <Index.TableRow>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="no-data-in-list"
                                          colSpan={6}
                                          align="center"
                                        >
                                          No data available
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    )}
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.TableContainer>
                            </Index.Box>
                          </Index.Grid>
                        ) : (
                          ""
                        )}
                        {/* table */}
                      </Index.Grid>

                      <Index.Box className="line" mt={3} mb={3}></Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};

export default SahayApplicationView;
