import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import DataService from "../config/DataService";
import {
  getActiveDeativeUser,
  getNotification,
} from "../redux/features/AddService";
import { logout } from "../redux/features/adminslice/AdminSlice";

const AdminPrivateRoutes = () => {
  const { token } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  console.log("PathName", pathname);
  useEffect(() => {
    dispatch(getActiveDeativeUser()).then((res) => {
      console.log("ress===", res);
      if (res?.payload?.status === 200) {
        if (
          res?.payload?.data?.isActive === false ||
          res?.payload?.data?.isDelete === true
        ) {
          // navigate("/admin/login");
        }
      }
    });
  }, [pathname]);

  const accessToken = (token) => {
    if (!token) return false;
    DataService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return true;
  };
  // useEffect(() => {
  //   if (token) {
  //     DataService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //   }
  // }, [token]);
  // useEffect(() => {
  //   dispatch(getNotification()).then((res) => {
  //     console.log("re1235",res);
  //     if (res?.payload?.status === 200) {
  //       if (
  //         res?.payload?.data?.isActive === false ||
  //         res?.payload?.data?.isDeleted === true ||
  //         res?.payload?.data?.length === 0
  //       ) {
  //         dispatch(logout());
  //         navigate("/");
  //       }
  //     }
  //   });
  // }, []);
  console.log("token", token);
  return accessToken(token) ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/login" replace={true} />
  );
};

export default AdminPrivateRoutes;
