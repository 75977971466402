import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./Cms.css";

import { updateContactUs } from "../../../../redux/features/AddService";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";

const Contactus = () => {
  const [getContactData, setGetContactData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const dispatch = PagesIndex.useDispatch();

  //all state

  // const [id, setId] = useState(getContactData && getContactData[0]?.id);

  const getCMSList = async () => {
    setLoading(true);
    try {
      const res = await DataService.get(Api.GET_CMS_LIST);

      let getContactUs = res?.data?.data?.filter((ele) => {
        return ele?.type === "contactus";
      });
      setGetContactData(getContactUs);

      if (res?.data?.status == 200) {
        setLoader()
      }
    } catch (error) {
      // toast.error(error.res.data.message || error.message);
    }
  };

  useEffect(() => {
    // dispatch(getCMSList());
    getCMSList();
  }, []);

  let intialValues = {
    email:
      getContactData && getContactData[0]?.email
        ? getContactData[0]?.email
        : "",
    mobileno:
      getContactData && getContactData[0]?.mobileno
        ? getContactData[0]?.mobileno
        : "",
    isSubmit: false,
  };

  //contact us submit form
  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    let data = {
      id: getContactData[0]?.id,
      email: values.email,
      mobileno: values.mobileno,

      type: "contactus",
    };
    dispatch(updateContactUs(data, getContactData[0]?.id)).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false)
        getCMSList();
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  

  return (
    <>

      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Typography className="admin-page-title" variant="h5">
            Contact Us
          </Index.Typography>{" "}
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={intialValues}
            validationSchema={PagesIndex.validationSchemaAdminCmsContactus}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleFocus,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box mt={3} className="barge-common-box">
                  <Index.Box className="">
                    <Index.Box className="grid-row cms-page-row">
                      <Index.Box className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 0, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 5",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Email*
                              </Index.Typography>
                              <Index.Box className="form-group cms-input-box">
                                <Index.TextField
                                  fullWidth
                                  id="email"
                                  size="small"
                                  className="form-control"
                                  placeholder="Enter email"
                                  name="email"
                                  value={values.email}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Index.Box>
                              {errors?.email && touched.email && (
                                <p className="error-text">{errors?.email}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 5",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Mobile Number*
                              </Index.Typography>
                              <Index.Box className="form-group cms-input-box">
                                <Index.TextField
                                  fullWidth
                                  type="number"
                                  id="mobileno"
                                  size="small"
                                  className="form-control"
                                  placeholder="Enter mobile number"
                                  name="mobileno"
                                  value={values.mobileno}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "mobileno",
                                      e.target.value.slice(0, 10)
                                    );
                                  }}
                                />
                              </Index.Box>
                              {errors?.mobileno && touched.mobileno && (
                                <p className="error-text">{errors?.mobileno}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      mt={5}
                      className="save-btn-main border-btn-main res-set-search"
                    >
                      <Index.Button
                        color="primary"
                        variant="contained"
                        className="save-user-btn border-btn"
                        type="submit"
                        disabled={values.isSubmit}
                      >
                        Update
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </>
      )}
    </>
  );
};

export default Contactus;
