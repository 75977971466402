import React, { useEffect, useState } from "react";
import Index from "../../../../container/Index";
import PropTypes from "prop-types";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import PagesIndex from "../../../PagesIndex";
import Pagination from "../../../../components/common/Pagination";
import {
  addDisbursementData,
  getCommitteeCurrentSahayApplicationListStaff,
  getCommitteePreviousSahayApplicationListStaff,
  getCurrentSahayApplicationListStaff,
  getDisbursementDataList,
  getDisbursementDataListAdmin,
  getPreviousSahayApplicationListAdmin,
  getSahayApplicationListAdmin,
  getSingleSahayApplication,
} from "../../../../redux/features/AddService";
import moment from "moment";
import {
  STATUSES,
  editPreviousSahay,
} from "../../../../redux/features/adminslice/AdminSlice";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as xlsx from "xlsx";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { useSelector } from "react-redux";
import { OutlinedInput } from "@mui/material";
import ExportSampleSheet from "../../../../components/common/excelSheet/ExportSampleSheet";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const DisbursementList = () => {
  const { sahayApplicationAdminGet, getPriviousListData, status } =
    PagesIndex.useSelector((state) => state.admin);
  const location = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  const currentTabValue = location?.state?.activeTab;

  //all state
  const [searchedData, setSearchedData] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [currentData, setCurrentData] = useState();
  const [statusFilter, setStatusFilter] = useState(0);
  // const [checkStatus, setCheckStatus] = useState("");
  const [applicationListData, setApplicationListData] = useState([]);
  const [applicationListDataCommittee, setApplicationListDataCommittee] =
    useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [disburseSahayId, setDisburseSahayId] = useState("");

  //////////////////// Previous List Data //////////////////
  const [previousSearchedData, setPreviousSearchedData] = React.useState([]);
  const [previousPageData, setPreviousPageData] = useState([]);
  const [disbursementList, setDisbursementList] = React.useState([]);
  const [remarkIdData, setRemarkIdData] = useState();
  const [remarkData, setRemarkData] = useState("");
  const [loader, setLoader] = useState(true);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [saveErrorData, setSaveErrorData] = React.useState([]);
  const [saveErrorCountData, setSaveErrorCountData] = React.useState([]);

  const disbursementCSVSample = [
    {
      VoucherNo: "",
      SahayApplicationId: "",
      RepayDetail: "",
      BankIssueName: "",
      ChequeNo: "",
      chequeDate: "",
      CheckissueDate: "",
    },
  ];

  const { state } = PagesIndex.useLocation();

  const handleCloseErrorModal = () => setOpenErrorModal(false);

  const RowIdHighlightData = state?.rowID;
  //Redux
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();

  //// Upload CSV FILE DATA TO PAYLOAD //////////

  // function modifyData(data, id) {
  //   // const modifiedData = data.map((item) => {
  //   //   const modifiedItem = {};

  //   //   for (const key in item) {
  //   //     if (item.hasOwnProperty(key)) {
  //   //       const newKey = key.replace(/\s+/g, "").toLowerCase();
  //   //       modifiedItem[newKey] = item[key];
  //   //     }
  //   //   }

  //   //   return modifiedItem;
  //   // });
  //   const uploadDataForAddCsv = data[0];

  //   const dataDisbursement = new FormData();
  //   dataDisbursement.append("SahayApplicationId", +id);
  //   dataDisbursement.append("RepayDetail", uploadDataForAddCsv?.RepayDetail);
  //   dataDisbursement.append(
  //     "BankIssueName",
  //     uploadDataForAddCsv?.BankIssueName
  //   );
  //   dataDisbursement.append("ChequeNo", uploadDataForAddCsv?.ChequeNo);
  //   dataDisbursement.append(
  //     "chequeDate",
  //     moment(uploadDataForAddCsv?.chequeDate).format("YYYY-MM-DD")
  //   );

  //   dataDisbursement.append("VoucherNo", uploadDataForAddCsv?.VoucherNo);

  //   dispatch(addDisbursementData(dataDisbursement)).then((res) => {
  //     if (res?.payload?.status === 201) {
  //       // if (tabValue === 0 && rollData === "committee") {
  //       //   dispatch(getCommitteePreviousSahayApplicationListStaff()).then(
  //       //     (data) => {
  //       //       if (data?.payload) {
  //       //         setPreviousListData(data?.payload);
  //       //       }
  //       //     }
  //       //   );
  //       // }
  //       if (rollData?.toLowerCase()?.match("admin")?.length) {
  //         dispatch(getDisbursementDataListAdmin()).then((data) => {
  //           if (data?.payload) {
  //             setLoader(false);
  //             setDisbursementList(data?.payload);
  //           }
  //           setLoader(false);
  //         });
  //       } else if (
  //         rollData?.toLowerCase()?.match("staff")?.length ||
  //         rollData?.toLowerCase()?.match("committee")?.length
  //       ) {
  //         dispatch(getDisbursementDataList()).then((data) => {
  //           if (data?.payload) {
  //             setLoader(false);
  //             setDisbursementList(data?.payload);
  //           }
  //           setLoader(false);
  //         });
  //       }
  //     }
  //   });

  //   // return modifiedData;
  // }

  // const modifiedData = modifyData(csvData);

  //// To Change the CSV ///////////
  // const handleCSVDisbursementChange = (e) => {
  //   const file = e.target.files[0];
  //   // if (file) {
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const csvData = e.target.result;

  //     const workbook = xlsx.read(csvData, { type: "binary" });

  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //     const json = xlsx.utils.sheet_to_json(worksheet);

  //     // setCSVData(json);
  //     modifyData(json, disburseSahayId);
  //   };

  //   reader?.readAsBinaryString(file);
  //   // }
  // };

  /////////////////////////////////////////

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  useEffect(() => {
    // if (tabValue === 0 && rollData === "committee") {
    if (rollData?.toLowerCase()?.match("admin")?.length) {
      dispatch(getDisbursementDataListAdmin()).then((data) => {
        if (data?.payload) {
          setLoader(false);
          setDisbursementList(data?.payload);
        }
        setLoader(false);
      });
    } else if (
      rollData?.toLowerCase()?.match("staff")?.length ||
      rollData?.toLowerCase()?.match("committee")?.length
    ) {
      dispatch(getDisbursementDataList()).then((data) => {
        if (data?.payload) {
          setLoader(false);
          // setDisbursementList(data?.payload);
          const filterData = data?.payload?.filter((ele) => {
            if (rollData === "staff" || rollData === "committee") {
              return ele?.isDisbursed == statusFilter;
            }
            return true;
          });

          setDisbursementList(filterData);
        }
        setLoader(false);
      });
    }
    // }
  }, [statusFilter]);

  //   }, [tabValue === 0, rollData === "committee"]);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(disbursementList);
  }, [disbursementList]);
  // useEffect(() => {
  //   setPreviousSearchedData(sahayApplicationAdminGet);
  // }, [getPriviousListData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(disbursementList);
    setSearchedData(
      disbursementList.filter(
        (item) =>
          item?.familyId?.toString().includes(searched.toLowerCase()) ||
          item?.mem_fname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.mem_lname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.SahayTypeName?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.subSahayTypeName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.sahay_amount?.toString().includes(searched.toLowerCase())
      )
    );
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOPen = (remarkId) => {
    setOpen(true);
    setRemarkIdData(remarkId);
  };

  const handleViewDisbursementData = (viewDisbursementData) => {
    viewDisbursementData["lastStatus"] = statusFilter;
    navigate(`/${rollData}/view-disbursement`, { state: viewDisbursementData });
  };

  //Disbursment Bulk CSV Inport
  function modifyBulkCSVData(data) {
    const modifiedData = data.map((item) => {
      const modifiedItem = {};

      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const newKey = key.replace(/\s+/g, "").toLowerCase();
          modifiedItem[newKey] = item[key];
        }
      }

      return modifiedItem;
    });

    dispatch(addDisbursementData({ disbursements: modifiedData })).then(
      (res) => {
        if (res?.payload?.status === 201 || res?.payload?.status === 200) {
          // if (res?.payload?.data?.errorsCount?.length > 0) {
          if (res?.payload?.data?.errorsCount > 0) {
            setOpenErrorModal(true);
            setSaveErrorCountData(res?.payload?.data?.errorsCount);
          }

          if (res?.payload?.data?.length > 0) {
            setOpenErrorModal(true);
            setSaveErrorData(res?.payload?.data);
          }

          if (res?.payload?.status === 201 || res?.payload?.status === 200) {
            if (res?.payload?.data?.insertedCount > 0) {
              // toast.success(
              //   `${res?.payload?.data?.insertedCount} disbursement inserted successfully`
              // );
            } else if (res?.payload?.data?.errorsCount > 0) {
              // toast.error(
              //   `${res?.payload?.data?.errorsCount} disbursement not inserted`
              // );
            } else if (
              res?.payload?.data?.insertedCount > 0 &&
              res?.payload?.data?.errorsCount > 0
            ) {
              // toast.success(
              //   `${res?.payload?.data?.insertedCount} disbursement inserted successfully`
              // );
              // toast.error(
              //   `${res?.payload?.data?.errorsCount} disbursement not inserted`
              // );
            }
            if (rollData?.toLowerCase()?.match("admin")?.length) {
              dispatch(getDisbursementDataListAdmin()).then((data) => {
                if (data?.payload) {
                  setLoader(false);
                  setDisbursementList(data?.payload);
                }
                setLoader(false);
              });
            } else if (
              rollData?.toLowerCase()?.match("staff")?.length ||
              rollData?.toLowerCase()?.match("committee")?.length
            ) {
              dispatch(getDisbursementDataList()).then((data) => {
                if (data?.payload) {
                  setLoader(false);
                  const filterData = data?.payload?.filter((ele) => {
                    if (rollData === "staff" || rollData === "committee") {
                      return ele?.isDisbursed == statusFilter;
                    }
                    return true;
                  });
                  setDisbursementList(filterData);
                }
                setLoader(false);
              });
            }
          }
        }
      }
    );

    // return modifiedData;
  }

  const handleBulkBulkChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const workbook = xlsx.read(csvData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = xlsx.utils.sheet_to_json(worksheet);
        modifyBulkCSVData(json);
      };

      reader.readAsBinaryString(file);
    }
  };

  //Edit Disbursement
  const handleEdit = (row) => {
    row["lastStatus"] = statusFilter;
    navigate(`/${rollData}/edit-disbursement-application`, {
      state: {
        editDisbursement: row,
        SahayApplicationId: row,
      },
    });
  };

  React.useEffect(() => {
    if (state?.prevState) {
      setStatusFilter(state?.prevState);
    }
  }, [state]);

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Disbursement
                      </Index.Typography>
                    </Index.Box>
                    {/* {rollData?.toLowerCase()?.match("staff")?.length ? (
                      <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                        <Index.Box className="mobile-menu-show-hover">
                          <PagesIndex.Link
                            to={`/${rollData}/apply-for-sahay`}
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button
                              variant="contained"
                              disableRipple
                              className="apply-sahay-btn"
                            >
                              <Index.ControlPointIcon />
                            </Index.Button>
                          </PagesIndex.Link>
                          <Index.Box className="hover-tooltip-custom">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="title-tooltip"
                            >
                              Apply For Disbursement
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ) : (
                      ""
                    )} */}
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollData?.toLowerCase()?.match("staff")?.length ||
                    rollData?.toLowerCase()?.match("committee")?.length ? (
                      <Index.Box className="lable-input-grp pending-button-sahay">
                        <Index.FormControl
                          sx={{ width: "100%" }}
                          className="main-select"
                        >
                          <Index.Select
                            className="select-drop"
                            displayEmpty
                            value={statusFilter}
                            onChange={(e) => {
                              setStatusFilter(e.target.value);
                            }}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                          >
                            <Index.MenuItem value={0}>Pending</Index.MenuItem>
                            <Index.MenuItem value={1}>Completed</Index.MenuItem>
                            {/* <Index.MenuItem value={"Rejected"}>
                                Rejected
                              </Index.MenuItem> */}
                            {/* <Index.MenuItem value={"Hold"}>Hold</Index.MenuItem> */}
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                    ) : null}
                    {/* <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                  <PagesIndex.Link
                    to={"/admin/apply-for-disbursement"}
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button
                      variant="contained"
                      disableRipple
                      className="apply-sahay-btn"
                    >
                      <Index.ControlPointIcon />{" "}
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box> */}
                    <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                      {rollData?.toLowerCase()?.match("admin")?.length ? (
                        <>
                          <LightTooltip>
                            <Index.Button className="csv-button">
                              <label
                                htmlFor="actual-btn-bulk"
                                style={{ cursor: "pointer" }}
                              >
                                Import CSV
                              </label>
                              <input
                                type="file"
                                id="actual-btn-bulk"
                                // onChange={
                                //   tabValue === 0
                                //     ? handleCSVChange
                                //     : handleCSVChangeCurrent
                                // }
                                onChange={handleBulkBulkChange}
                                hidden
                                accept=".csv"
                              />
                            </Index.Button>
                          </LightTooltip>
                        </>
                      ) : null}
                    </Index.Box>
                    {rollData?.toLowerCase()?.match("admin")?.length ? (
                      <LightTooltip title="Export Disbursement CSV Sample">
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <ExportSampleSheet
                                apiData={disbursementCSVSample}
                                fileName={"Disbursement CSV Sample"}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </LightTooltip>
                    ) : null}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container sahay-application-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table sahay-appli-table"
                  >
                    <Index.TableHead className="sahay-appli-head">
                      <Index.TableRow className="sahay-appli-tr">
                        <Index.TableCell className="sahay-appli-tr">
                          Family Id
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Year
                        </Index.TableCell>
                        <Index.TableCell className="sahay-appli-tr">
                          User Name
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Sahay Category
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Sub Sahay Category
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Approved Amount
                        </Index.TableCell>
                        {/* <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Addharcard No.
                        </Index.TableCell> */}

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Staff Status
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Disbursement Status
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Committee Status
                        </Index.TableCell>

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Committee Remark
                        </Index.TableCell>
                        {rollPermissionData?.permissionArray?.includes(
                          "Disbursement_view"
                        ) ||
                        rollPermissionData?.permissionArray?.includes(
                          "Disbursement_add"
                        ) ||
                        rollPermissionData?.permissionArray?.includes(
                          "Disbursement_edit"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.TableCell
                              className="sahay-appli-tr"
                              align="left"
                            >
                              Action
                            </Index.TableCell>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody
                    // className={
                    //   status == STATUSES.LOADING
                    //     ? "sahay-appli-tbody"
                    //     : "loading-table"
                    // }
                    >
                      {/* {status !== STATUSES.LOADING ? ( */}
                      {pageData?.length ? (
                        pageData?.map((row, index) => (
                          <Index.TableRow
                            key={index}
                            className={`sahay-appli-tr ${
                              row?.id === RowIdHighlightData
                                ? "highlighted-row"
                                : ""
                            }`}
                          >
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.familyId}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.yearFrom} - {row?.toYear}
                              {/* {`${row?.yearFrom} - ${row?.toYear}`} */}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.mem_fname} {row?.mem_mname} {row?.mem_lname}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.SahayTypeName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.subSahayTypeName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {/* {row?.SahayAmmount == null ||
                              row?.SahayAmmount == 0
                                ? row?.sahay_amount
                                : row?.sahay_amount == null ||
                                  row?.sahay_amount == 0
                                ? row?.SahayAmmount
                                : "0"} */}
                              {row?.ApproveAmount}
                            </Index.TableCell>
                            {/* <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.AadharNo}
                            </Index.TableCell> */}
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              <Index.Box
                                className={`status-details ${
                                  row?.newStatusColumn == "Pending"
                                    ? "pending - status"
                                    : row?.newStatusColumn == "Approved"
                                    ? "approved-status"
                                    : row?.newStatusColumn == "Rejected"
                                    ? "reject-status"
                                    : ""
                                }`}
                              >
                                {/* {checkStatus.toUpperCase()} */}
                                {/* Pending */}
                                {row?.newStatusColumn}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              <Index.Box
                                className={`status-details ${
                                  row?.isDisbursed === 0
                                    ? "pending - status"
                                    : row?.isDisbursed === 1
                                    ? "approved-status"
                                    : ""
                                }`}
                              >
                                {/* {checkStatus.toUpperCase()} */}
                                {row?.isDisbursed === 0
                                  ? "Pending"
                                  : row?.isDisbursed === 1
                                  ? "Completed"
                                  : "-"}
                                {/* {row?.newStatusColumn} */}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              <Index.Box
                                className={`status-details ${
                                  row?.committeeStatus == "Pending"
                                    ? "pending - status"
                                    : row?.committeeStatus == "Approved"
                                    ? "approved-status"
                                    : row?.committeeStatus == "Rejected"
                                    ? "reject-status"
                                    : ""
                                }`}
                              >
                                {/* {checkStatus.toUpperCase()} */}
                                {row?.committeeStatus === null
                                  ? "-"
                                  : // : row?.newStatusColumn === "Rejected"
                                    // ? "-"
                                    row?.committeeStatus}
                                {/* {row?.newStatusColumn} */}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              {row?.committeeRemark === "null" ? (
                                "-"
                              ) : row?.committeeRemark === "undefined" ? (
                                "-"
                              ) : row?.committeeRemark === null ? (
                                "-"
                              ) : (
                                <LightTooltip title={row?.committeeRemark}>
                                  {row.committeeRemark
                                    ?.split(" ")
                                    ?.slice(0, 3)
                                    ?.join(" ")}
                                </LightTooltip>
                              )}
                            </Index.TableCell>
                            {rollPermissionData?.permissionArray?.includes(
                              "Disbursement_view"
                            ) ||
                            rollPermissionData?.permissionArray?.includes(
                              "Disbursement_add"
                            ) ||
                            rollPermissionData?.permissionArray?.includes(
                              "Disbursement_edit"
                            ) ||
                            rollPermissionNameData === "admin" ? (
                              <>
                                <Index.TableCell
                                  sx={{ display: "flex" }}
                                  className="sahay-appli-td"
                                >
                                  <Index.Box className="flex-content-sahay">
                                    {rollPermissionData?.permissionArray?.includes(
                                      "Disbursement_add"
                                    ) ? (
                                      rollData?.toLowerCase()?.match("staff")
                                        ?.length ? (
                                        <Index.Box className="approved-btn">
                                          <LightTooltip title="Apply For Disbursement">
                                            <Index.Button
                                              disabled={
                                                row?.isDisbursed === 1
                                                  ? true
                                                  : false
                                              }
                                              className="approve-btn-list"
                                              onClick={() =>
                                                navigate(
                                                  `/${rollData}/add-disbursement-application`,
                                                  {
                                                    state: {
                                                      SahayApplicationId: row,
                                                      prevState: statusFilter,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              <img
                                                src={PagesIndex.Svg.plusyellow}
                                                className="approve-plus"
                                              ></img>
                                            </Index.Button>
                                          </LightTooltip>
                                        </Index.Box>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {rollPermissionData?.permissionArray?.includes(
                                      "Disbursement_view"
                                    ) || rollPermissionNameData === "admin" ? (
                                      <LightTooltip title="View Disbursement">
                                        <Index.IconButton
                                          disabled={
                                            row?.isDisbursed === 0
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            handleViewDisbursementData(row)
                                          }
                                        >
                                          <Index.Visibility className="muiico-view" />
                                        </Index.IconButton>
                                      </LightTooltip>
                                    ) : (
                                      ""
                                    )}
                                    {/* <Index.Box className="reject-btn">
                                <Index.IconButton
                                  className="approve-btn-list"
                                  color="primary"
                                  aria-label="upload picture"
                                  component="label"
                                  // onClick={() => {
                                  //   // dispatch(editPreviousSahay(row));
                                  //   // navigate(
                                  //   //   "/admin/edit-apply-for-previous-sahay",
                                  //   //   {
                                  //   //     state: row,
                                  //   //   }
                                  //   // );
                                  // }}
                                >
                                  <Index.EditIcon className="muiico-view" />
                                </Index.IconButton>
                              </Index.Box> */}
                                    {/* <Index.Box className="approved-btn">
                              <Index.Button
                                disabled={
                                  rollData === "staff"
                                    ? row?.newStatusColumn === "Approved"
                                      ? true
                                      : false
                                    : row?.committeeStatus === "Approved"
                                    ? true
                                    : false
                                }
                                className="approve-btn-list"
                                onClick={() =>
                                  handleSahayApplicationStatus(
                                    row?.applicationId,
                                    "Approved"
                                  )
                                }
                              >
                                <img
                                  src={PagesIndex.Svg.approve}
                                  className="approve"
                                ></img>
                              </Index.Button>
                            </Index.Box> */}

                                    {/* {rollData === "committee" ? (
                              <Index.Box className="approved-btn">
                                <LightTooltip title="Remarks">
                                  <Index.Button
                                    disabled={
                                      row?.committeeStatus === "Approved"
                                    }
                                    className="approve-btn-list"
                                    onClick={() =>
                                      handleOPen(row?.applicationId)
                                    }
                                  >
                                    <img
                                      src={PagesIndex.Svg.plusyellow}
                                      className="approve-plus"
                                    ></img>
                                  </Index.Button>
                                </LightTooltip>
                              </Index.Box>
                            ) : (
                              ""
                            )} */}
                                    {/* <Index.Box className="reject-btn">
                              <Index.Button
                                disabled={
                                  rollData === "staff"
                                    ? row?.newStatusColumn === "Rejected" ||
                                      (row?.committeeStatus === "Approved" &&
                                        row?.newStatusColumn === "Approved")
                                      ? true
                                      : false
                                    : row?.committeeStatus === "Rejected"
                                    ? true
                                    : false
                                }
                                className="reject-btn-list"
                                onClick={() =>
                                  handleSahayApplicationStatus(
                                    row?.applicationId,
                                    "Rejected"
                                  )
                                }
                              >
                                <img
                                  src={PagesIndex.Svg.incorrect}
                                  className="reject"
                                ></img>
                              </Index.Button>
                            </Index.Box> */}
                                    {rollData?.toLowerCase()?.match("staff")
                                      ?.length &&
                                      row?.isDisbursed == 1 && (
                                        <>
                                          <LightTooltip title="Edit">
                                            <Index.IconButton
                                              onClick={() => handleEdit(row)}
                                            >
                                              <Index.EditIcon className="muiico-edit" />
                                            </Index.IconButton>
                                          </LightTooltip>
                                        </>
                                      )}

                                    {/* {rollPermissionData?.permissionArray?.includes(
                                      "Disbursement_edit"
                                    ) ? (
                                      rollData?.toLowerCase()?.match("staff")
                                        ?.length ? (
                                        <Index.Box className="approved-btn">
                                          <LightTooltip title="Upload Disbursement CSV file">
                                            <Index.Button
                                              className="approve-btn-list"
                                              disabled={
                                                row?.isDisbursed === 1
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <label htmlFor="actual-btn">
                                                <img
                                                  alt="plus"
                                                  src={
                                                    PagesIndex.Svg.ImportButton
                                                  }
                                                  // className="approve-plus"
                                                  onClick={(e) =>
                                                    setDisburseSahayId(row?.id)
                                                  }
                                                  className="cursor-pointer"
                                                />
                                              </label>
                                              <input
                                                type="file"
                                                id="actual-btn"
                                                onChange={(e) =>
                                                  handleCSVDisbursementChange(e)
                                                }
                                                hidden
                                                accept=".csv"
                                              />
                                            </Index.Button>
                                          </LightTooltip>
                                        </Index.Box>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )} */}
                                  </Index.Box>
                                </Index.TableCell>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                      {/* ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )} */}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>

              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* /////////////////////// THis is for the Error message ///////////////// */}

          <Index.Modal
            open={openErrorModal}
            onClose={handleCloseErrorModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner"
            >
              <Index.Box className="remark-textarea-details">
                <Index.Box className="set-text-area ">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                    sx={{ color: "red" }}
                  >
                    {saveErrorData.length > 0
                      ? "This Required fields are not found in CSV sheet"
                      : ""}
                  </Index.Typography>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {saveErrorData.length > 0 ? (
                      <ul>
                        {saveErrorData &&
                          saveErrorData.map((error, index) => (
                            <li key={index} style={{ marginBottom: "10px" }}>
                              {error}.
                            </li>
                          ))}
                      </ul>
                    ) : (
                      `${saveErrorCountData}  data not insert from Disbursement CSV`
                    )}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          {/* ////////////////////////////////////////////////////////////////////////////// */}
        </>
      )}
    </>
  );
};

export default DisbursementList;
