import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "../../../../assets/css/global.css";

import { useDispatch } from "react-redux";
import { getAdminDashboardDetails } from "../../../../redux/features/AddService";
import { Dashboard } from "@mui/icons-material";
import PagesIndex from "../../../PagesIndex";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState();
  const [loader, setLoader] = useState(true);

  const getDashBoardListData = () => {
    dispatch(getAdminDashboardDetails()).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setDashboardData(res?.payload);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    getDashBoardListData();
  }, []);
  return (
    <>
      {" "}
      {loader ? (
        <PageLoading />
      ) : (
        <div className="main-dashboard">
          <Index.Box className="">
            <Index.Typography className="head-title" component="h1">
              Dashboard
            </Index.Typography>

            <Index.Grid className="main-gap" container spacing={2}>
              <Index.Grid item lg={4} md={4} sm={6} xs={12}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-content">
                    <Index.Box className="dashboard-card-image">
                      <img src={PagesIndex.Png.Numberusers}></img>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Number of users
                      </Index.Typography>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label-number"
                      >
                        {dashboardData?.UserCount
                          ? dashboardData?.UserCount?.[0]?.count +
                            dashboardData?.UserCount?.[1]?.count
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item lg={4} md={4} sm={6} xs={12}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-content">
                    <Index.Box className="dashboard-card-image-2">
                      <img src={PagesIndex.Png.Received}></img>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Received applications
                      </Index.Typography>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label-number"
                      >
                        {dashboardData?.SahayApplicationCount
                          ?.count_sahayApplication
                          ? dashboardData?.SahayApplicationCount
                              ?.count_sahayApplication
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item lg={4} md={4} sm={6} xs={12}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-content">
                    <Index.Box className="dashboard-card-image-3">
                      <img src={PagesIndex.Png.Pending}></img>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pending applications
                      </Index.Typography>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label-number"
                      >
                        {dashboardData?.PendingSahayApplication
                          ?.count_Pending_sahayApplication
                          ? dashboardData?.PendingSahayApplication
                              ?.count_Pending_sahayApplication
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item lg={4} md={4} sm={6} xs={12}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-content">
                    <Index.Box className="dashboard-card-image-4">
                      <img src={PagesIndex.Png.Approved}></img>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Approved applications
                      </Index.Typography>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label-number"
                      >
                        {dashboardData?.ApprovedSahayApplication
                          ?.count_Approve_sahayApplication
                          ? dashboardData?.ApprovedSahayApplication
                              ?.count_Approve_sahayApplication
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item lg={4} md={4} sm={6} xs={12}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-content">
                    <Index.Box className="dashboard-card-image-5">
                      <img src={PagesIndex.Png.Awarded}></img>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Awarded applicants
                      </Index.Typography>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label-number"
                      >
                        {dashboardData?.AwardedSahayApplicationCount
                          ?.count_Approve_sahayApplication
                          ? dashboardData?.AwardedSahayApplicationCount
                              ?.count_Approve_sahayApplication
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item lg={4} md={4} sm={6} xs={12}>
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-content">
                    <Index.Box className="dashboard-card-image-6">
                      <img src={PagesIndex.Png.amount}></img>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Awarded amount
                      </Index.Typography>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label-number"
                      >
                        {dashboardData?.SumApproveAmountSahayApplication
                          ?.Sum_ApproveAmount_SahayApplication
                          ? dashboardData?.SumApproveAmountSahayApplication
                              ?.Sum_ApproveAmount_SahayApplication
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </div>
      )}
    </>
  );
};

export default AdminDashboard;
