import Svg from "../assets/Svg";
import Png from "../assets/Png";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, FieldArray } from "formik";
import { MuiOtpInput } from "mui-one-time-password-input";
import { toast } from "react-toastify";
import Pagination from "../components/common/Pagination";
import DeleteModal from "../components/common/deletemodel/DeleteModel";
import moment from "moment/moment";
import { RingLoader, PulseLoader } from "react-spinners";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import dayjs from "dayjs";
import {
  validationSchemaLogin,
  validationSchemaUserLogin,
  validationSchemaForgotpassword,
  validationSchemaResetPassword,
  validationSchemaChangepassword,
  validationSchemaYearOfSahay,
  validationSchemaNewMember,
  validationSchemaUpdateAdminProfile,
  validationSchemaUpdateUserProfile,
  validationSchemaSahayTypeMaster,
  validationSchemaSubSahayTypeMaster,
  validationSchemaRollMaster,
  validationSchemaUserMasterAdd,
  validationSchemaUserMasterUpdate,
  validationSchemaAdminOtp,
  validationSchemaUserOtp,
  validationSchemaAdminCmsContactus,
  validationSchemaAdminCmsTermsAndCondition,
  validationSchemaAdminCmsPrivacyAndPolicy,
} from "../validation/Validation";
import {
  ActionItem,
  Search,
  StyledInputBase,
} from "../components/common/search/Search.styled";

import PageLoading from "../components/common/pageLoading/PageLoading";
import NoRecordFound from "../components/common/noRecordFound/NoRecordFound";

const PagesIndex = {
  PulseLoader,
  RingLoader,
  moment,
  dayjs,
  DeleteModal,
  Pagination,
  Search,
  StyledInputBase,
  ActionItem,
  FieldArray,
  toast,
  Formik,
  Svg,
  Png,
  CKEditor,
  ClassicEditor,
  Outlet,
  Link,
  useDispatch,
  useNavigate,
  useLocation,
  axios,
  validationSchemaLogin,
  validationSchemaUserLogin,
  validationSchemaForgotpassword,
  validationSchemaResetPassword,
  validationSchemaChangepassword,
  validationSchemaYearOfSahay,
  validationSchemaUpdateUserProfile,
  validationSchemaRollMaster,
  useSelector,
  validationSchemaNewMember,
  MuiOtpInput,
  validationSchemaUpdateAdminProfile,
  validationSchemaSahayTypeMaster,
  validationSchemaSubSahayTypeMaster,
  validationSchemaAdminOtp,
  validationSchemaUserMasterAdd,
  validationSchemaUserMasterUpdate,
  validationSchemaUserOtp,
  validationSchemaAdminCmsContactus,
  PageLoading,
  NoRecordFound,
  validationSchemaAdminCmsTermsAndCondition,
  validationSchemaAdminCmsPrivacyAndPolicy,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
};

export default PagesIndex;
