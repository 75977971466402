import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import "./disbursementSummary.css";
import {
  getDisbursementSummary,
  getSahaySubTypeList,
  getSahayTypeList,
  listYearOfSahay,
} from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import { Autocomplete } from "@mui/material";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { useReactToPrint } from "react-to-print";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function DisbursementSummaryList() {
  const { getSubSahayReportList } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  const Navigate = useNavigate();
  const fileName = "Disbursement Summary CSV";
  const [excel, setExcel] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowPerPage, setRowPerPage] = useState("25");
  const dispatch = PagesIndex.useDispatch();
  const [filterYear, setFilterYear] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [sahayTypeList, setSahayTypeList] = useState([]);
  const [yearOfSahayList, setYearOfSahayList] = useState([]);

  const [listData, setListData] = useState([]);
  const [sahayName, setSahayName] = useState("");
  const [sahayCategory, setSahayCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (filterYear) {
      // setLoader(true);
      const data = {
        startDate: startDate,
        endDate: endDate,
        sahayName: sahayName,
        sahayCategory: sahayCategory,
        sahayYear: filterYear,
        searchTerm: searchTerm,
      };
      dispatch(getDisbursementSummary(data)).then((res) => {
        if (res?.payload?.status == 200) {
          setListData(res?.payload?.data);
          setLoader(false);
        } else {
          setListData([]);
        }
        setLoader(false);
      });
    }
  }, [filterYear, startDate, endDate, sahayName, sahayCategory, searchTerm]);

  useEffect(() => {
    if (listData?.subSahayDetails?.length > 0) {
      const customPrintHeadings = listData?.subSahayDetails?.map((item) => ({
        "Sahay Name": item?.SahayCategory,
        "SubSahay Name": item?.SubSahayName,
        Year: `${listData?.yearOfSahay?.fromYear} - ${listData?.yearOfSahay?.toYear}`,
        "No Of Applications": item?.Approved_Disbursed_NoOfApplication,
        Amount: item?.Disbursed_Amount,
      }));

      setExcel(customPrintHeadings);
      setPrintData(customPrintHeadings);
    }
  }, [listData]);

  //get Sub sahay list
  const getSahaySubTypeData = async (id) => {
    dispatch(getSahaySubTypeList(id)).then((res) => {
      if (res?.payload) {
        setSahaySubTypeList(res?.payload);
      } else {
        setSahaySubTypeList([]);
      }
    });
  };

  const fetchAllYearOfSahay = () => {
    //Get current financial year
    function getCurrentFinancialYear() {
      var financialYear = "";
      var today = new Date();
      if (today.getMonth() + 1 <= 3) {
        financialYear = today.getFullYear() - 1 + "-" + today.getFullYear();
      } else {
        financialYear = today.getFullYear() + "-" + (today.getFullYear() + 1);
      }
      return financialYear;
    }
    const financialYear = getCurrentFinancialYear();
    const filterFromYear = financialYear.split("-")[0];
    const filterToYear = financialYear.split("-")[1];

    // const currentYear = new Date().getFullYear();
    dispatch(listYearOfSahay()).then((data) => {
      if (data?.payload) {
        const filterCurrYear = data?.payload?.filter((ele) => {
          return ele?.fromYear == filterFromYear && ele?.toYear == filterToYear;
          // return ele?.fromYear == currentYear && ele?.toYear == currentYear + 1;
        });
        setFilterYear(filterCurrYear?.[0]?.id || "");
        // setFilterYear("");
        setYearOfSahayList(data?.payload);
      } else {
        setYearOfSahayList([]);
      }
    });
  };

  //Fetch Sahay List

  const fetchSahayTypeList = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  useEffect(() => {
    fetchSahayTypeList();
    fetchAllYearOfSahay();
  }, []);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes(
      "Disbursement Summary_view"
    ) ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    Navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Disbursement Summary
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-row sahay-multiple-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="app-report-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <PagesIndex.Search
                          className="search sahay-multiple-search"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        >
                          <PagesIndex.StyledInputBase placeholder="Search" />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahayTypeList || []}
                          getOptionLabel={(option) => option?.Sahay_category}
                          onChange={(value, newvalue) => {
                            setSahaySubTypeList([]);
                            setSahayName("");
                            setSahayCategory(newvalue?.Sahay_category);
                            getSahaySubTypeData(newvalue?.Id);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sahay Name" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahaySubTypeList}
                          getOptionLabel={(option) => option?.SahayName}
                          onChange={(value, newvalue) => {
                            setSahayName(newvalue?.SahayName);
                          }}
                          value={
                            sahaySubTypeList &&
                            sahaySubTypeList.length > 0 &&
                            sahayName
                              ? sahaySubTypeList.find(
                                  (item) => item.SahayName === sahayName
                                )
                              : null
                          }
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              label="Sub Sahay Name"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={yearOfSahayList || []}
                          getOptionLabel={(option) =>
                            `${option?.fromYear}-${option?.toYear}`
                          }
                          value={
                            yearOfSahayList[
                              yearOfSahayList.findIndex((e) => e.id == 3102)
                            ]
                          }
                          onChange={(value, newvalue) => {
                            setFilterYear(newvalue?.id);
                          }}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue
                              ?.toLowerCase()
                              ?.replace(/\s/g, "");
                            const uniqueYear = new Set();
                            return options?.filter((option) => {
                              const fromYear = option?.fromYear
                                ?.toString()
                                ?.toLowerCase()
                                ?.replace(/\s/g, "");
                              const toYear = option?.toYear
                                ?.toString()
                                ?.toLowerCase()
                                ?.replace(/\s/g, "");
                              const year = `${fromYear}-${toYear}`;
                              // Check unique
                              if (uniqueYear?.has(year)) {
                                return false;
                              }
                              // Check search
                              if (year?.includes(inputLowerCase)) {
                                uniqueYear?.add(year);
                                return true;
                              }
                              return false;
                            });
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sahay Year" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                          sx={{ position: "absolute" }}
                        >
                          <Index.DemoContainer components={["DatePicker"]}>
                            <Index.DatePicker
                              className="date-select"
                              label="From Date"
                              name="fromDate"
                              onChange={(value) => {
                                setStartDate(
                                  moment(value?.$d).format("YYYY-MM-DD")
                                );
                              }}
                              format="YYYY-MM-DD"
                              sx={{
                                bottom: "4px",
                                width: "100%",
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error: false,
                                },
                              }}
                            />
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                          sx={{ position: "absolute" }}
                        >
                          <Index.DemoContainer components={["DatePicker"]}>
                            <Index.DatePicker
                              className="date-select"
                              label="To Date"
                              name="toDate"
                              onChange={(value) => {
                                setEndDate(
                                  moment(value?.$d).format("YYYY-MM-DD")
                                );
                              }}
                              format="YYYY-MM-DD"
                              sx={{
                                bottom: "4px",
                                width: "100%",
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error: false,
                                },
                              }}
                            />
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                    {listData?.subSahayDetails?.length > 0 && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <FileExcel apiData={excel} fileName={fileName} />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <Index.Button onClick={handlePrint}>
                                Print
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container user-master-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="ds-table-design-main barge-table ds-user-master-table "
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Sr. No.
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sahay Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sub Sahay Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Year
                        </Index.TableCell>

                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Disbursement
                          <Index.Table
                            aria-label="simple table"
                            className="ds-table-design-main barge-table ds-user-master-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                <Index.TableCell>
                                  No of application
                                </Index.TableCell>
                                <Index.TableCell>Amount</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {listData?.subSahayDetails?.length ? (
                        listData?.subSahayDetails?.map((data, index) => (
                          <Index.TableRow className="user-master-tr">
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {index + 1}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.SahayCategory || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.SubSahayName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {`${listData?.yearOfSahay?.fromYear} - ${listData?.yearOfSahay?.toYear}` ||
                                  "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                <Index.Table
                                  aria-label="simple table"
                                  className="ds-table-design-main barge-table ds-user-master-table-inner"
                                >
                                  <Index.TableBody>
                                    <Index.TableRow>
                                      <Index.TableCell align="center">
                                        {
                                          data?.Approved_Disbursed_NoOfApplication
                                        }
                                      </Index.TableCell>
                                      <Index.TableCell align="center">
                                        {data?.Disbursed_Amount}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design ds-flex-end">
                <Index.Typography variant="h7">
                  Total Disbursed Amount :{" "}
                  <Index.Typography className="total-amount" variant="h8">
                    {listData?.totalDisbursedAmount}
                  </Index.Typography>
                </Index.Typography>

                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={listData?.subSahayDetails}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            <PrintReport
              componentRef={componentRef}
              tableData={printData}
              reportName={[
                { name: "", type: "DisbursementSummaryList" },
                { name: "Disbursement", type: "DisbursementSummaryList" },
              ]}
            />
            ;
          </Index.Box>
        </>
      )}
    </>
  );
}
