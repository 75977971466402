import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Sidebar from "../../../../components/admin/defaultLayout/Sidebar";
import "./dashboard.css";
import "./dashboard.responsive.css";
import PagesIndex from "../../../PagesIndex";
import { imagePath } from "../../../../config/DataService";
import { logout } from "../../../../redux/features/adminslice/AdminSlice";
import { getAdminProfile } from "../../../../redux/features/AddService";
import { useParams } from "react-router-dom";

const DashboardLayout = () => {
  const { adminProfileGet ,userProfileGet  } = PagesIndex.useSelector((state) => state.admin);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();


  const params = useParams()
  //all state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [windowSize,setWindowSize] = useState(false)
  const open2 = Boolean(anchorEl);

  //mui function
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    document.body.classList["add"]("menu-set-main");
  };
  //mui function
  const handleClose2 = () => {
    setAnchorEl(null);
    document.body.classList["remove"]("menu-set-main");
  };

  //logout function
  const handleLogout = () => {
    dispatch(logout());

    localStorage.removeItem("token");
    localStorage?.removeItem("RollName")
    PagesIndex.toast.success("Logged out");
    setTimeout(() => {
      navigate("/admin/login");
    }, 1000);
  };
  useEffect(()=>{
    let width = window.innerWidth
    if (width < 992) {
      setWindowSize(true)
    }else{
      setWindowSize(false)
    }
    // setWindowSize
    console.log(width,"width")
  },[window.innerWidth])

  useEffect(() => {
    dispatch(getAdminProfile());
  }, []);

  useEffect(() => {
    document.body.classList[open ? "add" : "remove"]("body-no-class");
  }, [open]);

  return (
    <div className="main-dashboard">
      <Index.Box
        className={`${open ? "overlay" : ""}`}
        onClick={() => setOpen((e) => !e)}
      ></Index.Box>
      <Sidebar setOpen={setOpen} open={open} windowSize={windowSize} />
      <Index.Box className="right-dashboard-content">
        <header>
          <Index.Box className={`main-header ${!open ? "" : "pl-none"}`}>
            <Index.Box
              className="collapse-btn"
              onClick={() => setOpen((e) => !e)}
            >
              <img src={PagesIndex.Svg.collapse} />
            </Index.Box>
            <Index.Box className="admin-header-right">
              <Index.Box className="admin-header-drop-main">
                <Index.Button
                  className="drop-header-btn"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick2}
                >
                  <Index.Box className="head-right">
                    <Index.Box className="header-user-detail">
                      <Index.Typography variant="p" className="head-user-title">
                        {adminProfileGet?.name}
                      </Index.Typography>
                      <Index.Typography variant="p" className="head-user-mail">
                        {adminProfileGet?.email}
                      </Index.Typography>
                    </Index.Box>

                    <img
                      src={
                        !adminProfileGet?.image || adminProfileGet?.image === null
                      ? PagesIndex.Png.usericon 
                      : `${imagePath}${adminProfileGet?.image}`
                       }
                      className="headprofile"
                    />
                  </Index.Box>
                </Index.Button>
              </Index.Box>
              <Index.Menu
                className="drop-header-menu admin-header-profile-ul"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <PagesIndex.Link to={`/${rollData}/update-profile`}>
                  <Index.MenuItem
                    onClick={handleClose2}
                    className="drop-header-menuitem"
                  >
                    {" "}
                    <img
                      src={PagesIndex.Svg.profilegrey}
                      className="drop-header"
                    />{" "}
                    Profile
                  </Index.MenuItem>
                </PagesIndex.Link>
                
                <PagesIndex.Link to={`/${rollData}/change-password`}>
                  <Index.MenuItem
                    onClick={handleClose2}
                    className="drop-header-menuitem"
                  >
                    <img
                      src={PagesIndex.Svg.settings}
                      className="drop-header"
                    />
                    Change Password
                  </Index.MenuItem>
                </PagesIndex.Link>
                <Index.MenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                  className="drop-header-menuitem logout-profile"
                >
                  <img src={PagesIndex.Svg.edit} className="drop-header" />
                  Logout
                </Index.MenuItem>
              </Index.Menu>
            </Index.Box>
          </Index.Box>
        </header>
        <Index.Box className={`admin-panel-content ${!open ? "" : "pl-none"} ${location?.pathname?.includes("/apply-for-previous-sahay")  ? "apply-for-sahay-main-content":""} `} >
          <PagesIndex.Outlet  role= {params}/>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default DashboardLayout;
