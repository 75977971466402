import React from "react";

const PrintReport = ({ componentRef, tableData, reportName }) => {
  const printData =
    tableData &&
    tableData?.map((ele, i) => {
      return {
        "S.No": i + 1,
        ...ele,
      };
    });

  const noOfPages = Math.ceil(printData?.length / 3);
  const pageCount = [];

  for (let i = 1; i <= noOfPages; i++) {
    pageCount.push(i);
  }
  
  return (
    <div ref={componentRef}>
      <>
        <table
          style={{
            maxWidth: 700,
            margin: "auto",
            borderCollapse: "collapse",
            width: "100%",
            marginTop: 25,
            marginBottom: 50,
          }}
        >
          {pageCount &&
            pageCount?.map((ele, index) => {
              return (
                <tbody
                  key={ele}
                  style={{
                    display: "block",
                    pageBreakAfter: "always",
                    marginTop: 25,
                  }}
                >
                  {reportName?.[0].type == "ApplicationReport" && (
                    <tr>
                      {reportName?.map((ele, i) => {
                        return (
                          <th
                            style={{
                              color: "#000000",
                              fontSize: 18,
                              lineHeight: 2,
                              fontWeight: 700,
                              fontFamily: '"Poppins", sans-serif',
                              verticalAlign: "baseline",
                              borderCollapse: "collapse",
                              border: "2px solid black",
                              padding: 5,
                            }}
                            key={ele?.name}
                            colSpan={(i == 0 && 3) || 2}
                          >
                            {ele?.name}
                          </th>
                        );
                      })}
                    </tr>
                  )}
                  {reportName?.[0].type == "DisbursementSummaryList" && (
                    <tr>
                      {reportName?.map((ele, i) => {
                        return (
                          <th
                            style={{
                              color: "#000000",
                              fontSize: 18,
                              lineHeight: 2,
                              fontWeight: 700,
                              fontFamily: '"Poppins", sans-serif',
                              verticalAlign: "baseline",
                              borderCollapse: "collapse",
                              border: "2px solid black",
                              padding: 5,
                            }}
                            key={ele?.name}
                            colSpan={(i == 0 && 4) || 2}
                          >
                            {ele?.name}
                          </th>
                        );
                      })}
                    </tr>
                  )}
                  {reportName?.[0].type == "DisbursementReport" && (
                    <tr>
                      {reportName?.map((ele, i) => {
                        return (
                          <th
                            style={{
                              color: "#000000",
                              fontSize: 18,
                              lineHeight: 2,
                              fontWeight: 700,
                              fontFamily: '"Poppins", sans-serif',
                              verticalAlign: "baseline",
                              borderCollapse: "collapse",
                              border: "2px solid black",
                              padding: 5,
                            }}
                            key={ele?.name}
                            colSpan={
                              (i == 0 && 6) ||
                              (i == 1 && 5) ||
                              (i == 2 && 2) ||
                              2
                            }
                          >
                            {ele?.name}
                          </th>
                        );
                      })}
                    </tr>
                  )}

                  <tr>
                    {printData?.length > 0 &&
                      Object.keys(printData[0]).map((key) => (
                        <th
                          style={{
                            color: "#000000",
                            fontSize: 18,
                            lineHeight: 2,
                            fontWeight: 700,
                            fontFamily: '"Poppins", sans-serif',
                            verticalAlign: "baseline",
                            borderCollapse: "collapse",
                            border: "2px solid black",
                            padding: 5,
                          }}
                          key={key}
                        >
                          {key}
                        </th>
                      ))}
                  </tr>

                  {printData?.length > 0 &&
                    printData?.slice(index * 3, 3 * ele)?.map((item, index) => (
                      <tr key={index}>
                        {Object.values(item).map((value, subIndex) => (
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: 13,
                              color: "black",
                              lineHeight: 3,
                              fontWeight: 600,
                              fontFamily: '"Poppins", sans-serif',
                              verticalAlign: "baseline",
                              borderCollapse: "collapse",
                              border: "2px solid black",
                              padding: 5,
                            }}
                            key={subIndex}
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              );
            })}
        </table>
      </>
    </div>
  );
};

export default PrintReport;
