import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getRoleMaster } from "../../../../redux/features/AddService";
import moment from "moment";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";

const RoleMasterList = () => {
  const { getRoleMasterData } = PagesIndex.useSelector((state) => state.admin);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [rowId, setRowId] = useState("");
  const [loader, setLoader] = useState(true);
  const [searchedData, setSearchedData] = useState([]);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  let deleteText = "Role type";

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();

  const handleRoleMasterEdit = (data) => {
    navigate(`/${rollData}/role-master/add`, {
      state: { edit: "Edit", RollEditData: data },
    });
  };

  const handleDeleteOpen = (id) => {
    setRowId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const deleteRollMaster = async () => {
    try {
      const res = await DataService.post(
        `${Api.ADMIN_DELETE_ROLE_MASTER}/${rowId}`
      );

      if (res?.data?.status == 200) {
        toast.success(res?.data?.message);
        setLoader(false);
        dispatch(getRoleMaster());
      }
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  const handleDeleteRecord = () => {
    deleteRollMaster();

    setDeleteOpen(false);
  };

  useEffect(() => {
    dispatch(getRoleMaster()).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    setSearchedData(getRoleMasterData?.data);
  }, [getRoleMasterData?.data]);
  // search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(getRoleMasterData?.data);
    setSearchedData(
      getRoleMasterData?.data?.filter((item) =>
        item?.roleName.toLowerCase().includes(searched.toLowerCase())
      )
    );
  };

  

  if (rollPermissionData?.permissionArray?.includes("Role Master_view")|| rollPermissionNameData === "admin") {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Role Master
                      </Index.Typography>
                    </Index.Box>
                    {rollPermissionData?.permissionArray?.includes(
                      "Role Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <Index.Box className="mobile-menu-show-hover">
                            <PagesIndex.Link
                              to={`/${rollData}/role-master/add`}
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                            <Index.Box className="hover-tooltip-custom">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="title-tooltip"
                              >
                                {" "}
                                Add Role Master
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollPermissionData?.permissionArray?.includes(
                      "Role Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button  desk-hidden-show">
                          <Index.Button
                            onClick={() =>
                              navigate(`/${rollData}/role-master/add`)
                            }
                            variant="contained"
                          >
                            <Index.ControlPointIcon className="plus-add" />
                            Add Role Master
                          </Index.Button>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="sahay-table">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container role-master-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main barge-table role-master-table"
                  >
                    <Index.TableHead className="role-master-head">
                      <Index.TableRow className="role-master-tr">
                        <Index.TableCell
                          align="left"
                          className="role-master-th"
                        >
                          Parent Role Type
                        </Index.TableCell>
                        <Index.TableCell
                          align="left"
                          className="role-master-th"
                        >
                          Role Type
                        </Index.TableCell>
                        <Index.TableCell
                          align="left"
                          className="role-master-th"
                        >
                          Created Date{" "}
                        </Index.TableCell>
                        <Index.TableCell
                          align="center"
                          className="role-master-th"
                        >
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="role-master-tbody">
                      {pageData?.length ? (
                        pageData?.map((data) => (
                          <Index.TableRow className="role-master-tr">
                            <Index.TableCell
                              align="left"
                              className="role-master-td"
                            >
                              {data?.roleParentName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="role-master-td"
                            >
                              {data?.roleName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="role-master-td"
                            >
                              {moment
                                .utc(data?.createdAt ? data?.createdAt : null)
                                .format("YYYY-MM-DD, h:mm:ss A")}
                            </Index.TableCell>

                            <Index.TableCell
                              align="center"
                              className="role-master-td"
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignContent: "space-around",
                              }}
                            >
                              {rollPermissionData?.permissionArray?.includes(
                                "Role Master_edit"
                              ) || rollPermissionNameData === "admin" ? (
                                <>
                                  <Index.IconButton
                                    onClick={() => handleRoleMasterEdit(data)}
                                  >
                                    <Index.EditIcon className="muiico-edit" />
                                  </Index.IconButton>
                                </>
                              ) : (
                                ""
                              )}
                              {rollPermissionData?.permissionArray?.includes(
                                "Role Master_delete"
                              ) || rollPermissionNameData === "admin" ? (
                                <>
                                  <Index.IconButton
                                    onClick={() =>
                                      handleDeleteOpen(data?.roleId)
                                    }
                                  >
                                    <Index.DeleteIcon className="muiico-delete" />
                                  </Index.IconButton>
                                </>
                              ) : (
                                ""
                              )}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <PagesIndex.Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
};

export default RoleMasterList;
