import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import {
  getSahaylist,
  subSahaylistAdd,
  subSahaylistEdit,
} from "../../../../redux/features/AddService";
import "./Subsahaytype.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Subsahaytype = () => {
  //useselector
  // const getSahaydata = PagesIndex.useSelector(
  //   (state) => state.admin.getSahaydata
  // );

  //navigate dispatch uselocation
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { state } = PagesIndex.useLocation();
  const [subSahayExist, setSubSahayExist] = useState("");
  const [sahaytypeMasterlist, setSahaytypeMasterlist] = useState([]);
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  //get edit data in list
  const editData = state?.row;

  //initialValues
  let initialValues = {
    sahayName: editData ? editData.sahayName : "",
    sahayCategory: editData ? editData?.sahayCategoryId : "",
    sahayForm: editData ? editData?.sahayForm : "",
    isSubmit: false,
  };

  //handle submit
  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    if (editData?.Id) {
      let updatedata = {
        SubSahayId: editData?.Id,
        sahayName: values?.sahayName,
        sahayCategory: values?.sahayCategory,
      };
      dispatch(subSahaylistEdit(updatedata)).then((res) => {
        if (res?.payload == 200) {
          navigate(`/${rollData}/subtype-master`);
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
        if (res?.payload?.status == 409) {
          setSubSahayExist(res?.payload?.message);
        } else {
          setSubSahayExist("");
        }
      });
    } else {
      dispatch(subSahaylistAdd(values)).then((res) => {
        if (res?.payload == 201) {
          navigate(`/${rollData}/subtype-master`);
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
        if (res?.payload?.status == 409) {
          setSubSahayExist(res?.payload?.message);
        } else {
          setSubSahayExist("");
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getSahaylist()).then((res) => {
      if (res?.payload?.status === 200) {
        setSahaytypeMasterlist(res?.payload?.data);
      }
    });
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
  rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes(
      "Sub Sahay Type Master_add"
    ) ||
    rollPermissionData?.permissionArray?.includes("Sub Sahay Type Master_edit")|| rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {state ? state?.edit : "Add"} Sub Sahay Type
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaSubSahayTypeMaster}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              handleBlur,
              touched,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Sahay Type*
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.sahayCategory}
                          name="sahayCategory"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {sahaytypeMasterlist?.map((datas) => (
                            <Index.MenuItem
                              key={datas?.Id}
                              // name={datas?.Sahay_category}
                              value={datas?.Id}
                            >
                              {datas?.Sahay_category}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>

                        <Index.FormHelperText></Index.FormHelperText>
                      </Index.Box>
                      {errors?.sahayCategory && touched.sahayCategory && (
                        <p className="error-text">{errors?.sahayCategory}</p>
                      )}
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Sub Sahay Type*
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          name="sahayName"
                          value={values.sahayName}
                          hiddenLabel
                          onBlur={handleBlur}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue == "") {
                              setSubSahayExist("");
                            }
                            setFieldValue("sahayName", inputValue);
                          }}
                          id="filled-hidden-label-normal"
                          placeholder="Enter Sub Sahay Type"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          autoComplete="off"
                        />
                      </Index.Box>
                      {errors?.sahayName && touched.sahayName && (
                        <p className="error-text">{errors?.sahayName}</p>
                      )}
                      {subSahayExist !== "" && values?.sahayName !== "" && (
                        <p className="error-text">{subSahayExist}</p>
                      )}
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={12} md={3} lg={3}>
                      <Index.Box
                        // mt={3}
                        // ml={3}
                        className="common-button blue-button flex-start save-btn custom-space-details text-btn-end-ipad"
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disabled={values.isSubmit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Subsahaytype;
