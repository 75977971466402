import React, { useEffect, useState } from "react";
import Index from "../../Index";
import "../../../container/admin/pages/dashboardLayout/dashboard.css";
import "../../../container/admin/pages/dashboardLayout/dashboard.responsive.css";
import PagesIndex from "../../../container/PagesIndex";
import { imagePath } from "../../../config/DataService";
import { userLogout } from "../../../redux/features/adminslice/AdminSlice";
import { getUserProfileData } from "../../../redux/features/AddService";

const UserDashboardLayout = () => {
  const { getUserLoginDetails, userProfileGet } = PagesIndex.useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    let data = {
      vastipatrak: getUserLoginDetails?.vastipatrak,
    };
    dispatch(getUserProfileData(data));
  }, []);

  //all state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  const open2 = Boolean(anchorEl);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    document.body.classList["add"]("menu-set-main");
  };

  const handleClose2 = () => {
    setAnchorEl(null);
    document.body.classList["remove"]("menu-set-main");
  };

  useEffect(() => {
    document.body.classList[open ? "add" : "remove"]("body-no-class");
  }, [open]);

  //logout
  const handleLogout = () => {
    navigate("/");
    PagesIndex.toast.success("Logged out");
    dispatch(userLogout());
    localStorage?.removeItem("RollName");
  };

  return (
    <div className="main-dashboard">
      <Index.Box
        className={`${open ? "overlay" : ""}`}
        onClick={() => setOpen((e) => !e)}
      ></Index.Box>
      <Index.Box className="right-dashboard-content">
        <header>
          <Index.Box className={`main-header ${!open ? "" : "pl-none"}`}>
            <Index.Box className="head-right">
              {/* <img src={PagesIndex.Svg.bell} className="bell-icon" /> */}
            </Index.Box>

            <Index.Box className="admin-header-right">
              <Index.Box className="admin-header-drop-main">
                <Index.Button
                  className="drop-header-btn"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick2}
                >
                  <Index.Box className="head-right">
                    <Index.Box className="header-user-detail">
                      <Index.Typography variant="p" className="head-user-title">
                        {userProfileGet
                          ? userProfileGet?.firstName
                            ? userProfileGet?.firstName
                            : getUserLoginDetails?.mem_fname
                          : getUserLoginDetails?.mem_fname}
                      </Index.Typography>
                      <Index.Typography variant="p" className="head-user-mail">
                        {userProfileGet
                          ? userProfileGet?.mobile
                          : getUserLoginDetails?.mem_mobile}
                      </Index.Typography>
                    </Index.Box>
                    <img
                      alt="checkheader"
                      src={
                        userProfileGet?.image === null
                          ? PagesIndex.Png.usericon
                          : `${imagePath}${userProfileGet?.image}`
                      }
                      className="headprofile"
                    />
                  </Index.Box>
                </Index.Button>
              </Index.Box>
              <Index.Menu
                className="drop-header-menu admin-header-profile-ul"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <PagesIndex.Link to="/user/setting">
                  <Index.MenuItem
                    onClick={handleClose2}
                    className="drop-header-menuitem"
                  >
                    {" "}
                    <img
                      src={PagesIndex.Svg.profilegrey}
                      className="drop-header"
                    />{" "}
                    Setting
                  </Index.MenuItem>
                </PagesIndex.Link>
                {/* <PagesIndex.Link to="/user/update-profile">
                  <Index.MenuItem
                    onClick={handleClose2}
                    className="drop-header-menuitem"
                  >
                    {" "}
                    <img
                      src={PagesIndex.Svg.profilegrey}
                      className="drop-header"
                    />{" "}
                    Profile
                  </Index.MenuItem>
                </PagesIndex.Link> */}

                <Index.MenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                  className="drop-header-menuitem logout-profile"
                >
                  {" "}
                  <img src={PagesIndex.Svg.edit} className="drop-header" />{" "}
                  Logout
                </Index.MenuItem>
              </Index.Menu>
            </Index.Box>
          </Index.Box>
        </header>
        <Index.Box
          className={`admin-panel-content user-panel-content  ${
            !open ? "" : "pl-none"
          }`}
        >
          <PagesIndex.Outlet />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default UserDashboardLayout;
