import PagesIndex from "../../../container/PagesIndex";
import Index from "../../Index";

const PageLoading = (props) => (
  // <Index.TableRow>
  //   <Index.TableCell colSpan={props.colSpan} className="border-bottom-loader">
      <Index.Box className="loading-spinner">
        <PagesIndex.RingLoader color="#003d90" loading="loading" size={50} />
      </Index.Box>
  //   </Index.TableCell>
  // </Index.TableRow>
);

export default PageLoading;
