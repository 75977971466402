import React from "react";
import Index from "../../Index";

const RowPerPageDropdown = ({rowPerPage,setRowPerPage}) => {

    const handleChangePagination = (event) => {
        setRowPerPage(event.target.value);
    };
  return (
    <>
      <Index.Box className="dropdown-box pagination-dropdown-box">
        <Index.FormControl className="form-control pagination-form-control">
          <Index.Select
            className="dropdown-select pagination-select"
            value={rowPerPage}
            onChange={handleChangePagination}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <Index.MenuItem value={"15"} className="pagination-menuitem">
              15
            </Index.MenuItem>
            <Index.MenuItem value={"25"} className="pagination-menuitem">
              25
            </Index.MenuItem>
            <Index.MenuItem value={"35"} className="pagination-menuitem">
              35
            </Index.MenuItem>
            <Index.MenuItem value={"All"} className="pagination-menuitem">
              All
            </Index.MenuItem>
          </Index.Select>
        </Index.FormControl>
      </Index.Box>
    </>
  );
};

export default RowPerPageDropdown;
