import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./Cms.css";
import { updateCMSTermsAndCondition } from "../../../../redux/features/AddService";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";

const TermsCondition = () => {
  // const { getTermsAndCon } = PagesIndex.useSelector((state) => state.admin);
  const dispatch = PagesIndex.useDispatch();

  const [getTermConditionData, setGetTermConditionData] = useState({});
   const [loader, setLoader] = useState(true);
  let intialValues = {
    title: getTermConditionData[0]?.title ? getTermConditionData[0]?.title : "",
    description: getTermConditionData[0]?.description
      ? getTermConditionData[0]?.description
      : "",
    isSubmit: false,
  };
  const getCMSList = async () => {
    try {
      const res = await DataService.get(Api.GET_CMS_LIST);
setLoader(false)
      let getPrivacyPolicy = res?.data?.data?.filter((ele) => {
        return ele?.type == "termsAndCondition";
      });
      setGetTermConditionData(getPrivacyPolicy);
    } catch (error) {
      // toast.error(error?.res?.data.message || error.message);
    }
  };

  useEffect(() => {
    getCMSList();
  }, []);
 
  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    let data = {
      id: getTermConditionData[0]?.id,
      title: values.title,
      description: values.description,
      type: "termsAndCondition",
    };
    dispatch(
      updateCMSTermsAndCondition(data, getTermConditionData[0]?.id)
    ).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false)
        getCMSList();
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  return (
    <>

    {loader ? (
      <PageLoading />
    ) : (
    <>
      {" "}
      <Index.Typography className="admin-page-title" variant="h5">
        Terms & Condition
      </Index.Typography>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={intialValues}
        validationSchema={PagesIndex.validationSchemaAdminCmsTermsAndCondition}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleFocus,
        }) => (
          <form onSubmit={handleSubmit}>
            <Index.Box mt={3} className="barge-common-box">
              <Index.Box className="cms">
                <Index.Box className="grid-row cms-page-row">
                  <Index.Box className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 5",
                          md: "span 4",
                          lg: "span 10",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Title*
                          </Index.Typography>
                          <Index.Box className="cms-input-box">
                            <Index.TextField
                              size="small"
                              fullWidth
                              id="title"
                              className="form-control"
                              placeholder="Enter title"
                              name="title"
                              value={values?.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Index.Box>
                          {errors?.title && touched.title && (
                            <p className="error-text">{errors?.title}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 10",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box" mt={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Description*
                          </Index.Typography>
                          <Index.Box className="form-group">
                            <PagesIndex.CKEditor
                              editor={PagesIndex?.ClassicEditor}
                              data={values?.description}
                              name="description"
                              // onBlur={handleBlur}
                              onChange={(event, editor) => {
                                const data = editor?.getData();
                                setFieldValue("description", data);
                              }}
                            />
                          </Index.Box>
                          {errors?.description && touched?.description && (
                            <p className="error-text">{errors?.description}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  mt={5}
                  className="save-btn-main border-btn-main res-set-search"
                >
                  <Index.Button
                    color="primary"
                    variant="contained"
                    className="save-user-btn border-btn"
                    type="submit"
                    disabled={values.isSubmit}
                  >
                    Update
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </form>
        )}
      </PagesIndex.Formik>
    </>
   )}
   </>
  );
};

export default TermsCondition;
