import PagesIndex from "../container/PagesIndex";

// ------Client live-------
// export const API_ENDPOINT = "https://api-sahay.patanmandal.org/api/";
// export const imagePath = "https://api-sahay.patanmandal.org/uploads/";
// // ------Client live-------

// ------Demo Client live-------
// export const API_ENDPOINT = "https://t5ks9tg3-3030.inc1.devtunnels.ms/api/";
// export const imagePath = "https://t5ks9tg3-3030.inc1.devtunnels.ms/uploads/";
// ------Client live-------
// // ------Demo Client live-------
export const API_ENDPOINT = "https://api-sahay.appworkdemo.com/api/";
export const imagePath = "https://api-sahay.appworkdemo.com/uploads/";
// // ------Client live-------


const dataService = PagesIndex.axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    config.headers.Authorization = `Bearer ${token}`;
    return config;
    // Authorization: `Bearer ${data.token}`,
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default dataService;
